import axios, { AxiosResponse } from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
})

export const authAPI = {
    login(data: LoginParamsType) {
        return instance.post<LoginParamsType, AxiosResponse<LoginResponseType>>('api/token/', data)
    },
    me(token: MeParamsType) {
        return instance.post<MeParamsType, AxiosResponse<MeResponseType>>('api/token/verify/', token)
    },
    refresh(refresh: RefreshParamsType) {
        return instance.post<AccessParamsType>('/api/token/refresh/', refresh)
    }
}

//types
export type LoginParamsType = {
    username: string
    password: string
}

export type LoginResponseType = {
    refresh: string
    access: string
    first_name: string,
    last_name: string,
    username: string | null,
    photo: string,
    role: string
}

export type MeParamsType = {
    token: string
}

export type RefreshParamsType = {
    refresh: string
}

export type AccessParamsType = {
    access: string
}

export type MeResponseType = {
    detail: string
    code: string
}
