import { Dispatch } from "redux";
import { HolidayResponseType, holidaysAPI, HolidaysType } from "../api/holidays-api";
import { loadToken } from "../utils/localStorage";
import { setAppStatusAC } from "./app-reducer";
import { handleServerAppError, handleServerNetworkError } from "../utils/error-utils";
import { AppRootStateType } from "./store";


const SET_LIST_HOLIDAYS = "holidaysReducer/GET_LIST_HOLIDAYS";
const ADD_HOLIDAY = "holidaysReducer/ADD_HOLIDAY";
const EDIT_HOLIDAY = "holidaysReducer/EDIT_HOLIDAY";
const REMOVE_HOLIDAY = "holidaysReducer/REMOVE_HOLIDAY";
const JOIN_HOLIDAY = "holidaysReducer/JOIN_HOLIDAY";
const LEAVE_HOLIDAY = "holidaysReducer/LEAVE_HOLIDAY";

const initialState: Array<HolidaysType> = []

export const holidaysReducer = (
    state: Array<HolidaysType> = initialState,
    action: ActionsType
): Array<HolidaysType> => {
    switch (action.type) {
        case SET_LIST_HOLIDAYS: {
            return action.holidays.map((tl) => ({ ...tl })) 
        }
        case JOIN_HOLIDAY: {
            return state.map(hol => hol.id === action.branchId
                ? {
                    ...hol,
                    official: [...hol.official, action.officialHoliday],
                    suggestion: hol.suggestion.filter(el => el.id !== action.officialHoliday.id)
                }
                : hol)
        }
        case LEAVE_HOLIDAY: {
            const officialsHol = state.find(hol => hol.id === action.branchId)
            const leavedHol = officialsHol && officialsHol.official
                .find(el => el.id === action.holidayId)
            if (leavedHol) {
                return state.map(hol => hol.id === action.branchId ?
                    {
                        ...hol,
                        official: hol.official.filter(el => el.id !== action.holidayId),
                        suggestion: [...hol.suggestion, leavedHol]
                    }
                    : hol)
            } else {
                return state.map(hol => hol.id === action.branchId ?
                    {
                        ...hol,
                        official: hol.official.filter(el => el.id !== action.holidayId),
                    }
                    : hol)
            }
        }
        case ADD_HOLIDAY:
            return state.map(hol => hol.id === action.branchId
                ? { ...hol, private: [...hol.private, action.privateHoliday] }
                : hol)

        case EDIT_HOLIDAY: {
            return state.map((hol) =>
                hol.id === action.model.id
                    ? {
                        ...hol, private: hol.private.map(pr => pr.id === action.model.id
                            ? { ...pr, ...action.model }
                            : pr)
                    }
                    : hol
            );
        }
        case REMOVE_HOLIDAY:
            return state.map(hol => hol.id === action.currentBranchId ?
                { ...hol, private: hol.private.filter(el => el.id !== action.holidayId) }
                : hol)
        default:
            return state;
    }
};

// actions
export const addHolidayAC = (branchId: number, privateHoliday: HolidayResponseType) =>
    ({ type: ADD_HOLIDAY, branchId, privateHoliday, } as const);
export const setListHolidaysAC = (holidays: Array<HolidaysType>) =>
    ({ type: SET_LIST_HOLIDAYS, holidays } as const);
export const joinHolidaysAC = (branchId: number, officialHoliday: HolidayResponseType) =>
    ({ type: JOIN_HOLIDAY, branchId, officialHoliday } as const);
export const leaveHolidaysAC = (branchId: number, holidayId: number) =>
    ({ type: LEAVE_HOLIDAY, holidayId, branchId } as const);
export const editHolidayAC = (model: PrivateHolidayUpdateModelType) => ({ type: EDIT_HOLIDAY, model } as const);
export const removeHolidayAC = (currentBranchId: number, holidayId: number) =>
({ type: REMOVE_HOLIDAY, currentBranchId, holidayId } as const);

// thunks
export const fetchHolidaysList =
    () => async (dispatch: Dispatch<any>) => {
        dispatch(setAppStatusAC("loading"))
        try {
            const tokenLS = await loadToken();
            const res = await holidaysAPI.getList(tokenLS);
            dispatch(setListHolidaysAC(res.data));
            dispatch(setAppStatusAC("succeeded"))
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
        dispatch(setAppStatusAC("idle"))
    };


export const joinHolidayTC = (holidayId: number) =>
    async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
        //dispatch(setAppStatusAC("loading"))
        const currentBranch = getState().branches.currentBranch
        try {
            const tokenLS = loadToken();
            const res = await holidaysAPI.joinToHoliday(tokenLS, holidayId, currentBranch);
            dispatch(joinHolidaysAC(currentBranch, res.data));
            dispatch(setAppStatusAC("succeeded"))
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
        dispatch(setAppStatusAC("idle"))
    };

export const leaveHolidayTC =
    (holidayId: number) => async (dispatch: Dispatch<any>,
        getState: () => AppRootStateType) => {
       // dispatch(setAppStatusAC("loading"))
        const currentBranchId = getState().branches.currentBranch
        try {
            const tokenLS = loadToken();
            await holidaysAPI.leaveHoliday(tokenLS, holidayId, currentBranchId);
            dispatch(leaveHolidaysAC(currentBranchId, holidayId));
            dispatch(setAppStatusAC("succeeded"))
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
        dispatch(setAppStatusAC("idle"))
    };

export const addHolidayTC = (data: any) => async (dispatch: Dispatch<any>,
    getState: () => AppRootStateType) => {
    dispatch(setAppStatusAC("loading"))
    const currentBranchId = getState().branches.currentBranch
    try {
        const tokenLS = await loadToken();
        let res = await holidaysAPI.addHoliday(data, tokenLS);
        await dispatch(addHolidayAC(currentBranchId, res.data));
        await dispatch(setAppStatusAC("succeeded"));
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
};

export const editHolidayTC =
    (id: number, data: any) => async (dispatch: Dispatch<any>) => {
        dispatch(setAppStatusAC("loading"))
        try {
            const tokenLS = await loadToken();
            let res = await holidaysAPI.editHoliday(data, tokenLS, id);
            dispatch(editHolidayAC(res.data));
            dispatch(setAppStatusAC("succeeded"));
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
    };

export const removeHolidayTC =
    (holidayId: number) => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
        dispatch(setAppStatusAC("loading"))
        const currentBranchId = getState().branches.currentBranch
        try {
            const tokenLS = loadToken();
            await holidaysAPI.removeHoliday(tokenLS, holidayId);
            dispatch(removeHolidayAC(currentBranchId, holidayId));
            dispatch(setAppStatusAC("succeeded"))
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
        dispatch(setAppStatusAC("idle"))
    };

//types
type ActionsType =
    | ReturnType<typeof setListHolidaysAC>
    | ReturnType<typeof addHolidayAC>
    | ReturnType<typeof editHolidayAC>
    | ReturnType<typeof removeHolidayAC>
    | ReturnType<typeof joinHolidaysAC>
    | ReturnType<typeof leaveHolidaysAC>

export type PrivateHolidayUpdateModelType = {
    id?: number;
    owner?: number;
    owner_display?: string;
    branches?: [];
    name?: string;
    date?: string;
    start_time?: string;
    end_time?: string;
    official?: boolean;
};
