import { FC, useState } from "react";
import "./Form.scss";
import Selects from "../../components/Widgets/Selects";


export const Form: FC = () => {
    const [value, setValue] = useState<string>()
    const categoryChoices = [
        { label: 'Cars' },
        { label: 'Boards' },
        { label: 'Wheels' },
    ]

    return (
        <div className="form">
            <Selects />
        </div>
    )
}
