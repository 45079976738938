import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, NavLink } from "react-router-dom";
import { fetchEmployeesListTC, removeEmployeeTC } from "../../redux/employees-reducer";
import { PATH } from "../Routes";
import './Employees.scss'
import { ModalAlertDelete } from "../../components/ModalsAlerts/ModalAlertDelete";
import { ProfileSideBar } from "../Profile/ProfileSideBar/ProfileSideBar";
import { initializeAppTC } from "../../redux/app-reducer";
import Preloader from "../Preloader/Preloader";
import {
    getEmployeesSelector,
    getIsLoggedInSelector,
    getStatusSelector
} from "../../redux/selectors";

export const Employees = () => {
    const dispatch = useDispatch();

    const employees = useSelector(getEmployeesSelector);
    const status = useSelector(getStatusSelector);
    const isLoggedIn = useSelector(getIsLoggedInSelector);

    //dialog alerts
    const [openAlertDialogForDelete, setOpenAlertDialogForDelete] = useState(false);
    const [modalDataId, setModalDataId] = useState<number>(0);

    useEffect(() => {
        if (status === "failed") {
            dispatch(initializeAppTC())
        }
    }, [status])

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchEmployeesListTC())
        }
    }, [dispatch])

    const removeEmployee = (id: number) => {
        dispatch(removeEmployeeTC(id))
        setModalDataId(0)
    }

    const openAlertHandler = (employeeId: number) => {
        setModalDataId(employeeId)
        setOpenAlertDialogForDelete(true)
    }
    const closeAlertHandler = () => {
        setOpenAlertDialogForDelete(false)
    }

    if (!isLoggedIn) {
        return <Navigate to={PATH.LOGIN} />
    }

    return (
        <>
            <ProfileSideBar />
            <div className="employees">
                <h1 className="employees__title">Employee list</h1>
                <div className="employees__add">
                    <NavLink
                        className="button button_size-small button_position-left button_color-transparent"
                        to={PATH.ADD_EMPLOYEE}>+ add
                        employee</NavLink>
                </div>
                {status === 'loading' ? (
                    <Preloader />
                ) : (
                    <div>
                        <table className='employees__table'>
                            <thead className="thead">
                                <tr className="tr">
                                    <th className="th"><span>{'Vorname'}</span></th>
                                    <th className="th"><span>{'Login'}</span></th>
                                    <th className="th"><span>{'Optionen'}</span></th>
                                </tr>
                            </thead>
                            <tbody className="tbody">
                                {employees.map(employee => <tr key={employee.id}
                                    className="tr">
                                    <td className="td">{employee.first_name} {employee.last_name}</td>
                                    <td className="td">{employee.username}</td>
                                    <td className="td">
                                        <div className="btns">
                                            <NavLink
                                                className="button button_size-small  button_color-green"
                                                to={`${PATH.EMPLOYEES}${employee.id}/`}>Edit</NavLink>
                                            <button
                                                onClick={() => openAlertHandler(employee.id)}
                                                className="button button_size-small  button_color-delete"
                                            >Delete
                                            </button>
                                        </div>
                                    </td>

                                </tr>)
                                }
                                {openAlertDialogForDelete &&
                                    <>
                                        <ModalAlertDelete deleteCallback={removeEmployee}
                                            id={modalDataId}
                                            titleQuestion="Delete employee?"
                                            actionTitle="Delete"
                                            cancelCallback={closeAlertHandler} />
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                )
                }
            </div>
        </>
    )
}



