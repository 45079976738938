import {Dispatch} from "redux";
import {clientsAPI, ClientsLinksType, ClientsType} from "../api/clients-api";
import {loadToken} from "../utils/localStorage";
import {setAppStatusAC} from "./app-reducer";
import {AppRootStateType} from "./store";
import {handleServerAppError, handleServerNetworkError} from "../utils/error-utils";
import {EventsLinksType} from "../api/events-api";


const SET_CLIENTS_LIST = 'clientsReducer/SET_CLIENTS_LIST'
const ADD_CLIENT = 'clientsReducer/ADD_CLIENT'
const EDIT_CLIENT = 'clientReducer/EDIT_CLIENT'
const REMOVE_CLIENT = 'clientsReducer/REMOVE_CLIENTS'
const SET_CLIENTS_PARAMS = 'clientsReducer/SET_CLIENTS_PARAMS'
const SET_APPEARED = 'clientsReducer/SET_APPEARED'
const SET_CURRENT_PAGE = 'clientsReducer/SET_CURRENT_PAGE'

const initialState: InitialStateType = {
    currentPage: null,
    clientsData: {
        links: {
            next: null,
            previous: null,
        },
        count: 0,
        current_page: 0,
        lastPage: 0,
        current: 0,
        results: [] as Array<ClientsType>
    }
}

type InitialStateType = {
    currentPage: string | null
    clientsData: {
        links: EventsLinksType
        count: number
        current_page: number
        lastPage: number
        current: number
        results: Array<ClientsType>
    }
}


export const clientsReducer = (state: InitialStateType = initialState, action: ActionsType): InitialStateType => {
    switch (action.type) {
        case SET_CLIENTS_PARAMS: {
            return {...state, clientsData: {...state.clientsData, ...action.module}}
        }
        case SET_CLIENTS_LIST: {
            return {
                ...state,
                clientsData: {
                    ...state.clientsData,
                    results: action.clients.map((tl) => ({...tl}))
                }
            }
        }
        case ADD_CLIENT: {
            return {
                ...state, clientsData: {
                    ...state.clientsData,
                    results: [action.model, ...state.clientsData.results]
                }
            }
        }
        case EDIT_CLIENT: {
            return {
                ...state,
                clientsData: {
                    ...state.clientsData,
                    results: state.clientsData.results.map(e => e.id === action.model.id
                        ? {...e, ...action.model}
                        : e)
                }
            }
        }
        case REMOVE_CLIENT: {
            return {
                ...state, clientsData: {
                    ...state.clientsData,
                    results: state.clientsData.results.filter(e => e.id !== action.id)
                }
            }
        }

        case SET_APPEARED: {
            return {
                ...state, clientsData: {
                    ...state.clientsData,
                    results: state.clientsData.results.map(e => e.id === action.model.id
                        ? {...action.model}
                        : e)
                }
            }
        }

        case SET_CURRENT_PAGE: {
            return {
                ...state, currentPage: action.page
            }
        }

        default:
            return state
    }
}

// actions
export const setClientsParamsAC = (module: ClientsParamsType) =>
    ({type: SET_CLIENTS_PARAMS, module} as const)
export const setClientsListAC = (clients: Array<ClientsType>) =>
    ({type: SET_CLIENTS_LIST, clients} as const)
export const addClientAC = (model: ClientsType) =>
    ({type: ADD_CLIENT, model} as const)
export const editClientAC = (model: UpdateClientsModelType) =>
    ({type: EDIT_CLIENT, model} as const)
export const removeClientAC = (id: number) =>
    ({type: REMOVE_CLIENT, id} as const)
export const setAppearedAC = (model: ClientsType) =>
    ({type: SET_APPEARED, model} as const)
export const setCurrentClientsPageAC = (page: string | null) =>
    ({type: SET_CURRENT_PAGE, page} as const);

// thunks
export const fetchClientsList = () => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        let res = await clientsAPI.getList(tokenLS)
        dispatch(setClientsParamsAC(res.data))
        dispatch(setClientsListAC(res.data.results))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

export const fetchAppearedTC = (clientId: number) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        const res = await clientsAPI.getAppeared(tokenLS, clientId)
        dispatch(setAppearedAC(res.data))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

export const addClientTC = (data: any) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        const res = await clientsAPI.addClient(data, tokenLS)
        dispatch(addClientAC(res.data))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
}

export const editClientTC = (id: number, data: any) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        const res = await clientsAPI.editClient(data, tokenLS, id)
        dispatch(editClientAC(res.data))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
}

export const removeClientTC = (clientId: number) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        await clientsAPI.removeClient(tokenLS, clientId)
        dispatch(removeClientAC(clientId))
        fetchClientsList()
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
}

export const getNextClientsList = () => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const next = getState().clients.clientsData.links.next
    dispatch(setAppStatusAC('loading'))
    try {
        const tokenLS = await loadToken()
        const res = await clientsAPI.getNextList(tokenLS, next)
        dispatch(setClientsParamsAC(res.data))
        dispatch(setClientsListAC(res.data.results))
        dispatch(setCurrentClientsPageAC(next))
        dispatch(setAppStatusAC('succeeded'))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

export const getPrevClientsList = () => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const prev = getState().clients.clientsData.links.previous
    dispatch(setAppStatusAC('loading'))
    try {
        const tokenLS = await loadToken()
        const res = await clientsAPI.getPrevList(tokenLS, prev)
        dispatch(setClientsParamsAC(res.data))
        dispatch(setClientsListAC(res.data.results))
        dispatch(setCurrentClientsPageAC(prev))
        dispatch(setAppStatusAC('succeeded'))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

export const getCurrentClientsList = () => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const curr = getState().clients.currentPage
    dispatch(setAppStatusAC('loading'))
    try {
        const tokenLS = await loadToken()
        const res = await clientsAPI.getPrevList(tokenLS, curr)
        dispatch(setClientsParamsAC(res.data))
        dispatch(setClientsListAC(res.data.results))
        dispatch(setCurrentClientsPageAC(curr))
        dispatch(setAppStatusAC('succeeded'))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

//types
type ActionsType = ReturnType<typeof setClientsListAC>
    | ReturnType<typeof addClientAC>
    | ReturnType<typeof removeClientAC>
    | ReturnType<typeof editClientAC>
    | ReturnType<typeof setClientsParamsAC>
    | ReturnType<typeof setAppearedAC>
    | ReturnType<typeof setCurrentClientsPageAC>

export type UpdateClientsModelType = {
    id?: number
    organization?: any
    first_name?: string
    last_name?: string
    insurance_type?: string
    email?: string
    phone?: string
    blocked?: any
    not_appeared?: number
    branches?:any
}

export type ClientsParamsType = {
    links: ClientsLinksType
    count: number
    current_page: number
    lastPage: number
    current: number
}
