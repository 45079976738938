import React, { FC, useState } from "react";
import "./Calendar.scss"
import Calendar, { Detail } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { logo } from "../../assets/img/image";
import dayjs from "dayjs";

type Calendar = {
    setDate?: (date: Date) => void
    onPrevClick?: () => void
    onNextClick?: () => void
    branchId?: number
    setChosenWeekInCalendar?: (branchId: number, week: string) => void
    onDayClick?: () => void
    onDayClickCustom?: (date: string) => void
    onTimeClick2?: (time: string) => void
    disabled?: boolean
    disabledCallback?: (activeStartDate: Date, date: Date, view: Detail) => boolean
}

export const CalendarGeneral: FC<Calendar> = (
    {
        setDate, onPrevClick,
        onNextClick, branchId,
        setChosenWeekInCalendar, onDayClick, onDayClickCustom,
        disabled, disabledCallback, onTimeClick2,
    }) => {

    const [startDate, setStartDate] = useState(new Date());
    const [actionState, setActionState] = useState("");

    const onDateClick = (date: Date) => {
        setStartDate(date)

        setDate && setDate(date)

        if (setChosenWeekInCalendar && branchId) {
            setChosenWeekInCalendar(branchId, dayjs(date).format('YYYY-MM-DD'))

            onDayClick && onDayClick()
        }
        onDayClickCustom && onDayClickCustom(dayjs(date).format('YYYY-MM-DD'))
    }

    return (
        <div onClick={(e) => e.stopPropagation()}>


            <Calendar

                showFixedNumberOfWeeks={true}
                onChange={onDateClick}
                onActiveStartDateChange={
                    ({ action }) => {
                        setActionState(action)
                        if (action === "prev") {
                            onPrevClick && onPrevClick()
                        }
                        if (action === "next") {
                            onNextClick && onNextClick()
                        }
                    }
                }
                tileDisabled={disabled && disabledCallback
                    ? ({
                        activeStartDate,
                        date,
                        view
                    }) => disabledCallback(activeStartDate, date, view)

                    : ({ activeStartDate, date, view }) => false}


                value={startDate}
                nextLabel={<img src={logo.arrow_right} alt="next" />}
                prevLabel={<img src={logo.arrow_left} alt="previous" />}
                next2Label={null}
                prev2Label={null}
                //defaultView={'year'}
                 //showWeekNumbers={true}
                //view={"month"}
                
                locale={"en"} />
        </div>
    );
}