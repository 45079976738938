import { Dispatch } from "redux";
import {
    ClientFilterParamsType,
    clientsAPI,
} from "../api/clients-api";
import { loadToken } from "../utils/localStorage";
import { setAppStatusAC } from "./app-reducer";
import { handleServerAppError, handleServerNetworkError } from "../utils/error-utils";
import { setClientsListAC, setClientsParamsAC } from "./clients-reducer";
import { AppRootStateType } from "./store";


const SET_CLIENT_FILTER_PARAMS = 'clientsFilterReducer/SET_CLIENT_FILTER_PARAMS'
const ADD_CURRENT_CLIENTS_FILTER_INSURANCE = 'clientsFilterReducer/ADD_CURRENT_CLIENTS_FILTER_INSURANCE'
const REMOVE_CURRENT_CLIENTS_FILTER_INSURANCE = 'clientsFilterReducer/REMOVE_CURRENT_CLIENTS_FILTER_INSURANCE'
const ADD_CURRENT_CLIENTS_BRANCHES = 'clientsFilterReducer/ADD_CURRENT_CLIENTS_BRANCHES'

const initialState: InitialStateType = {
    currentFilterParams: {
        insurance_type__in: [],
        branches__in: [],
        search: {}
    },

    clientsFilterParamsList: {
        search: {
            help_text: '',
            hidden: false,
            initial_value: null,
            input_type: '',
            label: '',
            required: true,
            type: '',
            widget_type: '',
        },
        email: {},
        full_name: {},
        insurance_type__in: { choices: {} },
        branches__in: { choices: {} },
        phone: {},
    }

}

type InitialStateType = {
    currentFilterParams: {
        insurance_type__in: Array<string>
        branches__in: Array<string>
        search: {}
    }

    clientsFilterParamsList: {
        search: {}
        email: {}
        full_name: {}
        insurance_type__in: { choices: {} }
        branches__in: { choices: {} }
        phone: {}
    }
}


export const clientsFilterReducer = (state: InitialStateType = initialState, action: ActionsType): InitialStateType => {
    switch (action.type) {
        case SET_CLIENT_FILTER_PARAMS: {
            return { ...state, clientsFilterParamsList: action.params }
        }
        case ADD_CURRENT_CLIENTS_FILTER_INSURANCE: {
            return {
                ...state,
                currentFilterParams: {
                    ...state.currentFilterParams,
                    insurance_type__in: [...state.currentFilterParams.insurance_type__in, action.params],
                    /*  branches__in: [action.params]  */
                }
            }
        }
        case ADD_CURRENT_CLIENTS_FILTER_INSURANCE: {
            return {
                ...state,
                currentFilterParams: {
                    ...state.currentFilterParams,
                    insurance_type__in: [...state.currentFilterParams.insurance_type__in, action.params],
                    branches__in: [action.params]
                }
            }
        }
        case REMOVE_CURRENT_CLIENTS_FILTER_INSURANCE: {
            return {
                ...state,
                currentFilterParams: {
                    ...state.currentFilterParams,
                    insurance_type__in: state.currentFilterParams.insurance_type__in
                        .filter(insurance => insurance !== action.params)
                }
            }
        }

        case ADD_CURRENT_CLIENTS_BRANCHES: {
            return {
                ...state,
                currentFilterParams: {
                    ...state.currentFilterParams,
                    branches__in: [action.params]
                }
            }
        }

        default:
            return state
    }
}

// actions
export const setClientFilterParamsAC = (params: ClientFilterParamsType) =>
    ({ type: SET_CLIENT_FILTER_PARAMS, params } as const);
export const addCurrentClientsFilterInsuranceAC = (params: string) =>
    ({ type: ADD_CURRENT_CLIENTS_FILTER_INSURANCE, params } as const);
export const removeCurrentClientsFilterInsuranceAC = (params: string) =>
    ({ type: REMOVE_CURRENT_CLIENTS_FILTER_INSURANCE, params } as const);
export const setCurrentParamBranchAC = (params: string) =>
    ({ type: ADD_CURRENT_CLIENTS_BRANCHES, params } as const)

// thunks
export const fetchFilteredClientsParamsListTC =
    () => async (dispatch: Dispatch<any>) => {
        dispatch(setAppStatusAC("loading"));
        try {
            const tokenLS = await loadToken();
            const res = await clientsAPI.getFilteredParamsClientsList(tokenLS);
            dispatch(setClientFilterParamsAC(res.data));
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
        dispatch(setAppStatusAC("idle"))
    };

export const setFilteredClientsOfInsuranceTC = (currentInsurance: string) =>
    async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {

        const currentInsuranceChecked = getState().clientsFilter.currentFilterParams.insurance_type__in
            .find(insurance => insurance === (currentInsurance))
        try {
            if (currentInsuranceChecked) {
                dispatch(removeCurrentClientsFilterInsuranceAC(currentInsurance))
            } else {
                dispatch(addCurrentClientsFilterInsuranceAC(currentInsurance))
            }
            dispatch(fetchFilteredClientsListOfGeneral())
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
    };

export const fetchFilteredClientsListOfGeneral =
    () => async (dispatch: Dispatch<any>,
        getState: () => AppRootStateType) => {
        const insuranceOrdering = getState().clientsFilter.currentFilterParams.insurance_type__in
        const branchOrdering = getState().clientsFilter.currentFilterParams.branches__in
        dispatch(setAppStatusAC("loading"));
        try {
            const tokenLS = await loadToken();
            const res = await clientsAPI.getFilteredListOfInsurance(tokenLS,
                insuranceOrdering, branchOrdering);

            dispatch(setClientsParamsAC(res.data));
            dispatch(setClientsListAC(res.data.results));
            dispatch(setAppStatusAC("succeeded"));
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
        dispatch(setAppStatusAC("idle"))
    };

export const fetchFilteredClientsListOfBranchTC = (branchId: any) => async (dispatch: Dispatch<any>,
    getState: () => AppRootStateType) => {
    const insuranceOrdering = getState().clientsFilter.currentFilterParams.insurance_type__in
    const branchOrdering = getState().clientsFilter.currentFilterParams.branches__in
        .find(br => br === (branchId))
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        let res = await clientsAPI.getFilteredListOfBranches(tokenLS, branchId)
        dispatch(setCurrentParamBranchAC(branchId))
        dispatch(setClientsParamsAC(res.data));
        dispatch(setClientsListAC(res.data.results));
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

export const searchOfFullNameClientsTC =
    (name: string) => async (dispatch: Dispatch<any>) => {
        dispatch(setAppStatusAC("loading"));
        try {
            const tokenLS = await loadToken();
            const res = await clientsAPI.searchOfFullName(tokenLS, name);
            dispatch(setClientsParamsAC(res.data));
            dispatch(setClientsListAC(res.data.results));
            dispatch(setAppStatusAC("succeeded"));
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
        dispatch(setAppStatusAC("idle"))
    };

//types
type ActionsType = ReturnType<typeof setClientFilterParamsAC>
    | ReturnType<typeof addCurrentClientsFilterInsuranceAC>
    | ReturnType<typeof removeCurrentClientsFilterInsuranceAC>
    | ReturnType<typeof setCurrentParamBranchAC>