import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, NavLink } from "react-router-dom";
import { PATH } from "../Routes";
import './Reasons.scss'
import {
    ModalAlertDelete
} from "../../components/ModalsAlerts/ModalAlertDelete";
import { fetchReasonsList, removeReasonTC } from "../../redux/reasons-reducer";
import { fetchBranchesList } from "../../redux/branches-reducer";
import { ProfileSideBar } from "../Profile/ProfileSideBar/ProfileSideBar";
import { initializeAppTC } from "../../redux/app-reducer";
import Preloader from "../Preloader/Preloader";
import {
    getCurrentBranchSelector, getIsLoggedInSelector,
    getReasonsSelector,
    getStatusSelector
} from "../../redux/selectors";

export const Reasons = () => {
    const dispatch = useDispatch()

    const reasons = useSelector(getReasonsSelector);
    const currentBranch = useSelector(getCurrentBranchSelector);
    const status = useSelector(getStatusSelector);
    const isLoggedIn = useSelector(getIsLoggedInSelector);

    const currentReason = reasons.length && reasons.find((reason) => reason.id === currentBranch)
    const currentDoctors = currentReason && currentReason.doctors

    //dialog alerts
    const [openAlertDialogForDelete, setOpenAlertDialogForDelete] = useState(false)
    const [modalDataId, setModalDataId] = useState<number>(0);

    useEffect(() => {
        if (status === "failed") {
            dispatch(initializeAppTC())
        }
    }, [status])

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchReasonsList())
            dispatch(fetchBranchesList())
        }
    }, [dispatch])

    const removeReason = (id: number) => {
        dispatch(removeReasonTC(id))
        setModalDataId(0)
    }

    const openAlertHandler = (employeeId: number) => {
        setModalDataId(employeeId)
        setOpenAlertDialogForDelete(true)
    }
    const closeAlertHandler = () => {
        setOpenAlertDialogForDelete(false)
    }


    if (!isLoggedIn) {
        return <Navigate to={PATH.LOGIN} />
    }

    return (
        <>
            <ProfileSideBar />
            <div className="employees">
                <h1 className="employees__title">Reasons list</h1>
                <div className="employees__add">
                    <NavLink
                        className="button button_size-small button_position-left button_color-transparent"
                        to={PATH.ADD_REASON}>+ add
                        reason</NavLink>
                </div>
                {status === 'loading' ? (
                    <Preloader />
                ) : (
                    <div>
                        <table className='employees__table'>
                            <thead className="thead">
                                <tr className="tr">
                                    <th className="th"><span>{'Title'}</span></th>
                                    <th className="th"><span>{'Doctor'}</span></th>
                                    <th className="th"><span>{'Termindauer'}</span></th>
                                    <th className="th"><span>{'Terminanfrage'}</span></th>
                                    <th className="th"><span>{'Optionen'}</span></th>
                                </tr>
                            </thead>
                            <tbody className="tbody">
                                {reasons.map(reasons =>
                                    <tr key={reasons.id} className="tr">
                                        <td style={{
                                            backgroundColor: `${reasons.color}38`,
                                            borderColor: `${reasons.color}`
                                        }}
                                            className="td-color">
                                            <span
                                                style={{
                                                    opacity: 1
                                                }}
                                                className="td">
                                                {reasons.title}
                                            </span>
                                        </td>
                                        <td className="td">
                                            {
                                                reasons.doctors.map(doc =>
                                                    <span

                                                        key={doc.id}>{doc.first_name} {doc.last_name} <br /></span>)
                                            }

                                        </td>
                                        <td className="td">{reasons.offset_time_display}</td>
                                        <td className="td">{reasons.limit_time}</td>
                                        <td className="td">
                                            <div className="btns">
                                                <NavLink
                                                    className="button button_size-small  button_color-green"
                                                    to={`${PATH.REASONS}${reasons.id}`}>Edit</NavLink>

                                                <button onClick={() => openAlertHandler(reasons.id)}
                                                    className="button button_size-small  button_color-delete"
                                                >Delete
                                                </button>
                                            </div>
                                        </td>

                                    </tr>
                                )
                                }
                                {openAlertDialogForDelete &&
                                    <>
                                        <ModalAlertDelete deleteCallback={removeReason}
                                            id={modalDataId}
                                            titleQuestion="Delete reason?"
                                            actionTitle="Delete"
                                            cancelCallback={closeAlertHandler} />
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                )
                }
            </div>
        </>
    )
}
