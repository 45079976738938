
export const validation = (errorState:string, formDataArray: Array<string>) => {

    const errorFieldKey = Object.keys(errorState)
    const matched = formDataArray.filter( el => errorFieldKey.indexOf( el ) > -1 );
    const testErrorField = formDataArray.filter(field => field === matched.filter(el => el === field)[0])
    const testErrorMessage = Object.entries(errorState)
    const testErrorFieldMessage = testErrorMessage.filter(mes => mes[0] === testErrorField.filter(el => el === mes[0])[0])

    return {
        testErrorField,
        testErrorFieldMessage
    }
}