import React, { FC, memo } from "react";
import "./ScheduleSideBar.scss"
import { useDispatch, useSelector } from "react-redux";
import Radio from "../../../components/Widgets/Radio";
import {
    getScheduleBranchActiveWeekTC,
    getScheduleBranchTC,
    setCurrentBranchesAC
} from "../../../redux/branches-reducer";
import { setIsLoggedInAC } from "../../../redux/auth-reducer";
import {
    getActiveWeekSelector,
    getBranchesSelector,
    getCurrentBranchSelector
} from "../../../redux/selectors";


type EventsSideBarPropsType = {}

export const ScheduleSideBar: FC<EventsSideBarPropsType> = memo(() => {
    const dispatch = useDispatch();

    const currentBranch = useSelector(getCurrentBranchSelector);
    const activeWeek = useSelector(getActiveWeekSelector);
    const branches = useSelector(getBranchesSelector);

    const setBranch = (branchId: number) => {
        if (activeWeek) {
            dispatch(getScheduleBranchActiveWeekTC(branchId))
        } else {
            dispatch(getScheduleBranchTC(branchId))
        }
        dispatch(setCurrentBranchesAC(branchId))
        dispatch(setIsLoggedInAC(true))
    };

    const onClickHandler = (branchId: number) => {
        setBranch(branchId)
    };

    return (
        <>
            <form className="form-filter">
                <div className="filter-block">
                    <div className="filter-title">Branches</div>
                    {
                        branches.map(branch => (
                            <div className="filter-param" key={branch.id}>
                                <Radio
                                    state="small"
                                    name={"branches"}
                                    label={branch.name}
                                    value={branch.id}
                                    id={`${branch.id}`}
                                    checked={branch.id === currentBranch}
                                    onChange={() => {
                                        onClickHandler(branch.id)
                                    }}
                                />
                            </div>
                        )
                        )
                    }
                </div>
            </form>
        </>
    )
}
)
