import React, {FC, memo, useCallback, useEffect, useState} from "react";
import './Schedule.scss'
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { PATH } from "../Routes";
import { logo } from "../../assets/img/image";
import {
    fetchBranchesListFirstRender,
    getScheduleBranchActiveWeekTC,
    getScheduleBranchChosenCalendarWeekTC,
    getScheduleBranchNextWeekTC,
    getScheduleBranchPrevWeekTC,
    setCurrentPathAC
} from "../../redux/branches-reducer";
import {
    DateListEventType,
    DateOfListType,
} from "../../api/branches-api";
import {
    initializeAppTC,
    setAppStatusAC
} from "../../redux/app-reducer";
import Preloader from "../Preloader/Preloader";
import { DayOfTheWeek } from "./DayOfTheWeek/DayOfTheWeek";
import { calcGridSchedule } from "../../utils/schedule/calcGridSchedule";
import {
    changeStatusDoctorTC, changeStatusDoneTC,
    changeStatusNotTC,
    changeStatusTC,
    setChosenDayInScheduleAC,
} from "../../redux/events-reducer";
import {
    ModalAlertEvents
} from "../../components/ModalsAlerts/ModalAlertEvents/ModalAlertEvents";
import { CalendarGeneral } from "../../components/Calendar/Calendar";
import {
    getActiveWeekSelector,
    getCurrentBranchSelector, getEndTimeWorkDayDayDateSelector, getEventsSelector,
    getIsLoggedInSelector, getScheduleBranchDateSelector,
    getScheduleBranchSelector, getStartTimeWorkDayDateSelector,
    getStatusSelector
} from "../../redux/selectors";

type ScheduleType = {

}

export const Schedule: FC<ScheduleType> = memo(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const status = useSelector(getStatusSelector);
    const isLoggedIn = useSelector(getIsLoggedInSelector);
    const scheduleBranch = useSelector(getScheduleBranchSelector);
    const scheduleBranchDate = useSelector(getScheduleBranchDateSelector);
    const currentBranch = useSelector(getCurrentBranchSelector);
    const activeWeek = useSelector(getActiveWeekSelector);
    const startTimeWorkDay = useSelector(getStartTimeWorkDayDateSelector);
    const endTimeWorkDay = useSelector(getEndTimeWorkDayDayDateSelector);
    const events = useSelector(getEventsSelector);

    const [openAlertDialogEvents, setOpenAlertDialogEvents] = useState(false);
    const [modalDateEvents, setModalDateEvents] = useState<string>("");
    const [modalEventsData, setModalEventsData] = useState<Array<DateListEventType>>([]);
    const [openCalendarGeneral, setOpenCalendarGeneral] = useState<boolean>(false);

    useEffect(() => {
        if (status === "failed") {
            dispatch(initializeAppTC())
        }
    }, [status])

    useEffect(() => {
        dispatch(initializeAppTC())
        if (isLoggedIn) {
            if (!activeWeek) {
                dispatch(fetchBranchesListFirstRender())
            } else {
                dispatch(getScheduleBranchActiveWeekTC(currentBranch))
            }
        }
        return () => {
            dispatch(setAppStatusAC("idle"))
        };
    }, [dispatch, events])

    useEffect(() => {
        if (isLoggedIn) {
            const intervalId = setInterval(() => {

                dispatch(getScheduleBranchActiveWeekTC(currentBranch))
            }, 60000)
            return () => clearInterval(intervalId)
        }

    }, [dispatch])

    const setDate = useCallback((date: string) => {
        return new Date(date)
    }, [])

    const nextWeek = (branchId: number,) => {
        dispatch(getScheduleBranchNextWeekTC(branchId))
    }

    const prevWeek = (branchId: number,) => {
        dispatch(getScheduleBranchPrevWeekTC(branchId))
    }

    const setChosenWeekInCalendar = useCallback((branchId: number, week: string) => {
        dispatch(getScheduleBranchChosenCalendarWeekTC(branchId, week))
    }, [])

    const closeAlertHandler = useCallback(() => {
        setOpenAlertDialogEvents(false);
    }, []);

    const setDateForModalAlert = useCallback((time: string, date_of_lists: DateOfListType) => {
        const selectedHour = date_of_lists.events.filter(event => event.start.slice(11, 13) === time.slice(11, 13))
        const eventId = selectedHour.length && selectedHour[0].id

        dispatch(setCurrentPathAC(location.pathname))
        setModalDateEvents(time);

        if (selectedHour && selectedHour.length === 1) {
            navigate(`${PATH.EVENTS}${eventId}/`);
        }
        if (selectedHour && selectedHour.length > 1) {
            setModalEventsData(selectedHour)
            openAlertHandlerCancel(/*date, time*/)
        }
    }, [])

    const openAlertHandlerCancel = () => {
        setOpenAlertDialogEvents(true);
    };

    const onFreeDateClick = useCallback((date: string, time: string) => {
        dispatch(setChosenDayInScheduleAC({ date, time }))
        dispatch(setCurrentPathAC(location.pathname))
        navigate(PATH.ADD_EVENT);
    }, [])

    const calcGrid = calcGridSchedule(startTimeWorkDay, endTimeWorkDay)

    const onStatusEventClick = (eventId: number) => {
        dispatch(changeStatusTC(eventId))
    }

    const onStatusEventDoctorClick = (eventId: number) => {
        dispatch(changeStatusDoctorTC(eventId))
    }

    const onStatusEventNotClick = (eventId: number) => {
        dispatch(changeStatusNotTC(eventId))
    }

    const onStatusEventDoneClick = (eventId: number) => {
        dispatch(changeStatusDoneTC(eventId))
    }

    if (!isLoggedIn) {
        return <Navigate to={PATH.LOGIN} />
    }

    return (
        <>
            <div className="schedule">
                <div className="schedule__title-wrapper">
                    <h1 className="title">Schedule</h1>
                </div>
                {status === 'loading' ? (
                    <Preloader />
                ) : (
                    <div className="schedule__calendar">
                        <div className="header">
                            <div className="nav">
                                <span className="nav-current">
                                    {setDate(scheduleBranchDate[0] &&
                                        scheduleBranchDate[0].date).toUTCString().slice(8, 16)}

                                </span>
                                <span onClick={() => {
                                    setOpenCalendarGeneral(!openCalendarGeneral)
                                }}
                                    className="nav-week">
                                    <img src={logo.calendar}
                                        alt="calendar"
                                        className="calendar-img" />

                                    {setDate(scheduleBranchDate[0] &&
                                        scheduleBranchDate[0].date)
                                        .toLocaleDateString().slice(0, 5)} - {setDate(scheduleBranchDate[0] &&
                                            scheduleBranchDate[scheduleBranchDate.length - 1].date).toLocaleDateString().slice(0, 5)}
                                </span>
                                <div className="nav-prev"
                                    onClick={() => {
                                        prevWeek(currentBranch)
                                    }}>
                                    <img src={logo.arrow_left} alt="previous" />
                                </div>
                                <div className="nav-next"
                                    onClick={() => {
                                        nextWeek(currentBranch)
                                    }}>
                                    <img src={logo.arrow_right} alt="next" />
                                </div>
                            </div>
                        </div>
                        <div className="timeLine">
                            <span className="time-title">Time</span>
                            {
                                calcGrid.timeWorkDayFinally &&
                                calcGrid.timeWorkDayFinally
                                    .map((hour, index) => (
                                        <span key={index}
                                            style={{ height: `${calcGrid.currentHeightLine(Number(hour.slice(3, 5)))}px` }}
                                            className="line">
                                            {hour}
                                        </span>
                                    ))
                            }
                        </div>
                        <div className="days">
                            {
                                scheduleBranchDate.map((dayOfWeek) => (
                                    <DayOfTheWeek
                                        key={dayOfWeek.date_index}
                                        dayIndex={dayOfWeek.date_index}
                                        dayName={dayOfWeek.date_name}
                                        setDate={setDate}
                                        scheduleBranchDate={scheduleBranchDate}
                                        scheduleBranch={scheduleBranch}
                                        timeWorkDayFinally={calcGrid.timeWorkDayFinally}
                                        onFreeDateClick={onFreeDateClick}
                                        currentHeightLine={calcGrid.currentHeightLine}
                                        setDateForModalAlert={setDateForModalAlert}
                                        onStatusEventClick={onStatusEventClick}
                                        onStatusEventDoctorClick={onStatusEventDoctorClick}
                                        onStatusEventNotClick={onStatusEventNotClick}
                                        onStatusEventDoneClick={onStatusEventDoneClick}
                                    />
                                ))
                            }
                        </div>
                    </div>

                )
                }
                {openAlertDialogEvents && (
                    <>
                        <ModalAlertEvents
                            date={modalDateEvents}
                            eventsData={modalEventsData}
                            cancelCallback={closeAlertHandler}
                            onFreeDateClick={onFreeDateClick}
                        // dayIndex={dayOfWeek.date_index}
                        />
                    </>
                )}
                {openCalendarGeneral && (
                    <div
                        onClick={() => {
                            setOpenCalendarGeneral(false)
                        }}
                        className="schedule__wrapper-calendar-general">
                        <CalendarGeneral
                            branchId={currentBranch}
                            setChosenWeekInCalendar={setChosenWeekInCalendar}
                            onDayClick={() => {
                                setOpenCalendarGeneral(false)
                            }}
                        />
                    </div>
                )}
            </div>
        </>
    )
})
