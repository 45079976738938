import "./Radio.scss";
import { ChangeEvent, DetailedHTMLProps, FC, InputHTMLAttributes } from "react";
import { FormDataModalType } from "../ModalsAlerts/ModalAlertEditWorkSchedule";
import { Path } from "react-hook-form";

type DefaultInputPropsType = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

type RadioType = DefaultInputPropsType & {
    state?: string;
    name?: Path<FormDataModalType> | string;
    register?: any
    spanClassName?: string
    value?: any
    checked?: boolean
    input_type?: string
    disabled?: boolean
    label?: any
    onChangeChecked?: (checked: boolean) => void
};

const Radio: FC<RadioType> = (
    {
        type, state,
        onChange, onChangeChecked,
        className, spanClassName, register,
        children, id, value,
        label, disabled, name, defaultChecked,
        ...restProps
    }

) => {
    const onChangeCallback = (e: ChangeEvent<HTMLInputElement>) => {
        onChange
            && onChange(e)

        onChangeChecked
            && onChangeChecked(e.currentTarget.checked)
    }
    const customCheckboxClass = `
        radio radio_${state}
    `
    return (
        <div className={customCheckboxClass} >
            <input
                state={state}
                className="radio__input"
                type="radio"
                name={name}
                {...register && {...register(name)}}
                id={`id_${id}`}
                value={value}
                disabled={disabled}
                onChange={onChangeCallback}
                defaultChecked={defaultChecked}
                label={label}
                {...restProps}
            />
            <label className="radio__label" htmlFor={`id_${id}`} >
                <div className="radio__d">
                {label}
                </div>
             
            </label>
        </div>
    )
}


export default Radio;
