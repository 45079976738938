import React, { ChangeEvent, FC, useState } from "react";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import InputsWidgets from "./InputsWidgets";
import './TimePicker.scss';
import { stat } from "fs";


type ReactSelectType1 = {
    error?: string | null
    help_text?: string | null
    label?: string
    placeholder?: string
    control: any
    onClick?: () => void
    value?: any
    name: string
    defaultValue?: any
    onChangeText?: (value: string) => void
    selectedDate: any
    register?: any;
    step?: any
    state?:any
    disabled?: any

}



export const TimePicker: FC<ReactSelectType1> = (
    {
        control, onClick, error, placeholder, step,
        state,
        defaultValue, help_text, label, name, value,
        onChangeText, selectedDate, register, disabled,
        ...restProps
    }) => {

    const { onChange, ...args } = register(name)
    const onChangeHandler = (value: string, onChange: (...event: any[]) => void) => {
        const date = new Date(value)
        onChange(date)
    }

    return (
        <div className="timePicker">
            <div >
                <Controller
                    name={name}
                    control={control}
                    render={({ field: { value, onChange, name } }) => (
                        <div>
                            <DatePicker
                                name={name}
                                onChange={onChange}
                                selected={value}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                disabled={disabled}
                                //popperPlacement="bottom"
                                customInput={
                                    <InputsWidgets
                                        state={state}
                                        help_text={help_text}
                                        input_type={'time'}
                                        //disabled={disabled}
                                        autoFocus={true}
                                        step={step}
                                        error={error}
                                        name={name}
                                        label={label}
                                        defaultValue={value}
                                        onChangeText={(text) => {
                                            onChangeHandler(text, onChange)
                                        }
                                    
                                    }
                                 />}
                            />
                        </div>
                    )}
                />
            </div>
        </div>
    )
};