import axios from "axios";
import { BranchesType } from "./branches-api";
import { PrivateHolidayUpdateModelType } from "../redux/holidays-reducer";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const holidaysAPI = {
  getList(token: string) {
    return instance.get<Array<HolidaysType>>("/api/holidays/", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  joinToHoliday(token: string, holidayId: number, branchId: number) {
    return instance.get<HolidayResponseType>(`/api/holidays/${holidayId}/branch/${branchId}/add/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  leaveHoliday(token: string, holidayId: number, branchId: number) {
    return instance.get<Array<HolidaysType>>(`/api/holidays/${holidayId}/branch/${branchId}/remove/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  addHoliday(data: any, token: string) {
    return instance.post<any>("/api/holidays/create/", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  editHoliday(data: any, token: string, holidayId: number) {
    return instance.put<PrivateHolidayUpdateModelType>(`/api/holidays/${holidayId}/`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  removeHoliday(token: string, holidayId: number) {
    return instance.delete<any>(`/api/holidays/${holidayId}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

export type HolidayResponseType = {
  id: number;
  owner: number;
  owner_display: string;
  branches: any;
  name: string;
  date: string;
  start_time: string;
  end_time: string;
  official: boolean;
};

export type HolidaysType = {
  id: number;
  name: string;
  official: Array<HolidayResponseType>;
  private: Array<HolidayResponseType>;
  suggestion: Array<HolidayResponseType>;
};
