import React, {FC} from "react";
import {motion} from "framer-motion";
import './ModalAlertEvents.scss';
import {DateListEventType} from "../../../api/branches-api";
import {PATH} from "../../../pages/Routes";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setCurrentPathAC} from "../../../redux/branches-reducer";
import {logo} from "../../../assets/img/image";

type ModalAlertPropsType = {
    date: string
    eventsData: Array<DateListEventType>
    cancelCallback: () => void
    onFreeDateClick: (date: string, time: string) => void
    // dayIndex: number
}

const baseURL = process.env.REACT_APP_BASE_URL;

export const ModalAlertEvents: FC<ModalAlertPropsType> = ({
                                                              date,
                                                              cancelCallback,
                                                              eventsData,
                                                              onFreeDateClick,
                                                              // dayIndex,
                                                          }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const setDate = (date: string) => {
        return new Date(date);
    };

    const onCancelHandler = () => {
        cancelCallback()
    }

    const onEventClick = (eventId: number) => {
        navigate(`${PATH.EVENTS}${eventId}/`)
    }

    const eventPositionInModal = (start: string) => {
        return Number(start.slice(14, 16))
    }

    const eventHeight = (start: string, end: string) => {

        const heightEvent = (new Date(end).getTime() - new Date(start).getTime()) / (1000 * 60)
        return heightEvent <= 30 ? 90 : heightEvent + 90
    }

    const addEvent = (e: any) => {
        e.stopPropagation()
        dispatch(setCurrentPathAC(location.pathname))
        onFreeDateClick(date,
            `${date.slice(11, 14)}00`)
    }

    const minuteGrid = ["00", "05", "10", "15", "20", "25",
        "30", "35", "40", "45", "50", "55",]

    const minDate = () => {
        return eventsData.reduce(function (p, v) {
            return ( setDate(p.start).getTime()  < setDate(v.start).getTime() ? p : v );
        });
    }
    const maxDate = () => {
        return eventsData.reduce(function (p, v) {
            return ( setDate(p.end).getTime()  > setDate(v.end).getTime() ? p : v );
        });
    }

    return (
        <motion.div
            className={"events-window "} onMouseDown={onCancelHandler}>
            <motion.div
                className="events-window__alert"
                onMouseDown={(e) => e.stopPropagation()}
                onClick={e => e.stopPropagation()}>
                <motion.div
                    animate={{
                        x: 0
                    }}
                    className="title">

                    <span className="title__time">{`${(date).slice(11, 14)}00`}</span>

                    <span
                        className="title__date">{setDate(date).toUTCString().slice(0, 11)}</span>

                    <div className="title__edd-button"
                         onClick={addEvent}>
                        <span className="edd-button_title">+</span>
                    </div>
                </motion.div>
                <motion.div className="events-wrapper">

                    {
                        eventsData && eventsData.map(event => (
                            <div className="day-event"
                                 onClick={(e) => {
                                     e.stopPropagation()
                                     onEventClick(event.id)
                                 }}
                                 key={event.id}

                                 style={{
                                     // height: `90px`,
                                     height: `${eventHeight(event.start, event.end)}px`,
                                     // top: `calc(60px + 90px)`,
                                     marginTop: `calc(${eventPositionInModal(event.start) * 3}px)`,
                                     // top: `calc(60px + px)`,
                                     borderColor: `${event.reason_color}`,
                                     background: `${event.reason_color}38`
                                 }}>

                                <span className="reason">{event.reason_display}</span>
                                <span
                                    className="start">{`${event.start.toString().slice(11, 16)} - ${event.end.toString().slice(11, 16)}`}
                                </span>
                                <div
                                    className="client">{event.first_name} {event.last_name}
                                </div>

                                <div className="doctor-photo" style={{display: "none"}}>
                                    <img className="photo"
                                         src={event.doctor.photo
                                             ? /*logo.notLogo*/ `${baseURL}${event.doctor.photo}`
                                             : logo.notLogo}
                                         alt="Doctor"/>
                                </div>

                            </div>))
                    }

                    <div className="grid">
                        {
                            minuteGrid.map((minutes, index) =>
                                <div key={index} className="line-grid"></div>)
                        }
                    </div>


                    {/*<div className="edd-button" onClick={addEvent}>*/}
                    {/*    <span className="edd-button_title">+</span>*/}
                    {/*</div>*/}

                </motion.div>
            </motion.div>
        </motion.div>

    )
}

