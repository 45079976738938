import React, {useEffect, useMemo, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import "./FormClients.scss";
import "../../../components/Widgets/Button/Button.scss";
import {
  addClientTC,
  editClientTC,
  getCurrentClientsList,
  removeClientTC
} from "../../../redux/clients-reducer";
import {useDispatch, useSelector} from "react-redux";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {PATH} from "../../Routes";
import Inputs from "../../../components/Widgets/Inputs";
import {validation} from "../../../utils/validation";
import {fetchClientsListFieldsTC} from "../../../redux/fields-reducer";
import {
  defaultChoicesMultiSelect,
  defaultChoicesSelect,
  newOption
} from "../../../components/Widgets/Select/ReactSelectStyles";
import {ProfileSideBar} from "../../Profile/ProfileSideBar/ProfileSideBar";
import CheckboxInput from "../../../components/Widgets/CheckboxInput";
import {ModalAlertDelete} from "../../../components/ModalsAlerts/ModalAlertDelete";
import {ReactSelect} from "../../../components/Widgets/Select/ReactSelect";
import {useErrorHandler} from "../../../utils/useErrorHandler";
import Preloader from "../../Preloader/Preloader";
import {
  getClientsSelector,
  getCurrentClientsPageSelector,
  getErrorStateSelector,
  getFieldsClientsSelector,
  getIsLoggedInSelector,
  getStatusSelector
} from "../../../redux/selectors";

type FormDataType = {
  first_name: string;
  last_name: string;
  insurance_type: { value: any, label: any };
  email: string;
  phone: string;
  blocked: any;
  branches: { value: any, label: any } | []

};

export const FormClients = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate()

  const clients = useSelector(getClientsSelector);
  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const errorState = useSelector(getErrorStateSelector);
  const currentClientsPage = useSelector(getCurrentClientsPageSelector);
  const fields = useSelector(getFieldsClientsSelector);

  const [valueClient, setValueClient] = useState<boolean>(false);

  //for edit client
  const idUrl = params.id;
  const clientId = Number(idUrl);

  const currentClient = clients.length && clients.filter((cl) => cl.id === clientId)[0];

  //validation
  const formDataArray = [
    "first_name",
    "last_name",
    "insurance_type",
    "email",
    "phone",
    'blocked',
    'branches',
  ];

  const [errorGeneral, setErrorGeneral] = useState<string | null>(null)

  const firstNameErrorHandler = useErrorHandler("first_name", errorState, formDataArray)
  const lastNameErrorHandler = useErrorHandler("last_name", errorState, formDataArray)
  const insuranceTypeErrorHandler = useErrorHandler("insurance_type", errorState, formDataArray)
  const emailErrorHandler = useErrorHandler("email", errorState, formDataArray)
  const phoneErrorHandler = useErrorHandler("phone", errorState, formDataArray)
  const blockedErrorHandler = useErrorHandler("blocked", errorState, formDataArray)
  const branchesErrorHandler = useErrorHandler("branches", errorState, formDataArray)

  const [openAlertDialogForDelete, setOpenAlertDialogForDelete] = useState(false)
  const [modalDataId, setModalDataId] = useState<number>(0);


  //for checkbox
  const [chosenBlock, setChosenBlock] = useState<boolean>(false)

  //for select
  const choices = Object.entries(fields.insurance_type.choices).map(br => br)
  const valueOptionInsurance = fields && Object.entries(fields.insurance_type.choices).map((b: any) => newOption(b[0], b[1]))
  const labelOptionInsurance = currentClient && currentClient.insurance_type.toString()

  const choicesBranches = Object.entries(fields.branches.choices).map(br => br)
  const valueOptionBranches = fields && Object.entries(fields.branches.choices).map((b: any) => newOption(b[0], b[1]))
  const labelOptionBranches = currentClient && currentClient.branches.map((el: any) => el.toString())

  const clientData = currentClient && {
    first_name: currentClient.first_name,
    last_name: currentClient.last_name,
    email: currentClient.email,
    phone: currentClient.phone,
    branches: currentClient.branches &&
        defaultChoicesMultiSelect(valueOptionBranches, labelOptionBranches),
    insurance_type: currentClient.insurance_type &&
        defaultChoicesSelect(valueOptionInsurance, labelOptionInsurance)[0],
    block: currentClient.blocked,
  }

  const removeClient = async (clientId: number) => {
    await dispatch(removeClientTC(clientId))

    setModalDataId(0)
    setValueClient(true)
  }

  const openAlertHandler = (clientId: number) => {
    setOpenAlertDialogForDelete(true)
  }
  const closeAlertHandler = () => {
    setOpenAlertDialogForDelete(false)
  }

  useEffect(() => {
    if (validation(errorState, formDataArray).testErrorField.length === 0) {
      if (typeof errorState === "object") {
        setErrorGeneral(errorState && Object.values(errorState)[0])
      } else {
        setErrorGeneral(errorState)
      }
    }
    return () => {
      setErrorGeneral(null)
    };
  }, [status])

  useEffect(() => {
    if (clientData) {
      reset(clientData)
    }
  }, [currentClient])

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchClientsListFieldsTC());
      if (currentClientsPage) {
        dispatch(getCurrentClientsList())
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (status === "failed") {
      firstNameErrorHandler.setErrorCallback()
      lastNameErrorHandler.setErrorCallback()
      insuranceTypeErrorHandler.setErrorCallback()
      emailErrorHandler.setErrorCallback()
      phoneErrorHandler.setErrorCallback()
      blockedErrorHandler.setErrorCallback()
      branchesErrorHandler.setErrorCallback()
    }
  }, [status])

  const {register, handleSubmit, reset, control} =
      useForm<FormDataType>({
        mode: "onBlur",
        defaultValues: useMemo(() => {
          if (clientData) {
            return clientData
          }
        }, [clientData])
      });


  const onSubmit: SubmitHandler<FormDataType> = (data) => {

    const newData = {
      branches: data.branches && Object.values(data.branches).map((br: any) => br.value),
      insurance_type: data.insurance_type && data.insurance_type.value,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      blocked: data.blocked,
    }

    // let br = data.branches = []
    // const newDataAdd = {
    //   branches: br,
    //   insurance_type: data.insurance_type && data.insurance_type.value,
    //   first_name: data.first_name,
    //   last_name: data.last_name,
    //   email: data.email,
    //   phone: data.phone,
    //   blocked: data.blocked,
    // }

    if (currentClient) {
      dispatch(editClientTC(clientId, newData));
    } else {
      dispatch(addClientTC(newData));
    }
    setValueClient(true);
  };

  if (valueClient && status === "succeeded") {
    navigate(PATH.CLIENTS)
  }

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN}/>;
  }

  return (
      <>
        <ProfileSideBar/>
        <div className="add-client" onClick={() => {
          setErrorGeneral(null)
        }}>
          <h1 className="add-client__title">{currentClient ? "Client edit" : "Client create"}</h1>
          {status === 'loading' ? (
              <Preloader/>

          ) : (
              <div>
                <form onSubmit={handleSubmit(onSubmit)} className="add-client__form">
                  <label className="add-client__inputs-label">Info</label>
                  <div className="add-client__inputs-info">
                    <Inputs
                        error={firstNameErrorHandler.error}
                        help_text={fields.first_name.help_text || firstNameErrorHandler.error && firstNameErrorHandler.errorMessageCurrentField[1]}
                        onClick={firstNameErrorHandler.onFieldClick}
                        state={"active"}
                        register={register}
                        input_type={fields.first_name.input_type}
                        name={"first_name"}
                        label={fields.first_name.label}
                        defaultValue={currentClient ? currentClient.first_name : fields.first_name.initial_value}
                        resetForm={reset}
                        {...reset}
                    />
                    <Inputs
                        error={lastNameErrorHandler.error}
                        onClick={lastNameErrorHandler.onFieldClick}
                        help_text={fields.last_name.help_text || lastNameErrorHandler.error && lastNameErrorHandler.errorMessageCurrentField[1]}
                        state={"active"}
                        register={register}
                        input_type={fields.last_name.input_type}
                        name={"last_name"}
                        label={fields.last_name.label}
                        defaultValue={currentClient ? currentClient.last_name : fields.last_name.initial_value}
                        resetForm={reset}
                        {...reset}
                    />
                    <Inputs
                        error={emailErrorHandler.error}
                        onClick={emailErrorHandler.onFieldClick}
                        help_text={fields.email.help_text || emailErrorHandler.error && emailErrorHandler.errorMessageCurrentField[1]}
                        state={"active"}
                        input_type='email'
                        register={register}
                        name={"email"}
                        label={fields.email.label}
                        defaultValue={currentClient ? currentClient.email : fields.email.initial_value}
                        resetForm={reset}
                        {...reset}
                    />
                    <Inputs
                        error={phoneErrorHandler.error}
                        onClick={phoneErrorHandler.onFieldClick}
                        help_text={fields.phone.help_text || phoneErrorHandler.error && phoneErrorHandler.errorMessageCurrentField[1]}
                        state={"active"}
                        register={register}
                        input_type={fields.phone.input_type}
                        name={"phone"}
                        label={fields.phone.label}
                        defaultValue={currentClient ? currentClient.phone : fields.phone.initial_value}
                        resetForm={reset}
                        {...reset}
                    />
                  </div>
                  <div className="add-client__inputs-setting">
                    <div>
                      <ReactSelect
                          name="insurance_type"
                          error={insuranceTypeErrorHandler.error}
                          help_text={insuranceTypeErrorHandler.error && insuranceTypeErrorHandler.errorMessageCurrentField[1]}
                          label={fields.insurance_type.label}
                          placeholder={fields.insurance_type.label}
                          onClick={insuranceTypeErrorHandler.onFieldClick}
                          control={control}
                          defaultValue={currentClient &&
                              defaultChoicesSelect(valueOptionInsurance, labelOptionInsurance)[0]}
                          isMulti={false}
                          options={choices && Object.entries(fields.insurance_type.choices).map((b: any) => newOption(b[0], b[1]))}
                      />
                    </div>
                  </div>
                  <label
                      className="add-client__inputs-label">
                    {fields.branches.label}
                  </label>
                  <div className="add-client__inputs-setting">
                    <ReactSelect
                        name="branches"
                        error={branchesErrorHandler.error}
                        help_text={branchesErrorHandler.error && branchesErrorHandler.errorMessageCurrentField[1]}
                        label={fields.branches.label}
                        placeholder={fields.branches.label}
                        onClick={branchesErrorHandler.onFieldClick}
                        control={control}
                        isMulti={true}
                        options={choicesBranches && Object.entries(fields.branches.choices).map((b: any) => newOption(b[0], b[1]))}
                    />
                  </div>
                  <div className="blocked">
                    <CheckboxInput
                        state="square"
                        key={fields.blocked.initial_value}
                        name='blocked'
                        register={register}
                        defaultChecked={currentClient ? currentClient.blocked : fields.blocked.initial_value}
                        onClick={() => {
                          setChosenBlock(true)
                        }}
                        id={`${fields.blocked.initial_value}`}
                        label={fields.blocked.label}
                    />
                  </div>
                  <div>
                    <button
                        className="button button_size-middle button_position-right button_color-black">
                      Sichern
                    </button>
                    {currentClient ? <div className="btnd">
                      <button type="button" onClick={() => openAlertHandler(clientId)}
                              className="button button_size-middle button_position-right button_color-delete"
                      >Delete
                      </button>
                    </div> : <div></div>}

                  </div>
                  {
                      (status === 'failed' && errorGeneral) &&
                      <p className="general-error">{errorGeneral}</p>
                  }
                </form>
              </div>
          )}

          {openAlertDialogForDelete && <ModalAlertDelete deleteCallback={removeClient}
                                                         id={clientId}
                                                         titleQuestion="Delete client?"
                                                         actionTitle="Delete"
                                                         cancelCallback={closeAlertHandler}
          />
          }
        </div>
      </>
  );
};
