import React from "react";
import {NavLink} from "react-router-dom";
import {PATH} from "../../Routes";
import "./ProfileSideBar.scss"
import {editStateMenuAC} from "../../../redux/app-reducer";
import {useDispatch, useSelector} from "react-redux";
import {useLogout} from "../../../utils/logout";
import {getOpenMenuAppSelector} from "../../../redux/selectors";

export const ProfileSideBar = () => {
    const dispatch = useDispatch();

    const openMenu = useSelector(getOpenMenuAppSelector);

    const closeMenuCallback = () => {
        if (openMenu) {
            dispatch(editStateMenuAC(false))
        }
    }

    const logoutHandler = useLogout()

    return (
        <nav className="admin__left-nav">
            <ul className="list">
                <li onClick={closeMenuCallback} className="item"><NavLink
                    to={PATH.PROFILE}>Profile</NavLink></li>
                <li onClick={closeMenuCallback} className="item"><NavLink
                    to={PATH.BRANCHES}>Branches</NavLink></li>
                <li onClick={closeMenuCallback} className="item"><NavLink
                    to={PATH.EMPLOYEES}>Employees</NavLink></li>
                <li onClick={closeMenuCallback} className="item"><NavLink
                    to={PATH.REASONS}>Reasons</NavLink></li>
                <li onClick={closeMenuCallback} className="item"><NavLink
                    to={PATH.WORK_SCHEDULE}>Work schedule</NavLink></li>
                <li onClick={closeMenuCallback} className="item"><NavLink
                    to={PATH.HOLIDAYS}>Holidays</NavLink></li>
                       <li onClick={closeMenuCallback} className="item"><NavLink
                    to={PATH.CONTACTS}>Contacts</NavLink></li>
                {/*<li onClick={closeMenuCallback} className="item"><NavLink*/}
                {/*    to={""}>iFrame</NavLink></li>*/}
                <li className="item" onClick={logoutHandler}><NavLink
                    to={""}>Logout</NavLink></li>
            </ul>
        </nav>
    )
}