// @ts-ignore
import termiNowLogo from './logo.svg';
// @ts-ignore
import noLogo from './no-image.png';
// @ts-ignore
import arrow_down from './arrow_dd_down.svg';
// @ts-ignore
import arrow_up from './arrow_dd_up.svg';
// @ts-ignore
import pencil from './pencil.svg';
// @ts-ignore
import backet from './backet.svg';
// @ts-ignore
import arrow_r from './next.svg';
// @ts-ignore
import arrow_l from './prev.svg';
// @ts-ignore
import calendar from './calendar.svg';
// @ts-ignore
import time from './times.svg';
// @ts-ignore
import iconClose from './closeW.png';
// @ts-ignore
import iconOk from './icons8.svg';

export const logo = {
    termiLogo: termiNowLogo,
    notLogo: noLogo,
    arrow_down: arrow_down,
    arrow_up: arrow_up,
    add_photo: pencil,
    remove_photo: backet,
    arrow_right: arrow_r,
    arrow_left: arrow_l,
    calendar: calendar,
    time: time,
    iconClose: iconClose,
    iconOk: iconOk,
}

export const addPhotoImg = {
    backgroundImage: `url(${pencil})`
}
export const removePhotoImg = {
    backgroundImage: `url(${backet})`
}
export const defaultPhotoImg = {
    backgroundImage: `url(${noLogo})`
}