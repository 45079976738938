import { EmployeeType } from './employees-api';

import axios from "axios";
import { HolidayResponseType } from "./holidays-api";
import { DoctorType } from "./reasons-api";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
})

export const branchesAPI = {
    getList(token: string) {
        return instance.get<Array<BranchesType>>('/api/branches/', {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    addBranch(data: any, token: string) {
        console.log(data)
        return instance.post<BranchesType>('/api/branches/create/', data, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    editBranch(data: any, token: string, branchId: number) {
        return instance.put<BranchesType>(`/api/branches/${branchId}/`, data, {

            headers: {
                'Authorization': `Bearer ${token}`,
            }

        })
    },

    removeBranch(token: string, branchId: number) {
        return instance.delete<any>(`/api/branches/${branchId}/`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    getScheduleBranch(token: string, branchId: number, date: string | null) {
        return instance.get<ScheduleBranchType>(`/api/branches/${branchId}/schedule/?day=${date}`, {

            headers: {
                'Authorization': `Bearer ${token}`,
            }

        })
    },

    getScheduleFirstRenderBranch(token: string, branchId: number) {
        return instance.get<ScheduleBranchType>(`/api/branches/${branchId}/schedule/`, {

            headers: {
                'Authorization': `Bearer ${token}`,
            }

        })
    },

    getScheduleBranchOfWeekly(token: string, branchId: number, week: string | null | Array<string>) {
        return instance.get<ScheduleBranchType>(`/api/branches/${branchId}/schedule/?day=${week}`, {

            headers: {
                'Authorization': `Bearer ${token}`,
            }

        })
    },
}

export type DateListEventType = {
    branch: number
    branch_display: string
    client: any
    client_display: string
    created_on: string
    date_birth: string
    desired_date: boolean
    email: string
    email_reminded: boolean
    end: string
    first_name: string
    gender: number
    gender_display: string
    id: number
    insurance_type: string
    insurance_type_display: string
    is_client: boolean
    is_client_display: string
    last_name: string
    message: string
    phone: string
    reason: number
    reason_display: string
    sms_reminded: boolean
    start: string
    status: string
    status_display: string
    updated_on: string
    uuid_field: string
    from_start: string
    wait_minutes: number
    wait_time: string
    reason_color: string

    doctor: DoctorType
    visit_time: string
    waiting_time: string
}

export type BranchesType = {
    id: number
    name: string,
    phone: string,
    whatsapp: string,
    email: string,
    sity: string,
    street: string,
    street_number: string,
    zip_code: string,
    show_title: boolean,
    free_date: boolean,
    employees: any,
    sms_remind_text: string,
    email_remind_text: string,
    email_cancel_text: string,
    email_order_text: string,
    sms_cancel_text: string,
    sms_order_text: string,
    concurrent_records: any,
    desired_date: boolean,
}

export type DateOfListType = {
    date: string
    events: Array<DateListEventType>
    date_index: number
    date_name: string
    holidays: HolidayResponseType[]
}

export type ScheduleBranchType = {
    next_week: string
    previous_week: string
    current_week: Array<string>
    date_of_lists: Array<DateOfListType>
    day_end: string
    day_start: string
}
