import React, { ChangeEvent, FC, useState } from "react";
import { Controller } from "react-hook-form";
import Inputs from "./Inputs";
import DatePicker from "react-datepicker";
import InputsWidgets from "./InputsWidgets";
import './ReactDateTimePicker.scss';


type ReactSelectType1 = {
    error?: string | null
    help_text?: string | null
    label?: string
    placeholder?: string
    control: any
    onClick?: () => void
    name: string
    defaultValue?: any
    onChangeText?: (value: string) => any
    selectedDate: any
    register?: any;
}

export const ReactDateTimePicker: FC<ReactSelectType1> = (
    {
        control, onClick, error, placeholder,
        defaultValue, help_text, label, name,
        onChangeText, selectedDate, register,
        ...restProps
    }) => {

    const { onChange, ...args } = register(name)

    let dataaa = new Date()
    dataaa.toString().slice(2)

    const onChangeHandler = (value: string, onChange: (...event: any[]) => void) => {
        const date = new Date(value)
        date.toISOString()
        onChange(date)
    }

    return (
        <div className="dateTimePicker">
            <div>
                <Controller
                    name={name}
                    control={control}
                    render={({ field: { value, onChange, name } }) => (
                        <div>
                            <DatePicker
                                name={name}
                                onChange={onChange}
                                selected={value || defaultValue}
                                calendarStartDay={1}
                                showTimeSelect
                                timeIntervals={15}
                                dateFormat={`yyyy-MM-dd'T'HH:mm:ss`}
                                timeFormat="HH:mm"
                                timeCaption="time"
                                customInput={
                                    <InputsWidgets
                                        state="datePicker"
                                        error={error}
                                        help_text={help_text}
                                        // register={register}
                                        input_type={'datetime-local'}
                                        name={name}
                                        defaultValue={defaultValue}
                                        onInputClick={onClick}
                                        onChangeText={(text) => onChangeHandler(text, onChange)}
                                        label={label}
                                    />
                                }
                            />

                        </div>
                    )}
                />
            </div>
        </div>
    )
};


