import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
})

export const waitingRoomAPI = {
    getList(token: string, branchId: number) {
        return instance.get<WaitingRoomType>(`/api/${branchId}/client/status/`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    getListOfBranch(token: string, branchId: number) {
        return instance.get<WaitingRoomFilterParamsType>(`/api/client/waiting-room/filter-form/?branch=${branchId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    getFilteredListOfBranch(token: string, branchId: string) {
        return instance.get<any>(`/api/client/waiting-room/filter-form/?branch=${branchId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },
}

export type WaitingRoomFilterParamsType = {
    branch: { choices: {} }
}

//types
export type WaitingResponseType = {
    id: number,
    uuid_field: string,
    start: string,
    end: string,
    desired_date: boolean,
    client: number,
    client_display: string,
    status: string,
    status_display: string,
    insurance_type: string,
    insurance_type_display: string,
    branch: number,
    branch_display: string,
    reason: number,
    reason_display: string,
    reason_color: string,
    gender: number,
    gender_display: string,
    first_name: string,
    last_name: string,
    date_birth: string,
    email: string,
    phone: string,
    is_client: boolean,
    is_client_display: string,
    message: string,
    created_on: string,
    updated_on: string,
    email_reminded: boolean,
    sms_reminded: boolean,
    waiting_time: string,
    wait_seconds: number
    visit_time:string,
}

export type WaitingRoomType = {
    waiting_room: Array<WaitingResponseType>
    at_the_doctor: Array<WaitingResponseType>
}
