import { Dispatch } from "redux";
import { loadToken } from "../utils/localStorage";
import { setAppStatusAC } from "./app-reducer";
import { handleServerAppError, handleServerNetworkError } from "../utils/error-utils";
import { waitingRoomAPI, WaitingRoomFilterParamsType } from "../api/waitingRoom-api";
import { AppRootStateType } from "./store";


const SET_LIST_OF_BRANCH = 'waitingRoomFilterReducer/SET_LIST_OF_BRANCH'
const SET_CURRENT_PARAM_BRANCH = 'waitingRoomFilterReducer/SET_CURRENT_PARAM_BRANCH'

const initialState: InitialStateType = {
    currentFilterParams: '',
    waitingRoomFilterParamsList: {
        branch: { choices: {} },
    }
};

type InitialStateType = {
    currentFilterParams: string
    waitingRoomFilterParamsList: {
        branch: { choices: {} }

    }
}

export const waitingRoomFilterReducer = (state: InitialStateType = initialState, action: ActionsType): InitialStateType => {
    switch (action.type) {
        case SET_LIST_OF_BRANCH: {
            return {
                ...state,
                waitingRoomFilterParamsList: action.params
            }
        }
        case SET_CURRENT_PARAM_BRANCH: {
            return {
                ...state,
                currentFilterParams: action.params
            }
        }
        default:
            return state
    }
}

// actions
export const setListOfBranchAC = (params: WaitingRoomFilterParamsType) => ({ type: SET_LIST_OF_BRANCH, params } as const)
export const setCurrentParamBranchAC = (params: string) => ({ type: SET_CURRENT_PARAM_BRANCH, params } as const)

// thunks
export const fetchFilteredWaitingRoomParamsListTC = () =>
    async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
        const branchId = getState().branches.currentBranch
        dispatch(setAppStatusAC("loading"))
        try {
            const tokenLS = await loadToken()
            let res = await waitingRoomAPI.getListOfBranch(tokenLS, branchId)
            dispatch(setListOfBranchAC(res.data))
            //dispatch(setListWaitingRoomAC(res.data))
            dispatch(setAppStatusAC("succeeded"))

        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
        dispatch(setAppStatusAC("idle"))
    }

export const fetchFilteredWaitingRoomListOfBranchTC = (branchId: string) => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        let res = await waitingRoomAPI.getFilteredListOfBranch(tokenLS, branchId)
        dispatch(setCurrentParamBranchAC(branchId))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

type ActionsType = ReturnType<typeof setListOfBranchAC> | ReturnType<typeof setCurrentParamBranchAC>

