import React, { useEffect, useState } from 'react';
// import './App.css';
import '../assets/fonts/poppins/style.css';
import { useDispatch, useSelector } from "react-redux";
import { initializeAppTC, RequestStatusType } from "../redux/app-reducer";
import { PATH, RoutesComponent } from "../pages/Routes";
import { AppRootStateType } from "../redux/store";
import { SideBar } from "../pages/Main/SideBar/SideBar";
import { Header } from "../pages/Main/Header/Header";
import '../pages/Main/Main.scss'
import { Route, Routes, useLocation } from "react-router-dom";
import { refreshTC } from "../redux/auth-reducer";
import { Login } from '../pages/Login/Login';
import { Register } from '../pages/Register/Register';
import SuccessPage from '../pages/Success/SuccessPage/SuccessPage';


type AppType = {}

const App: React.FC<AppType> = () => {
    const dispatch = useDispatch()

    const isLoggedIn = useSelector<AppRootStateType, boolean>(state => state.auth.isLoggedIn)
    const location = useLocation().pathname
    const [currentPath, setCurrentPath] = useState<string>()

    useEffect(() => {
        dispatch(initializeAppTC())
    }, [isLoggedIn])

    useEffect(() => {
        if (location !== currentPath && location !== PATH.LOGIN) {
            setCurrentPath(location)
            if (isLoggedIn) {
                dispatch(refreshTC())
            }
        }
    }, [location])

    useEffect(() => {
        if (!isLoggedIn) {
            dispatch(initializeAppTC())
        }
    }, [dispatch])

    useEffect(() => {
        setInterval(() => {
            dispatch(initializeAppTC())
            // }, 1860000)
        }, 300000)
    }, [dispatch])


    switch (location) {
        case '/login/':
            return <Login />
        case '/register/':
            return <Register />
        case '/success-email/':
            return <SuccessPage textSuccess="Click on the confirmation link in the email to activate you account" />
        case "/success-page/":
            return <SuccessPage textSuccess="You booking is successfull" />
        case '/success-error/':
            return <SuccessPage textSuccess="User not found" />
        case '/success-create/':
            return <SuccessPage textSuccess="Your account has been created" />
    }

    return (
        <>
            <div className="admin">
                <SideBar />
                <Header />
                <RoutesComponent />
            </div>
        </>
    );
}

export default App;
