import { Dispatch } from "redux";
import { authAPI, LoginParamsType } from "../api/auth-api";
import {
    loadProfileDataLS,
    saveProfileDataLS,
    saveToken
} from "../utils/localStorage";
import { setProfileDataAC } from "./profile-reducer";
import { setAppStatusAC } from "./app-reducer";
import { handleServerAppError, handleServerNetworkError } from "../utils/error-utils";


const IS_LOGGED_IN = 'authReducer/SET-IS-LOGGED-IN'
export const RESET_REDUCER_GROUP = 'authReducer/RESET_REDUCER_GROUP'

const initialState = {
    isLoggedIn: false,
}

type InitialStateType = typeof initialState

export const authReducer = (state: InitialStateType = initialState, action: ActionsType): InitialStateType => {
    switch (action.type) {
        case IS_LOGGED_IN:
            return { ...state, isLoggedIn: action.value }
        case RESET_REDUCER_GROUP:
            return { ...initialState };
        default:
            return state
    }
}

// actions
export const setIsLoggedInAC = (value: boolean) =>
    ({ type: IS_LOGGED_IN, value } as const)
export const resetReducerGroupAC = () =>
    ({ type: RESET_REDUCER_GROUP } as const)

// thunks
export const loginTC = (data: LoginParamsType) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const res = await authAPI.login(data)
        dispatch(setIsLoggedInAC(true))
        saveToken(res.data.access)
        saveProfileDataLS(res.data)

        const user = await loadProfileDataLS()
        dispatch(setProfileDataAC(user))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
}

export const refreshTC = () => async (dispatch: Dispatch<any>) => {
    const profileData = loadProfileDataLS()
    const refresh = await {
        "refresh": profileData.refresh
    }
    try {
        const res = await authAPI.refresh(refresh)
        saveToken(res.data.access)
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
}

//types
export type ResetReducerGroupActionType = ReturnType<typeof resetReducerGroupAC>;

type ActionsType = ReturnType<typeof setIsLoggedInAC>
    | ResetReducerGroupActionType
