import React, { ChangeEvent, FC, useState } from "react";
import { Controller } from "react-hook-form";
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from "react-datepicker";
import InputsWidgets from "./InputsWidgets";
import './ReactDatePicker.scss';


type ReactSelectType = {
    error?: string | null
    help_text?: string | null
    label?: string
    placeholder?: string
    control: any
    onClick?: () => void
    onChange?: () => void
    onFocus?: () => void
    onInputClick?: () => void
    name: string
    id?: string
    defaultValue?: any
    onChangeText?: (value: string) => any
    selectedDate: any
    register?: any;
    input_type?: any
    maxLenght?: any
}

export const ReactDatePicker: FC<ReactSelectType> = (
    {
        control, onClick, error, placeholder,
        defaultValue, help_text, label, name,
        input_type, maxLenght,

        onChangeText, selectedDate, register,
        ...restProps
    }) => {


    const { onChange, ...args } = register(name)

    const onChangeHandler = (value: string, onChange: (...event: any[]) => void) => {
        const date = new Date(value)
        onChange(date)
    }

    return (
        <div className="datePicker">

            <Controller
                name={name}
                control={control}
                render={({ field: { value, onChange } }) => (
                    <DatePicker
                        name={name}
                        id={`id_${name}`}
                        onChange={onChange}
                        dateFormat="yyyy-MM-dd"
                        selected={value}
                        //onInputClick={onInputClickHandler}
                        calendarStartDay={1}
                        yearDropdownItemNumber={20}
                        showYearDropdown
                        scrollableYearDropdown
                      
                       // closeOnScroll={(e) => e.target === document}
                        //popperContainer={containerElementOutsideOfScrollableDiv}
                        //popperPlacement="bottom-right"
                        customInput={
                            <InputsWidgets
                                help_text={help_text}
                                input_type={'date'}
                                error={error}
                                state={'datePicker'}
                                name={name}
                                maxLength={maxLenght}
                                label={label}
                                onInputClick={onClick}
                                defaultValue={defaultValue}
                                onChangeText={(text) => {
                                    onChangeHandler(text, onChange)
                                }
                                }
                            />
                        }
                    />

                )}
            />
        </div>

    )
};

