import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from "react-redux";
import {persistor, store} from "./redux/store";
import './assets/sass/base.scss'
import {PersistGate} from "redux-persist/integration/react";


ReactDOM.render(
    <BrowserRouter>
        <React.StrictMode>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                <App/>
                </PersistGate>
            </Provider>
        </React.StrictMode>
    </BrowserRouter>, document.getElementById('root')
);

reportWebVitals();
