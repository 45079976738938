import React, {FC, useState} from "react";
import './WaitingRoomFilter.scss'
import {useDispatch, useSelector} from "react-redux";
import {AppRootStateType} from "../../../redux/store";
import Radio from "../../../components/Widgets/Radio";
import {SubmitHandler, useForm} from "react-hook-form";
import { WaitingRoomFilterParamsType } from "../../../api/waitingRoom-api";
import {
    fetchFilteredWaitingRoomListOfBranchTC,
} from "../../../redux/waitingRoom-filter-reducer";
import {
    getScheduleBranchActiveWeekTC,
    getScheduleBranchTC, setCurrentBranchesAC
} from "../../../redux/branches-reducer";
import {setIsLoggedInAC} from "../../../redux/auth-reducer";
import {
    getActiveWeekSelector,
    getCurrentBranchSelector,
    getFilterParamsSelector
} from "../../../redux/selectors";

type FormDataType = {
}

type EventsSideBarPropsType = {
  
}

export const WaitingRoomFilter: FC<EventsSideBarPropsType> = () => {
    const dispatch = useDispatch()

    const currentBranch = useSelector(getCurrentBranchSelector);
    const activeWeek = useSelector(getActiveWeekSelector);
    const filterParams = useSelector(getFilterParamsSelector);

    const [stateBranches, setStateBranches] = useState<boolean>(true)

    const choicesBranch = filterParams.branch && Object.entries(filterParams.branch.choices).map(br => br)

    const filterBranch = (params: string) => {
        dispatch(fetchFilteredWaitingRoomListOfBranchTC(params))
        if (activeWeek) {
            dispatch(getScheduleBranchActiveWeekTC(Number(params)))
        } else {
            dispatch(getScheduleBranchTC(Number(params)))
        }
        dispatch(setCurrentBranchesAC(Number(params)))
        dispatch(setIsLoggedInAC(true))
    }

    const {
    } = useForm<FormDataType>({
        mode: 'onBlur',
    });

    const onSubmit: SubmitHandler<FormDataType> = data => {

    }

    return (
        <div className="form-waiting-filter">
            <div className="filter-block">
                <div className="filter-title"
                     onClick={() => {
                         setStateBranches(!stateBranches)
                     }}>
                    Branches
                </div>
                {
                    filterParams.branch && choicesBranch.map((branch, index) => (
                        <div key={index}
                             className={stateBranches ? "filter-param param-branch" : 'filter-param-none'}>
                            <Radio
                                id={branch[0]}
                                state={"small"}
                                checked={ Number(branch[0]) === currentBranch}
                                onChange={() => {
                                    filterBranch(branch[0])
                                }}
                                name={"insurance_type"}
                                value={branch[0]}
                                label={branch[1]}
                            />
                        </div>)
                    ) 
                }
            </div>
        </div>
    )
}
