import React, {useEffect, useMemo, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import "./FormsEmployee.scss";
import "../../../components/Widgets/Button/Button.scss";
import {
    addEmployeesTC,
    editEmployeeTC,
    fetchEmployeesListTC
} from "../../../redux/employees-reducer";
import {useDispatch, useSelector} from "react-redux";
import {Navigate, useParams} from "react-router-dom";
import {PATH} from "../../Routes";
import Inputs from "../../../components/Widgets/Inputs";
import {validation} from "../../../utils/validation";
import {fetchEmployeesListFieldsTC} from "../../../redux/fields-reducer";
import {ProfileSideBar} from "../../Profile/ProfileSideBar/ProfileSideBar";
import {
    defaultChoicesMultiSelect,
    defaultChoicesSelect,
    newOption
} from "../../../components/Widgets/Select/ReactSelectStyles";
import {ReactSelect} from "../../../components/Widgets/Select/ReactSelect";
import {useErrorHandler} from "../../../utils/useErrorHandler";
import {
    getEmployeesSelector,
    getErrorStateSelector,
    getFieldsEmployeesSelector, getIsLoggedInSelector, getStatusSelector
} from "../../../redux/selectors";

type FormDataType = {
    first_name: string
    last_name: string
    password: string
    password2: string
    branch_set: {}
    role: { value: any, label: any }
}

export const FormsEmployee = () => {
    const dispatch = useDispatch()
    const params = useParams();

    const employees = useSelector(getEmployeesSelector);
    const fields = useSelector(getFieldsEmployeesSelector);
    const errorState = useSelector(getErrorStateSelector);
    const status = useSelector(getStatusSelector);
    const isLoggedIn = useSelector(getIsLoggedInSelector);

    const [valueEmployee, setValueEmployee] = useState<boolean>(false)

    //for edit employee
    const idUrl = params.id
    const employeeId = Number(idUrl)

    const currentEmployee = employees.length && employees.filter(emp => emp.id === employeeId)[0]

    //validation
    const formDataArray = ["first_name", "last_name", "password", "password2", "branch_set", 'role']

    const [errorGeneral, setErrorGeneral] = useState<string | null>(null)

    const firstNameErrorHandler = useErrorHandler("first_name", errorState, formDataArray)
    const lastNameErrorHandler = useErrorHandler("last_name", errorState, formDataArray)
    const passwordErrorHandler = useErrorHandler("password", errorState, formDataArray)
    const password2ErrorHandler = useErrorHandler("password2", errorState, formDataArray)
    const branchSetErrorHandler = useErrorHandler("branch_set", errorState, formDataArray)
    const roleErrorHandler = useErrorHandler("role", errorState, formDataArray)

    // for select
    const choicesBranch = Object.entries(fields.branch_set.choices).map(br => br)
    const valueOptionBranch = fields && Object.entries(fields.branch_set.choices).map((b: any) => newOption(b[0], b[1]))
    const labelOptionBranch = currentEmployee && currentEmployee.branch_set.map((el: any) => el.toString())

    const choicesRole = Object.entries(fields.role.choices).map(br => br)
    const valueOptionRole = fields && Object.entries(fields.role.choices).map((b: any) => newOption(b[0], b[1]))
    const labelOptionRole = currentEmployee && currentEmployee.role.toString()

    const employeeData = currentEmployee && {
        first_name: currentEmployee.first_name,
        last_name: currentEmployee.last_name,
        branch_set: currentEmployee.branch_set &&
            defaultChoicesMultiSelect(valueOptionBranch, labelOptionBranch),
        role: currentEmployee.role &&
            defaultChoicesSelect(valueOptionRole, labelOptionRole)[0],
    }

    useEffect(() => {
        if (validation(errorState, formDataArray).testErrorField.length === 0) {
            if (typeof errorState === "object") {
                setErrorGeneral(errorState && Object.values(errorState)[0])
            } else {
                setErrorGeneral(errorState)
            }
        }
        return () => {
            setErrorGeneral(null)
        };
    }, [status])

    useEffect(() => {
        if (employeeData) {
            reset(employeeData)
        }
    }, [currentEmployee])

    useEffect(() => {
        dispatch(fetchEmployeesListFieldsTC())
        dispatch(fetchEmployeesListTC())
    }, [dispatch])

    useEffect(() => {
        if (status === "failed") {
            firstNameErrorHandler.setErrorCallback()
            lastNameErrorHandler.setErrorCallback()
            passwordErrorHandler.setErrorCallback()
            password2ErrorHandler.setErrorCallback()
            branchSetErrorHandler.setErrorCallback()
            roleErrorHandler.setErrorCallback()
        }
    }, [status])

    const {
        register,
        handleSubmit,
        reset,
        control,
    } = useForm<FormDataType>({
        mode: 'onBlur',
        defaultValues: useMemo(() => {
            if (employeeData) {
                return employeeData
            }
        }, [employeeData])
    });
    const onSubmit: SubmitHandler<FormDataType> = data => {

        const newData = {
            branch_set: data.branch_set && Object.values(data.branch_set).map((br: any) => br.value),
            first_name: data.first_name,
            last_name: data.last_name,
            password: data.password,
            password2: data.password2,
            role: data.role && data.role.value
        }

        if (currentEmployee) {
            dispatch(editEmployeeTC(employeeId, newData))
        } else {
            dispatch(addEmployeesTC(newData))
        }

        setValueEmployee(true)
    }

    if (valueEmployee && status === "succeeded") {
        return <Navigate to={PATH.EMPLOYEES}/>
    }

    if (!isLoggedIn) {
        return <Navigate to={PATH.LOGIN}/>;
    }

    return (
        <>
            <ProfileSideBar/>
            <div className="add-employee" onClick={() => {
                setErrorGeneral(null)
            }}>
                <h1 className="add-employee__title">{currentEmployee ? "Employee edit" : "Employee create"}</h1>
                <form onSubmit={handleSubmit(onSubmit)} className="add-employee__form">
                    <label className="add-employee__inputs-label">Info</label>
                    <div className="add-employee__inputs-info">
                        <Inputs
                            error={firstNameErrorHandler.error}
                            help_text={fields.first_name.help_text || firstNameErrorHandler.error && firstNameErrorHandler.errorMessageCurrentField[1]}
                            onClick={firstNameErrorHandler.onFieldClick}
                            state={"active"}
                            register={register}
                            input_type={fields.first_name.input_type}
                            name={"first_name"}
                            label={fields.first_name.label}
                            defaultValue={currentEmployee ? currentEmployee.first_name : fields.first_name.initial_value}
                            resetForm={reset}
                            {...reset}
                        />
                        <Inputs
                            error={lastNameErrorHandler.error}
                            onClick={lastNameErrorHandler.onFieldClick}
                            help_text={fields.last_name.help_text || lastNameErrorHandler.error && lastNameErrorHandler.errorMessageCurrentField[1]}
                            state={"active"}
                            register={register}
                            input_type={fields.last_name.input_type}
                            name={"last_name"}
                            label={fields.last_name.label}
                            defaultValue={currentEmployee ? currentEmployee.last_name : fields.last_name.initial_value}
                            resetForm={reset}
                            {...reset}
                        />
                    </div>
                    <label className="add-employee__inputs-label">Setting</label>
                    <div className="add-employee__inputs-setting">
                        <Inputs
                            error={passwordErrorHandler.error}
                            onClick={passwordErrorHandler.onFieldClick}
                            help_text={fields.password.help_text || passwordErrorHandler.error && passwordErrorHandler.errorMessageCurrentField[1]}
                            state={"active"}
                            input_type='password'
                            register={register}
                            name={"password"}
                            label={fields.password.label}
                            resetForm={reset}
                            {...reset}
                        />
                        <Inputs
                            error={password2ErrorHandler.error}
                            onClick={password2ErrorHandler.onFieldClick}
                            help_text={fields.password2.help_text || password2ErrorHandler.error && password2ErrorHandler.errorMessageCurrentField[1]}
                            state={"active"}
                            register={register}
                            input_type='password'
                            name={"password2"}
                            label={fields.password2.label}
                            resetForm={reset}
                            {...reset}
                        />
                        <ReactSelect
                            name="role"
                            error={roleErrorHandler.error}
                            help_text={roleErrorHandler.error && roleErrorHandler.errorMessageCurrentField[1]}
                            label={fields.role.label}
                            placeholder={fields.role.label}
                            onClick={roleErrorHandler.onFieldClick}
                            control={control}
                            isMulti={false}
                            defaultValue={currentEmployee &&
                                defaultChoicesSelect(valueOptionRole, labelOptionRole)[0]}
                            options={choicesRole && Object.entries(fields.role.choices).map((b: any) => newOption(b[0], b[1]))}
                        />
                    </div>

                    <label
                        className="add-employee__inputs-label">{fields.branch_set.label}</label>
                    <div className="add-employee__inputs-setting">
                        <ReactSelect
                            name="branch_set"
                            error={branchSetErrorHandler.error}
                            help_text={branchSetErrorHandler.error && branchSetErrorHandler.errorMessageCurrentField[1]}
                            label={fields.branch_set.label}
                            placeholder={fields.branch_set.label}
                            onClick={branchSetErrorHandler.onFieldClick}
                            control={control}
                            isMulti={true}
                            options={choicesBranch && Object.entries(fields.branch_set.choices).map((b: any) => newOption(b[0], b[1]))}
                        />
                    </div>
                    <div>
                        <button
                            className='button button_size-middle button_position-right button_color-black'>Sichern
                        </button>
                    </div>
                    {
                        (status === 'failed' && errorGeneral) &&
                        <p className="general-error">{errorGeneral}</p>
                    }
                </form>
            </div>
        </>
    )
}
