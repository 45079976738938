import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppRootStateType } from "../../redux/store";
import { Navigate, NavLink } from "react-router-dom";
import { PATH } from "../Routes";
import './OrderList.scss'
import {
    ModalAlertDelete
} from "../../components/ModalsAlerts/ModalAlertDelete";
import { removeReasonTC } from "../../redux/reasons-reducer";
import { fetchBranchesList } from "../../redux/branches-reducer";
import { ProfileSideBar } from "../Profile/ProfileSideBar/ProfileSideBar";
import { initializeAppTC } from "../../redux/app-reducer";
import Preloader from "../Preloader/Preloader";
import { fetchOrderList } from "../../redux/order-reducer";
import { getIsLoggedInSelector, getOrdersSelector, getStatusSelector } from "../../redux/selectors";

export const OrderList = () => {
    const dispatch = useDispatch()

    const status = useSelector(getStatusSelector);
    const isLoggedIn = useSelector(getIsLoggedInSelector);
    const orders = useSelector(getOrdersSelector);

    //dialog alerts
    const [openAlertDialogForDelete, setOpenAlertDialogForDelete] = useState(false)
    const [modalDataId, setModalDataId] = useState<number>(0);

    useEffect(() => {
        if (status === "failed") {
            dispatch(initializeAppTC())
        }
    }, [status])

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchOrderList())
            dispatch(fetchBranchesList())
        }
    }, [dispatch])

    const removeReason = (id: number) => {
        dispatch(removeReasonTC(id))
        setModalDataId(0)
    }

    const openAlertHandler = (employeeId: number) => {
        setModalDataId(employeeId)
        setOpenAlertDialogForDelete(true)
    }
    const closeAlertHandler = () => {
        setOpenAlertDialogForDelete(false)
    }


    if (!isLoggedIn) {
        return <Navigate to={PATH.LOGIN} />
    }

    return (
        <>
            <ProfileSideBar />
            <div className="employees">
                <h1 className="employees__title">Orders list</h1>
                <div className="employees__add">
                    <NavLink
                        className="button button_size-small button_position-left button_color-transparent"
                        to={PATH.ADD_ORDER}>+ add
                        order</NavLink>
                </div>
                {status === 'loading' ? (
                    <Preloader />
                ) : (
                    <div>
                        <table className='employees__table'>
                            <thead className="thead">
                                <tr className="tr">
                                    <th className="th"><span>{'#'}</span></th>
                                    <th className="th"><span>{'Client'}</span></th>
                                    <th style={{ width: '200px' }} className="th"><span>{'Material'}</span></th>
                                    <th className="th"><span>{'Versicherung'}</span></th>
                                    <th className="th"><span>{'Optionen'}</span></th>
                                </tr>
                            </thead>
                            <tbody className="tbody">
                                {orders.map(order => <tr key={order.id} className="tr">
                                    <td className="td">{order.id}</td>
                                    <td className="td">{order.client_display}</td>
                                    <td className="td">{order.material_display}</td>
                                    <td className="td">{order.insurance_type_display}</td>
                                    <td className="td">
                                        <div className="btns">
                                            <NavLink
                                                className="button button_size-small  button_color-green"
                                                to={`${PATH.ORDERS}${order.id}`}>Edit</NavLink>

                                            <button onClick={() => openAlertHandler(order.id)}
                                                className="button button_size-small  button_color-delete"
                                            >Delete
                                            </button>
                                        </div>
                                    </td>
                                </tr>)
                                }
                                {openAlertDialogForDelete &&
                                    <>
                                        <ModalAlertDelete deleteCallback={removeReason}
                                            id={modalDataId}
                                            titleQuestion="Delete reason?"
                                            actionTitle="Delete"
                                            cancelCallback={closeAlertHandler} />
                                    </>
                                }
                            </tbody>

                        </table>

                    </div>
                )
                }
            </div>
        </>
    )
}
