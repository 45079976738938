import React, {FC} from "react";

// @ts-ignore
import s from './Success.module.scss';
// @ts-ignore
import timeImg from '../../../assets/img/times.svg';
// @ts-ignore
import titleImg from '../../../assets/img/title.svg';


type SuccessType = {}

export const Success: FC<SuccessType> = () => {
    return (
        <div className={s.main}>
            <div className={s.main_wrapper}>
                <div className={s.user_cont}>
                    <div className={s.user_wrapper}>
                        <div className={s.avatar}>
                        </div>
                        <div className={s.user_name_block}>
                            <div className={s.user_name_field1}>
                            </div>
                            <div className={s.user_name_field2}>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.title_img_wrapper}>
                    <img src={titleImg} alt={'Terminow'} className={s.titleImg}/>
                </div>
                <div className={s.content_block}>
                    <h1 className={s.content_title}>Success!</h1>
                    <div className={s.text_wrapper}>
                        <span className={s.text}>- Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Cras ultricies lorem quis velit hendrerit,
                eget fringilla nulla lacinia. Mauris at fringilla risus,
                quis gravida dui.</span>
                    </div>
                    <div className={s.date_block}>
                        <img src={timeImg} className={s.time_img} alt={'time'}/>
                        <div className={s.date_value}>Your date: <span className={s.day}>Thursday 26, 09:00</span>
                        </div>
                    </div>
                    <div className={s.content_block_two}>
                        <span className={s.text}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Cras ultricies lorem quis velit hendrerit, eget fringilla nulla lacinia.
                Mauris at fringilla risus, quis gravida dui. Etiam rutrum nibh dui,
                in tempus lorem semper eget.</span>
                    </div>
                    <button className={s.button}>Button</button>
                    <div className={s.covid_block}>
                        <h2 className={s.covid_title}>Coronavirus (Covid-19)</h2>
                        <div className={s.covid_text_wrapper}>
                        <span className={s.covid_text}>- Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cras ultricies lorem quis velit hendrerit,
                    eget fringilla nulla lacinia. Mauris at fringilla risus,
                    quis gravida dui. Etiam rutrum nibh dui, in tempus lorem semper eget.
                    Pellentesque habitant morbi tristique senectus et netus et malesuada
                    fames ac turpis egestas. Nulla rhoncus condimentum fringilla.
                    Cras tincidunt a quam vel ultrices. Suspendisse aliquet</span>
                        </div>
                    </div>
                </div>
                <div className={s.footer_block}>
                    <span className={s.footer_text}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>
                </div>
            </div>
        </div>
    )
}


