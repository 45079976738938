import { Dispatch } from "redux";
import { authAPI } from "../api/auth-api";
import {
    RESET_REDUCER_GROUP, resetReducerGroupAC,
    ResetReducerGroupActionType,
    setIsLoggedInAC
} from "./auth-reducer";
import {
    loadProfileDataLS,
    loadToken,
    saveProfileDataLS,
    saveToken
} from "../utils/localStorage";
import { setProfileDataAC } from "./profile-reducer";
import { handleServerAppError, handleServerNetworkError } from "../utils/error-utils";
import { AppRootStateType, persistor } from "./store";


const SET_INITIALIZED = 'appReducer/SET-INITIALIZED'
const SET_STATUS = 'appReducer/SET-STATUS'
const SET_ERROR = 'appReducer/SET_ERROR'
const EDIT_STATE_MENU = 'appReducer/EDIT_STATE_MENU'

const initialState: InitialStateType = {
    status: 'idle',
    error: '',
    isInitialized: false,
    isOpenProfileMenu: false,
}

export const appReducer = (state: InitialStateType = initialState, action: ActionsType): InitialStateType => {
    switch (action.type) {
        case SET_INITIALIZED:
            return { ...state, isInitialized: action.isInitialized }

        case SET_STATUS:
            return { ...state, status: action.status }

        case SET_ERROR:
            return { ...state, error: action.error }

        case EDIT_STATE_MENU:
            return { ...state, isOpenProfileMenu: action.openMenu }

        case RESET_REDUCER_GROUP:
            return { ...initialState };

        default:
            return { ...state }
    }
}

//action
export const setAppIsInitializedAC = (isInitialized: boolean) => (
    { type: SET_INITIALIZED, isInitialized } as const)

export const setAppErrorAC = (error: string) => (
    { type: SET_ERROR, error } as const)

export const setAppStatusAC = (status: RequestStatusType) => (
    { type: SET_STATUS, status } as const)

export const editStateMenuAC = (openMenu: boolean) => (
    { type: EDIT_STATE_MENU, openMenu } as const)

// thunk
export const initializeAppTC = () => async (dispatch: Dispatch,
    getState: () => AppRootStateType) => {
    const tokenLS = await loadToken()
    try {
        const token = await {
            "token": tokenLS
        }
        if (tokenLS !== '') {
            await authAPI.me(token)
            dispatch(setIsLoggedInAC(true));
            const user = loadProfileDataLS()
            dispatch(setProfileDataAC(user))
            dispatch(setAppStatusAC("succeeded"))
        } else {
            dispatch(setIsLoggedInAC(false));
        }

    } catch (error: any) {
        if (tokenLS !== '') {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
            dispatch(setIsLoggedInAC(false));
        }
        saveToken('')
        saveProfileDataLS('')
        dispatch(resetReducerGroupAC())
    }
    dispatch(setAppStatusAC("idle"))
}


//types
export type RequestStatusType = 'idle' | 'loading' | 'succeeded' | 'failed' | 'authError'

export type InitialStateType = {
    status: RequestStatusType
    error: string
    isInitialized: boolean
    isOpenProfileMenu: boolean
}

export type ActionsType = ReturnType<typeof setAppIsInitializedAC>
    | ReturnType<typeof setAppStatusAC>
    | ReturnType<typeof setAppErrorAC>
    | ReturnType<typeof editStateMenuAC>
    | ResetReducerGroupActionType
