import { registrationReducer } from './registration-reducer';
import { applyMiddleware, combineReducers, createStore, Store } from "redux";
import { authReducer } from "./auth-reducer";
import thunk from "redux-thunk";
import { appReducer } from "./app-reducer";
import { employeesReducer } from "./employees-reducer";
import { scheduleWorkReducer } from "./scheduleWork-reducer";
import { eventsReducer } from "./events-reducer";
import { profileReducer } from "./profile-reducer";
import { clientsReducer } from "./clients-reducer";
import { branchesReducer } from './branches-reducer';
import { reasonsReducer } from "./reasons-reducer";
import { holidaysReducer } from "./holidays-reducer";
import { fieldsReducer } from "./fields-reducer";
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { clientsFilterReducer } from "./clients-filter-reducer";
import { waitingRoomReducer } from './waitingRoom-reducer';
import { eventsFilterReducer } from "./events-filter-reducer";
import { waitingRoomFilterReducer } from './waitingRoom-filter-reducer';
import { contactsReducer } from './contacts-reducer';
import { registerReducer } from './register-reducer';
import { orderReducer } from './order-reducer';


const rootReducer = combineReducers({
    auth: authReducer,
    app: appReducer,
    employees: employeesReducer,
    schedule: scheduleWorkReducer,
    events: eventsReducer,
    eventsFilter: eventsFilterReducer,
    profile: profileReducer,
    clients: clientsReducer,
    clientsFilter: clientsFilterReducer,
    waitingRoomFilter: waitingRoomFilterReducer,
    branches: branchesReducer,
    reasons: reasonsReducer,
    holidays: holidaysReducer,
    fields: fieldsReducer,
    registration: registrationReducer,
    waitingRoom: waitingRoomReducer,
    contacts: contactsReducer,
    register: registerReducer,
    order: orderReducer,
})

export type AppRootStateType = ReturnType<typeof rootReducer>

//persist
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ["auth", "app", "profile", "branches", "registration", "clients",
        "events", "employees", "reasons", "holidays", "contacts"],
    blacklist: []
}

export const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store: Store = createStore(persistedReducer, applyMiddleware(thunk))
export const persistor = persistStore(store)

// @ts-ignore
window.store = store;