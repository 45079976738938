import "./CheckboxInput.scss";
import React, { ChangeEvent, DetailedHTMLProps, FC, InputHTMLAttributes, useState } from "react";
import { FormDataModalType } from "../ModalsAlerts/ModalAlertEditWorkSchedule";
import { Path } from "react-hook-form";
import { EventsType } from "../../api/events-api";
import { AppRootStateType } from "../../redux/store";
import { useSelector } from "react-redux";


type DefaultInputPropsType = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

type CheckboxInputType = DefaultInputPropsType & {
    onChangeChecked?: (checked: boolean) => void
    spanClassName?: string
    register?: any
    id: string;
    label?: any;
    state?: string;
    name?: Path<FormDataModalType> | string;
    input_type?: string;
    disabled?: boolean;
    help_text?: string
    color?: any
}

const CheckboxInput: FC<CheckboxInputType> = (

    {
        type, state,
        onChange, onChangeChecked,
        className, spanClassName, register,
        children, id, help_text, color,
        label, disabled, name, defaultChecked,

        ...restProps
    }
) => {
    const onChangeCallback = (e: ChangeEvent<HTMLInputElement>) => {
        onChange
            && onChange(e)

        onChangeChecked
            && onChangeChecked(e.currentTarget.checked)
    }
    const customCheckboxClass = `
        checkbox checkbox_${state}
    `
    const [check, setCheck] = useState(true);
    const changeColor = () => {
        if (check) {
            setCheck(false)
        } else {
            setCheck(true)
        }
    }
    const events = useSelector<AppRootStateType, Array<EventsType>>(
        (states) => states.events.events.results

    );
    const idE= events.map(i=>i.id)
    const colorReason = events.map(c => c.reason_color)

    return (
        <div className={customCheckboxClass}>
            <input
                state={state}
                {...register && { ...register(name) }}
                type='checkbox'
                onChange={onChangeCallback}
                name={name}
                help_text={help_text}
                color={color}
                id={`id_${id}`}
                className="checkbox__input"
                defaultChecked={defaultChecked}
                disabled={disabled}
                label={label}
                {...restProps}
                onClick={changeColor}
            />
            <label className="checkbox__label" htmlFor={`id_${id}`} >

            {/*     <div key={`${id}`}className="checkbox__color" style={{
                    display: 'none',
                    height: '11px',
                    width: '11px',
                    float: 'right',
                    marginTop: '5px',
                    background: `${colorReason[1]}`,
                    borderRadius: '10px',
                    //opacity:0

                }}></div> */}

                <span className={check ? 'checkbox__text1' : 'checkbox__text'}><div className="d">{label}</div></span>
            </label>
        </div>
    )
}

export default CheckboxInput;


/*import "./CheckboxInput.scss";
import { FC } from "react";


type CheckboxInputType = {
    state?: string;
    name: string;
    id: string;
    value?: string;
    checked?: boolean;
    input_type?: string;
    disabled?: boolean;
    label?: string;
}

const CheckboxInput: FC<CheckboxInputType> = (props) => {
    return (
        <div className='checkbox' >
            <input
                className="checkbox__input"
                type="checkbox"
                name={props.name}
                id={`id_${props.id}`}
                value={props.value}
                disabled={props.disabled}
                checked={props.checked} />
            <label className="checkbox__label" htmlFor={`id_${props.id}`} >
                {props.label}
            </label>
        </div>
    )
}

export default CheckboxInput;*/
