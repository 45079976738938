export const calcGridSchedule = (startTimeWorkDay: string, endTimeWorkDay: string) => {
    // calculation of the grid depending on the start time of work and the end time of work
    const startTimeNumber = Number(startTimeWorkDay.slice(0, 2))
    const endTimeNumber = Number(endTimeWorkDay.slice(0, 2))
    const timeWorkDefault = [startTimeNumber]
    for (let i: any = startTimeNumber + 1; i <= endTimeNumber; i++) {
        timeWorkDefault.push(i)
    }

    const timeWorkDay = timeWorkDefault.map(hour => hour < 10 ? `0${hour.toString()}:00` : `${hour}:00`)
    const timeWorkDayEditStart = timeWorkDay.map((hour, index) =>
        index === 0 ? startTimeWorkDay.slice(0, 5) : hour)
    const timeWorkDayFinally = timeWorkDayEditStart.map((hour, index) =>
        hour === timeWorkDay[timeWorkDay.length - 1] ? endTimeWorkDay.slice(0, 5) : hour)

    // hour line height
    const fullHeight = 180
    const fullTime = 60
    const currentHeightLine = (hour: number) => {
        return hour === 0 ? fullHeight : (fullHeight * (fullTime - hour)) / fullTime
    }

    return {
        timeWorkDayFinally,
        currentHeightLine
    }
}