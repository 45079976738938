import { Dispatch } from "redux";
import { employeesAPI, EmployeeType } from "../api/employees-api";
import { loadToken } from "../utils/localStorage";
import { setAppStatusAC } from "./app-reducer";
import { handleServerAppError, handleServerNetworkError } from "../utils/error-utils";


const SET_LIST_EMPLOYEES = 'employeesReducer/SET_LIST_EMPLOYEES'
const ADD_EMPLOYEE = 'employeesReducer/ADD_EMPLOYEE'
const EDIT_EMPLOYEE = 'employeesReducer/EDIT_EMPLOYEE'
const REMOVE_EMPLOYEE = 'employeesReducer/REMOVE_EMPLOYEE'

const initialState: Array<EmployeeType> = []

export const employeesReducer = (state: Array<EmployeeType> = initialState, action: ActionsType): Array<EmployeeType> => {
    switch (action.type) {
        case SET_LIST_EMPLOYEES:
            return action.employees.map((tl) => ({ ...tl }))
        case ADD_EMPLOYEE: {
            return [...state,
            {
                id: action.payload.id,
                username: action.payload.username,
                first_name: action.payload.firstName,
                last_name: action.payload.lastName,
                branch_set: action.payload.branch_set,
                role: action.payload.role,
            }]
        }
        case EDIT_EMPLOYEE: {
            return state.map(e => e.id === action.payload.id
                ? {
                    ...e,
                    username: action.payload.username,
                    first_name: action.payload.firstName,
                    last_name: action.payload.lastName,
                    role: action.payload.role
                }
                : e)
        }
        case REMOVE_EMPLOYEE: {
            return state.filter(e => e.id !== action.id)
        }
        default:
            return state
    }
}

// actions
export const setListEmployeesAC = (employees: Array<EmployeeType>) =>
    ({ type: SET_LIST_EMPLOYEES, employees } as const)
export const addEmployeeAC = (id: number, username: string,
    firstName: string, lastName: string, branch_set: [], role: any) =>
({
    type: ADD_EMPLOYEE,
    payload: { id, username, firstName, lastName, branch_set, role }
} as const)
export const editEmployeeAC = (id: number, username: string, firstName: string, lastName: string, role: any) =>
    ({ type: EDIT_EMPLOYEE, payload: { id, username, firstName, lastName, role } } as const)
export const removeEmployeesAC = (id: number) =>
    ({ type: REMOVE_EMPLOYEE, id } as const)


// thunks
export const fetchEmployeesListTC = () => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        let res = await employeesAPI.getList(tokenLS)
        dispatch(setListEmployeesAC(res.data))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

export const addEmployeesTC = (data: any) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        let res = await employeesAPI.addEmployee(data, tokenLS)
        dispatch(addEmployeeAC(res.data.id,
            res.data.username,
            res.data.first_name,
            res.data.last_name,
            res.data.branch_set,
            res.data.role))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
}

export const editEmployeeTC = (id: number, data: any) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        let res = await employeesAPI.editEmployee(data, tokenLS, id)
        dispatch(editEmployeeAC(res.data.id, res.data.username, res.data.first_name, res.data.last_name, res.data.role))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
}

export const removeEmployeeTC = (employeeId: number) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = loadToken()
        await employeesAPI.removeEmployee(tokenLS, employeeId)
        dispatch(removeEmployeesAC(employeeId))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

//types
type ActionsType = ReturnType<typeof setListEmployeesAC>
    | ReturnType<typeof addEmployeeAC>
    | ReturnType<typeof editEmployeeAC>
    | ReturnType<typeof removeEmployeesAC>