import React, { FC, memo } from "react";
import { EventSchedule } from "./EventSchedule/EventSchedule";
import { DateOfListType, ScheduleBranchType } from "../../../api/branches-api";
import dayjs from "dayjs";

type DayOfTheWeekPropsType = {
    dayIndex: number
    dayName: string
    setDate: (date: string) => Date
    scheduleBranchDate: Array<DateOfListType>
    scheduleBranch: ScheduleBranchType
    timeWorkDayFinally: Array<string>
    onFreeDateClick: (date: string, time: string) => void
    currentHeightLine: (hour: number) => number
    setDateForModalAlert: (date: string, date_of_lists: DateOfListType) => void
    onStatusEventClick: (eventId: number) => void
    onStatusEventDoctorClick: (eventId: number) => void
    onStatusEventNotClick: (eventId: number) => void
    onStatusEventDoneClick: (eventId: number) => void
}

export const DayOfTheWeek: FC<DayOfTheWeekPropsType> = memo((
    {
        setDate, scheduleBranchDate, scheduleBranch,
        timeWorkDayFinally, onFreeDateClick, currentHeightLine,
        dayIndex, dayName, setDateForModalAlert, onStatusEventClick,
        onStatusEventNotClick, onStatusEventDoctorClick, onStatusEventDoneClick,
    }) => {

    const weeklyWorkSchedule = timeWorkDayFinally.map((time) => ({
        time,
        days: scheduleBranchDate
    }))

    const minuteGrid = ["00", "05", "10", "15", "20", "25",
        "30", "35", "40", "45", "50", "55",]

    const dayNow = dayjs().format("YYYY-MM-DD")

    const DayNowBlockStyle = scheduleBranchDate[dayIndex].date === dayNow ? "dayNow" : "day"
    const WeekDateNowBlockStyle = scheduleBranchDate[dayIndex].date === dayNow ? "dayDateNow" : "dayDate"

    const DayWeekendBlockStyle = scheduleBranchDate[dayIndex].holidays.length ? "dayHoliday" : "day"
    const WeekDateWeekendBlockStyle = scheduleBranchDate[dayIndex].holidays.length ? "dayDateHoliday" : "dayDate"

    const generalDayStyle = scheduleBranchDate[dayIndex].date === dayNow &&
        scheduleBranchDate[dayIndex].holidays.length
        ? DayWeekendBlockStyle
        : scheduleBranchDate[dayIndex].date === dayNow
            ? DayNowBlockStyle
            : scheduleBranchDate[dayIndex].holidays.length
                ? DayWeekendBlockStyle
                : "day"


    const generalDateStyle = scheduleBranchDate[dayIndex].date === dayNow &&
        scheduleBranchDate[dayIndex].holidays.length
        ? WeekDateWeekendBlockStyle
        : scheduleBranchDate[dayIndex].date === dayNow
            ? WeekDateNowBlockStyle
            : scheduleBranchDate[dayIndex].holidays.length
                ? WeekDateWeekendBlockStyle
                : "dayDate"

    const currentHoliday = scheduleBranchDate[dayIndex].holidays.length
        ? scheduleBranchDate[dayIndex].holidays[0].name
        : ''

    return (
        <div className={generalDayStyle}
            title={generalDayStyle === "dayHoliday" ? currentHoliday : ""}>
            <div className={generalDateStyle}>
                <span className={"dayDate_weekDay"}>{dayName}</span>
                <span className={"dayDate_date"}>
                    {setDate(scheduleBranchDate[dayIndex] &&
                        scheduleBranchDate[dayIndex].date)
                        .toLocaleDateString().slice(0, 5)}
                </span>
            </div>

            <EventSchedule
                events={scheduleBranch.date_of_lists[dayIndex] &&
                    scheduleBranch.date_of_lists[dayIndex].events}
                dayIndex={dayIndex}
                scheduleBranchDate={scheduleBranchDate}
                timeWorkDayFinally={timeWorkDayFinally}
                setDateForModalAlert={setDateForModalAlert}
                onFreeDateClick={onFreeDateClick}
                onStatusEventClick={onStatusEventClick}
                onStatusEventDoctorClick={onStatusEventDoctorClick}
                onStatusEventNotClick={onStatusEventNotClick}
                onStatusEventDoneClick={onStatusEventDoneClick}
            />

            <div className="dayDate_free-day-block">
                {
                    weeklyWorkSchedule && weeklyWorkSchedule.map((day, index) => (
                        <div key={index}
                            style={{ height: `${currentHeightLine(Number(day.time.slice(3, 5)))}px` }}
                            className="dayDate_free-day-block_free-day">
                            <div className="free-time-block">
                                {
                                    minuteGrid.map((minute, index) =>
                                        <div key={index}
                                            className="time-elem"
                                            onClick={() => {
                                                onFreeDateClick(day.days[dayIndex].date,
                                                    `${day.time.slice(0, 3)}${minute}`)
                                            }}
                                        >
                                        </div>)
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
)