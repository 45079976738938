import React, {useEffect, useMemo, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import "./FormsContacts.scss";
import "../../../components/Widgets/Button/Button.scss";
import {useDispatch, useSelector} from "react-redux";
import {Navigate, useParams} from "react-router-dom";
import {PATH} from "../../Routes";
import Inputs from "../../../components/Widgets/Inputs";
import {validation} from "../../../utils/validation";
import {fetchContactsListFieldsTC} from "../../../redux/fields-reducer";
import {
    defaultChoicesMultiSelect,
    newOption,
} from "../../../components/Widgets/Select/ReactSelectStyles";
import {ProfileSideBar} from "../../Profile/ProfileSideBar/ProfileSideBar";
import {ReactSelect} from "../../../components/Widgets/Select/ReactSelect";
import { addContactTC, editContactTC, fetchContactsList } from "../../../redux/contacts-reducer";
import {useErrorHandler} from "../../../utils/useErrorHandler";
import {
    getContactsSelector, getErrorStateSelector, getFieldsContactsSelector,
    getIsLoggedInSelector,
    getStatusSelector
} from "../../../redux/selectors";


type FormDataType = {
    branches: { value: any, label: any }
    title: string
    email: string
    phone: number
    address: string

}

export const FormsContacts = () => {
    const dispatch = useDispatch();

    const contacts = useSelector(getContactsSelector);
    const status = useSelector(getStatusSelector);
    const isLoggedIn = useSelector(getIsLoggedInSelector);
    const errorState = useSelector(getErrorStateSelector);
    const fields = useSelector(getFieldsContactsSelector);

    const [valueReason, setValueReason] = useState<boolean>(false)

    //for edit reason
    const params = useParams();
    const idUrl = params.id
    const contactId = Number(idUrl)
    const currentContact = contacts.length && contacts.filter(res => res.id === contactId)[0]

    //validation
    const formDataArray = ["branches", "title", "email", "phone",
        "address"]

    const [errorGeneral, setErrorGeneral] = useState<string | null>(null)

    const branchesErrorHandler = useErrorHandler("branches", errorState, formDataArray)
    const titleErrorHandler = useErrorHandler("title", errorState, formDataArray)
    const emailErrorHandler = useErrorHandler("email", errorState, formDataArray)
    const phoneErrorHandler = useErrorHandler("phone", errorState, formDataArray)
    const addressErrorHandler = useErrorHandler("address", errorState, formDataArray)

    // for select
    const choicesBranches = Object.entries(fields.branches.choices).map(br => br)
    const valueOptionBranches = fields && Object.entries(fields.branches.choices).map((b: any) => newOption(b[0], b[1]))
    const labelOptionBranches = currentContact && currentContact.branches.map((el: any) => el.toString())


    const reasonData = currentContact
        ? {
            title: currentContact.title,
            email: currentContact.email,
            phone: currentContact.phone,
            address: currentContact.address,
            branches: currentContact
                ? defaultChoicesMultiSelect(valueOptionBranches, labelOptionBranches)
                : '',
        }
        : {
            title: fields.title.initial_value,
            email: fields.email.initial_value,
            phone: fields.phone.initial_value,
            address: fields.address.initial_value,
        }

    useEffect(() => {
        if (validation(errorState, formDataArray).testErrorField.length === 0) {
            if (typeof errorState === "object") {
                setErrorGeneral(errorState && Object.values(errorState)[0])
            } else {
                setErrorGeneral(errorState)
            }
        }
        return () => {
            setErrorGeneral(null)
        };
    }, [status])

    useEffect(() => {
        if (reasonData) {
            reset(reasonData)
        }
    }, [currentContact, fields])

    useEffect(() => {
        dispatch(fetchContactsListFieldsTC())
        dispatch(fetchContactsList())
    }, [dispatch])

    useEffect(() => {
        if (status === "failed") {
            branchesErrorHandler.setErrorCallback()
            titleErrorHandler.setErrorCallback()
            emailErrorHandler.setErrorCallback()
            phoneErrorHandler.setErrorCallback()
            addressErrorHandler.setErrorCallback()
        }
    }, [status])

    const {
        register,
        handleSubmit,
        control,
        reset,
    } = useForm<FormDataType>({
        mode: 'onBlur',
        defaultValues: useMemo(() => {
            if (reasonData) {
                return reasonData
            }
        }, [reasonData, fields])
    });

    const onSubmit: SubmitHandler<FormDataType> = (data) => {
        const newData = {
            branches: data.branches && Object.values(data.branches).map((br: any) => br.value),
            title: data.title,
            email: data.email ,
            phone: data.phone,
            address: data.address,
        }
        if (currentContact) {
            dispatch(editContactTC(contactId, newData))

        } else {
            dispatch(addContactTC(newData))
        }
        setValueReason(true)
    }

    if (valueReason && status === "succeeded") {
        return <Navigate to={PATH.CONTACTS}/>
    }

    if (!isLoggedIn) {
        return <Navigate to={PATH.LOGIN}/>;
    }

    return (
        <>
            <ProfileSideBar/>
            <div className="add-reason" onClick={() => {
                setErrorGeneral(null)
            }}>
                <h1 className="add-reason__title">{currentContact ? "Contact edit" : "Contact add"}</h1>
                <form onSubmit={handleSubmit(onSubmit)} className="add-reason__form">

                    <label className="add-reason__inputs-label">Info</label>
                    <div className="add-reason__inputs-info">
                        <Inputs
                            error={titleErrorHandler.error}
                            help_text={fields.title.help_text || titleErrorHandler.error && titleErrorHandler.errorMessageCurrentField[1]}
                            onClick={titleErrorHandler.onFieldClick}
                            state={"active"}
                            register={register}
                            input_type={fields.title.input_type}
                            name={"title"}
                            label={fields.title.label}
                            defaultValue={currentContact ? currentContact.title : fields.title.initial_value}
                            resetForm={reset}
                            {...reset}
                        />
                        <Inputs
                            error={emailErrorHandler.error}
                            help_text={fields.email.help_text || emailErrorHandler.error&& emailErrorHandler.errorMessageCurrentField[1]}
                            onClick={emailErrorHandler.onFieldClick}
                            state={"active"}
                            register={register}
                            input_type='email'
                            name={"email"}
                            label={fields.email.label}
                            defaultValue={currentContact ? currentContact.email : fields.email.initial_value}
                            resetForm={reset}
                            {...reset}
                        />
                    </div>
                    <div className="add-reason__inputs-setting">

                        <Inputs
                            error={phoneErrorHandler.error}
                            help_text={fields.phone.help_text || phoneErrorHandler.error && phoneErrorHandler.errorMessageCurrentField[1]}
                            onClick={phoneErrorHandler.onFieldClick}
                            state={"active"}
                            register={register}
                            name={"phone"}
                            label={fields.phone.label}
                            defaultValue={currentContact ? currentContact.phone : fields.phone.initial_value}
                            resetForm={reset}
                            {...reset}
                        />
                        <Inputs
                            error={addressErrorHandler.error}
                            help_text={fields.address.help_text || addressErrorHandler.error && addressErrorHandler.errorMessageCurrentField[1]}
                            onClick={addressErrorHandler.onFieldClick}
                            state={"active"}
                            register={register}
                            input_type={"text"}
                            name={"address"}
                            label={fields.address.label}
                            defaultValue={currentContact ? currentContact.address : fields.address.initial_value}
                            resetForm={reset}
                            {...reset}
                        />
                    </div>
                    <label className="add-reason__inputs-label">{fields.branches.label}</label>
                    <div className="add-reason__inputs-branch">
                        <ReactSelect
                            name="branches"
                            error={branchesErrorHandler.error}
                            help_text={fields.branches.help_text || branchesErrorHandler.error && branchesErrorHandler.errorMessageCurrentField[1]}
                            label={fields.branches.label}
                            placeholder={fields.branches.label}
                            onClick={branchesErrorHandler.onFieldClick}
                            control={control}
                            isMulti={true}
                            options={choicesBranches && Object.entries(fields.branches.choices).map((b: any) => newOption(b[0], b[1]))}
                        />
                    </div>
                    <div className="_button-wrapper">

                        <button
                            className='button button_size-middle button_position-right button_color-black'>Sichern
                        </button>
                    </div>
                    {
                        (status === 'failed' && errorGeneral) &&
                        <p className="general-error">{errorGeneral}</p>
                    }
                </form>
            </div>
        </>
    )
}
