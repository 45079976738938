import { Dispatch } from "redux";
import { loadToken } from "../utils/localStorage";
import { reasonsAPI, ReasonType } from "../api/reasons-api";
import { setAppStatusAC } from "./app-reducer";
import { handleServerAppError, handleServerNetworkError } from "../utils/error-utils";


const SET_LIST_REASONS = 'reasonsReducer/SET_LIST_REASONS'
const ADD_REASON = 'reasonsReducer/ADD_REASON'
const EDIT_REASON = 'reasonsReducer/EDIT_REASON'
const REMOVE_REASON = 'reasonsReducer/REMOVE_REASON'

const initialState: Array<ReasonType> = []

export const reasonsReducer = (state: Array<ReasonType> = initialState, action: ActionsType): Array<ReasonType> => {
    switch (action.type) {
        case SET_LIST_REASONS: {
            return action.reasons.map((tl) => ({ ...tl }))
        }
        case ADD_REASON: {
            return [...state, action.model]
        }
        case EDIT_REASON: {
            return state.map(e => e.id === action.model.id
                ? { ...e, ...action.model }
                : e)
        }
        case REMOVE_REASON: {
            return state.filter(e => e.id !== action.id)
        }
        default:
            return state
    }
}

// actions
export const setListReasonsAC = (reasons: Array<ReasonType>) => ({ type: SET_LIST_REASONS, reasons } as const)
export const addReasonAC = (model: ReasonType) => ({ type: ADD_REASON, model } as const)
export const editReasonAC = (model: ReasonModelType) => ({ type: EDIT_REASON, model } as const)
export const removeReasonAC = (id: number) => ({ type: REMOVE_REASON, id } as const)

// thunks
export const fetchReasonsList = () => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        let res = await reasonsAPI.getList(tokenLS)
        dispatch(setListReasonsAC(res.data))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

export const addReasonTC = (data: any) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        let res = await reasonsAPI.addReason(data, tokenLS)
        dispatch(addReasonAC(res.data))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
}

export const editReasonTC = (id: number, data: any) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        let res = await reasonsAPI.editReason(data, tokenLS, id)
        dispatch(editReasonAC(res.data))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
}

export const removeReasonTC = (reasonId: number) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = loadToken()
        await reasonsAPI.removeReason(tokenLS, reasonId)
        dispatch(removeReasonAC(reasonId))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
}

//types
type ActionsType = ReturnType<typeof setListReasonsAC>
    | ReturnType<typeof addReasonAC>
    | ReturnType<typeof editReasonAC>
    | ReturnType<typeof removeReasonAC>

export type ReasonModelType = {
    id?: number
    branches?: Array<number>
    title?: string
    offset_time?: number
    limit_time?: number
    color?: string
    order?: number
}