import React, { FC, useEffect, useState } from "react";
import "./ClientsFilter.scss"
import { useDispatch, useSelector } from "react-redux";
import {
    setCurrentParamBranchAC,
    searchOfFullNameClientsTC,
    setFilteredClientsOfInsuranceTC,
    fetchFilteredClientsListOfGeneral,
} from "../../../redux/clients-filter-reducer";
import CheckboxInput from "../../../components/Widgets/CheckboxInput";
import { SubmitHandler, useForm } from "react-hook-form";
import Inputs from "../../../components/Widgets/Inputs";
import { getScheduleBranchActiveWeekTC, getScheduleBranchTC, setCurrentBranchesAC } from "../../../redux/branches-reducer";
import { setIsLoggedInAC } from "../../../redux/auth-reducer";
import Radio from "../../../components/Widgets/Radio";
import {
    getActiveWeekSelector,
    getCurrentBranchSelector,
    getCurrentInsuranceParamsClientsSelector,
    getFilterParamsClientsSelector
} from "../../../redux/selectors";


type FormDataType = {
    insurance_type: string
}

type EventsSideBarPropsType = {}

export const ClientsFilter: FC<EventsSideBarPropsType> = () => {
    const dispatch = useDispatch()

    const filterParams = useSelector(getFilterParamsClientsSelector);
    const currentInsuranceParams = useSelector(getCurrentInsuranceParamsClientsSelector);
    const currentBranch = useSelector(getCurrentBranchSelector);
    const activeWeek = useSelector(getActiveWeekSelector);

    const [stateBranches, setStateBranches] = useState<boolean>(true)
    const [stateInsurance, setStateInsurance] = useState<boolean>(true)

    useEffect(() => {
        if (currentInsuranceParams.length) {
            setStateInsurance(true)
        }

    }, [])

    const choicesInsurance = filterParams.insurance_type__in && Object.entries(filterParams.insurance_type__in.choices).map(br => br);
    const choicesBranch = filterParams.branches__in && Object.entries(filterParams.branches__in.choices).map(br => br);
    const search = filterParams.search && Object.entries(filterParams.search).map(l => l[1])

    const filterInsurance = (params: string) => {
        dispatch(setFilteredClientsOfInsuranceTC(params))
    }

    const onSearchChange = (event: string) => {
        dispatch(searchOfFullNameClientsTC(event))
    }

    const filterBranch = (params: string) => {
        dispatch(setCurrentParamBranchAC(params))
        dispatch(fetchFilteredClientsListOfGeneral())

        if (activeWeek) {
            dispatch(getScheduleBranchActiveWeekTC(Number(params)))
        } else {
            dispatch(getScheduleBranchTC(Number(params)))
        }
        dispatch(setCurrentBranchesAC(Number(params)))
        dispatch(setIsLoggedInAC(true))
    }

    const {
        register,
        handleSubmit,
    } = useForm<FormDataType>({
        mode: 'onBlur',
    });

    const onSubmit: SubmitHandler<FormDataType> = data => {

    }

    return (
        <form className="form-client-filter" onSubmit={handleSubmit(onSubmit)}>
            <div className="filter-block">
                <div className="filter-title1"
                    onClick={() => {
                        setStateBranches(!stateBranches)
                    }}
                >Branches</div>
                {
                    filterParams.branches__in && choicesBranch.map((branches, index) =>
                        <div className={stateBranches ? "filter-param1 param-branch" : 'filter-param-none'} key={index}>
                            <Radio
                                id={`${index}`}
                                state={"small"}
                                checked={Number(branches[0]) === currentBranch}
                                onChange={() => {
                                    filterBranch(branches[0])
                                }}
                                name={"branches"}
                                value={branches[0]}
                                label={branches[1]}
                            />
                        </div>
                    )
                }
            </div>
            <div className="search">
                <Inputs
                    state="search"
                    label={search[5]}
                    name="search"
                    style={{
                        height: "50px",
                        paddingTop: "0",
                        marginBottom: "0"
                    }}
                    onChangeText={onSearchChange}
                    register={register}
                />
            </div>
            <div className="filter-block">
                <div className="filter-title"
                    onClick={() => {
                        setStateInsurance(!stateInsurance)
                    }}
                >Versicherung
                </div>
                {
                    filterParams.insurance_type__in && choicesInsurance.map((insurance, index) =>
                        <div className="filter-param" key={index}>
                            <CheckboxInput
                                id={insurance[0][1]}
                                state={"square"}
                                checked={currentInsuranceParams.find(ci => ci === insurance[0]) === insurance[0]}
                                onChange={() => {
                                    filterInsurance(insurance[0])
                                }}
                                name={"insurance_type"}
                                value={insurance[0]}
                                label={insurance[1]}
                            />
                        </div>
                    )
                }
            </div>
        </form>
    )
}