import React, {FC} from "react";
import {NavLink} from "react-router-dom";
import {PATH} from "../../Routes";
import {logo} from "../../../assets/img/image";
import {useDispatch, useSelector} from "react-redux";
import {useLogout} from "../../../utils/logout";
import {editStateMenuAC} from "../../../redux/app-reducer";
import {getOpenMenuAppSelector, getUserProfileSelector} from "../../../redux/selectors";

type HeaderPropsType = {}

const baseURL = process.env.REACT_APP_BASE_URL;

export const Header: FC<HeaderPropsType> = () => {
    const dispatch = useDispatch();

    const openMenu = useSelector(getOpenMenuAppSelector);
    const user = useSelector(getUserProfileSelector);

    const editStateMenu = () => {
        dispatch(editStateMenuAC(!openMenu))
    }

    const closeMenu = () => {
        if (openMenu) {
            dispatch(editStateMenuAC(false))
        }
    }

    const logoutHandler = useLogout()

    const classNameMenu = openMenu ? "profile-menu-open" : "profile-menu"
    // const userPhoto = user.photo ? `http://api.terminow.de${user.photo}` : logo.notLogo;
    const userPhoto = user.photo ? `${baseURL}${user.photo}` : logo.notLogo;

    return (
        <header className="admin__header">
            <nav className="admin__header_nav">
                <ul className="admin__header_nav_list">
                    <li className="admin__header_nav_list_item"
                        onClick={closeMenu}>
                        <NavLink
                            className="admin__header_nav_list_item_link"
                            to={PATH.SCHEDULE}><span
                            className="span">Schedule</span></NavLink>
                    </li>
                    <li className="admin__header_nav_list_item"
                        onClick={closeMenu}>
                        <NavLink
                            className="admin__header_nav_list_item_link"
                            to={PATH.EVENTS}><span
                            className="span">Events</span></NavLink>
                    </li>
                    <li className="admin__header_nav_list_item"
                        onClick={closeMenu}>
                        <NavLink
                            className="admin__header_nav_list_item_link"
                            to={PATH.WAITING_ROOM}><span
                            className="span">Waiting room</span></NavLink>
                    </li>
                    <li className="admin__header_nav_list_item"
                        onClick={closeMenu}>
                        <NavLink
                            className="admin__header_nav_list_item_link"
                            to={PATH.CLIENTS}><span
                            className="span">Clients</span></NavLink>
                    </li>
                    <li className="admin__header_nav_list_item"
                        onClick={closeMenu}>
                        <NavLink
                            className="admin__header_nav_list_item_link"
                            to={PATH.ORDERS}><span
                            className="span">Orders</span></NavLink>
                    </li>
                </ul>
            </nav>
            <div className="profile">
                <div className="user">
                    <div className="user-name" onClick={editStateMenu}>
                        <span className="first-name">{user.first_name}</span>
                        <span className="last-name">{user.last_name}</span>
                        {
                            openMenu ?
                                <img src={logo.arrow_up} alt='up' className="arrow"/>
                                : <img src={logo.arrow_down} alt='down'
                                       className="arrow"/>
                        }
                    </div>
                    <img src={userPhoto}
                         className="user-photo"
                         alt="user"/>
                </div>
                <div className={classNameMenu}>
                    <nav className="nav">
                        <ul className="menu-list">
                            <li className="list-item" onClick={editStateMenu}>
                                <NavLink className='item-link'
                                         to={PATH.PROFILE}>Profile</NavLink>
                            </li>
                            <li className="list-item" onClick={editStateMenu}>
                                <NavLink className='item-link'
                                         to={PATH.BRANCHES}>Branches</NavLink>
                            </li>
                            <li className="list-item" onClick={editStateMenu}>
                                <NavLink className='item-link'
                                         to={PATH.EMPLOYEES}>Employees</NavLink>
                            </li>
                            <li className="list-item" onClick={editStateMenu}>
                                <NavLink className='item-link'
                                         to={PATH.REASONS}>Reasons</NavLink>
                            </li>
                            <li className="list-item" onClick={editStateMenu}>
                                <NavLink className='item-link'
                                         to={PATH.WORK_SCHEDULE}>Work
                                    schedule</NavLink>
                            </li>
                            <li className="list-item" onClick={editStateMenu}>
                                <NavLink className='item-link'
                                         to={PATH.HOLIDAYS}>Holidays</NavLink>
                            </li>

                            {/*<li className="list-item" onClick={editStateMenu}>*/}
                            {/*    <NavLink className='item-link'*/}
                            {/*             to={""}>iFrame</NavLink>*/}
                            {/*</li>*/}
                            <li className="list-item" onClick={editStateMenu}>
                                <NavLink className='item-link'
                                         to={PATH.CONTACTS}>Contacts</NavLink>
                            </li>

                            <li className="list-item" onClick={logoutHandler}>
                                <NavLink className='item-link'
                                         to={""}>Logout</NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    )
}

