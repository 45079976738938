import { Dispatch } from "redux";
import {
    DataOfClientsType,
    DateInfoOfClientType,
    registrationAPI, WorkScheduleInfoType
} from "../api/registration-api";
import { handleServerAppError, handleServerNetworkError } from "../utils/error-utils";
import { setAppStatusAC } from "./app-reducer";


const GET_DATE_REGISTRATION = 'registrationReducer/GET_DATE_REGISTRATION'
const SET_DATA_STEP1 = 'registrationReducer/SET_DATA_STEP1'
const GET_BRANCH_INFO = 'registrationReducer/GET_BRANCH_INFO'

const initialState = {
    chosenBranch: 0,
    chosenInsuranceType: '',
    chosenReason: '',
    chosenDate: '',
    branchInfo: {
        official: [],
        private: [],
        reasons: [],
        workschedule: [
            {} as WorkScheduleInfoType
        ] as Array<WorkScheduleInfoType>
    } as DateInfoOfClientType,
    clientsData: {} as DataOfClientsType
}

export type InitialStateType = {
    chosenBranch: number
    chosenInsuranceType?: string
    chosenReason?: string
    chosenDate?: string
    branchInfo: DateInfoOfClientType
    clientsData: DataOfClientsType
}

export const registrationReducer = (state: InitialStateType = initialState, action: ActionsType): InitialStateType => {
    switch (action.type) {
        case GET_DATE_REGISTRATION:
            return { ...state, clientsData: action.data }
        case GET_BRANCH_INFO:
            return { ...state, branchInfo: action.data }
        case SET_DATA_STEP1:
            return {
                ...state,
                chosenBranch: action.payload.branchId,
                chosenInsuranceType: action.payload.chosenInsuranceType,
                chosenReason: action.payload.chosenReason,
                chosenDate: action.payload.chosenDate
            }
        default:
            return state
    }
}

// actions
export const getDateRegistration = (data: DataOfClientsType) =>
    ({ type: GET_DATE_REGISTRATION, data } as const)
export const setDataStep1 =
    (branchId: number, chosenInsuranceType?: string, chosenReason?: string, chosenDate?: string) =>
    ({
        type: SET_DATA_STEP1, payload: {
            branchId,
            chosenInsuranceType,
            chosenReason,
            chosenDate
        }
    } as const)
export const getBranchInfoAC = (data: DateInfoOfClientType) =>
    ({ type: GET_BRANCH_INFO, data } as const)

// thunks
export const getDateRegistrationTC =
    (branchId: number, reasonId: number, week: number) => async (dispatch: Dispatch<any>) => {
        dispatch(setAppStatusAC("loading"))
        try {
            const res = await registrationAPI.getClientWeek(branchId, reasonId, week);
            dispatch(getDateRegistration(res.data));
            dispatch(setAppStatusAC("succeeded"))
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
        dispatch(setAppStatusAC("idle"))
    };

export const getDateRegistrationFirstRenderTC =
    (branchId: number, reasonId: number) => async (dispatch: Dispatch<any>) => {
        dispatch(setAppStatusAC("loading"))
        try {
            const res = await registrationAPI.getClientWeekFirstRender(branchId, reasonId);
            dispatch(getDateRegistration(res.data));
            dispatch(setDataStep1(branchId, undefined, reasonId.toString(), undefined))
            dispatch(setAppStatusAC("succeeded"))
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
        dispatch(setAppStatusAC("idle"))
    };

export const createClientEventTC =
    (data: any, branchId: number) => async (dispatch: Dispatch<any>) => {
        dispatch(setAppStatusAC("loading"))
        try {
            const res = await registrationAPI.createClientEvent(data, branchId);
            dispatch(setAppStatusAC("succeeded"))
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
    };

export const getBranchInfoTC =
    (branchId: number) => async (dispatch: Dispatch<any>) => {
        dispatch(setAppStatusAC("loading"))
        try {
            const res = await registrationAPI.getClientBranchInfo(branchId);
            dispatch(getBranchInfoAC(res.data))
            dispatch(setAppStatusAC("succeeded"))
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
        dispatch(setAppStatusAC("idle"))
    };

//types
type ActionsType = ReturnType<typeof getDateRegistration>
    | ReturnType<typeof setDataStep1>
    | ReturnType<typeof getBranchInfoAC>
