
export const newOption = (value: string, label: string) => {
    return {
        value,
        label
    }
}

export const defaultChoicesMultiSelect = (allChoices: any, currentReasonString: any) => {
    return allChoices.filter((el: any) => currentReasonString.indexOf((el.value)) > -1)
}
export const defaultChoicesSelect = (allChoices: any, currentReasonString: any) => {
    return allChoices.filter((el: any) => el.value === currentReasonString)
}

export const customStyles = {
    control: (base: any) => ({
        ...base,
        width: "100%",
        minHeight: "64px",
        border: "2px solid #D6D8E7",
        borderRadius: "16px",
        boxShadow: 'none',
        "&:hover": {
            border:` 2px solid #2a2a30`,
            backgroundColor: `#ffffff`,
        },
    }),
    placeholder: (base: any) => ({
        ...base,
        position: "relative",
        // top: "-12px",
        left: "-10px",
        margin: "8px",

    }),
    input: (base: any) => ({
        ...base,
        width: "200px",
        // margin: "8px 8px 8px 60px",
        margin: "8px 8px 8px 0px",
    }),
    multiValueLabel: (base: any) => ({
        ...base,
        width: "100%",
        height: "100%",
        "&:hover": {
            paddingTop: "-10px",
            cursor: "pointer"
        }
    }),
    valueContainer: (base: any) => ({
        ...base,
        position: "relative",
        top: "12px",
        left: "14px",
        width: "100%",
        height: "100%",
    }),
    menu : (base:any)=> ({
        ...base,
       fontSize:'17px'

        //backgroundColor: `#737484`,
        //maxHeight:'260px'

    })
}

export const customStylesMulti = {
    control: (base: any) => ({
        ...base,
        width: "100%",
        minHeight: "64px",
        border: "2px solid #D6D8E7",
        borderRadius: "16px",
        boxShadow: 'none',
        "&:hover": {
            border:` 2px solid #2a2a30`,
            backgroundColor: `#ffffff`,
        },
    }),
    placeholder: (base: any) => ({
        ...base,
        position: "relative",
        top: "-5px",
        left: "-10px",
        margin: "8px",

    }),
    input: (base: any) => ({
        ...base,
        width: "200px",
        //height:"300px",
        // margin: "8px 8px 8px 60px",
        margin: "8px 8px 8px 0px",
    }),
    multiValueLabel: (base: any) => ({
        ...base,
        width: "100%",
        height: "100%",
        "&:hover": {
            paddingTop: "-10px",
            cursor: "pointer"
        }
    }),
    valueContainer: (base: any) => ({
        ...base,
        position: "relative",
        // top: "12px",
        left: "14px",
        width: "100%",
        height: "100%",
        paddingTop: "8px"
    }),
}

export const customStylesError = {

    control: (base: any) => ({

        ...base,
        width: "100%",
        minHeight: "64px",
        border: "2px solid #ed2e7e",
        borderRadius: "16px",
        boxShadow: 'none',
        backgroundColor: `#fff3f8`,
        "&:hover": {
            border:` 2px solid #2a2a30`,
            backgroundColor: `#ffffff`
        },
    }),
    placeholder: (base: any) => ({
        ...base,
        position: "relative",
        top: "0px",
        left: "-10px",
        margin: "8px",
        color: "#c30052",
    }),
    input: (base: any) => ({
        ...base,
        width: "200px",
        margin: "8px 8px 8px 60px",
    }),
    multiValueLabel: (base: any) => ({
        ...base,
        width: "100%",
        height: "100%",
        "&:hover": {
            paddingTop: "-10px",
            background: "red"
        }
    }),
    valueContainer: (base: any) => ({
        ...base,
        position: "relative",
        // top: "12px",
        left: "18px",
        width: "100%",
        height: "100%",
    }),
}