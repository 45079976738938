import { AppRootStateType } from "../store";
import { EventDetailType, EventFilterParamsType, EventsType } from "../../api/events-api";

export const getEventsSelector = (state: AppRootStateType): Array<EventsType> =>
    state.events.events.results;

export const getPreviousEventsSelector = (state: AppRootStateType): string | null =>
    state.events.events.links.previous;

export const getNextEventsSelector = (state: AppRootStateType): string | null =>
    state.events.events.links.next;

export const getCurrentPagePaginationEventsSelector = (state: AppRootStateType): number =>
    state.events.events.current_page;

export const getCurrentEventsSelector = (state: AppRootStateType): number =>
    state.events.events.current;

export const getCurrentPageSelector = (state: AppRootStateType): string | null =>
    state.events.currentPage;

export const getCurrentOrderingSelector = (state: AppRootStateType): string | null =>
    state.events.currentOrdering;

export const getChosenDaySelector = (state: AppRootStateType): {
    date: string
    time: string
} | null =>
    state.events.chosenDayInSchedule;

export const getDetailsEventsSelector = (state: AppRootStateType): EventDetailType =>
    state.events.currentDetails;

export const getFilterParamsEventsSelector = (state: AppRootStateType): EventFilterParamsType =>
    state.eventsFilter.eventFilterParamsList;

export const getCurrentInsuranceParamsEventsSelector = (state: AppRootStateType): Array<string> =>
    state.eventsFilter.currentFilterParams.insurance_type__in;

export const getCurrentReasonParamsEventsSelector = (state: AppRootStateType): Array<string> =>
    state.eventsFilter.currentFilterParams.reason__in;

export const getCurrentStatusParamsEventsSelector = (state: AppRootStateType): Array<string> =>
    state.eventsFilter.currentFilterParams.status__in;

export const getCurrentColorParamsEventsSelector = (state: AppRootStateType): Array<string> =>
    state.eventsFilter.currentFilterParams.reason__color;
export const getCurrentBranchParamsEventsSelector = (state: AppRootStateType): Array<string> =>
    state.eventsFilter.currentFilterParams.branch;
