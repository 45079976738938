import React, { FC, useEffect, useState } from "react";
import "./EventsFilter.scss"
import { useDispatch, useSelector } from "react-redux";
import {
    searchOfFullNameTC,
    setFilteredEventsOfReasonTC,
    setFilteredEventsOfInsuranceTC,
    setFilteredEventsOfStatusTC, fetchFilteredEventsParamsListTC, setFilteredEventsOfBranchTC,/*  setFilteredEventsOfReasonColorTC */
} from "../../../redux/events-filter-reducer";
import CheckboxInput from "../../../components/Widgets/CheckboxInput";
import { logo } from "../../../assets/img/image";
import Radio from "../../../components/Widgets/Radio";
import Inputs from "../../../components/Widgets/Inputs";
import { useForm } from "react-hook-form";
import {

    getScheduleBranchActiveWeekTC,
    getScheduleBranchTC, setCurrentBranchesAC
} from "../../../redux/branches-reducer";
import {
    getActiveWeekSelector,
    getBranchesSelector,
    getCurrentBranchSelector, getCurrentColorParamsEventsSelector,
    getCurrentInsuranceParamsEventsSelector,
    getCurrentReasonParamsEventsSelector,
    getCurrentStatusParamsEventsSelector,
    getFilterParamsEventsSelector
} from "../../../redux/selectors";
import { getCurrentBranchParamsEventsSelector } from "../../../redux/selectors/EventsSelectors";


type FormDataType = {
    insurance_type: string
}

type EventsSideBarPropsType = {
}

export const EventsFilter: FC<EventsSideBarPropsType> = () => {
    const dispatch = useDispatch()

    const currentBranch = useSelector(getCurrentBranchSelector);
    const branches = useSelector(getBranchesSelector);
    const activeWeek = useSelector(getActiveWeekSelector);
    const filterParams = useSelector(getFilterParamsEventsSelector);
    const currentInsuranceParams = useSelector(getCurrentInsuranceParamsEventsSelector);
    const currentReasonParams = useSelector(getCurrentReasonParamsEventsSelector);
    const currentStatusParams = useSelector(getCurrentStatusParamsEventsSelector);
    const currentColorParams = useSelector(getCurrentColorParamsEventsSelector);
    const currentBranchParams = useSelector(getCurrentBranchParamsEventsSelector);

    const [stateBranches, setStateBranches] = useState<boolean>(true)
    const [stateInsurance, setStateInsurance] = useState<boolean>(true)
    const [stateReason, setStateReason] = useState<boolean>(true)
    const [stateReasonColor, setStateReasonColor] = useState<boolean>(true)
    const [stateStatus, setStateStatus] = useState<boolean>(true)
    const [stateBranch, setStateBranch] = useState<boolean>(true)

    useEffect(() => {
        if (currentInsuranceParams.length) {
            setStateInsurance(true)
        }

        if (currentReasonParams.length) {
            setStateReason(!stateReason)
        }

        if (currentStatusParams.length) {
            setStateStatus(true)
        }

        if (currentColorParams.length) {
            setStateReasonColor(true)
        }
        if (currentBranchParams.length) {
            setStateBranch(true)
        }
    }, [])

    const choicesInsurance = filterParams.insurance_type__in && Object.entries(filterParams.insurance_type__in.choices).map(br => br)
    const choicesReason = filterParams.reason__in && Object.entries(filterParams.reason__in.choices).map(br => br)
    const choicesReasonColor = filterParams.reason__color && Object.entries(filterParams.reason__color.choices).map(br => br)
    const choicesBranch = filterParams.branch && Object.entries(filterParams.branch.choices).map(br => br)
    const colors = choicesReasonColor.map(color => color[1])

    const choicesStatus = filterParams.status__in && Object.entries(filterParams.status__in.choices).map(br => br)

    const search = filterParams.search && Object.entries(filterParams.search).map(l => l[1])

  const setBranch = (branchId: number) => {
        if (activeWeek !== null) {
            dispatch(getScheduleBranchActiveWeekTC(branchId));
        } else {
            dispatch(getScheduleBranchTC(branchId));
        }
        dispatch(setCurrentBranchesAC(branchId));
        dispatch(fetchFilteredEventsParamsListTC())
    };

    const onBranchClick = (branchId: number) => {
        setBranch(branchId)
    } 

    const filterInsurance = (params: string) => {
        dispatch(setFilteredEventsOfInsuranceTC(params))
    }

    const filterReason = (params: string) => {
        dispatch(setFilteredEventsOfReasonTC(params))
    }
    const filterBranch = (params: string) => {
        dispatch(setFilteredEventsOfBranchTC(params))
    }

    const filterStatus = (params: string) => {
        dispatch(setFilteredEventsOfStatusTC(params))
    }

    const onSearchChange = (value: string) => {
        dispatch(searchOfFullNameTC(value))
    }

    const {
        register,
    } = useForm<FormDataType>({
        mode: 'onBlur',
    });

    return (
        <div className="form-event-filter">
            <div className="filter-block">
                <div className="filter-title1">
                    Branches
                </div>
                {
                    filterParams.branch && choicesBranch.map((branch,index)=> (
                        <div
                            key={index}
                            className={stateBranches
                                ? "filter-param param-branch"
                                : "filter-param-none"}>
                            <Radio
                                state="small"
                                name={"branches"}
                                label={`${branch[1]}  `}
                                value={branch[0]}
                                id={index.toString()}
                                checked={Number(branch[0]) === currentBranch}
                                onChange={() => {
                                    filterBranch(branch[0])
                                    onBranchClick(Number(branch[0]))
                                }}
                            />
                        </div>
                    )
                    )
                }

            </div>
            <div className="search">
                <Inputs
                    onChangeText={onSearchChange}
                    label={search[5]}
                    state={'search'}
                    name="search"
                    style={{
                        height: "50px",
                        paddingTop: "0",
                        marginBottom: "0"
                    }}
                    register={register}
                />
            </div>
            <div className="filter-block">
                <div className="filter-title"
                    onClick={() => {
                        setStateInsurance(!stateInsurance)
                    }}>
                    <span className="filter-title-name"> Versicherung</span>
                    {
                        stateInsurance
                            ? <img className="arrow-event-filter"
                                alt='up'
                                src={logo.arrow_up} />
                            : <img className="arrow-event-filter"
                                alt='down'
                                src={logo.arrow_down} />
                    }
                </div>

                {
                    filterParams.insurance_type__in && choicesInsurance.map((insurance, index) => (
                        <div key={index}
                            className={stateInsurance ? "filter-param" : "filter-param-none"}>
                            <CheckboxInput
                                id={insurance[0][1]}
                                state={"square"}
                                checked={currentInsuranceParams.find(ci => ci === insurance[0]) === insurance[0]}
                                onChange={() => { filterInsurance(insurance[0]) }}
                                name={"insurance_type"}
                                value={insurance[0]}
                                label={insurance[1]}
                            />
                        </div>)
                    )
                }
            </div>
            <div className="filter-title-color"
                onClick={() => {
                    setStateReason(!stateReason)
                    setStateReasonColor(!stateReason)
                }}>
                <span className="filter-title-name-color">Besuchsgrund</span>
                {
                    stateReason
                        ? <img className="arrow-event-filter-color"
                            alt='up'
                            src={logo.arrow_up} />
                        : <img className="arrow-event-filter-color"
                            alt='down'
                            src={logo.arrow_down} />
                }
            </div>
            <div className="filter-block-color">
                <div style={{
                    width:'75%',
                    marginLeft: '30px'
                }}>
                    {
                        filterParams.reason__in && choicesReason.map((reason, index) => (
                            <div key={index}
                                className={stateReason ? "filter-param-color" : "filter-param-none"}>
                                <CheckboxInput
                                    id={reason[0]}
                                    state={"color"}
                                    checked={currentReasonParams.find(cr => cr === reason[0]) === reason[0]}
                                    onChange={() => {
                                        { filterReason(reason[0]) }
                                    }
                                    }
                                    name={"reasons"}
                                    value={reason[0]}
                                    label={`${reason[1]}  `}
                                />
                            </div>
                        )

                     )
                    }
                </div>
                <div style={{
                    //marginLeft: '91px'
                }}>
                    {
                        filterParams.reason__color && colors.map((reason, index) => (
                            <div key={index}
                                className={stateReasonColor ? "filter-param-color" : "filter-param-none"}>
                                <div className="color"
                                    style={{
                                        background: `#${reason}`
                                    }}

                                >
                                </div>
                            </div>
                        )
                        )
                    }
                </div>
            </div>
            <div className="filter-block">
                <div className="filter-title2"
                    onClick={() => {
                        setStateStatus(!stateStatus)
                    }}>
                    <span className="filter-title-name">Status</span>

                    {
                        stateStatus
                            ? <img className="arrow-event-filter"
                                alt='up'
                                src={logo.arrow_up} />
                            : <img className="arrow-event-filter"
                                alt='down'
                                src={logo.arrow_down} />
                    }
                </div>
                {
                    filterParams.status__in && choicesStatus.map((status, index) => (
                        <div key={index}
                            className={stateStatus ? "filter-param" : "filter-param-none"}>
                            <CheckboxInput
                                id={status[0]}
                                state={"square"}
                                checked={currentStatusParams.find(cs => cs === status[0]) === status[0]}
                                onChange={() => { filterStatus(status[0]) }}
                                name={"status"}
                                value={status[0]}
                                label={status[1]}
                            />
                        </div>)
                    )
                }
            </div>
        </div>
    )
}
