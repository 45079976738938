import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
})

export const reasonsAPI = {
    getList(token: string) {
        return instance.get<Array<ReasonType>>('/api/reasons/', {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    addReason(data: any, token: string) {
        return instance.post<ReasonType>('/api/reasons/create/', data, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    editReason(data: any, token: string, reasonId: number) {
        return instance.put<ReasonType>(`/api/reasons/${reasonId}/`, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    removeReason(token: string, reasonId: number) {
        return instance.delete<any>(`/api/reasons/${reasonId}/`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },
}

//types
export type BrunchesType = {
    branches: Array<number>
}

export type DoctorType = {
    id: number
    first_name: string,
    last_name: string,
    email: string,
    photo: null,
    phone: null,
    role: string,
    owner: number,
    owner_display: number
}

export type ReasonType = {
    id: number
    branches: Array<number>
    title: string
    offset_time: number
    limit_time: number
    color: string
    order: number
    doctors: Array<DoctorType>
    doctor_display: string
    offset_time_display: string
}
