import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
})

export const employeesAPI = {
    getList(token: string) {
        return instance.get<Array<EmployeeType>>('/api/employees/', {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    addEmployee(data: any, token: string) {
        return instance.post<EmployeeType>('/api/employees/create/', data, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    editEmployee(data: any, token: string, employeeId: number) {
        return instance.put<EmployeeType>(`/api/employees/${employeeId}/`, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    removeEmployee(token: string, employeeId: number) {
        return instance.delete<any>(`/api/employees/${employeeId}/`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },
}

//types
export type EmployeeType = {
    id: number
    username: string
    first_name: string
    last_name: string
    branch_set: []
    role: any
}
