import React, {FC} from "react";
import {logo} from "../../assets/img/image";
import "./Pagination.scss"

type PaginationPropsType = {
    previous: string | null
    next: string | null
    current: number
    currentPage: number
    previousCallback: () => void
    nextCallback: () => void
}

export const Pagination: FC<PaginationPropsType> = (
    {previous, next, currentPage,
        current, previousCallback, nextCallback}) => {

    const btnPrevClassName = previous === null ? 'btn-pagination-disabled' : 'btn-pagination'
    const btnNextClassName = next === null ? 'btn-pagination-disabled' : 'btn-pagination'

  return (
      <div className="pagination">
          <div className="button-container">
              <button
                  className={btnPrevClassName}
                  disabled={previous === null}
                  onClick={previousCallback}>
                  <img src={logo.arrow_left} alt="previous"/>
              </button>
              <span className="page-number">{current} / {currentPage}</span>
              <button
                  className={btnNextClassName}
                  disabled={next === null}
                  onClick={nextCallback}>
                  <img src={logo.arrow_right} alt="next"/>
              </button>
          </div>
      </div>
  )
}