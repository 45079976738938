import React, {FC, memo} from "react";
import {DateListEventType, DateOfListType,} from "../../../../api/branches-api";
import {logo} from "../../../../assets/img/image";

type EventSchedulePropsType = {
    events: Array<DateListEventType>
    scheduleBranchDate: Array<DateOfListType>
    dayIndex: number
    timeWorkDayFinally: Array<string>
    setDateForModalAlert: (date: string, date_of_lists: DateOfListType) => void
    onFreeDateClick: (date: string, time: string) => void
    onStatusEventClick: (eventId: number) => void
    onStatusEventDoctorClick: (eventId: number) => void
    onStatusEventNotClick: (eventId: number) => void
    onStatusEventDoneClick: (eventId: number) => void
}

export const EventSchedule: FC<EventSchedulePropsType> = memo((
        {
            events, scheduleBranchDate, onFreeDateClick,
            dayIndex, timeWorkDayFinally, setDateForModalAlert, onStatusEventClick,
            onStatusEventNotClick, onStatusEventDoctorClick, onStatusEventDoneClick,
        }) => {

        const currentPositionEvent = (from_start: string) => {
            const pixelsConversion = 3
            return Number(from_start) * pixelsConversion
        }

        const eventsInOneHour = (from_start: string, index: number) => {
            const filterEl = scheduleBranchDate[dayIndex].events[index].start.slice(11, 13)

            return events.filter(event => event.start.slice(11, 13) === filterEl).length
        }

        const onEventClickSetDate = (date: string, date_of_lists: DateOfListType) => {
            setDateForModalAlert(date, date_of_lists)
        }

        const optionalClasses = (from_start: string, start: string, index: number) => {
            const filterEl = scheduleBranchDate[dayIndex].events[index].start.slice(11, 13)
            const fromStartArr = events.filter(event => event.start.slice(11, 13) === filterEl)

            return fromStartArr.length > 1 ? "day-several-events" : "day-event"

        }

        const customDisplay = (from_start: string, start: string, index: number, id: number) => {
            const filterEl = scheduleBranchDate[dayIndex].events[index].from_start
            const fromStartArr = events.filter(event => (Number(event.from_start) + 29) >= Number(filterEl))
            const firstEvent = fromStartArr.find((el, index) => index === 0)

            return firstEvent && firstEvent.id === id ? "inherit" : "none"
        }

        const eventHeight = (start: string, end: string) => {

            const heightEvent = (new Date(end).getTime() - new Date(start).getTime()) / (1000 * 60)
            return heightEvent <= 30 ? 90 : heightEvent * 3
        }

        return (
            <>

                <ul>
                    {
                        events && events.map((event, index) => event.start && event.start.toString().slice(0, 10)
                        === scheduleBranchDate[dayIndex].date.slice(0, 10)
                            ?
                            <li key={event.id}
                                className={optionalClasses(event.from_start, event.start, index)}
                                title=""
                                style={{
                                    display: `${customDisplay(event.from_start, event.start, index, event.id)}`,
                                    height: `${eventHeight(event.start, event.end)}px`,
                                    top: `calc(60px + ${currentPositionEvent(event.from_start)}px)`,
                                    borderColor: `${event.reason_color}`,
                                    background: 'white',
                                }}
                                onClick={() => {
                                    onEventClickSetDate(scheduleBranchDate[dayIndex].events[index].start,
                                        scheduleBranchDate[dayIndex])
                                }}
                            >
                                <div style={{
                                    display: `${customDisplay(event.from_start, event.start, index, event.id)}`,
                                    height: `${eventHeight(event.start, event.end)}px`,
                                    top: `calc(60px + ${currentPositionEvent(event.from_start)}px)`,
                                    borderColor: `${event.reason_color}`,
                                    background: `${event.reason_color}38`,
                                    margin: '-10px',
                                    borderRadius: '0px 12px 12px 0px',
                                    padding: '10px'
                                }}>
                                <span
                                    className="reason">{event.reason_display}
                                </span>
                                    <div
                                        className="start">{event.start.toString().slice(11, 16)}

                                        {
                                            event.status === "waiting_room" &&
                                            <span>
                                                <img
                                                    title="At the doctor"
                                                    alt="At the doctor"
                                                    className="img-ok"
                                                    onClick={(e) => {
                                                        onStatusEventDoctorClick(event.id)
                                                        e.stopPropagation()
                                                    }}
                                                    src={logo.iconOk}/>
                                                <img
                                                    className="img-Close"
                                                    title="Did not wait"
                                                    alt="Did not wait"
                                                    onClick={(e) => {
                                                        onStatusEventNotClick(event.id)
                                                        e.stopPropagation()
                                                    }}
                                                    src={logo.iconClose}/>
                                            </span>
                                        }

                                        {
                                            event.status === "at_the_doctor" &&
                                            <span
                                                className="waiting-room"
                                                onClick={(e) => {
                                                    onStatusEventDoneClick(event.id)
                                                    e.stopPropagation()
                                                }}>Done</span>
                                        }

                                        {
                                            event.status === "new" &&
                                            <span
                                                className="waiting-room"
                                                onClick={(e) => {
                                                    onStatusEventClick(event.id)
                                                    e.stopPropagation()
                                                }}>Waiting room</span>
                                        }


                                        {/*{*/}
                                        {/*    event.status !== "waiting_room"*/}
                                        {/*        ? <span*/}
                                        {/*            className="waiting-room"*/}
                                        {/*            onClick={(e) => {*/}
                                        {/*                onStatusEventClick(event.id)*/}
                                        {/*                e.stopPropagation()*/}
                                        {/*            }}>Waiting room</span>*/}
                                        {/*        : <span>*/}
                                        {/*        <img*/}
                                        {/*            title="At the doctor"*/}
                                        {/*            alt="At the doctor"*/}
                                        {/*            className="img-ok"*/}
                                        {/*            onClick={(e) => {*/}
                                        {/*                onStatusEventDoctorClick(event.id)*/}
                                        {/*                e.stopPropagation()*/}
                                        {/*            }}*/}
                                        {/*            src={logo.iconOk}/>*/}
                                        {/*        <img*/}
                                        {/*            className="img-Close"*/}
                                        {/*            title="Did not wait"*/}
                                        {/*            alt="Did not wait"*/}
                                        {/*            onClick={(e) => {*/}
                                        {/*                onStatusEventNotClick(event.id)*/}
                                        {/*                e.stopPropagation()*/}
                                        {/*            }}*/}
                                        {/*            src={logo.iconClose}/>*/}
                                        {/*    </span>*/}
                                        {/*}*/}

                                    </div>
                                    {
                                        event.status === "waiting_room" &&
                                        <span>Wartezeit: {event.waiting_time}</span>
                                    }

                                    {
                                        event.status === "at_the_doctor" &&
                                        <span>Wartezeit: {event.visit_time}</span>
                                    }

                                    <div
                                        className="client">{event.first_name} {event.last_name}
                                    </div>

                                    <div
                                        className="doctor">{event.doctor.first_name} {event.doctor.last_name}
                                    </div>
                                    {
                                        optionalClasses(event.from_start, event.start, index) === "day-several-events" &&
                                        <div className="amount-events">
                                        <span
                                            className="amount-content">
                                            {`+${eventsInOneHour(event.from_start, index) - 1}`}
                                        </span>
                                        </div>
                                    }

                                    <div className="edd-button"
                                         onClick={(e) => {
                                             e.stopPropagation()
                                             onFreeDateClick(scheduleBranchDate[dayIndex].date,
                                                 `${event.start.slice(11, 16)}`)

                                         }}>

                                        <span className="edd-button_title">+</span>
                                    </div>
                                </div>

                            </li>
                            : '')
                    }
                </ul>
            </>
        )
    }
)
