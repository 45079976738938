import React, { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import "../../../components/Widgets/Button/Button.scss";
import {
  addHolidayTC,
  editHolidayTC,
  fetchHolidaysList
} from "../../../redux/holidays-reducer";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { PATH } from "../../Routes";
import { validation } from "../../../utils/validation";
import { fetchHolidayListFieldsTC, } from "../../../redux/fields-reducer";
import Inputs from "../../../components/Widgets/Inputs";
import {
  defaultChoicesMultiSelect,
  newOption
} from "../../../components/Widgets/Select/ReactSelectStyles";
import { ProfileSideBar } from "../../Profile/ProfileSideBar/ProfileSideBar";
import { ReactSelect } from "../../../components/Widgets/Select/ReactSelect";
import { ReactDatePicker, /* ReactDateTimePicker */ } from "../../../components/Widgets/ReactDatePicker";
import { TimePicker } from "../../../components/Widgets/TimePicker";
import dayjs from "dayjs";
import { useErrorHandler } from "../../../utils/useErrorHandler";
import Preloader from "../../Preloader/Preloader";
import {
  getChosenDaySelector,
  getCurrentBranchSelector,
  getErrorStateSelector,
  getFieldsHolidaysSelector,
  getHolidaysSelector, getIsLoggedInSelector, getStatusSelector
} from "../../../redux/selectors";

type FormDataType = {
  name: string;
  date: string | Date
  start_time: string | Date;
  end_time: string | Date;
  branches: { value: any, label: any };
};

export const FormsHoliday = () => {
  const dispatch = useDispatch();

  const holidays = useSelector(getHolidaysSelector);
  const fields = useSelector(getFieldsHolidaysSelector);
  const errorState = useSelector(getErrorStateSelector);
  const status = useSelector(getStatusSelector);
  const currentBranch = useSelector(getCurrentBranchSelector);
  const chosenDay = useSelector(getChosenDaySelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);

  const [valueHoliday, setValueHoliday] = useState<boolean>(false);

  //for edit employee
  const params = useParams();
  const idUrl = params.id;
  const holidayId = Number(idUrl);
  const currentBranchHoliday = holidays.length && holidays.find(h => h.id === currentBranch)
  const currentHoliday = currentBranchHoliday && currentBranchHoliday.private.find(pr => pr.id === holidayId);
  const chosenDateEdit = chosenDay && `${chosenDay.date.slice(0, 10)}T${chosenDay.time}`
  const startTimeDefaultValue = currentHoliday ? currentHoliday?.date : chosenDay ? chosenDateEdit : fields.date.initial_value

  //validation
  const formDataArray = ["name", "date", "start_time", "end_time", "branches"];

  const [errorGeneral, setErrorGeneral] = useState<string | null>(null)

  const nameErrorHandler = useErrorHandler("name", errorState, formDataArray)
  const dateErrorHandler = useErrorHandler("date", errorState, formDataArray)
  const startTimeErrorHandler = useErrorHandler("start_time", errorState, formDataArray)
  const endTimeErrorHandler = useErrorHandler("end_time", errorState, formDataArray)
  const branchesErrorHandler = useErrorHandler("branches", errorState, formDataArray)

  const [startDate, setStartDate] = useState<any>(new Date());
  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());

  //for select
  const choices = Object.entries(fields.branches.choices).map(br => br)
  const valueOption = fields && Object.entries(fields.branches.choices).map((b: any) => newOption(b[0], b[1]))
  const labelOption = currentHoliday && currentHoliday.branches.map((el: any) => el.toString())

  const holidayData = currentHoliday
    ? {

      name: currentHoliday.name,
      date: new Date(currentHoliday.date),
      start_time: new Date(`${currentHoliday.date} ${currentHoliday.start_time}`),
      end_time: new Date(`${currentHoliday.date} ${currentHoliday.end_time}`),
      branches: currentHoliday &&
        defaultChoicesMultiSelect(valueOption, labelOption)
    }

    : {
      name: fields.name.initial_value && new Date(fields.name.initial_value),
      date: fields.date.initial_value && new Date(fields.date.initial_value),
    }

  useEffect(() => {
    if (validation(errorState, formDataArray).testErrorField.length === 0) {
      if (typeof errorState === "object") {
        setErrorGeneral(errorState && Object.values(errorState)[0])
      } else {
        setErrorGeneral(errorState)
      }
    }
    return () => {
      setErrorGeneral(null)
    };
  }, [status])

  useEffect(() => {
    if (holidayData) {
      reset(holidayData)
    }
  }, [currentHoliday, fields])

  useEffect(() => {
    dispatch(fetchHolidayListFieldsTC())
    dispatch(fetchHolidaysList())
  }, [dispatch])

  useEffect(() => {
    if (status === "failed") {
      nameErrorHandler.setErrorCallback()
      dateErrorHandler.setErrorCallback()
      startTimeErrorHandler.setErrorCallback()
      endTimeErrorHandler.setErrorCallback()
      branchesErrorHandler.setErrorCallback()
    }
  }, [status]);

  const { register, handleSubmit, control, reset} =
    useForm<FormDataType>({
      mode: "onBlur",
      defaultValues: useMemo(() => {
        if (holidayData) {
          return holidayData
        }
      }, [holidayData, fields])
    });

  const onSubmit: SubmitHandler<FormDataType> = (data) => {
    let time_start = dayjs(data.start_time).format("HH:mm")
    let time_end = dayjs(data.end_time).format("HH:mm")
    const newData = {
      branches: data.branches && Object.values(data.branches).map((br: any) => br.value),
      name: data.name,
      date: dayjs(data.date).format("YYYY-MM-DD"),
      start_time: time_start,
      end_time: time_end,
    }

    if (currentHoliday) {
      dispatch(editHolidayTC(holidayId, newData));
    } else {
      dispatch(addHolidayTC(newData));
    }
    setValueHoliday(true);
  };

  if (status === "succeeded" && valueHoliday) {
    return <Navigate to={PATH.HOLIDAYS} />;
  }

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }

  return (
    <>
      <ProfileSideBar />
      <div className="add-employee" onClick={() => {
        setErrorGeneral(null)
      }}>
        <h1 className="add-employee__title">
          {currentHoliday ? "Holiday edit" : "Holiday create"}
        </h1>
        {status === 'loading' ? (
          <Preloader />
        ) : (
          <div>
            <form onSubmit={handleSubmit(onSubmit)} className="add-employee__form">
              <label className="add-employee__inputs-label">Official</label>
              <div className="add-employee__inputs-info">
                <Inputs
                  error={nameErrorHandler.error}
                  help_text={fields.name.help_text || nameErrorHandler.error && nameErrorHandler.errorMessageCurrentField[1]}
                  onClick={nameErrorHandler.onFieldClick}
                  state={"active"}
                  register={register}
                  input_type={fields.name.input_type}
                  name={"name"}
                  label={fields.name.label}
                  defaultValue={currentHoliday ? currentHoliday.name : fields.name.initial_value}
                  resetForm={reset}
                  {...reset}
                />
                <div>
                  <ReactDatePicker
                    selectedDate={selectedDate}
                    control={control}
                    label={fields.date.label}
                    // onInputClick={dateErrorHandler.onFieldClick}
                    onClick={dateErrorHandler.onFieldClick}
                    name={"date"}
                    help_text={dateErrorHandler.error && dateErrorHandler.errorMessageCurrentField[1]}
                    error={dateErrorHandler.error}
                    register={register}
                  />
                </div>
                <TimePicker
                  selectedDate={startDate}
                  control={control}
                  label={fields.start_time.label}
                  onClick={startTimeErrorHandler.onFieldClick}
                  name={"start_time"}
                  defaultValue={currentHoliday ? currentHoliday.start_time : fields.start_time.initial_value}
                  help_text={fields.start_time.help_text || startTimeErrorHandler.error && startTimeErrorHandler.errorMessageCurrentField[1]}
                  error={startTimeErrorHandler.error}
                  register={register}
                />
                <TimePicker
                  selectedDate={startDate}
                  control={control}
                  label={fields.end_time.label}
                  onClick={endTimeErrorHandler.onFieldClick}
                  name={"end_time"}
                  help_text={fields.end_time.help_text || endTimeErrorHandler.error && endTimeErrorHandler.errorMessageCurrentField[1]}
                  error={endTimeErrorHandler.error}
                  register={register}
                />
              </div>
              <label className="add-employee__inputs-label">{fields.branches.label}</label>
              <div className="add-branch__inputs-setting">
                <ReactSelect
                  name="branches"
                  error={branchesErrorHandler.error}
                  help_text={branchesErrorHandler.error && branchesErrorHandler.errorMessageCurrentField[1]}
                  label={fields.branches.label}
                  placeholder={fields.branches.label}
                  onClick={branchesErrorHandler.onFieldClick}
                  control={control}
                  isMulti={true}
                  options={choices && Object.entries(fields.branches.choices).map((b: any) => newOption(b[0], b[1]))}
                />
              </div>
              <div>
                <button
                  className="button button_size-middle button_position-right button_color-black">
                  Sichern
                </button>
              </div>
              {
                (status === 'failed' && errorGeneral) &&
                <p className="general-error">{errorGeneral}</p>
              }
            </form>

          </div>
        )
        }
      </div>
    </>
  );
};
