import "./Textarea.scss";

import {
    ChangeEvent,
    DetailedHTMLProps,
    FC,
    TextareaHTMLAttributes,
    useMemo,
    useState,
} from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Path } from "react-hook-form";
import { FormDataType } from "../../pages/Login/Login";

type DefaultTextareaPropsType = DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>


type TextareaType = DefaultTextareaPropsType & {
    state?: string
    input_name?: string
    error?: string | null
    help_text?: string | null
    label?: string
    img?: any
    name?: Path<FormDataType> | string
    onEnter?: () => void
    resetForm?: () => void
    onChangeText?: (value?: string) => void
    register?: any
    input_type?: string;
    defaultValue?: any
    height?: string
    width?: string
    resize?: string
};


const Textarea: FC<TextareaType> = ({
    onChange, onChangeText,
    onKeyPress,onEnter,
    className,error,
    name, state, img, input_type,defaultValue,
                                          /*input_name,*/ help_text,
    label, register, resetForm,
    width, height, resize,
    ...restProps



}) => {

    const [focused, setFocus] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [btn, setBtn] = useState(false);
    const [value, setValue] = useState('');
    const [classs, setClass] = useState('');
    /*   const {
        register,
        handleSubmit
      } = useForm(); */

    const onInput = () => {
        setBtn(true);
    }

    const onFocusHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setFocus(true);
        setClass(e.currentTarget.value)
    }

    const onBlur = () => {
        setFocus(false);
        setBtn(false);
    }


/*     const isActive = useMemo(() => {
        return focused || restProps.value
    }, [focused, restProps.value]); */

    /*   const onChange = (event: any) => {
  
          props.onChange?.(event.target.value);
          setValue(event.target.value);
          console.log(event.target.value) */
    /*    props.onChange && props.onChange(event)
       props.onChangeText && props.onChangeText(event.currentTarget.value)
    */
    const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
        onChange && onChange(e);
        onChangeText && onChangeText(e.currentTarget.value);
        setValue(e.target.value);
        //console.log(e.currentTarget.value)
    }

    const onButtonClick = () => {
        resetForm && resetForm()
    }
    
    const customTextareaClass = ` 
    area area_${state} 
    area_${img} 
   
    ${error ? "area_error " : ""}
    ${focused ? "area_focus" : "area_unfocus"} 
    ${value.length ? "area_focus" : "area_unfocus"}
    ${defaultValue ? "area_focus" : "area_unfocus"}`

    return (
        <div
            className={customTextareaClass}>

            {
                register ?
                    <textarea className="area__text"
                        /* style={{resize: resize, width: width, height: height}} */
                              defaultValue={defaultValue}
                              {...register(name, {
                                  onBlur: (e: ChangeEvent<HTMLTextAreaElement>) => {
                                      if (value !== '') {
                                          setClass(e.currentTarget.value);
                                          setFocus(false)
                                      } else {
                                          setFocus(false)
                                      }
                                  },
                                  onChange: onChangeHandler
                              })}
                              id={`id_${name}`}
                              onFocus={onFocusHandler}
                        //onBlur={onBlur}
                              onChange={onChangeHandler}
                              disabled={state ==="disabled"}
                              onInput={onInput}
                        //step={step}
                              {...restProps}
                    />
                    :
                    <textarea className="area__text"
                        /* style={{resize: resize, width: width, height: height}} */
                              defaultValue={defaultValue}
                              id={`id_${name}`}
                              onFocus={onFocusHandler}
                              onChange={onChangeHandler}
                              disabled={state ==="disabled"}
                              onInput={onInput}
                    />
            }

            <label className="area__label" htmlFor={`id_${name}`}>
                {label}
            </label>
            <span className="area__help-text">{help_text}</span>
           {/*  {btn && <div className="area__clear" onMouseDown={onButtonClick} ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 6L18.7742 18.7742" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6 18.7742L18.7742 5.99998" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            </div>} */}
        </div>
    );


}














export default Textarea;
