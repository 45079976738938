import React, { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import "./FormEvent.scss";
import "../../../components/Widgets/Button/Button.scss";
import {
    addEventTC,
    editEventTC,
    fetchEventsList,
    fetchSortEventsList,
    getCurrentEventsList,
    setChosenDayInScheduleAC,
} from "../../../redux/events-reducer";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { PATH } from "../../Routes";
import Inputs from "../../../components/Widgets/Inputs";
import { validation } from "../../../utils/validation";
import { fetchEventsListFieldsTC } from "../../../redux/fields-reducer";
import {
    defaultChoicesSelect,
    newOption
} from "../../../components/Widgets/Select/ReactSelectStyles";
import Textarea from "../../../components/Widgets/Textarea";
import { ProfileSideBar } from "../../Profile/ProfileSideBar/ProfileSideBar";
import { ReactSelect } from "../../../components/Widgets/Select/ReactSelect";
import { setCurrentPathAC } from "../../../redux/branches-reducer";
import { ReactDatePicker, } from "../../../components/Widgets/ReactDatePicker";
import dayjs from "dayjs";
import { ReactDateTimePicker } from "../../../components/Widgets/ReactDateTimePicker";
import { useErrorHandler } from "../../../utils/useErrorHandler";
import Preloader from "../../Preloader/Preloader";
import {
    getBranchesSelector,
    getChosenDaySelector,
    getCurrentBranchSelector,
    getCurrentOrderingSelector,
    getCurrentPageSelector,
    getCurrentPathSelector,
    getErrorStateSelector,
    getEventsSelector, getFieldsEventsSelector,
    getIsLoggedInSelector,
    getStatusSelector
} from "../../../redux/selectors";

type FormDataType = {
    first_name: string
    last_name: string
    email: string
    phone: string
    reason: { value: any, label: any }
    start: string | Date
    insurance_type: { value: any, label: any }
    date_birth: string | Date
    message: string
    gender: { value: any, label: any }
    status: { value: any, label: any }
    doctor: { value: any, label: any }
    note: string
}

export const FormEvent = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const errorState = useSelector(getErrorStateSelector);
    const status = useSelector(getStatusSelector);
    const isLoggedIn = useSelector(getIsLoggedInSelector);
    const currentBranch = useSelector(getCurrentBranchSelector);
    const branches = useSelector(getBranchesSelector);
    const events = useSelector(getEventsSelector);
    const currentPage = useSelector(getCurrentPageSelector);
    const currentOrdering = useSelector(getCurrentOrderingSelector);
    const chosenDay = useSelector(getChosenDaySelector);
    const currentPath = useSelector(getCurrentPathSelector);
    const fields = useSelector(getFieldsEventsSelector);

    const [valueEvent, setValueEvent] = useState<boolean>(false);

    //for edit event
    const idUrl = params.id;
    const eventId = Number(idUrl);

    const currentEvent = events.length && events.find((ev) => ev.id === eventId);

    const chosenDateEdit = chosenDay && `${chosenDay.date.slice(0, 10)}T${chosenDay.time}`
    const startTimeDefaultValue = chosenDay ? chosenDateEdit : currentEvent ? currentEvent.start : fields.start.initial_value
    const DateDefaultValue = currentEvent ? currentEvent?.date_birth : chosenDay ? chosenDateEdit : fields.date_birth.initial_value

    const [selectedDate, setSelectedDate] = useState<any>(new Date());

    //validation
    const formDataArray = ["first_name", "last_name", "status", "reason",
        "email", "phone", "start", "insurance_type", /*"branch", "client",*/
        "message", "date_birth", "gender", "doctor", "note"];
    const firstNameErrorHandler = useErrorHandler("first_name", errorState, formDataArray);
    const lastNameErrorHandler = useErrorHandler("last_name", errorState, formDataArray);
    const statusErrorHandler = useErrorHandler("status", errorState, formDataArray);
    const reasonErrorHandler = useErrorHandler("reason", errorState, formDataArray);
    const emailErrorHandler = useErrorHandler("email", errorState, formDataArray);
    const phoneErrorHandler = useErrorHandler("phone", errorState, formDataArray);
    const startErrorHandler = useErrorHandler("start", errorState, formDataArray);
    const insuranceTypeErrorHandler = useErrorHandler("insurance_type", errorState, formDataArray);
    const messageErrorHandler = useErrorHandler("message", errorState, formDataArray);
    const dateBirthErrorHandler = useErrorHandler("date_birth", errorState, formDataArray);
    const genderErrorHandler = useErrorHandler("gender", errorState, formDataArray);
    const doctorErrorHandler = useErrorHandler("doctor", errorState, formDataArray);
    const noteErrorHandler = useErrorHandler("note", errorState, formDataArray);

    const [errorGeneral, setErrorGeneral] = useState<string | null>(null);

    // for select
    const choicesInsurance = Object.entries(fields.insurance_type.choices).map(br => br)
    const valueOptionInsurance = fields && Object.entries(fields.insurance_type.choices).map((b: any) => newOption(b[0], b[1]))
    const labelOptionInsurance = currentEvent && currentEvent.insurance_type

    const choicesReason = Object.entries(fields.reason.choices).map(br => br)
    const valueOptionReason = fields && Object.entries(fields.reason.choices).map((b: any) => newOption(b[0], b[1]))
    const labelOptionReason = currentEvent && currentEvent.reason.toString()

    const choicesGender = Object.entries(fields.gender.choices).map(br => br)
    const valueOptionGender = fields && Object.entries(fields.gender.choices).map((b: any) => newOption(b[0], b[1]))
    const labelOptionGender = currentEvent && currentEvent.gender.toString()

    const choicesStatus = Object.entries(fields.status.choices).map(br => br)
    const valueOptionStatus = fields && Object.entries(fields.status.choices).map((b: any) => newOption(b[0], b[1]))
    const labelOptionStatus = currentEvent && currentEvent.status.toString()

    const choicesDoctor = Object.entries(fields.doctor.choices).map(br => br)
    const valueOptionDoctor = fields && Object.entries(fields.doctor.choices).map((b: any) => newOption(b[0], b[1]))
    const labelOptionDoctor = currentEvent && currentEvent.doctor.id?.toString()

    const eventData = currentEvent && {
        first_name: currentEvent.first_name,
        last_name: currentEvent.last_name,
        email: currentEvent.email,
        phone: currentEvent.phone,
        reason: currentEvent.reason &&
            defaultChoicesSelect(valueOptionReason, labelOptionReason)[0],
        // start: currentEvent.start,
        start: startTimeDefaultValue && new Date(startTimeDefaultValue),
        status: currentEvent.status &&
            defaultChoicesSelect(valueOptionStatus, labelOptionStatus)[0],
        insurance_type: currentEvent.insurance_type &&
            defaultChoicesSelect(valueOptionInsurance, labelOptionInsurance)[0],
        message: currentEvent.message,
        // date_birth: currentEvent.date_birth,
        date_birth: new Date(currentEvent.date_birth),
        gender: currentEvent &&
            defaultChoicesSelect(valueOptionGender, labelOptionGender)[0],
        doctor: currentEvent &&
            defaultChoicesSelect(valueOptionDoctor, labelOptionDoctor)[0],
        note: currentEvent.note,
    }

    useEffect(() => {
        if (validation(errorState, formDataArray).testErrorField.length === 0) {
            if (typeof errorState === "object") {
                setErrorGeneral(errorState && Object.values(errorState)[0])
            } else {
                setErrorGeneral(errorState)
            }
        }
        return () => {
            setErrorGeneral(null)
        };
    }, [status])

    useEffect(() => {
        return () => {
            dispatch(setChosenDayInScheduleAC(null))
        }
    }, [currentPath])

    useEffect(() => {
        if (eventData) {
            reset(eventData)
        }
    }, [currentEvent])

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchEventsListFieldsTC(currentBranch))
            if (currentOrdering) {
                dispatch(fetchSortEventsList(currentOrdering))
            }
            if (currentPage) {
                dispatch(getCurrentEventsList())
            }
            if (!currentPage && !currentOrdering) {
                 dispatch(fetchEventsList())
            }
        }

    }, [dispatch])

    useEffect(() => {
        return () => {
            dispatch(setCurrentPathAC(null))
        };
    }, [currentPath])

    useEffect(() => {
        if (status === "failed") {
            firstNameErrorHandler.setErrorCallback()
            lastNameErrorHandler.setErrorCallback()
            statusErrorHandler.setErrorCallback()
            reasonErrorHandler.setErrorCallback()
            emailErrorHandler.setErrorCallback()
            phoneErrorHandler.setErrorCallback()
            insuranceTypeErrorHandler.setErrorCallback()
            startErrorHandler.setErrorCallback()
            messageErrorHandler.setErrorCallback()
            dateBirthErrorHandler.setErrorCallback()
            genderErrorHandler.setErrorCallback()
            doctorErrorHandler.setErrorCallback()
            noteErrorHandler.setErrorCallback()
        }
    }, [status])

    const {
        register,
        handleSubmit,
        reset,
        control 
    } = useForm<FormDataType>({
        mode: 'onBlur',
        defaultValues: useMemo(() => {
            if (eventData) {
                return eventData
            }
        }, [eventData])
    });

    const onSubmit: SubmitHandler<FormDataType> = (data) => {
        const start = data.start ? dayjs(data.start).format("YYYY-MM-DDThh:mm") : dayjs(startTimeDefaultValue).format("YYYY-MM-DDThh:mm")
        const newData = {
            insurance_type: data.insurance_type && data.insurance_type.value,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone: data.phone,
            reason: data.reason && data.reason.value,
            start: start,
            status: data.status && data.status.value,
            message: data.message,
            date_birth: dayjs(data.date_birth).format("YYYY-MM-DD"),
            gender: data.gender && data.gender.value,
            doctor: data.doctor && data.doctor.value,
            note: data.note
        }

        if (currentEvent) {
            dispatch(editEventTC(eventId, newData))
        } else {
            dispatch(addEventTC(newData, currentBranch))
        }
        setValueEvent(true)
    }

    if (valueEvent && status == "succeeded") {
        if (currentPath) {
            return <Navigate to={currentPath} />
        } else {
            return <Navigate to={PATH.EVENTS} />
        }
    }

    if (!isLoggedIn) {
        return <Navigate to={PATH.LOGIN} />;
    }

    return (
        <>
            <ProfileSideBar />
            <div className="add-event" onClick={() => {
                setErrorGeneral(null)
            }}>
                <h1 className="add-event__title">{currentEvent ? "Event edit" : "Event add"}</h1>
                {branches.map(br => br.id === currentBranch ?
                    <span key={br.id} className="name">{br.name}</span> : ''
                )}

                {status === 'loading' ? (
                    <Preloader />
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)} className="add-event__form">
                        <label className="add-event__inputs-label">Info</label>
                        <div className="add-event__inputs-info">
                            <Inputs
                                error={firstNameErrorHandler.error}
                                help_text={fields.first_name.help_text || firstNameErrorHandler.error && firstNameErrorHandler.errorMessageCurrentField[1]}
                                onClick={firstNameErrorHandler.onFieldClick}
                                state={"active"}
                                register={register}
                                input_type={fields.first_name.input_type}
                                name={"first_name"}
                                label={fields.first_name.label}
                                defaultValue={currentEvent ? currentEvent?.first_name : fields.first_name.initial_value}
                                resetForm={reset}
                                {...reset}
                            />
                            <Inputs
                                error={lastNameErrorHandler.error}
                                onClick={lastNameErrorHandler.onFieldClick}
                                help_text={fields.last_name.help_text || lastNameErrorHandler.error && lastNameErrorHandler.errorMessageCurrentField[1]}
                                state={"active"}
                                register={register}
                                input_type={fields.last_name.input_type}
                                name={"last_name"}
                                label={fields.last_name.label}
                                resetForm={reset}
                                defaultValue={currentEvent ? currentEvent?.last_name : fields.last_name.initial_value}
                                {...reset}
                            />
                            <Inputs
                                error={emailErrorHandler.error}
                                onClick={emailErrorHandler.onFieldClick}
                                help_text={fields.email.help_text || emailErrorHandler.error && emailErrorHandler.errorMessageCurrentField[1]}
                                state={"active"}
                                register={register}
                                input_type='email'
                                name={"email"}
                                label={fields.email.label}
                                resetForm={reset}
                                defaultValue={currentEvent ? currentEvent?.email : fields.email.initial_value}
                                {...reset}
                            />
                            <Inputs
                                error={phoneErrorHandler.error}
                                onClick={phoneErrorHandler.onFieldClick}
                                help_text={fields.phone.help_text || phoneErrorHandler.error && phoneErrorHandler.errorMessageCurrentField[1]}
                                state={"active"}
                                register={register}
                                input_type={fields.phone.input_type}
                                name={"phone"}
                                label={fields.phone.label}
                                defaultValue={currentEvent ? currentEvent?.phone : fields.phone.initial_value}
                                resetForm={reset}
                                {...reset}

                            />
                            <ReactSelect
                                name="reason"
                                error={reasonErrorHandler.error}
                                help_text={fields.reason.help_text || reasonErrorHandler.error && reasonErrorHandler.errorMessageCurrentField[1]}
                                label={fields.reason.label}
                                placeholder={fields.reason.label}
                                onClick={reasonErrorHandler.onFieldClick}
                                control={control}
                                isMulti={false}
                                defaultValue={currentEvent &&
                                    defaultChoicesSelect(valueOptionReason, labelOptionReason)[0]}
                                options={choicesReason && Object.entries(fields.reason.choices).map((b: any) => newOption(b[0], b[1]))}
                            />
                            <ReactSelect
                                name="status"
                                error={statusErrorHandler.error}
                                help_text={statusErrorHandler.error && statusErrorHandler.errorMessageCurrentField[1]}
                                label={fields.status.label}
                                placeholder={fields.status.label}
                                onClick={statusErrorHandler.onFieldClick}
                                control={control}
                                isMulti={false}
                                defaultValue={currentEvent &&
                                    defaultChoicesSelect(valueOptionStatus, labelOptionStatus)[0]}
                                options={choicesStatus && Object.entries(fields.status.choices).map((b: any) => newOption(b[0], b[1]))}
                            />
                            <ReactSelect
                                name="insurance_type"
                                error={insuranceTypeErrorHandler.error}
                                help_text={insuranceTypeErrorHandler.error && insuranceTypeErrorHandler.errorMessageCurrentField[1]}
                                label={fields.insurance_type.label}
                                placeholder={fields.insurance_type.label}
                                onClick={insuranceTypeErrorHandler.onFieldClick}
                                control={control}
                                isMulti={false}
                                defaultValue={currentEvent &&
                                    defaultChoicesSelect(valueOptionInsurance, labelOptionInsurance)[0]}
                                options={choicesInsurance && Object.entries(fields.insurance_type.choices).map((b: any) => newOption(b[0], b[1]))}
                            />
                            <ReactSelect
                                name="gender"
                                error={genderErrorHandler.error}
                                help_text={genderErrorHandler.error && genderErrorHandler.errorMessageCurrentField[1]}
                                label={fields.gender.label}
                                placeholder={fields.gender.label}
                                onClick={genderErrorHandler.onFieldClick}
                                control={control}
                                isMulti={false}
                                defaultValue={currentEvent &&
                                    defaultChoicesSelect(valueOptionGender, labelOptionGender)[0]}
                                options={choicesGender && Object.entries(fields.gender.choices).map((b: any) => newOption(b[0], b[1]))}
                            />
                            <ReactSelect
                                name="doctor"
                                error={doctorErrorHandler.error}
                                help_text={doctorErrorHandler.error && doctorErrorHandler.errorMessageCurrentField[1]}
                                label={fields.doctor.label}
                                placeholder={fields.doctor.label}
                                onClick={doctorErrorHandler.onFieldClick}
                                control={control}
                                isMulti={false}
                                defaultValue={currentEvent &&
                                    defaultChoicesSelect(valueOptionDoctor, labelOptionDoctor)[0]}
                                options={choicesDoctor && Object.entries(fields.doctor.choices).map((b: any) => newOption(b[0], b[1]))}
                            />
                        </div>
                        <label className="add-event__inputs-label">Setting</label>
                        <div className="add-event__inputs-setting">
                            <ReactDatePicker
                                selectedDate={selectedDate}
                                control={control}
                                label={fields.date_birth.label}
                                onClick={dateBirthErrorHandler.onFieldClick}
                                name={"date_birth"}
                                help_text={dateBirthErrorHandler.error && dateBirthErrorHandler.errorMessageCurrentField[1]}
                                error={dateBirthErrorHandler.error}
                                register={register}
                            />
                            <ReactDateTimePicker
                                defaultValue={startTimeDefaultValue && new Date(startTimeDefaultValue)}
                                selectedDate={selectedDate}
                                control={control}
                                label={fields.start.label}
                                onClick={startErrorHandler.onFieldClick}
                                name={"start"}
                                register={register}
                                help_text={fields.start.help_text || startErrorHandler.error && startErrorHandler.errorMessageCurrentField[1]}
                                error={startErrorHandler.error}

                            />
                        </div>
                        <Textarea
                            error={messageErrorHandler.error}
                            onClick={messageErrorHandler.onFieldClick}
                            help_text={fields.message.help_text || messageErrorHandler.error && messageErrorHandler.errorMessageCurrentField[1]}
                            state={"active"}
                            register={register}
                            name="message"
                            input_type={fields.message.input_type}
                            label={fields.message.label}
                            resetForm={reset}
                            defaultValue={currentEvent ? currentEvent?.message : fields.message.initial_value}
                            {...reset}
                        />
                        <Textarea
                            error={noteErrorHandler.error}
                            onClick={noteErrorHandler.onFieldClick}
                            help_text={fields.note.help_text || noteErrorHandler.error && noteErrorHandler.errorMessageCurrentField[1]}
                            state={"active"}
                            register={register}
                            name="note"
                            input_type={fields.note.input_type}
                            label={fields.note.label}
                            resetForm={reset}
                            defaultValue={currentEvent ? currentEvent?.note : fields.note.initial_value}
                            {...reset}
                        />
                        <div>
                            <button
                                className='button button_size-middle button_position-right button_color-black'>Sichern
                            </button>
                        </div>
                        {
                            (status === 'failed' && errorGeneral) &&
                            <p className="general-error">{errorGeneral}</p>
                        }
                    </form>
                )}
            </div>
        </>
    )
}