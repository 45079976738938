import React, { FC, useEffect, useMemo, useState } from "react";
import './ModalAlertEditWorkSchedule.scss'
import { useDispatch, useSelector } from "react-redux";
import { SubmitHandler, useForm } from "react-hook-form";
import { WorkScheduleType } from "../../api/scheduleWork-api";
import { editScheduleWorkTC, fetchScheduleWork } from "../../redux/scheduleWork-reducer";
import CheckboxInput from "../Widgets/CheckboxInput";
import { TimePicker } from "../../components/Widgets/TimePicker";
import dayjs from "dayjs";
import {
    getBranchWorkScheduleSelector,
    getFieldsWorkScheduleSelector
} from "../../redux/selectors";


export type FormDataModalType = {
    day_of_week: string
    start_time_work: string | Date
    end_time_work: string | Date
    start_lunch_break: string | null | Date
    end_lunch_break: string | null | Date
    is_weekend: boolean
    lunch_break: boolean
}

type ModalAlertPropsType = {
    branchId: number
    workScheduleId: number
    workSchedule: Array<WorkScheduleType>
    cancelCallback: () => void
}

export const ModalAlertEditWorkSchedule: FC<ModalAlertPropsType> = ({
    branchId,
    workScheduleId,
    workSchedule,
    cancelCallback,
    ...restProps
}) => {
    const dispatch = useDispatch();

    const branches = useSelector(getBranchWorkScheduleSelector);
    const fields = useSelector(getFieldsWorkScheduleSelector);


    const currentBrunch = branches.filter(br => br.id === branchId)[0].workschedule
    const currentDay = currentBrunch.filter(sw => sw.id === workScheduleId)[0]
    const currentDayId = currentBrunch.filter(sw => sw.id === workScheduleId)[0].id

    const [weekend, setWeekend] = useState<boolean>(currentDay.is_weekend)
    const [lunch, setLunch] = useState<boolean>(currentDay.lunch_break)

    const [disabledState, setDisabledState] = useState(false)


    let todayDate = new Date().toISOString().slice(0, 10);


    const workScheduleData = currentDay && {
         day_of_week: currentDay.day_of_week,
        start_time_work: !currentDay.start_time_work
            ? new Date(`${todayDate} 00:00`)
            : new Date(`${todayDate} ${currentDay.start_time_work}`),
        end_time_work: !currentDay.end_time_work
            ? new Date(`${todayDate} 00:00`)
            : new Date(`${todayDate} ${currentDay.end_time_work}`),
        start_lunch_break: !currentDay.start_lunch_break
            ? new Date(`${todayDate} 00:00`)
            : new Date(`${todayDate} ${currentDay.start_lunch_break}`),
        end_lunch_break: !currentDay.end_lunch_break
            ? new Date(`${todayDate} 00:00`)
            : new Date(`${todayDate} ${currentDay.end_lunch_break}`),
        is_weekend: currentDay.is_weekend && currentDay.is_weekend,
        lunch_break: currentDay.lunch_break && currentDay.lunch_break
    }

    useEffect(() => {
        if (workScheduleData) {
            reset(workScheduleData)
        }
    }, [currentDay])

    const onCancelHandler = () => {
        cancelCallback()
    }

    const disabledWeekendHandler = () => {
        if (currentDay.is_weekend || weekend) {
            setWeekend(false)

        }
        if (!weekend) {
            setWeekend(true)
            setDisabledState(!disabledState)
        }
    }

    const disabledLunchHandler = () => {
        if (currentDay.lunch_break || lunch) {
            setLunch(false)
        }
        if (!lunch) {
            setLunch(true)
        }
    }

    const {
        register,
        control,
        handleSubmit,
        reset, /*formState: {errors}*/
    } = useForm<FormDataModalType>({
        mode: 'onBlur',
        defaultValues: useMemo(() => {
            if (workScheduleData) {
                return workScheduleData
            }
        }, [workScheduleData, fields])


    });

    const onSubmit: SubmitHandler<FormDataModalType> = data => {
        const newData = {
            day_of_week: currentDay.day_of_week,
            start_time_work: dayjs(data.start_time_work).format("HH:mm"),
            end_time_work: dayjs(data.end_time_work).format("HH:mm"),
            start_lunch_break: dayjs(data.start_lunch_break).format("HH:mm"),
            end_lunch_break: dayjs(data.end_lunch_break).format("HH:mm"),
            is_weekend: data.is_weekend,
            lunch_break: data.lunch_break
        }
        if (currentDay) {
            dispatch(editScheduleWorkTC(currentDayId, newData))
        }
        dispatch(fetchScheduleWork())
        cancelCallback()
    }
    /*
        let todayDate = new Date().toISOString().slice(0, 10);
        console.log(todayDate); */

    const disabledLunch = !weekend && lunch ? false : true
    /*     const defaultValueStartWork = '12:00'


        console.log(defaultValueStartWork)
        const defaultValueEndWork = currentDay.end_time_work === null ? '--:--' : currentDay.end_time_work
        const defaultValueStartLunch = currentDay.start_lunch_break === null ? '--:--' : currentDay.start_lunch_break
        const defaultValueEndLunch = currentDay.end_lunch_break === null ? '--:--' : currentDay.end_lunch_break */

    const [selectedDate, setSelectedDate] = useState<any>(new Date());

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="edit-window">
            <div className="edit-window__alert">
                <h2 className="title">{`Edit schedule`}</h2>
                <div className="input-wrapper">
                    <div className="checkbox-block">
                        <span className="span">Weekend</span>
                        <div className="input">
                            <CheckboxInput
                                state="square"
                                defaultChecked={currentDay.is_weekend}
                                id={`${currentDay.id}`}
                                register={register}
                                name="is_weekend"
                                //label={fields.is_weekend.label}

                                onChangeChecked={disabledWeekendHandler}
                            />
                        </div>
                    </div>


                    <div className="input-block">
                        <span className="span">Start</span>
                        <div className="input">
                            {/* <Inputs*/}
                            {/*name="start_time_work"*/}
                            {/*// state="time"*/}
                            {/*state="active"*/}
                            {/*step={300}*/}
                            {/*autoFocus={true}*/}
                            {/*register={register}*/}
                            {/*resetForm={reset}*/}
                            {/*label={fields.start_time_work.label}*/}
                            {/*disabled={weekend}*/}
                            {/*input_type={'time'}*/}
                            {/*// defaultValue={defaultValueStartWork}*/}
                            {/*{...restProps} />*/}

                            <TimePicker
                                state={!weekend ? 'timePicker' : 'disabled'}
                                disabled={weekend}
                                selectedDate={selectedDate}
                                control={control}
                                label={fields.start_time_work.label}
                                name={"start_time_work"}
                                step={300}
                                register={register}
                            // onChangeText={}
                            />
                        </div>
                    </div>
                    <div className="input-block">
                        <span className="span">End</span>
                        <div className="input">
                            <TimePicker
                                state={!weekend ? 'timePicker' : 'disabled'}
                                disabled={weekend}
                                selectedDate={selectedDate}
                                control={control}
                                label={fields.end_time_work.label}
                                name={"end_time_work"}
                                step={300}
                                register={register}
                            // onChangeText={}
                            />
                            {/*  <Inputs
                                name="end_time_work"
                                state="active"
                                step={300}
                                autoFocus={true}
                                register={register}
                                label={fields.end_time_work.label}
                                resetForm={reset}
                                input_type={'time'}
                                disabled={weekend}
                                defaultValue={defaultValueEndWork}
                                {...restProps} /> */}
                        </div>
                    </div>
                    <div className="checkbox-block">
                        <span className="span">Lunch</span>
                        <div className="input">
                            <CheckboxInput
                                defaultChecked={currentDay.lunch_break}
                                state="square"
                                disabled={weekend}
                                id={`${currentDay.id}`}
                                register={register}
                                name="lunch_break"
                                onChangeChecked={disabledLunchHandler}
                            />
                        </div>
                    </div>
                    <div className="input-block">
                        <span className="span">Start</span>
                        <div className="input">
                            <TimePicker
                                state={disabledLunch ? 'disabled' : 'timePicker'}
                                disabled={disabledLunch}
                                selectedDate={selectedDate}
                                control={control}
                                label={fields.start_lunch_break.label}
                                name={"start_lunch_break"}
                                step={300}
                                register={register}
                            // onChangeText={}
                            />
                            {/*     <Inputs
                                name="start_lunch_break"
                                // state="time"
                                state="active"
                                step={300}
                                autoFocus={true}
                                input_type={'time'}
                                register={register}
                                resetForm={reset}
                                label={fields.start_lunch_break.label}
                                disabled={disabledLunch}
                                defaultValue={defaultValueStartLunch}
                                {...restProps} /> */}
                        </div>
                    </div>
                    <div className="input-block">
                        <span className="span">End</span>
                        <div className="input">
                            <TimePicker

                                state={disabledLunch ? 'disabled' : 'timePicker'}
                                disabled={disabledLunch}
                                selectedDate={selectedDate}
                                control={control}
                                label={fields.end_lunch_break.label}
                                name={"end_lunch_break"}
                                step={300}
                                register={register}
                            // onChangeText={}
                            />

                            {/*    <Inputs
                                name="end_lunch_break"
                                // state="time"
                                state="active"
                                step={300}
                                input_type={'time'}
                                autoFocus={true}
                                register={register}
                                resetForm={reset}
                                label={fields.end_lunch_break.label}
                                disabled={disabledLunch}
                                defaultValue={defaultValueEndLunch}
                                {...restProps} /> */}
                        </div>
                    </div>
                </div>
                <div className="btn-wrapper">
                    <button onClick={onCancelHandler}
                        type="submit"
                        className="button button_size-middle button_position-right button_color-transparent">
                        Cancel
                    </button>
                    <button type="submit"
                        className="button button_size-middle button_position-left button_color-green buttonAlert">
                        Save
                    </button>
                </div>
            </div>
        </form>

    )
}

