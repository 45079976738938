import React, {useEffect} from "react";
import {fetchEventDetailsTC} from "../../../redux/events-reducer";
import {useDispatch, useSelector} from "react-redux";
import {Navigate, useParams} from "react-router-dom";
import "./EventDetails.scss"
import {initializeAppTC} from "../../../redux/app-reducer";
import {PATH} from "../../Routes";
import {
    getDetailsEventsSelector,
    getIsLoggedInSelector,
    getStatusSelector
} from "../../../redux/selectors";

export const EventDetails = () => {
    const params = useParams();
    const dispatch = useDispatch()

    const status = useSelector(getStatusSelector);
    const isLoggedIn = useSelector(getIsLoggedInSelector);
    const details = useSelector(getDetailsEventsSelector);

    const idUrl = params.id
    const eventId = Number(idUrl)

    useEffect(() => {
        if (status === "failed") {
            dispatch(initializeAppTC())
        }
    }, [status])

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchEventDetailsTC(eventId))
        }
    }, [dispatch])

    const detailsArr = Object.entries(details)

    if (!isLoggedIn) {
        return <Navigate to={PATH.LOGIN}/>;
    }
    console.log(detailsArr)

    return (
        <>
            <div className="details">
                <h1 className="details__title">Event details</h1>
                {
                    details && detailsArr.map((el, index) =>
                        <div key={index} className="details__detail">
                            <span><b>{el[0]}: </b></span>
                            <span>{typeof el[1] === "object" ? '' : el[1]}</span>
                        </div>)
                }
            </div>
        </>
    )
}