import {Dispatch} from "redux";
import {loadProfileDataLS, loadToken, saveProfileDataLS,} from "../utils/localStorage";
import {profileAPI, ProfileDataType} from "../api/profile-api";
import {setAppStatusAC} from "./app-reducer";
import {handleServerAppError, handleServerNetworkError} from "../utils/error-utils";
import {RESET_REDUCER_GROUP, ResetReducerGroupActionType} from "./auth-reducer";


const SET_PROFILE_DATA = 'profileReducer/SET_PROFILE_DATA'
const UPDATE_PROFILE_DATA = 'profileReducer/UPDATE_PROFILE_DATA'

const initialState = {} as ProfileDataType

type InitialStateType = typeof initialState

export const profileReducer = (state: InitialStateType = initialState, action: ActionsType): InitialStateType => {
    switch (action.type) {

        case SET_PROFILE_DATA: {
            return {...state, ...action.model}
        }
        case UPDATE_PROFILE_DATA: {
            return {
                ...state, ...action.model
            }
        }
        case RESET_REDUCER_GROUP:
            return { ...initialState };
        default:
            return state
    }
}

// actions
export const setProfileDataAC = (model: ProfileDataType) =>({type: SET_PROFILE_DATA, model} as const)
export const updateProfileDateAC = (model: UpdateProfileDataModelType) =>({type: UPDATE_PROFILE_DATA, model} as const)

// thunks
export const getProfileTC = () => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        let response = await profileAPI.getProfile(tokenLS)
        saveProfileDataLS(response.data)
        const user = await loadProfileDataLS()
        dispatch(setProfileDataAC(user))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

export const updateProfileTC = (data: UpdateProfileDataModelType) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        let response = await profileAPI.updateProfile(data, tokenLS)
        saveProfileDataLS(response.data)
        const user = await loadProfileDataLS()
        dispatch(updateProfileDateAC(user))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
}

//types
type ActionsType = ReturnType<typeof setProfileDataAC>
    | ReturnType<typeof updateProfileDateAC>
    | ResetReducerGroupActionType

export type UpdateProfileDataModelType = {
    id?: number
    first_name?: string
    last_name?: string
    email?: string
    photo?: File | null
    phone?: string
    role?: string
    owner?: string | null
}