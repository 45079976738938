import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const eventsAPI = {
  getSortList(token: string, ordering: string | null) {
    return instance.get<EventsResponseData>(
      `/api/events/?ordering=${ordering}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  getList(token: string) {
    return instance.get<EventsResponseData>("/api/events/", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  addEvent(data: any, token: string, branch: number) {
    return instance.post<EventsType>(
      `/api/events/create/?branch=${branch}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  editEvent(data: any, token: string, eventId: number) {
    return instance.put<EventsType>(`/api/events/${eventId}/`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  removeEvents(token: string, eventId: number) {
    return instance.delete<any>(`/api/events/${eventId}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getNextList(token: string, next: string | null) {
    return axios.get<EventsResponseData>(`${next}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getPrevList(token: string, prev: string | null) {
    return axios.get<EventsResponseData>(`${prev}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getEventDetails(token: string, eventId: number) {
    return instance.get<EventDetailType>(`/api/events/${eventId}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  cancelOrderEvent(token: string, uuid_field: string) {
    return instance.get<EventsType>(`/api/events/${uuid_field}/cancel/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getFilteredParamsList(token: string, branchId: number) {
    return instance.get<EventFilterParamsType>(
      `/api/events/filter-form/?branch=${branchId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  getFilteredListOfGeneral(token: string, ordering?: Array<string>,
    orderingReason?: Array<string>, orderingStatus?: Array<string>, orderingColor?: Array<string>, branch?: Array<string>) {
    return instance.get<EventsResponseData>(
      `/api/events/?insurance_type__in=${ordering}&reason__in=${orderingReason}&status__in=${orderingStatus}&reason__color=${orderingColor}&branch=${branch}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  searchOfFullName(token: string, name: string) {
    return instance.get<EventsResponseData>(
      `/api/events/?search=${name}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  changeStatus(token: string, eventId: number) {
    return instance.get<EventsType>(
      `api/client/events/${eventId}/set-status/?status=waiting_room`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  changeStatusOnDone(token: string, eventId: number) {
    return instance.get<EventsType>(
      `api/client/events/${eventId}/set-status/?status=done`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  changeStatusNotWait(token: string, eventId: number) {
    return instance.get<EventsType>(
      `api/client/events/${eventId}/set-status/?status=did_not_wait`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  changeStatusAtDoctor(token: string, eventId: number) {
    return instance.get<EventsType>(
      `api/client/events/${eventId}/set-status/?status=at_the_doctor`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
};

export type EventFilterParamsType = {
  full_name: { choices: {} }
  reason__color: { choices: {} },
  insurance_type__in: { choices: {} }
  reason__in: { choices: {} }
  status__in: { choices: {} }
  branch: { choices: {} }
  search: {}
}

export type EventDetailType = {
  branch: number
  branch_display: string
  client: null | any
  client_display: string
  created_on: string
  date_birth: string
  desired_date: boolean
  email: string
  email_reminded: boolean
  end: string
  first_name: string
  gender: number
  gender_display: string
  id: number
  insurance_type: string
  insurance_type_display: string
  is_client: boolean
  is_client_display: string
  last_name: string
  message: string
  phone: string
  reason: number
  reason_display: string
  rejected_by_client: boolean
  rejected_by_doctor: boolean
  sms_reminded: boolean
  start: string
  status: string
  status_display: string
  updated_on: string
  uuid_field: string
}

type DoctorType = {
  email: string
  first_name: string
  id: number
  last_name: string
  owner: number
  owner_display: string
  phone: string
  photo: string
  role: string
}

export type EventsType = {
  id: number;
  uuid_field: string;
  start: string;
  end: string;
  desired_date: boolean;
  client: null
  status: any;
  insurance_type: string;
  branch: number;
  reason: number;
  gender: any;
  first_name: string;
  last_name: string;
  date_birth: string;
  email: string;
  phone: string;
  is_client: boolean;
  message: string;
  created_on: string;
  updated_on: string;
  email_reminded: boolean;
  sms_reminded: boolean;
  branch_display: string;
  client_display: string;
  reason_display: string;
  reason_color: string
  gender_display: string
  insurance_type_display: string
  is_client_display: string
  status_display: any
  wait_seconds: number
  wait_time: string
  doctor: DoctorType
  finished_at: any
  note: string
  start_waiting_at: any
  visit_time: string
  waiting_time: string
};

export type EventsLinksType = {
  next: string | null;
  previous: string | null;
};

export type EventsResponseData = {
  links: EventsLinksType;
  count: number;
  current_page: number;
  lastPage: number;
  current: number;
  results: Array<EventsType>;
};

//ordering params
export const sortEventsParams = [
  {
    name: "Vorname",
    ordering: "first_name",
  },
  {
    name: "Vorname (absteigend)",
    ordering: "-first_name",
  },
  {
    name: "Besuchgrund",
    ordering: "reason",
  },
  {
    name: "Besuchgrund (absteigend)",
    ordering: "-reason",
  },
  {
    name: "Datum/Zeit",
    ordering: "start",
  },
  {
    name: "Datum/Zeit (absteigend)",
    ordering: "-start",
  },
  {
    name: "Status",
    ordering: "status",
  },
  {
    name: "Status (absteigend)",
    ordering: "-status",
  },
  {
    name: "Versicherung",
    ordering: "insurance_type",
  },
  {
    name: "Versicherung (absteigend)",
    ordering: "-insurance_type",
  },
];
