import React, { Component } from 'react'
import "./Selects.scss";
//@ts-ignore
import close from "../../assets/img/close.svg";
import {
    DetailedHTMLProps,
    FC,
    InputHTMLAttributes,
    useState,
} from "react";
import { Path } from "react-hook-form";
import { FormDataType } from "../../pages/Login/Login";
import Select from 'react-select'


type DefaultInputPropsType = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

type SelectType = DefaultInputPropsType & {
    id?: string
    register?: any;  //UseFormRegister<FormDataType>;
    resetForm?: () => void
    state?: string;
    name?: Path<FormDataType> | string;
    value?: any;
    multiple?: boolean
    options?: any
    label?: any


};


const Selects: FC<SelectType> = ({
    type, id,
    className,
    name, label, value, options, multiple
                                          /*input_name,*/,
    register, resetForm,
    ...restProps
}) => {
    const [isActive, setIsActive] = useState(false)
    /*  const options = [
         { value: value, label: label },
         { value: 'strawberry', label: 'Strawberry' },
         { value: 'vanilla', label: 'Vanilla' }
       ] */
    return (
        <div>

            <Select 
            className={"fieldss_select"} 
            id={id} name={name} 
            value={value} 
            />

        </div>
    )
}

export default Selects;

















/* import { DetailedHTMLProps, FC , InputHTMLAttributes} from "react";
import './Selects.scss';
import { FormDataType } from "../../pages/Login/Login";
import CheckboxInput from '../../components/Widgets/CheckboxInput';
import { Path } from "react-hook-form";



type DefaultSelectPropsType = DetailedHTMLProps<InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>

type SelectsType = DefaultSelectPropsType & {
    value?: any;
    id?:string;
    multiple?:boolean;
    register?:any
    name?: Path<FormDataType> | string;
    resetForm?: () => void
    
   
}

const Selects: FC<SelectsType> = (props) => {
    return (
        <div className={"field"}>
            <select className={"field_select"} id={props.id} name={props.name}  multiple={props.multiple}   />
            <option  className={"field_select-element"}>{props.value}</option>
            
        </div>
    )
        
}

export default Selects; */