import React, {useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {loginTC} from "../../redux/auth-reducer";
import {Navigate, NavLink} from "react-router-dom";
import './Login.scss'
import '../../components/Widgets/Button/Button.scss'
import {logo} from "../../assets/img/image";
import {PATH} from "../Routes";
import {validation} from "../../utils/validation";
import Inputs from "../../components/Widgets/Inputs";
import {
    getErrorStateSelector,
    getIsLoggedInSelector,
    getStatusSelector
} from "../../redux/selectors";


export type FormDataType = {
    username: string
    password: string
    detail: string
}

export const Login = ({...restProps}) => {
    const dispatch = useDispatch();

    const errorState = useSelector(getErrorStateSelector);
    const status = useSelector(getStatusSelector);
    const isLoggedIn = useSelector(getIsLoggedInSelector);

    //validation
    const formDataArray = ["username", "password", /*"detail"*/]
    const [errorGeneral, setErrorGeneral] = useState<string | null>(null)
    const [errorUserName, setErrorUserName] = useState<string | null>(null)
    const [errorPassword, setErrorPassword] = useState<string | null>(null)
    const [errorDetail, setErrorDetail] = useState<string | null>(null)

    const errorField = validation(errorState, formDataArray).testErrorField
    const errorFieldMessage = validation(errorState, formDataArray).testErrorFieldMessage

    const errorUserNameField = errorField.filter(el => el === "username")[0]
    const errorPasswordField = errorField.filter(el => el === "password")[0]
    const errorDetailField = errorField.filter(el => el === "detail")[0]

    const errorUserNameMessage = errorFieldMessage.filter(el => el[0] === "username")[0]
    const errorPasswordMessage = errorFieldMessage.filter(el => el[0] === "password")[0]
    const errorDetailMessage = errorFieldMessage.filter(el => el[0] === "detail")[0]

    const onClickUserNameHandler = () => {
        setErrorUserName(null)
        setErrorDetail(null)
    }
    const onClickPasswordHandler = () => {
        setErrorPassword(null)
        setErrorDetail(null)
    }

    useEffect(() => {
        if (validation(errorState, formDataArray).testErrorField.length === 0) {
            if (typeof errorState === "object") {
                setErrorGeneral(errorState && Object.values(errorState)[0])
            } else {
                setErrorGeneral(errorState)
            }
        }
        return () => {
            setErrorGeneral(null)
        };
    }, [status])

    useEffect(() => {
        if (status === "failed") {
            setErrorUserName(errorUserNameField)
            setErrorPassword(errorPasswordField)
            setErrorDetail(errorDetailField)
        }
    }, [status])

    const {
        register,
        handleSubmit,
        reset,
    } = useForm<FormDataType>({
        mode: 'onBlur',
    });
    const onSubmit: SubmitHandler<FormDataType> = data => {
        dispatch(loginTC(data))
    }

    if (isLoggedIn) {
        return <Navigate to={'/'}/>
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="login" onClick={() => {
            setErrorGeneral(null)
        }}>
            <NavLink to={PATH.LOGIN} className="img-wrapper">
                <img src={logo.termiLogo} className="img" alt="termiNow"/>
            </NavLink>
            <div className="wrapper">
                <h1 className="title">Anmelden</h1>
                <Inputs
                    error={errorUserName}
                    help_text={errorUserName && errorUserNameMessage[1]}
                    onClick={onClickUserNameHandler}
                    state={"active"}
                    register={register}
                    name={"username"}
                    label="Email"
                    resetForm={reset}
                    {...reset}
                />
                <Inputs
                    error={errorPassword}
                    onClick={onClickPasswordHandler}
                    help_text={errorPassword && errorPasswordMessage[1]}
                    name="password"
                    input_type="password"
                    state="active"
                    label="Password"
                    register={register}
                    resetForm={reset}
                    {...restProps}/>
                {
                    (status === 'failed' && errorGeneral) &&
                    <p className="general-error">{errorGeneral}</p>
                }
                <div>
                    <NavLink
                      to={`${PATH.REGISTER}`}
                        className="button button_size-middle button_position-left button_color-transparent"
                        type="submit">Signup
                    </NavLink>
                    <button
                        className="button button_size-middle button_position-right button_color-green"
                        type="submit">Login
                    </button>
                </div>
            </div>
        </form>
    );
}
