import React, {useEffect, useLayoutEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, NavLink } from "react-router-dom";
import {
  fetchHolidaysList,
  joinHolidayTC,
  leaveHolidayTC,
  removeHolidayTC,
} from "../../redux/holidays-reducer";
import { PATH } from "../Routes";
import "./Holidays.scss";
import {
  fetchBranchesListFirstRender,
} from "../../redux/branches-reducer";
import { ModalAlertDelete } from "../../components/ModalsAlerts/ModalAlertDelete";
import { initializeAppTC, setAppStatusAC } from "../../redux/app-reducer";
import { logo } from "../../assets/img/image";
import Preloader from "../Preloader/Preloader";
import {
  getActiveWeekSelector,
  getCurrentBranchSelector,
  getHolidaysSelector, getIsLoggedInSelector,
  getStatusSelector
} from "../../redux/selectors";


export const Holidays = () => {
  const dispatch = useDispatch();

  const holidays = useSelector(getHolidaysSelector);
  const currentBranch = useSelector(getCurrentBranchSelector);
  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const activeWeek = useSelector(getActiveWeekSelector);

  const currentHoliday = holidays.length !== 0 && holidays.find((holiday) => holiday.id === currentBranch)

  const currentOfficials = currentHoliday && currentHoliday.official

  //dialog alerts
  const [openAlertDialogForDelete, setOpenAlertDialogForDelete] = useState(false);
  const [modalDataId, setModalDataId] = useState<number>(0);

  useEffect(() => {
    if (status === "failed") {
      dispatch(initializeAppTC())
    }
  }, [status])

  // useEffect(() => {
  //   if (isLoggedIn && currentHoliday !== null) {
  //     dispatch(fetchHolidaysList());
  //   }
  // }, [dispatch])

  useLayoutEffect(() => {

      dispatch(fetchHolidaysList());

  }, [dispatch])

  useEffect(() => {
    if (isLoggedIn) {
      if (!activeWeek) {
        dispatch(fetchBranchesListFirstRender());
      }
    }
    return () => {
      dispatch(setAppStatusAC("idle"));
    };
  }, [dispatch]);

  const joinToHoliday = (holidayId: number) => {
    dispatch(joinHolidayTC(holidayId))
  }

  const leaveToHoliday = (holidayId: number) => {
    dispatch(leaveHolidayTC(holidayId))
  }

  const removeEmployee = (id: number) => {
    dispatch(removeHolidayTC(id));
    setModalDataId(0);
  };

  const openAlertHandler = (holidayId: number) => {
    setModalDataId(holidayId);
    setOpenAlertDialogForDelete(true);
  };

  const closeAlertHandler = () => {
    setOpenAlertDialogForDelete(false);
  };

  const OnClickHandler1 = () => {
    if (table1) {
      showTable1(false);
    } else {
      showTable1(true);
    }
  };

  const OnClickHandler2 = () => {
    if (table2) {
      showTable2(false);
    } else {
      showTable2(true);
    }
  };

  const OnClickHandler3 = () => {
    if (table3) {
      showTable3(false);
    } else {
      showTable3(true);
    }
  };

  const [table1, showTable1] = useState<any>(false);
  const [table2, showTable2] = useState<any>(false);
  const [table3, showTable3] = useState<any>(false);

  const setTimeHoliday = (start: string, end: string) => {
    const editStart = Number(start.slice(0, 2))
    const editEnd = Number(end.slice(0, 2))
    return editEnd - editStart
  }

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }

  return (
    <>
      <div className="holidays">
        <h1 className="holidays__title">Holidays list</h1>
        <div className="holidays__add">
          <NavLink
            className="button button_size-small button_position-left button_color-transparent"
            to={PATH.ADD_HOLIDAY}
          >
            + add holiday
          </NavLink>
        </div>
        {status === 'loading' ? (
          <Preloader />
        ) : (
          <div>
            <div onClick={OnClickHandler1} className="holidays__title-block private">
              <span className="title-holiday">Private</span>
              {
                table1
                  ? <img
                    onClick={OnClickHandler1}
                    src={logo.arrow_down}
                    alt="up"
                    className="arrow"
                  />
                  : <img
                    onClick={OnClickHandler1}
                    src={logo.arrow_up}
                    alt="up"
                    className="arrow"
                  />
              }
            </div>
            {table1 && <hr className="holidays__line" />}
            <table className="holidays__table">
              <thead className="thead">
                <tr className={` ${table1 ? "active" : "tr"}`}>
                  <th className="th">Name</th>
                  <th className="th">Date</th>
                  <th className="th">Time</th>
                  <th className="th">Option</th>
                </tr>
              </thead>
              <tbody
                className={` ${table1 ? "active" : "tbody"}`}

              >

                {currentHoliday &&
                  currentHoliday.private.map((priv) =>
                    <tr key={priv.id} className="tr">
                      <td className="td">{priv.name}</td>
                      <td className="td">{priv.date}</td>
                      <td className="td">
                        {
                          setTimeHoliday(priv.start_time, priv.end_time) > 8
                            || setTimeHoliday(priv.start_time, priv.end_time) === 0
                            ? "All day"
                            : `${priv.start_time.slice(0, 5)} — ${priv.end_time.slice(0, 5)}`
                        }
                      </td>
                      <td className="td">
                        <div className="btns">
                          <NavLink
                            className="button button_size-small  button_color-green"
                            to={`${PATH.HOLIDAYS}${priv.id}/`}
                          >
                            Edit
                          </NavLink>
                          <button
                            onClick={() => openAlertHandler(priv.id)}
                            className="button button_size-small  button_color-delete"
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                      {openAlertDialogForDelete && (
                        <>
                          <ModalAlertDelete
                            deleteCallback={removeEmployee}
                            id={modalDataId}
                            titleQuestion="Delete employee?"
                            actionTitle="Delete"
                            cancelCallback={closeAlertHandler}
                          />
                        </>
                      )}
                    </tr>
                  )
                }
              </tbody>
            </table>
            <div onClick={OnClickHandler2} className="holidays__title-block">
              <span className="title-holiday">Official</span>
              {
                table2
                  ? <img
                    onClick={OnClickHandler2}
                    src={logo.arrow_down}
                    alt="up"
                    className="arrow"
                  />
                  : <img
                    onClick={OnClickHandler2}
                    // src={addArrow}
                    src={logo.arrow_up}
                    alt="up"
                    className="arrow"
                  />
              }
            </div>
            {table2 && <hr className="holidays__line" />}
            <table className="holidays__table">
              <thead className="thead">
                <tr className={` ${table2 ? "active" : "tr"}`}>
                  <th className="th">Name</th>
                  <th className="th">Date</th>
                  <th className="th">Time</th>
                  <th className="th">Option</th>
                </tr>
              </thead>
              <tbody className={` ${table2 ? "active" : "tbody"}`}>
                {currentOfficials &&
                  currentHoliday.official.map((official) =>
                    <tr key={official.id} className="tr">
                      <td className="td">{official.name}</td>
                      <td className="td">{official.date}</td>
                      <td className="td">
                        {
                          setTimeHoliday(official.start_time, official.end_time) > 8
                            || setTimeHoliday(official.start_time, official.end_time) === 0
                            ? "All day"
                            : `${official.start_time.slice(0, 5)} — ${official.end_time.slice(0, 5)}`
                        }
                      </td>
                      <td className="td">
                        <div className="btns">
                          <button
                            onClick={() => {
                              leaveToHoliday(official.id)
                            }}
                            className="button button_size-small  button_color-delete"
                          >
                            Leave
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
            <div onClick={OnClickHandler3} className="holidays__title-block">
              <span className="title-holiday">Suggestion</span>
              {
                table3
                  ? <img
                    onClick={OnClickHandler3}
                    src={logo.arrow_down}
                    alt="up"
                    className="arrow"
                  />
                  : <img
                    onClick={OnClickHandler3}
                    src={logo.arrow_up}
                    alt="up"
                    className="arrow"
                  />
              }
            </div>
            {table3 && <hr className="holidays__line" />}
            <table className="holidays__table">
              <thead className="thead">
                <tr className={` ${table3 ? "active" : "tr"}`}>
                  <th className="th">Name</th>
                  <th className="th">Date</th>
                  <th className="th">Time</th>
                  <th className="th">Option</th>
                </tr>
              </thead>
              <tbody className={` ${table3 ? "active" : "tbody"}`}>
                {currentHoliday && currentHoliday.suggestion.map((suggestion) => (
                  <tr key={suggestion.id} className="tr">
                    <td className="td">{suggestion.name}</td>
                    <td className="td">{suggestion.date} </td>
                    <td className="td">
                      {
                        setTimeHoliday(suggestion.start_time, suggestion.end_time) > 8
                          || setTimeHoliday(suggestion.start_time, suggestion.end_time) === 0
                          ? "All day"
                          : `${suggestion.start_time.slice(0, 5)} — ${suggestion.end_time.slice(0, 5)}`
                      }
                    </td>
                    <td className="td">
                      <div className="btns">
                        <div
                          onClick={() => joinToHoliday(suggestion.id)}
                          className="button button_size-small  button_color-green"
                        >
                          Join
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  )
}
