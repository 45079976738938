import "./Inputs.scss";
import React from "react";
import {
    ChangeEvent,
    DetailedHTMLProps,
    FC,
    InputHTMLAttributes,
    useCallback,
    useEffect,
    useRef,
    useState
} from "react";
import { Path } from "react-hook-form";
import { FormDataType } from "../../pages/Login/Login";



type DefaultInputPropsType = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

type InputsNewType = DefaultInputPropsType & {
    onChangeText?: (value: string) => void
    onEnter?: () => void
    order?: string
    error?: string | null
    spanClassName?: string
    maxLength?: any
    label?: any
    register?: any
    resetForm?: () => void
    state?: string
    name?: Path<FormDataType> | string
    help_text?: string | null
    img?: string
    input_type?: string
    defaultValue?: any
};


const InputsNew: FC<InputsNewType> = ({
    type, step,
    onChange, onChangeText,
    onKeyPress, onEnter,
    error, order, maxLength,
    className, spanClassName,
    name, state, img,
    input_type, defaultValue,
    help_text,
    label, register, resetForm,
    ...restProps
}) => {
    const [focused, setFocus] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [btn, setBtn] = useState(false);
    const [classs, setClass] = useState('');
    const [value, setValue] = useState(defaultValue || '');
    const [labels, deleteLabel] = useState(true)

    const onInput = (event: any) => {
        setBtn(true);
    }

    const onFocusHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setFocus(true);
        setClass(e.currentTarget.value)
    }

    const onClickHandler = (e: any) => {
        deleteLabel(false)
    }

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(e)
        onChangeText && onChangeText(e.currentTarget.value)
        setValue(e.target.value)
    }

    const onButtonClick = () => {
        resetForm && resetForm()
    }

    const customInputClass = ` 
      field_${img} 
      ${focused ? "field_focus" : "field_unfocus"} 
      ${state === 'order' ? `order field_${state}` : ` field field_${state}  `}
      ${error ? "field_error " : ""}
      ${value.length ? "field_focus" : "field_unfocus"}
      ${defaultValue ? "field_focus" : "field_unfocus"}
 `
    return (

        <div
            className={customInputClass}>
            <input className="field__input"
                {...register(name, {
                    onBlur: (e: ChangeEvent<HTMLTextAreaElement>) => {
                        if (value !== '') {
                            setClass(e.currentTarget.value);
                            setFocus(false)
                        } else {
                            setFocus(false)
                        }
                    },
                    onChange: onChangeHandler
                })}
                maxLength={maxLength}
                id={`id_${name}`}
                onFocus={onFocusHandler}
                onClick={onClickHandler}
                value={value}
                type={input_type}
                disabled={state === "disabled"}
                onInput={onInput}
                step={step}
                {...restProps} />
            <label className={labels ? "field__label" : 'field__labels'}
                htmlFor={`id_${name}`}>
                {label}
            </label>
            <span className="field__help-text">{help_text}</span>
        </div>
    );
}

export default InputsNew;
