import React, { FC, useState } from "react";
import Textarea from "../../../components/Widgets/Textarea";

type IframeType = {
    branchId: number
    cancelCallback: () => void
}

export const Iframe: FC<IframeType> = ({ branchId, cancelCallback }) => {
    const [copySuccess, setCopySuccess] = useState('');
    const copyToClipBoard = async (copyMe: string) => {
        try {
            await navigator.clipboard.writeText(copyMe);
            await cancelCallback()

        } catch (err) {
            setCopySuccess('Failed to copy!');
        }
    };

    const textareaContent = `<script>
    localStorage.setItem('branchId', ${branchId});
</script>

<script defer="defer"
        src="https://widget.dev.terminow.de/static/js/main.8648229e.js"></script>
<link href="https://widget.dev.terminow.de/static/css/main.971b3376.css" rel="stylesheet">
<div id="terminow-registration" class="terminow-root"></div>
`
    return (
        <div className="iframe" onClick={(e) => e.stopPropagation()}>
            <div>
                <Textarea
                    name={"iframe"}
                    defaultValue={textareaContent}
                />
                <button
                    onClick={() => copyToClipBoard(textareaContent)}
                    className="button button_size-middle button_position-left button_color-green"
                    type="submit">Copy
                </button>
            </div>
        </div>
    )
}