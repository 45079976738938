import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Events.scss";
import {
  cancelOrderEventTC,
  changeStatusDoctorTC,
  changeStatusDoneTC,
  changeStatusNotTC,
  changeStatusTC,
  fetchEventsList,
  fetchSortEventsList,
  getNextEventsList,
  getPrevEventsList,
  removeEventTC, setCurrentPageAC,
} from "../../redux/events-reducer";
import { sortEventsParams } from "../../api/events-api";
import { Navigate, NavLink } from "react-router-dom";
import { PATH } from "../Routes";
import { ModalAlertDelete } from "../../components/ModalsAlerts/ModalAlertDelete";
import Preloader from "../Preloader/Preloader";
import { logo } from "../../assets/img/image";
import { Pagination } from "../../components/Pagination/Pagination";
import { initializeAppTC, setAppStatusAC } from "../../redux/app-reducer";
import {
  fetchBranchesListFirstRender
} from "../../redux/branches-reducer";
import {
  fetchFilteredEventsListOfGeneral,
  fetchFilteredEventsParamsListTC
} from "../../redux/events-filter-reducer";
import {
  getActiveWeekSelector,
  getCurrentEventsSelector,
  getEventsSelector,
  getIsLoggedInSelector,
  getStatusSelector,
  getPreviousEventsSelector,
  getNextEventsSelector,
  getCurrentPagePaginationEventsSelector
} from "../../redux/selectors";
//@ts-ignore
import img from "../../../src/assets/img/closeW.png";
//@ts-ignore
import img2 from "../../../src/assets/img/icons8.svg";

export const Events = () => {

  const dispatch = useDispatch();

  const events = useSelector(getEventsSelector);
  const previous = useSelector(getPreviousEventsSelector);
  const next = useSelector(getNextEventsSelector);
  const currentPagePagination = useSelector(getCurrentPagePaginationEventsSelector);
  const current = useSelector(getCurrentEventsSelector);
  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const activeWeek = useSelector(getActiveWeekSelector);

  //dialog alerts
  const [openAlertDialogForDelete, setOpenAlertDialogForDelete] = useState(false);
  const [openAlertDialogForCancel, setOpenAlertDialogForCancel] = useState(false);
  const [modalDataId, setModalDataId] = useState<number>(0);

  const [showWait, setShowWait] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [status1, setStatus] = useState('')

  const [btn, showBtns] = useState(true)

  //list of sort params
  const [titleSortList, setTitleSortList] =
    useState<string>("wähle eine Option");
  const [openMenu, setOpenMenu] = useState(false);

  const editStateMenu = () => {
    setOpenMenu(!openMenu);
  };
  const classNameMenu = openMenu ? "sort-list-open" : "sort-list";

  useEffect(() => {
    if (status === "failed") {
      dispatch(initializeAppTC())
    }
  }, [status])

  useLayoutEffect(() => {
    dispatch(fetchFilteredEventsListOfGeneral());
  }, [dispatch])


  useEffect(() => {
    if (isLoggedIn) {
      if (!activeWeek) {
        dispatch(fetchBranchesListFirstRender())
      }
      // dispatch(fetchFilteredEventsParamsListTC()) ///Filter events
    }

    return () => {
      dispatch(setAppStatusAC("idle"));
    };
  }, [events]);

  useEffect(() => {
    dispatch(fetchFilteredEventsParamsListTC())
  }, [dispatch])

  useEffect(() => {
    dispatch(setCurrentPageAC(null))
  }, [dispatch])

  const removeEvents = (id: number) => {
    dispatch(removeEventTC(id));
    setModalDataId(0);
  };

  const cancelOrderEvents = (id: number) => {
    const currentEvent = events.length && events.find((ev) => ev.id === id);
    const uuid_field = currentEvent && currentEvent.uuid_field
    if (uuid_field) {
      dispatch(cancelOrderEventTC(uuid_field));
    }
    setModalDataId(0);
  };

  const openAlertHandlerCancel = (eventsId: number) => {
    setModalDataId(eventsId);
    setOpenAlertDialogForCancel(true);
  };
  const changeStatus = (eventId: number, status: string) => {
    //setStatus('Zum Warteraum')
    dispatch(changeStatusTC(eventId))
    //dispatch(fetchEventsList())

    // setSelectedIndex(eventId)
  }

  const changeStatusNotWait = (eventId: number) => {
    dispatch(changeStatusNotTC(eventId))
    //setStatus(false)
  }

  const changeStatusDone = (eventId: number) => {
    dispatch(changeStatusDoneTC(eventId))

  }

  const changeStatusDoctor = (eventId: number) => {
    dispatch(changeStatusDoctorTC(eventId))
  }

  const closeAlertHandler = () => {
    setOpenAlertDialogForDelete(false);
    setOpenAlertDialogForCancel(false);
  };

  const nextList = () => {
    if (next !== null) {
      dispatch(getNextEventsList());
    }
  };

  const prevList = () => {
    if (previous !== null) {
      dispatch(getPrevEventsList());
    }
  };

  const sortList = (ordering: string, sortTitle: string) => {
    dispatch(fetchSortEventsList(ordering));
    setTitleSortList(sortTitle);
    setOpenMenu(false);
  };

  const setDate = (date: string) => {
    return new Date(date);
  };

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  };

  return (
    <>
      <div className="events">
        <h1 className="events__title">Events list</h1>
        <div className="events__add">
          <NavLink
            className="button button_size-small button_position-right button_color-transparent"
            to={PATH.ADD_EVENT}
          >
            + new event
          </NavLink>
        </div>
        <div className="events__sort" onClick={editStateMenu}>
          <h3 className="sort-title">
            <span className="title-name">Sortieren nach: </span>
            <b className="title-value">
              {titleSortList}
              {
                openMenu
                  ? (
                    <img src={logo.arrow_up} alt="up" className="arrow" />
                  )
                  : (
                    <img src={logo.arrow_down} alt="down" className="arrow" />
                  )
              }
            </b>
          </h3>
          <ul className={classNameMenu}>
            {sortEventsParams.map((sp) => (
              <li
                key={sp.name}
                className="list-item"
                onClick={() => {
                  sortList(sp.ordering, sp.name);
                }}
              >
                {sp.name}
              </li>
            ))}
          </ul>
        </div>
        {status === "loading" ? (
          <Preloader />
        ) : (
          <div>
            <table className="events__table">
              <thead className="thead">
                <tr className="tr">
                  <th className="th">
                    <span>{"#"}</span>
                  </th>
                  <th className="th">
                    <span>{"Full name"}</span>
                  </th>
                  <th className="th">
                    <span>{"Reason"}</span>
                  </th>
                  <th className="th">
                    <span>{"Date"}</span>
                  </th>
                  <th className="th">
                    <span>{"Status"}</span>
                  </th>
                  <th className="th">
                    <span>{"Options"}</span>
                  </th>
                </tr>
              </thead>
              <tbody className="tbody">
                {events.map((event, index, array) => {
                  return (
                    <tr key={event.id} className="tr">
                      <td className="td">{event.id}</td>
                      <td className="td">
                        {event.first_name} {event.last_name}
                      </td>
                      <td
                        style={{
                          backgroundColor: `${event.reason_color}38`,
                          borderColor: `${event.reason_color}`
                        }}
                        className="td-color">
                        <span
                          style={{
                            opacity: 1
                          }}
                          className="td">{event.reason_display}</span>
                      </td>
                      <td className="td date-event">
                        <span>{`${setDate(event.start).getUTCDate()} ${setDate(
                          event.start
                        )
                          .toDateString()
                          .slice(4, 7)}. ${setDate(
                            event.start
                          ).getFullYear()}`}
                        </span>
                        <b className="time">
                          {`${event.start && event.start.slice(11, 16)} - ${event.end && event.end.slice(11, 16)}`}
                        </b>
                      </td>
                      <td className="td">{event.status_display}</td>
                      <td className="td td-button-group">
                        {event.status === 'new' ?
                          <button
                            style={{
                              display: selectedIndex === event.id ? 'none' : '',
                            }}
                            onClick={() => {
                              changeStatus(event.id, event.status)
                              setSelectedIndex(event.id)
                            }
                            }
                            className={"button button_size-small button_color-blue2"}
                          >
                            Waiting
                          </button>
                          : ''
                        }
                        {event.status === 'waiting_room' ?
                          <>
                            <button
                              title="At the docotor"
                              onClick={() => changeStatusDoctor(event.id)}
                              className="button button_size-small  button_color-green2"
                            ><img style={{ width: '20px' }} src={img2}></img></button>
                            <button
                              title="Did not wait"
                              onClick={() => changeStatusNotWait(event.id)}
                              className="button button_size-small  button_color-delete2"
                            >  <img style={{ width: '16px' }} src={img} />
                            </button>

                          </> : ''
                        }
                        {event.status==='at_the_doctor'?
                          <button onClick={() => changeStatusDone(event.id)}
                          className="button button_size-small  button_color-green"
                        >Done
                        </button> : ''
                        }
                        <NavLink
                          className="button button_size-small button_color-green"
                          to={`${PATH.EVENTS}${event.id}/`}
                        >
                          Edit
                        </NavLink>
                        <button
                          onClick={() => openAlertHandlerCancel(event.id)}
                          className="button button_size-small  button_color-transparent"
                          disabled={event.status === "warned"}
                        >
                          Cancel
                        </button>
                        <NavLink
                          className="button button_size-small button_color-transparent"
                          to={`${PATH.EVENTS}details/${event.id}/`}
                        >
                          Details
                        </NavLink>
                      </td>
                    </tr>
                  );
                })}
                {openAlertDialogForDelete && (
                  <>
                    <ModalAlertDelete
                      deleteCallback={removeEvents}
                      id={modalDataId}
                      titleQuestion="Delete event?"
                      actionTitle="Delete"
                      cancelCallback={closeAlertHandler}
                    />
                  </>
                )}
                {openAlertDialogForCancel && (
                  <>
                    <ModalAlertDelete
                      deleteCallback={cancelOrderEvents}
                      id={modalDataId}
                      titleQuestion="Cancel event?"
                      actionTitle="Yes"
                      cancelCallback={closeAlertHandler}
                    />
                  </>
                )}
              </tbody>
            </table>
            {(next !== null || previous !== null) && (
              <Pagination
                previous={previous}
                next={next}
                current={current}
                currentPage={currentPagePagination}
                previousCallback={prevList}
                nextCallback={nextList}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

