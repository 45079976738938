import React, {FC, useEffect, useMemo, useState} from "react";
import './Profile.scss'
import {useDispatch, useSelector} from "react-redux";
import {Navigate} from "react-router-dom";
import {SubmitHandler, useForm} from "react-hook-form";
import {PATH} from "../Routes";
import Inputs from "../../components/Widgets/Inputs";
import {getProfileTC, updateProfileTC} from "../../redux/profile-reducer";
import {
    initializeAppTC,
    setAppStatusAC
} from "../../redux/app-reducer";
import {addPhotoImg, logo, removePhotoImg} from "../../assets/img/image";
import {validation} from "../../utils/validation";
import {ProfileSideBar} from "./ProfileSideBar/ProfileSideBar";
import {
    getErrorStateSelector,
    getIsLoggedInSelector,
    getStatusSelector, getUserProfileSelector
} from "../../redux/selectors";

type FormDataType = {
    first_name: string
    last_name: string
    email: string
    phone: string
    photo?: File
}

type ProfilePropsType = {}

const baseURL = process.env.REACT_APP_BASE_URL;

export const Profile: FC<ProfilePropsType> = () => {
    const dispatch = useDispatch();

    const errorState = useSelector(getErrorStateSelector);
    const status = useSelector(getStatusSelector);
    const isLoggedIn = useSelector(getIsLoggedInSelector);
    const user = useSelector(getUserProfileSelector);

    const [selectedPhoto, setSelectedPhoto] = useState<any>()
    const [isChosenPhoto, setIsChosenPhoto] = useState<boolean>(false)
    const [isRemovedPhoto, setIsRemovedPhoto] = useState<boolean>(false)

    // validation
    const formDataArray = ["first_name", "last_name", "email", "phone", "photo"]
    const [errorGeneral, setErrorGeneral] = useState<string | null>(null)
    const [errorFirst, setErrorFirst] = useState<string | null>(null)
    const [errorLast, setErrorLast] = useState<string | null>(null)
    const [errorEmail, setErrorEmail] = useState<string | null>(null)
    const [errorPhone, setErrorPhone] = useState<string | null>(null)

    const errorField = validation(errorState, formDataArray).testErrorField
    const errorFieldMessage = validation(errorState, formDataArray).testErrorFieldMessage

    const errorFirstNameField = errorField.filter(el => el === "first_name")[0]
    const errorLastNameField = errorField.filter(el => el === "last_name")[0]
    const errorEmailField = errorField.filter(el => el === "email")[0]
    const errorPhoneField = errorField.filter(el => el === "phone")[0]

    const errorFirstNameMessage = errorFieldMessage.filter(el => el[0] === "first_name")[0]
    const errorLastNameMessage = errorFieldMessage.filter(el => el[0] === "last_name")[0]
    const errorEmailMessage = errorFieldMessage.filter(el => el[0] === "email")[0]
    const errorPhoneMessage = errorFieldMessage.filter(el => el[0] === "phone")[0]

    const onClickFirstNameHandler = () => setErrorFirst(null)
    const onClickLastNameHandler = () => setErrorLast(null)
    const onClickEmailHandler = () => setErrorEmail(null)
    const onClickPhoneHandler = () => setErrorPhone(null)

    const userData = {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
    }

    useEffect(() => {
        if (validation(errorState, formDataArray).testErrorField.length === 0) {
            if (typeof errorState === "object") {
                setErrorGeneral(errorState && Object.values(errorState)[0])
            } else {
                setErrorGeneral(errorState)
            }
        }
        return () => {
            setErrorGeneral(null)
        };
    }, [status])

    useEffect(() => {
        if (status === "failed") {
            dispatch(initializeAppTC())
        }
    }, [status])

    useEffect(() => {
        reset(userData)
    }, [user])

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(getProfileTC())
        }
    }, [dispatch])

    // useEffect(() => {
    //
    //     if (status === "succeeded" || status === "failed") {
    //         const closingAlertTimer = setTimeout(() => {
    //             dispatch(setAppStatusAC("idle"))
    //         }, 3000)
    //
    //         return () => {
    //             clearTimeout(closingAlertTimer);
    //             dispatch(setAppStatusAC("idle"))
    //         };
    //     }
    // }, [status])

    useEffect(() => {
        if (status === "failed") {
            setErrorFirst(errorFirstNameField)
            setErrorLast(errorLastNameField)
            setErrorEmail(errorEmailField)
            setErrorPhone(errorPhoneField)
        }
    }, [status])

    const closeAlertStatus = () => {
        dispatch(setAppStatusAC("idle"))
    }

    const cleanPhotoHandler = () => {
        setIsChosenPhoto(false)
        setIsRemovedPhoto(true)
        setSelectedPhoto(null)
    }

    // const userPhoto = user.photo !== null && selectedPhoto !== null ? `http://api.terminow.de${user.photo}` : logo.notLogo;
    // const userPhotoPreview = isChosenPhoto ? URL.createObjectURL(selectedPhoto[0]) : `http://api.terminow.de${user.photo}`;

    const userPhoto = user.photo !== null && selectedPhoto !== null ? `${baseURL}${user.photo}` : logo.notLogo;
    const userPhotoPreview = isChosenPhoto ? URL.createObjectURL(selectedPhoto[0]) : `${baseURL}${user.photo}`;

    const {
        register,
        handleSubmit,
        reset, /*formState: {errors}*/
    } = useForm<FormDataType>({
        mode: 'onBlur',

        defaultValues: useMemo(() => {
            return userData
        }, [userData])

    });
    const onSubmit: SubmitHandler<FormDataType> = data => {
        setIsChosenPhoto(false)

        if (isChosenPhoto) {
            setSelectedPhoto(data.photo)
        }

        const notChosen = isRemovedPhoto ? 'del' : 'not'
        const newPhoto = isChosenPhoto ? selectedPhoto : notChosen

        const newData = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone: data.phone,
            photo: newPhoto,
        }

        dispatch(updateProfileTC(newData))
    }

    if (!isLoggedIn) {
        return <Navigate to={PATH.LOGIN}/>
    }

    return (
        <>

            <ProfileSideBar/>
            <div className="profile" onClick={closeAlertStatus}>
                <h1 className="profile__title">Profile</h1>
                <form onSubmit={handleSubmit(onSubmit)}
                      className="profile__profile-update">
                    <div className="upload-avatar">
                        <div className="upload-avatar__edit">
                            <label htmlFor="fileLoad" className="edit-label"
                                   style={addPhotoImg}>
                                <input
                                    id="fileLoad"
                                    className="edit-input"
                                    type="file"
                                    {...register("photo", {
                                        onChange: (e) => {
                                            if (e.target.files.length) {
                                                setSelectedPhoto(e.target.files)
                                                setIsChosenPhoto(true)
                                            } else {
                                                if (selectedPhoto) {
                                                    setIsChosenPhoto(true)
                                                } else {
                                                    setIsChosenPhoto(false)
                                                }
                                            }
                                        }
                                    })}/>
                            </label>
                        </div>
                        <div className="upload-avatar__clear">
                            <label htmlFor="fileClear" className="clear-label"
                                   style={removePhotoImg}>
                                <input
                                    id="fileClear"
                                    className="clear-input"
                                    type="checkbox"
                                    onClick={cleanPhotoHandler}
                                />
                            </label>
                        </div>
                        {
                            isChosenPhoto &&
                            <img src={userPhotoPreview} className="upload-avatar__preview"
                                 alt="user"/>
                        }
                        {
                            !isChosenPhoto &&
                            <img src={userPhoto} className="upload-avatar__preview"
                                 alt="user"/>
                        }
                    </div>
                    <label className="inputs-label">1. Personal information</label>
                    <div className="inputs-info">
                        <Inputs
                            state={"active"}
                            error={errorFirst}
                            help_text={errorFirst && errorFirstNameMessage[1]}
                            onClick={onClickFirstNameHandler}
                            register={register}
                            input_type={"text"}
                            name={"first_name"}
                            label={"Varname"}
                            defaultValue={user && user.first_name}
                            resetForm={reset}
                            {...reset}
                        />
                        <Inputs
                            state={"active"}
                            error={errorLast}
                            onClick={onClickLastNameHandler}
                            help_text={errorLast && errorLastNameMessage[1]}
                            register={register}
                            input_type={"text"}
                            name={"last_name"}
                            label={"Nachname"}
                            defaultValue={user && user.last_name}
                            resetForm={reset}
                            {...reset}
                        />
                    </div>
                    <label className="inputs-label">1. Personal information</label>
                    <div className="inputs-info">
                        <Inputs
                            state={"active"}
                            error={errorEmail}
                            onClick={onClickEmailHandler}
                            help_text={errorEmail && errorEmailMessage[1]}
                            register={register}
                            name={"email"}
                            input_type='email'
                            label={"E-mail"}
                            defaultValue={user && user.email}
                            resetForm={reset}
                            {...reset}
                        />
                        <Inputs
                            state={"active"}
                            error={errorPhone}
                            onClick={onClickPhoneHandler}
                            help_text={errorPhone && errorPhoneMessage[1]}
                            register={register}
                            input_type={"text"}
                            name={"phone"}
                            label={"Phone Number"}
                            defaultValue={user && user.phone}
                            resetForm={reset}
                            {...reset}
                        />
                    </div>
                    <div>
                        <button
                            className='button button_size-middle button_position-right button_color-black'>
                            Sichern
                        </button>
                    </div>
                </form>

                {
                    status === "succeeded" && <div className="profile__status success">
                        <div className="span">Saved</div>
                    </div>
                }
                {
                    status === "failed" && <div className="profile__status failed">
                        <div className="span">Failed</div>
                        {
                            (status === 'failed' && errorGeneral) &&
                            <p className="general-error">{errorGeneral}</p>
                        }
                    </div>

                }
            </div>
        </>
    )
}
