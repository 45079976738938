import {AppRootStateType} from "../store";
import {ClientFilterParamsType, ClientsType} from "../../api/clients-api";

export const getClientsSelector = (state: AppRootStateType): Array<ClientsType> =>
    state.clients.clientsData.results;

export const getPreviousClientsSelector = (state: AppRootStateType): string | null =>
    state.clients.clientsData.links.previous;

export const getNextClientsSelector = (state: AppRootStateType): string | null =>
    state.clients.clientsData.links.next;

export const getCurrentPagePaginationClientsSelector = (state: AppRootStateType): number =>
    state.clients.clientsData.current_page;

export const getCurrentClientsSelector = (state: AppRootStateType): number =>
    state.clients.clientsData.current_page;

export const getCurrentClientsPageSelector = (state: AppRootStateType): string | null =>
    state.clients.currentPage;

export const getFilterParamsClientsSelector = (state: AppRootStateType): ClientFilterParamsType =>
    state.clientsFilter.clientsFilterParamsList;

export const getCurrentInsuranceParamsClientsSelector = (state: AppRootStateType): Array<string> =>
    state.clientsFilter.currentFilterParams.insurance_type__in;


