import React, {useEffect, useMemo, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import "./FormsReason.scss";
import "../../../components/Widgets/Button/Button.scss";
import {useDispatch, useSelector} from "react-redux";
import {Navigate, useParams} from "react-router-dom";
import {PATH} from "../../Routes";
import Inputs from "../../../components/Widgets/Inputs";
import {
    addReasonTC,
    
    editReasonTC,
    fetchReasonsList
    
} from "../../../redux/reasons-reducer";
import {validation} from "../../../utils/validation";
import {fetchReasonsListFieldsTC} from "../../../redux/fields-reducer";
import {
    defaultChoicesMultiSelect,
    defaultChoicesSelect,
    newOption,
} from "../../../components/Widgets/Select/ReactSelectStyles";
import {ProfileSideBar} from "../../Profile/ProfileSideBar/ProfileSideBar";
import {ReactSelect} from "../../../components/Widgets/Select/ReactSelect";
import {useErrorHandler} from "../../../utils/useErrorHandler";
import {
    getErrorStateSelector, getFieldsReasonsSelector, getIsLoggedInSelector,
    getReasonsSelector,
    getStatusSelector
} from "../../../redux/selectors";

type FormDataType = {
    branches: { value: any, label: any }
    title: string
    offset_time: { value: any, label: any }
    limit_time: number
    order: number
    color: string
    doctors: { value: any, label: any }
}

export const FormsReason = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const reasons = useSelector(getReasonsSelector);
    const status = useSelector(getStatusSelector);
    const isLoggedIn = useSelector(getIsLoggedInSelector);
    const fields = useSelector(getFieldsReasonsSelector);
    const errorState = useSelector(getErrorStateSelector);

    const [valueReason, setValueReason] = useState<boolean>(false)

    //for edit reason
    const idUrl = params.id
    const reasonId = Number(idUrl)
    const currentReason = reasons.length && reasons.filter(res => res.id === reasonId)[0]

    //validation
    const formDataArray = ["branches", "title", "offset_time", "limit_time",
        "order", "color", "doctors"]
    const [errorGeneral, setErrorGeneral] = useState<string | null>(null)

    const branchesErrorHandler = useErrorHandler("branches", errorState, formDataArray)
    const titleErrorHandler = useErrorHandler("title", errorState, formDataArray)
    const offsetTimeErrorHandler = useErrorHandler("offset_time", errorState, formDataArray)
    const limitTimeErrorHandler = useErrorHandler("limit_time", errorState, formDataArray)
    const orderErrorHandler = useErrorHandler("order", errorState, formDataArray)
    const colorErrorHandler = useErrorHandler("color", errorState, formDataArray)
    const doctorsErrorHandler = useErrorHandler("doctors", errorState, formDataArray)

    // for select
    const choicesBranches = Object.entries(fields.branches.choices).map(br => br)
    const valueOptionBranches = fields && Object.entries(fields.branches.choices).map((b: any) => newOption(b[0], b[1]))
    const labelOptionBranches = currentReason && currentReason.branches.map((el: any) => el.toString())

    const choicesOffset = Object.entries(fields.branches.choices).map(br => br)
    const valueOptionOffset = fields && Object.entries(fields.offset_time.choices).map((b: any) => newOption(b[0], b[1]))
    const labelOptionOffset = currentReason && currentReason.offset_time.toString()

    const choicesDoctor = Object.entries(fields.doctors.choices).map(br => br)
    const valueOptionDoctor = fields && Object.entries(fields.doctors.choices).map((b: any) => newOption(b[0], b[1]))
    const labelOptionDoctor = currentReason && currentReason.doctors.map((el: any) => el.id.toString())

    const reasonData = currentReason
        ? {
            title: currentReason.title,
            offset_time: currentReason
                ? defaultChoicesSelect(valueOptionOffset, labelOptionOffset)[0]
                : '',
            limit_time: currentReason.limit_time,
            order: currentReason.order,
            color: currentReason.color,
            branches: currentReason
                ? defaultChoicesMultiSelect(valueOptionBranches, labelOptionBranches)
                : '',
            doctors: currentReason
                ? defaultChoicesMultiSelect(valueOptionDoctor, labelOptionDoctor)
                : '',
        }
        : {
            title: fields.title.initial_value,
            limit_time: fields.limit_time.initial_value,
            order: fields.order.initial_value,
            color: fields.color.initial_value,
        }

    useEffect(() => {
        if (validation(errorState, formDataArray).testErrorField.length === 0) {
            if (typeof errorState === "object") {
                setErrorGeneral(errorState && Object.values(errorState)[0])
            } else {
                setErrorGeneral(errorState)
            }
        }
        return () => {
            setErrorGeneral(null)
        };
    }, [status])

    useEffect(() => {
        if (reasonData) {
            reset(reasonData)
        }
    }, [currentReason, fields])

    useEffect(() => {
        dispatch(fetchReasonsListFieldsTC())
        dispatch(fetchReasonsList())
    }, [dispatch])

    useEffect(() => {
        if (status === "failed") {
            branchesErrorHandler.setErrorCallback()
            titleErrorHandler.setErrorCallback()
            offsetTimeErrorHandler.setErrorCallback()
            limitTimeErrorHandler.setErrorCallback()
            orderErrorHandler.setErrorCallback()
            colorErrorHandler.setErrorCallback()
            doctorsErrorHandler.setErrorCallback()
        }
    }, [status])

    const {
        register,
        handleSubmit,
        control,
        reset,
    } = useForm<FormDataType>({
        mode: 'onBlur',
        defaultValues: useMemo(() => {
            if (reasonData) {
                return reasonData
            }
        }, [reasonData, fields])
    });

    const onSubmit: SubmitHandler<FormDataType> = (data) => {
        const newData = {
            branches: data.branches && Object.values(data.branches).map((br: any) => br.value),
            title: data.title,
            offset_time: data.offset_time && data.offset_time.value,
            limit_time: data.limit_time,
            order: data.order,
            color: data.color,
            doctors: data.doctors && Object.values(data.doctors).map((br: any) => br.value),
        }

        if (currentReason) {
            dispatch(editReasonTC(reasonId, newData))

        } else {
            dispatch(addReasonTC(newData))
        }
        setValueReason(true)
    }

    if (valueReason && status === "succeeded") {
        return <Navigate to={PATH.REASONS}/>
    }

    if (!isLoggedIn) {
        return <Navigate to={PATH.LOGIN}/>;
    }

    return (
        <>
            <ProfileSideBar/>
            <div className="add-reason" onClick={() => {
                setErrorGeneral(null)
            }}>
                <h1 className="add-reason__title">{currentReason ? "Reason edit" : "Reason add"}</h1>
                <form onSubmit={handleSubmit(onSubmit)} className="add-reason__form">
                    <label className="add-reason__inputs-label">Info</label>
                    <div className="add-reason__inputs-info">
                        <Inputs
                            error={titleErrorHandler.error}
                            help_text={fields.title.help_text || titleErrorHandler.error && titleErrorHandler.errorMessageCurrentField[1]}
                            onClick={titleErrorHandler.onFieldClick}
                            state={"active"}
                            register={register}
                            input_type={fields.title.input_type}
                            name={"title"}
                            label={fields.title.label}
                            defaultValue={currentReason ? currentReason.title : fields.title.initial_value}
                            resetForm={reset}
                            {...reset}
                        />
                        <Inputs
                            error={orderErrorHandler.error}
                            help_text={fields.order.help_text || orderErrorHandler.error && orderErrorHandler.errorMessageCurrentField[1]}
                            onClick={orderErrorHandler.onFieldClick}
                            state={"active"}
                            register={register}
                            input_type="number"
                            name={"order"}
                            label={fields.order.label}
                            defaultValue={currentReason ? currentReason.order : fields.order.initial_value}
                            resetForm={reset}
                            {...reset}
                        />
                    </div>
                    <div className="add-reason__inputs-setting">
                        <ReactSelect
                            name="offset_time"
                            error={offsetTimeErrorHandler.error}
                            help_text={fields.offset_time.help_text || offsetTimeErrorHandler.error && offsetTimeErrorHandler.errorMessageCurrentField[1]}
                            label={fields.offset_time.label}
                            placeholder={fields.offset_time.label}
                            onClick={offsetTimeErrorHandler.onFieldClick}
                            control={control}
                            isMulti={false}
                            defaultValue={currentReason
                                ? defaultChoicesMultiSelect(valueOptionOffset, labelOptionOffset)[0]
                                : ''}
                            options={choicesOffset && Object.entries(fields.offset_time.choices).map((b: any) => newOption(b[0], b[1]))}
                        />
                        <Inputs
                            error={limitTimeErrorHandler.error}
                            help_text={fields.limit_time.help_text || limitTimeErrorHandler.error && limitTimeErrorHandler.errorMessageCurrentField[1]}
                            onClick={limitTimeErrorHandler.onFieldClick}
                            state={"active"}
                            register={register}
                            name={"limit_time"}
                            input_type="number"
                            label={fields.limit_time.label}
                            defaultValue={currentReason ? currentReason.limit_time : fields.limit_time.initial_value}
                            resetForm={reset}
                            {...reset}
                        />
                        <ReactSelect
                            name="doctors"
                            error={doctorsErrorHandler.error}
                            help_text={doctorsErrorHandler.error && doctorsErrorHandler.errorMessageCurrentField[1]}
                            label={fields.doctors.label}
                            placeholder={fields.doctors.label}
                            onClick={doctorsErrorHandler.onFieldClick}
                            control={control}
                            isMulti={true}
                            options={choicesDoctor && Object.entries(fields.doctors.choices).map((b: any) => newOption(b[0], b[1]))}
                        />
                        <Inputs
                            error={colorErrorHandler.error}
                            help_text={fields.color.help_text || colorErrorHandler.error && colorErrorHandler.errorMessageCurrentField[1]}
                            onClick={colorErrorHandler.onFieldClick}
                            state={"active"}
                            register={register}
                            input_type={"text"}
                            name={"color"}
                            label={fields.color.label}
                            defaultValue={currentReason ? currentReason.color : fields.color.initial_value}
                            resetForm={reset}
                            {...reset}
                        />
                    </div>
                    <label className="add-reason__inputs-label">{fields.branches.label}</label>
                    <div className="add-reason__inputs-branch">
                        <ReactSelect
                            name="branches"
                            error={branchesErrorHandler.error}
                            help_text={fields.branches.help_text || branchesErrorHandler.error && branchesErrorHandler.errorMessageCurrentField[1]}
                            label={fields.branches.label}
                            placeholder={fields.branches.label}
                            onClick={branchesErrorHandler.onFieldClick}
                            control={control}
                            isMulti={true}
                            options={choicesBranches && Object.entries(fields.branches.choices).map((b: any) => newOption(b[0], b[1]))}
                        />
                    </div>
                    <div className="_button-wrapper">
                        <button
                            className='button button_size-middle button_position-right button_color-black'>Sichern
                        </button>
                    </div>
                    {
                        (status === 'failed' && errorGeneral) &&
                        <p className="general-error">{errorGeneral}</p>
                    }
                </form>
            </div>
        </>
    )
}
