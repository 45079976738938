import { Dispatch } from "redux";
import { loadToken } from "../utils/localStorage";
import { setAppStatusAC } from "./app-reducer";
import { handleServerAppError, handleServerNetworkError } from "../utils/error-utils";
import { orderAPI, OrderType } from "../api/order-api";


const SET_LIST_ORDER = 'reasonsReducer/SET_LIST_ORDER'
const ADD_ORDER = 'reasonsReducer/ADD_ORDER'
const EDIT_ORDER = 'reasonsReducer/EDIT_ORDER'

const initialState: Array<OrderType> = []

export const orderReducer = (state: Array<OrderType> = initialState, action: ActionsType): Array<OrderType> => {
    switch (action.type) {
        case SET_LIST_ORDER: {
            return action.order.map((tl) => ({ ...tl }))
        }
        case ADD_ORDER: {
            return [...state, action.model]
        }
        case EDIT_ORDER: {
            return state.map(e => e.id === action.model.id
                ? { ...e, ...action.model }
                : e)
        }
        default:
            return state
    }
}

// actions
export const setListOrderAC = (order: Array<OrderType>) =>
    ({ type: SET_LIST_ORDER, order } as const)
export const addOrderAC = (model: OrderType) =>
    ({ type: ADD_ORDER, model } as const)
export const editOrderAC = (model: OrderModelType) =>
    ({ type: EDIT_ORDER, model } as const)

// thunks
export const fetchOrderList = () => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        let res = await orderAPI.getOrderList(tokenLS)
        dispatch(setListOrderAC(res.data))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

export const addOrderTC = (data: any) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        let res = await orderAPI.addOrder(data, tokenLS)
        dispatch(addOrderAC(res.data))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
}

export const editOrderTC = (id: number, data: any) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        let res = await orderAPI.editOrder(data, tokenLS, id)
        dispatch(editOrderAC(res.data))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
}

//types
type ActionsType = ReturnType<typeof setListOrderAC>
    | ReturnType<typeof addOrderAC>
    | ReturnType<typeof editOrderAC>

export type OrderModelType = {
    id?: number
    tooth_color?: null
    comment?: null
    material?: string
    client?: number
} 