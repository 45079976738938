import React, { FC, useEffect, useState } from "react";
import './WorkSchedule.scss'
import { useDispatch, useSelector } from "react-redux";
import { fetchScheduleWork } from "../../redux/scheduleWork-reducer";
import { Navigate } from "react-router-dom";
import { PATH } from "../Routes";
import {
    ModalAlertEditWorkSchedule
} from "../../components/ModalsAlerts/ModalAlertEditWorkSchedule";
import { ProfileSideBar } from "../Profile/ProfileSideBar/ProfileSideBar";
import { initializeAppTC } from "../../redux/app-reducer";
import { fetchWorkScheduleListFieldsTC } from "../../redux/fields-reducer";
import {
    getBranchWorkScheduleSelector,
    getIsLoggedInSelector,
    getStatusSelector
} from "../../redux/selectors";

type WorkSchedulePropsType = {}

export const WorkSchedule: FC<WorkSchedulePropsType> = () => {
    const dispatch = useDispatch();

    const branches = useSelector(getBranchWorkScheduleSelector);
    const status = useSelector(getStatusSelector);
    const isLoggedIn = useSelector(getIsLoggedInSelector);

    //dialog alerts
    const [openAlertDialogForEdit, setOpenAlertDialogForEdit] = useState<boolean>(false)
    const [modalDataId, setModalDataId] = useState<number>(0);
    const [modalDataWorkScheduleId, setModalDataWorkScheduleId] = useState<number>(0);

    useEffect(() => {
        if (status === "failed") {
            dispatch(initializeAppTC())
        }
    }, [status])

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchScheduleWork())
        }
    }, [openAlertDialogForEdit])

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchWorkScheduleListFieldsTC())
        }
    }, [dispatch])

    const openAlertHandler = (branchId: number) => {
        setModalDataId(branchId)
        setOpenAlertDialogForEdit(true)
    }
    const closeAlertHandler = () => {
        setOpenAlertDialogForEdit(false)
    }

    if (!isLoggedIn) {
        return <Navigate to={PATH.LOGIN} />
    }

    return (
        <>
            <ProfileSideBar />
            <div className="schedule-work">
                <div className="schedule-work__title-wrapper">
                    <h1 className="title">Work schedule</h1>
                </div>
                <div>
                    {
                        branches.map(b => <div className="schedule-work__table-wrapper"
                            key={b.id}>
                            <h2 className="branch-title">{b.name}</h2>
                            <table className="table">
                                <thead className="thead">
                                    <tr className="tr">
                                        <th className="th"><span>{'Mon'}</span></th>
                                        <th className="th"><span>{'Tue'}</span></th>
                                        <th className="th"><span>{'Wed'}</span></th>
                                        <th className="th"><span>{'Thu'}</span></th>
                                        <th className="th"><span>{'Fri'}</span></th>
                                        <th className="th day-weekend"><span>{'Sat'}</span></th>
                                        <th className="th day-weekend"><span>{'Sun'}</span></th>
                                    </tr>
                                </thead>
                                <tbody className="tbody">
                                    <tr key={b.id} className="branch">
                                        {b.workschedule.map(w => <td key={w.id}
                                            className={`${w.is_weekend ? "td td-weekend" : "td"}`}>
                                            {
                                                !w.is_weekend &&
                                                <div>
                                                    <b>Work time:</b>
                                                    <p>Start: {w.start_time_work.slice(0,5)}</p>
                                                    <p>End: {w.end_time_work.slice(0,5)}</p>
                                                    {
                                                        w.lunch_break &&
                                                        <div>
                                                            <b>Lunch:</b>
                                                            <p>Start: {w.start_lunch_break.slice(0,5)}</p>
                                                            <p>End: {w.end_lunch_break.slice(0,5)}</p>
                                                        </div>
                                                    }
                                                    {
                                                        !w.lunch_break &&
                                                        <div className="lunch-hidden">
                                                            <b>Lunch:</b>
                                                            <p>Start: {w.start_lunch_break}</p>
                                                            <p>End: {w.end_lunch_break}</p>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {
                                                w.is_weekend &&
                                                <div className="weekend">
                                                    <b className={"day-weekend-title"}>Weekend</b>
                                                </div>
                                            }
                                            <div className="btn-wrapper">
                                                <button
                                                    onClick={() => {
                                                        openAlertHandler(b.id)
                                                        setModalDataWorkScheduleId(w.id)
                                                    }}
                                                    className="button button_size-small button_position-right button_color-transparent">Edit
                                                </button>
                                            </div>

                                        </td>

                                        )
                                        }
                                    </tr>
                                </tbody>
                            </table>
                            {openAlertDialogForEdit &&
                                <>
                                    <ModalAlertEditWorkSchedule
                                        workSchedule={b.workschedule}
                                        branchId={modalDataId}
                                        workScheduleId={modalDataWorkScheduleId}
                                        cancelCallback={closeAlertHandler} />
                                </>
                            }
                        </div>
                        )}
                </div>
            </div>
        </>
    )
}

