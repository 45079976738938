import axios from "axios";
import { BranchesType } from "./branches-api";
import { EventsLinksType } from "./events-api";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
})

export const clientsAPI = {
    getList(token: string) {
        return instance.get<ClientsResponseDataType>('/api/clients/', {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    getAppeared(token: string, clientId: number) {
        return instance.get<ClientsType>(`/api/clients/${clientId}/not-appeared/`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    addClient(data: any, token: string) {
        return instance.post<ClientsType>('/api/clients/create/', data, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    editClient(data: any, token: string, clientId: number) {
        return instance.put<ClientsType>(`/api/clients/${clientId}/`, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    removeClient(token: string, clientId: number) {
        return instance.delete<any>(`/api/clients/${clientId}/`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    getNextList(token: string, next: string | null) {
        return axios.get<ClientsResponseDataType>(`${next}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    getPrevList(token: string, prev: string | null) {
        return axios.get<ClientsResponseDataType>(`${prev}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    getFilteredParamsClientsList(token: string) {
        return instance.get<ClientFilterParamsType>(
            `/api/clients/filter-form/`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    },

    getFilteredListOfInsurance(token: string, ordering?: Array<string>, orderingBranch?: Array<string>) {
        return instance.get<ClientsResponseDataType>(
            `/api/clients/?insurance_type__in=${ordering}&branches__in=${orderingBranch}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    },

    getFilteredListOfBranches(token: string, ordering?: string[],) {
        return instance.get<ClientsResponseDataType>(
            `/api/clients/?branches__in=${ordering}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    },

    searchOfFullName(token: string, name: string) {
        return instance.get<ClientsResponseDataType>(
            `/api/clients/?search=${name}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    },
}

export type ClientFilterParamsType = {
    email: {}
    full_name: {}
    insurance_type__in: { choices: {} }
    branches__in: { choices: {} }
    phone: {}
    search: {}
}

export type ClientsType = {
    id: number //
    organization: any
    first_name: string
    last_name: string
    insurance_type: string
    email: string
    phone: string
    blocked: any
    branches: Array<BranchesType>
    not_appeared: number
    insurance_type_display: string
    owner: number
    owner_display: string
}

export type ClientsLinksType = {
    next: string | null
    previous: string | null
}

export type ClientsResponseDataType = {
    links: EventsLinksType
    count: number
    current_page: number
    lastPage: number
    current: number
    results: Array<ClientsType>
}
