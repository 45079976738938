import './NotFound.scss';
import { NavLink } from "react-router-dom";
import { ProfileSideBar } from '../Profile/ProfileSideBar/ProfileSideBar';

const NotFound: React.FC = () => {
    return (
        <>
            <ProfileSideBar />
            <div className="container">
                <h1 className="container__title">404!</h1>
                <p className="container__p">Page not found.</p>
                <div className='container__btn'>
                    <NavLink to={'/'} className="btn">Go Home</NavLink>
                </div>
            </div>
        </>
    )
}

export default NotFound