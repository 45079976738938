import React, { useEffect, useMemo, useState } from "react";
import './Orders.scss';
import Inputs from "../../components/Widgets/Inputs";
import { useDispatch, useSelector } from "react-redux";
import { SubmitHandler, useForm } from "react-hook-form";
import { fetchOrderListFieldsTC } from "../../redux/fields-reducer";
import { Navigate, useParams } from "react-router-dom";
import {
    defaultChoicesMultiSelect,
    defaultChoicesSelect,
    newOption,
} from "../../components/Widgets/Select/ReactSelectStyles";
import { ReactSelect } from "../../components/Widgets/Select/ReactSelect";
import { ProfileSideBar } from "../Profile/ProfileSideBar/ProfileSideBar";
import { validation } from "../../utils/validation";
import { addOrderTC, editOrderTC, fetchOrderList } from "../../redux/order-reducer";
import { PATH } from "../Routes";
import Textarea from "../../components/Widgets/Textarea";
import { useErrorHandler } from "../../utils/useErrorHandler";
import Preloader from "../Preloader/Preloader";
import {
    getErrorStateSelector,
    getFieldsOrdersSelector, getIsLoggedInSelector,
    getOrdersSelector,
    getStatusSelector
} from "../../redux/selectors";


type FormDataType = {
    comment: null
    client: { value: any, label: any }
    material: {}
    tooth_color: null
    TP18: null
    TP17: null
    TP16: null
    TP15: null
    TP14: null
    TP13: null
    TP12: null
    TP11: null
    TP21: null
    TP22: null
    TP23: null
    TP24: null
    TP25: null
    TP26: null
    TP27: null
    TP28: null

    TP48: null
    TP47: null
    TP46: null
    TP45: null
    TP44: null
    TP43: null
    TP42: null
    TP41: null
    TP31: null
    TP32: null
    TP33: null
    TP34: null
    TP35: null
    TP36: null
    TP37: null
    TP38: null

    R18: null
    R17: null
    R16: null
    R15: null
    R14: null
    R13: null
    R12: null
    R11: null
    R21: null
    R22: null
    R23: null
    R24: null
    R25: null
    R26: null
    R27: null
    R28: null

    R48: null
    R47: null
    R46: null
    R45: null
    R44: null
    R43: null
    R42: null
    R41: null
    R31: null
    R32: null
    R33: null
    R34: null
    R35: null
    R36: null
    R37: null
    R38: null
    B18: null
    B17: null
    B16: null
    B15: null
    B14: null
    B13: null
    B12: null
    B11: null
    B21: null
    B22: null
    B23: null
    B24: null
    B25: null
    B26: null
    B27: null
    B28: null
    B48: null
    B47: null
    B46: null
    B45: null
    B44: null
    B43: null
    B42: null
    B41: null
    B31: null
    B32: null
    B33: null
    B34: null
    B35: null
    B36: null
    B37: null
    B38: null
}

export const Orders = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const order = useSelector(getOrdersSelector);
    const fields = useSelector(getFieldsOrdersSelector);
    const status = useSelector(getStatusSelector);
    const isLoggedIn = useSelector(getIsLoggedInSelector);
    const errorState = useSelector(getErrorStateSelector);

    //for edit
    const idUrl = params.id
    const orderId = Number(idUrl)
    const currentOrder = order.length && order.filter(res => res.id === orderId)[0]

    const [valueOrder, setValueOrder] = useState<boolean>(false)

    // for select
    /*     const choicesClients = Object.entries(fields.client.choices).map(br => br)
        const valueOptionClients = fields && Object.entries(fields.client.choices).map((b: any) => newOption(b[0], b[1]))
        const labelOptionClients = currentOrder && currentOrder.client.map((el: any) => el.toString()) */

    const choicesClietn = Object.entries(fields.client.choices).map(br => br)
    const valueOptionClient = fields && Object.entries(fields.client.choices).map((b: any) => newOption(b[0], b[1]))
    const labelOptionClient = currentOrder && currentOrder.client.toString()

    const choicesMaterial = Object.entries(fields.material.choices).map(br => br)
    const valueOptionMaterial = fields && Object.entries(fields.material.choices).map((b: any) => newOption(b[0], b[1]))
    const labelOptionMaterial = currentOrder && currentOrder.material

    //validation
    const formDataArray = ["client", "material", "tooth_color"]

    const [errorGeneral, setErrorGeneral] = useState<string | null>(null)

    const clientErrorHandler = useErrorHandler("client", errorState, formDataArray)
    const materialErrorHandler = useErrorHandler("material", errorState, formDataArray)
    const toothColorErrorHandler = useErrorHandler("tooth_color", errorState, formDataArray)

    const errorField = validation(errorState, formDataArray).testErrorField
    const errorFieldMessage = validation(errorState, formDataArray).testErrorFieldMessage

    const orderData = currentOrder && {
        client: currentOrder
            ? defaultChoicesMultiSelect(valueOptionClient, labelOptionClient)[0]
            : '',
        material: currentOrder.material &&
            defaultChoicesSelect(valueOptionMaterial, labelOptionMaterial)[0]
        ,
        tooth_color: currentOrder.tooth_color,
    }

    useEffect(() => {
        if (validation(errorState, formDataArray).testErrorField.length === 0) {
            if (typeof errorState === "object") {
                setErrorGeneral(errorState && Object.values(errorState)[0])
            } else {
                setErrorGeneral(errorState)
            }
        }
        return () => {
            setErrorGeneral(null)
        };
    }, [status])

    useEffect(() => {
        if (orderData) {
            reset(orderData)
        }
    }, [currentOrder, fields])

    useEffect(() => {
        dispatch(fetchOrderListFieldsTC())
        dispatch(fetchOrderList())

    }, [dispatch])

    useEffect(() => {
        if (status === "failed") {
            clientErrorHandler.setErrorCallback()
            materialErrorHandler.setErrorCallback()
            toothColorErrorHandler.setErrorCallback()
        }
 
    }, [status])

    const {
        register,
        handleSubmit,
        control,
        reset, 
    } = useForm<FormDataType>({
        mode: 'onBlur',
        defaultValues: useMemo(() => {
            if (orderData) {
                return orderData
            }
        }, [orderData])
    });

    const onSubmit: SubmitHandler<FormDataType> = (data) => {
        const newData = {
            tooth_color: data.tooth_color,
            client: data.client && data.client.value,
            material: data.material && Object.values(data.material)[0],
        }
        if (currentOrder) {
            dispatch(editOrderTC(orderId, newData))
        } else {
            dispatch(addOrderTC(newData))
        }
        setValueOrder(true)
    }

    if (valueOrder && status === "succeeded") {
        return <Navigate to={PATH.ORDERS} />
    }

    if (!isLoggedIn) {
        return <Navigate to={PATH.LOGIN} />;
    }

    return (
        <>
            <ProfileSideBar />
            <div className="template-order">
                <div className="wrapper">
                    <h1 className="page__header">Order create</h1>
                    {status === 'loading' ? (
                        <Preloader />
                    ) : (
                        <div>
                            <div className="forms">
                                <div className="setting">
                                    <label className="setting__text">Client data</label>
                                    <div className="setting__content">
                                        <ReactSelect
                                            name="client"
                                            error={clientErrorHandler.error}
                                            help_text={fields.client.help_text || clientErrorHandler.error && clientErrorHandler.errorMessageCurrentField[1]}
                                            label={fields.client.label}
                                            placeholder={fields.client.label}
                                            onClick={clientErrorHandler.onFieldClick}
                                            control={control}
                                            isMulti={false}
                                            defaultValue={currentOrder
                                                ? defaultChoicesMultiSelect(valueOptionClient, labelOptionClient)[0]
                                                : ''}
                                            options={choicesClietn && Object.entries(fields.client.choices).map((b: any) => newOption(b[0], b[1]))}
                                        />
                                        <ReactSelect
                                            name="material"
                                            error={materialErrorHandler.error}
                                            help_text={materialErrorHandler.error && materialErrorHandler.errorMessageCurrentField[1]}
                                            label={fields.material.label}
                                            placeholder={fields.material.label}
                                            onClick={materialErrorHandler.onFieldClick}
                                            control={control}
                                            isMulti={false}
                                            defaultValue={currentOrder &&
                                                defaultChoicesSelect(valueOptionMaterial, labelOptionMaterial)[0]
                                            }
                                            options={choicesMaterial && Object.entries(fields.material.choices).map((b: any) => newOption(b[0], b[1]))}

                                        />
                                        <Inputs
                                            error={toothColorErrorHandler.error}
                                            help_text={fields.tooth_color.help_text || toothColorErrorHandler.error && toothColorErrorHandler.errorMessageCurrentField[1]}
                                            onClick={toothColorErrorHandler.onFieldClick}
                                            register={register}
                                            name={"tooth_color"}
                                            label={fields.tooth_color.label}
                                            defaultValue={currentOrder ? currentOrder.tooth_color : fields.tooth_color.initial_value}
                                            resetForm={reset}
                                            {...reset}
                                        />
                                    </div>
                                    <div style={{ marginBottom: '-50px' }}>
                                        <Textarea
                                            state={"active"}
                                            register={register}
                                            input_type={fields.comment.input_type}
                                            name={"email_cancel_text"}
                                            label={fields.comment.label}
                                            defaultValue={currentOrder ? currentOrder.comment : fields.comment.initial_value}
                                            help_text={fields.comment.help_text}
                                            resetForm={reset}
                                            {...reset}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="page__text">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <label className="template-order__title">Zweite
                                        Meinung</label>
                                    <table className="template-order__table">
                                        <tbody className="tbody">
                                            <tr className="tr">
                                                <th className="th-color">TP</th>
                                                <td className="td">
                                                    <Inputs state="order"
                                                        maxLength={3}
                                                        name={'TP18'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.TP18.label} />
                                                </td>
                                                <td className="td">
                                                    <div style={{ marginBottom: '0px' }}>
                                                        <Inputs state="order"
                                                            maxLength={3}
                                                            name={'TP17'}
                                                            resetForm={reset}
                                                            {...reset}
                                                            register={register}
                                                            label={fields.TP17.label} />
                                                    </div>
                                                </td>
                                                <td className="td">
                                                    <Inputs

                                                        maxLength={3}
                                                        state="order"
                                                        name={'TP16'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.TP16.label} />
                                                </td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'TP15'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.TP15.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'TP14'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.TP14.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'TP13'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.TP13.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'TP12'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.TP12.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'TP11'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.TP11.label} /></td>
                                                <td className="td-middle"></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'TP21'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.TP21.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'TP22'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.TP22.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'TP23'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.TP23.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'TP24'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.TP24.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'TP25'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.TP25.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'TP26'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.TP26.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'TP27'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.TP27.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'TP28'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.TP28.label} /></td>

                                            </tr>

                                            <tr className="tr">
                                                <th className="th-color">R</th>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'R18'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.R18.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'R17'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.R17.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'R16'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.R16.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'R15'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.R15.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'R14'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.R14.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'R13'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.R13.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'R12'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.R12.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'R11'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.R11.label} /></td>
                                                <td className="td-middle"></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'R21'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.R21.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'R22'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.R22.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'R23'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.R23.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'R24'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.R24.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'R25'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.R25.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'R26'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.R26.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'R27'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.R27.label} /></td>
                                                <td className="td">
                                                    <Inputs
                                                        maxLength={3}
                                                        state="order"
                                                        name={'R28'}
                                                        resetForm={reset}
                                                        {...reset}
                                                        register={register}
                                                        label={fields.R28.label} /></td>


                                            </tr>
                                            <tr className="tr">
                                                <th className="th-color">B</th>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B18'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B18.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B17'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B17.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B16'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B16.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B15'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B15.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B14'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B14.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B13'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B13.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B12'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B12.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B11'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B11.label} /></td>
                                                <td className="td-middle"></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B21'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B21.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B22'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B22.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B23'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B23.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B24'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B24.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B25'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B25.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B26'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B26.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B27'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B27.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B28'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B28.label} /></td>

                                            </tr>
                                            <tr className="tr">
                                                <th className="th-color">#</th>
                                                <td className="td-color">18</td>
                                                <td className="td-color">17</td>
                                                <td className="td-color">16</td>
                                                <td className="td-color">15</td>
                                                <td className="td-color">14</td>
                                                <td className="td-color">13</td>
                                                <td className="td-color">12</td>
                                                <td className="td-color">11</td>
                                                <td className="td-middle"></td>
                                                <td className="td-color">21</td>
                                                <td className="td-color">22</td>
                                                <td className="td-color">23</td>
                                                <td className="td-color">24</td>
                                                <td className="td-color">25</td>
                                                <td className="td-color">26</td>
                                                <td className="td-color">27</td>
                                                <td className="td-color">28</td>

                                            </tr>
                                            <tr className="tr">
                                                <th className="th-color">#</th>
                                                <td className="td-color">48</td>
                                                <td className="td-color">47</td>
                                                <td className="td-color">46</td>
                                                <td className="td-color">45</td>
                                                <td className="td-color">44</td>
                                                <td className="td-color">43</td>
                                                <td className="td-color">42</td>
                                                <td className="td-color">41</td>
                                                <td className="td-middle"></td>
                                                <td className="td-color">31</td>
                                                <td className="td-color">32</td>
                                                <td className="td-color">33</td>
                                                <td className="td-color">34</td>
                                                <td className="td-color">35</td>
                                                <td className="td-color">36</td>
                                                <td className="td-color">37</td>
                                                <td className="td-color">38</td>

                                            </tr>
                                            <tr className="tr">
                                                <th className="th-color">B</th>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B48'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B48.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B47'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B47.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B46'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B46.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B45'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B45.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B44'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B44.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B43'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B43.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B42'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B42.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B41'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B41.label} /></td>
                                                <td className="td-middle"></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B31'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B31.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B32'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B32.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B33'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B33.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B34'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B34.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B35'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B35.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B36'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B36.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B37'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B37.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'B38'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.B38.label} /></td>

                                            </tr>
                                            <tr className="tr">
                                                <th className="th-color">R</th>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'R48'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.R48.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'R47'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.R47.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'R46'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.R46.label} /></td>
                                                <td className="td"><Inputs
                                                    state="order"
                                                    name={'R45'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.R45.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'R44'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.R44.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'R43'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.R43.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'R42'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.R42.label} /></td>
                                                <td className="td"><Inputs
                                                    state="order"
                                                    name={'R41'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.R41.label} /></td>
                                                <td className="td-middle"></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'R31'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.R31.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'R32'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.R32.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'R33'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.R33.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'R34'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.R34.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'R35'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.R35.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'R36'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.R36.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'R37'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.R37.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'R38'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.R38.label} /></td>

                                            </tr>
                                            <tr className="tr">
                                                <th className="th-color">TP</th>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'TP48'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.TP48.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'TP47'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.TP47.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'TP46'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.TP46.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'TP45'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.TP45.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'TP44'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.TP44.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'TP43'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.TP43.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'TP42'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.TP42.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'TP41'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.TP41.label} /></td>
                                                <td className="td-middle"></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'TP31'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.TP31.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'TP32'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.TP32.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'TP33'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.TP33.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'TP34'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.TP34.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'TP35'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.TP35.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'TP36'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.TP36.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'TP37'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.TP37.label} /></td>
                                                <td className="td"><Inputs
                                                    maxLength={3}
                                                    state="order"
                                                    name={'TP38'}
                                                    resetForm={reset}
                                                    {...reset}
                                                    register={register}
                                                    label={fields.TP38.label} /></td>

                                            </tr>
                                        </tbody>
                                    </table>
                                    <div>
                                        <button
                                            className='button button_size-middle button_position-right button_color-black'>Sichern
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                    {
                        (status === 'failed' && errorGeneral) &&
                        <p className="general-error">{errorGeneral}</p>
                    }
                </div>
            </div>
        </>
    )
}