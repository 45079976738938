import {Dispatch} from 'redux'
import {setAppErrorAC, setAppIsInitializedAC, setAppStatusAC} from "../redux/app-reducer";
import {resetReducerGroupAC, setIsLoggedInAC} from "../redux/auth-reducer";

export const handleServerAppError = <D>(error: any, dispatch: Dispatch<any>) => {
    if (error.response.status !== 404 && error.response.status !== 500) {
        dispatch(setAppErrorAC(error.response.data))
    } else if (error.response.status === 401) {
        // dispatch(setAppIsInitializedAC(false))
        dispatch(setIsLoggedInAC(false))
        dispatch(resetReducerGroupAC())
    } else {
        dispatch(setAppErrorAC(error.message))
    }

    dispatch(setAppStatusAC("failed"))
    console.log(error.message)
}

export const handleServerNetworkError = (error: any, dispatch: Dispatch<any>) => {

    if (error.message === "Network Error") {
        // alert(error.message)
        // console.log(error.message)
        dispatch(setAppErrorAC(error.message))
    } else {
        // console.log(error.message)
    }
    dispatch(setAppStatusAC("failed"))
}
