import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
})

export const scheduleWorkApi = {
    getList(token: string) {
        return instance.get<Array<BranchType>>('/api/work-schedule/', {
            headers: {
                'Authorization' : `Bearer ${token}`,
            }
        })
    },

    editScheduleWork(data: any, token: string, branchId: number) {
        return instance.put<any>(`/api/work-schedule/${branchId}/`, data, {
            headers: {
                'Authorization' : `Bearer ${token}`,
            }
        })
    },
}

//types
export type WorkScheduleType = {
    id: number
    day_of_week: string
    start_time_work: string
    end_time_work: string
    branch: number
    start_lunch_break: string
    end_lunch_break: string
    lunch_break: boolean
    is_weekend: boolean
}

export type BranchType = {
    id: number,
    name: string,
    workschedule: Array<WorkScheduleType>
}