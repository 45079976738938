import {Dispatch} from "redux";
import {loadToken} from "../utils/localStorage";
import {BrunchesType, reasonsAPI, ReasonType} from "../api/reasons-api";
import {setAppErrorAC, setAppStatusAC} from "./app-reducer";
import {handleServerAppError, handleServerNetworkError} from "../utils/error-utils";
import { contactsAPI, ContactsType } from "../api/contacts-api";


const SET_LIST_CONTACTS = 'reasonsReducer/SET_LIST_CONTACTS'
const ADD_CONTACTS = 'reasonsReducer/ADD_CONTACTS'
const EDIT_CONTACTS = 'reasonsReducer/EDIT_CONTACTS'
const REMOVE_CONTACTS = 'reasonsReducer/REMOVE_CONTACTS'

const initialState: Array<ContactsType> = []

export const contactsReducer = (state: Array<ContactsType> = initialState, action: ActionsType): Array<ContactsType> => {
    switch (action.type) {
        case SET_LIST_CONTACTS: {
            return action.contacts.map((tl) => ({...tl}))
        }
        case ADD_CONTACTS: {
            return [...state, action.model]
        }
        case EDIT_CONTACTS: {
            return state.map(e => e.id === action.model.id
                ? {...e, ...action.model}
                : e)
        }
        case REMOVE_CONTACTS: {
            return state.filter(e => e.id !== action.id)
        }
        default:
            return state
    }
}

// actions
export const setListContactsAC = (contacts: Array<ContactsType>) =>
    ({type: SET_LIST_CONTACTS, contacts} as const)
export const addContactAC = (model: ContactsType) =>
    ({type: ADD_CONTACTS, model} as const)
export const editContactAC = (model: ContactsModelType) =>
    ({type: EDIT_CONTACTS, model} as const)
export const removeContactAC = (id: number) =>
    ({type: REMOVE_CONTACTS, id} as const)


// thunks
export const fetchContactsList = () => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        let res = await contactsAPI.getList(tokenLS)
        dispatch(setListContactsAC(res.data))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

export const addContactTC = (data: any) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        let res = await contactsAPI.addContact(data, tokenLS)
        dispatch(addContactAC(res.data))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
}

export const editContactTC = (id: number, data: any) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        let res = await contactsAPI.editContact(data, tokenLS, id)
        dispatch(editContactAC(res.data))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
}

export const removeContactTC = (reasonId: number) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = loadToken()
        await contactsAPI.removeContact(tokenLS, reasonId)
        dispatch(removeContactAC(reasonId))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
}

//types
type ActionsType = ReturnType<typeof setListContactsAC>
    | ReturnType<typeof addContactAC>
    | ReturnType<typeof editContactAC>
    | ReturnType<typeof removeContactAC>

export type ContactsModelType = {
    id?: number
    branches?: any
    title?: string
    offset_time?: number
    limit_time?: number
    color?: string
    order?: number
}