export const loadToken = () => {
    try {
        const tokenJSON = localStorage.getItem('token');
        if (tokenJSON === null) {
            return undefined;
        }
        return JSON.parse(tokenJSON);
    } catch (err) {
        return undefined;
    }
};

export const saveToken = (data: any) => {
    try {
        const tokenJSON = JSON.stringify(data);
        localStorage.setItem('token', tokenJSON);
    } catch {
        // ignore write errors
    }
};


export const loadProfileDataLS = () => {
    try {
        const profileDataJSON = localStorage.getItem('profileData');
        if (profileDataJSON === null) {
            return undefined;
        }
        return JSON.parse(profileDataJSON);
    } catch (err) {
        return undefined;
    }
};

export const saveProfileDataLS = (data: any) => {
    try {
        const profileDataJSON = JSON.stringify(data);
        localStorage.setItem('profileData', profileDataJSON);
    } catch {
        // ignore write errors
    }
};

// export const loadIsLoggedInLS = () => {
//     try {
//         const isLoginJSON = localStorage.getItem('isLogin');
//         if (isLoginJSON === null) {
//             return undefined;
//         }
//         return JSON.parse(isLoginJSON);
//     } catch (err) {
//         return undefined;
//     }
// };
//
// // export const isLoggedInLS = loadIsLoggedInLS()
//
// export const saveIsLoggedInLS = (data: any) => {
//     try {
//         const isLoginJSON = JSON.stringify(data);
//         localStorage.setItem('isLogin', isLoginJSON);
//     } catch {
//         // ignore write errors
//     }
// };
