import { Dispatch } from "redux";
import { employeesAPI, EmployeeType } from "../api/employees-api";
import { loadToken } from "../utils/localStorage";
import { setAppStatusAC } from "./app-reducer";
import { handleServerAppError, handleServerNetworkError } from "../utils/error-utils";
import { waitingRoomAPI, WaitingRoomType } from "../api/waitingRoom-api";
import { AppRootStateType } from "./store";


const SET_LIST_WAITING_ROOM = 'waitingRoomReducer/SET_LIST_WAITING_ROOM'

const initialState: WaitingRoomType = {
    waiting_room:[],
    at_the_doctor:[]
}

export const waitingRoomReducer = (state: WaitingRoomType = initialState, action: ActionsType): WaitingRoomType => {
    switch (action.type) {
        case SET_LIST_WAITING_ROOM: {
            return {...state, ...action.waitingRoom }
        }
        default:
            return state
    }
}

// actions
export const setListWaitingRoomAC = (waitingRoom: WaitingRoomType,branchId:number) =>
    ({ type: SET_LIST_WAITING_ROOM, waitingRoom ,branchId} as const)

// thunks
export const fetchWaitingRoomListTC = () => async (dispatch: Dispatch<any>,getState: () => AppRootStateType) => {
    dispatch(setAppStatusAC("loading"))
    const currentBranchId = getState().branches.currentBranch
    try {
        const tokenLS = await loadToken()
        let res = await  waitingRoomAPI.getList(tokenLS,currentBranchId)
        dispatch(setListWaitingRoomAC(res.data,currentBranchId))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

export const fetchWaitingRoomListTC1 = () => async (dispatch: Dispatch<any>,getState: () => AppRootStateType) => {
    const currentBranchId = getState().branches.currentBranch
    try {
        const tokenLS = await loadToken()
        let res = await  waitingRoomAPI.getList(tokenLS,currentBranchId)
        dispatch(setListWaitingRoomAC(res.data,currentBranchId))
       dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

//types
type ActionsType = ReturnType<typeof setListWaitingRoomAC>

