import axios from "axios";
import { UpdateProfileDataModelType } from "../redux/profile-reducer";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
})

export const profileAPI = {
    getProfile(token: string) {
        return instance.get<any>(`api/profile/`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    updateProfile(data: UpdateProfileDataModelType | any, token: string) {
        const formData = new FormData()
        if (data.photo.length && data.photo !== 'not') {
            formData.append('photo', data.photo[0])
        }
        if (data.photo === 'del') {
            formData.append('photo', '')
        }
        formData.append('first_name', data.first_name)
        formData.append('last_name', data.last_name)
        formData.append('email', data.email)
        formData.append('phone', data.phone)
        return instance.put<any>(`/api/profile/`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'multipart/form-data'
            }
        })
    },
}

export type ProfileDataType = {
    id: number
    first_name: string
    last_name: string
    email: string
    photo: File | null
    phone: string
    role: string
    owner: string | null
}
