import React, {useEffect, useLayoutEffect, useState} from "react";
import './Clients.scss';
import {
  fetchAppearedTC,
  getNextClientsList,
  getPrevClientsList,
} from "../../redux/clients-reducer";
import { Navigate, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PATH } from "../Routes";
import { Pagination } from "../../components/Pagination/Pagination";
import { initializeAppTC, setAppStatusAC } from "../../redux/app-reducer";
import { fetchBranchesListFirstRender } from "../../redux/branches-reducer";
import Preloader from "../Preloader/Preloader";
import { fetchFilteredClientsListOfBranchTC, fetchFilteredClientsParamsListTC } from "../../redux/clients-filter-reducer";
import {
  getActiveWeekSelector,
  getClientsSelector,
  getCurrentBranchSelector,
  getCurrentClientsSelector,
  getCurrentPagePaginationClientsSelector, getIsLoggedInSelector,
  getNextClientsSelector,
  getPreviousClientsSelector, getStatusSelector
} from "../../redux/selectors";


export const Clients = () => {
  const dispatch = useDispatch()

  const clients = useSelector(getClientsSelector);
  const previous = useSelector(getPreviousClientsSelector);
  const next = useSelector(getNextClientsSelector);
  const currentPagePagination = useSelector(getCurrentPagePaginationClientsSelector);
  const currentBranch = useSelector(getCurrentBranchSelector);
  const current = useSelector(getCurrentClientsSelector);
  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const activeWeek = useSelector(getActiveWeekSelector);

  //dialog alerts
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (status === "failed") {
      dispatch(initializeAppTC())
    }
  }, [status])

  useLayoutEffect(() => {
    dispatch(fetchFilteredClientsListOfBranchTC(currentBranch.toString())) // filtered list
    dispatch(fetchFilteredClientsParamsListTC())
  }, [dispatch])

  useEffect(() => {
    if (isLoggedIn) {
      if (!activeWeek) {
        dispatch(fetchBranchesListFirstRender())
      }
    }

    return () => {
      dispatch(setAppStatusAC("idle"))
    };
  }, [dispatch])

  const onClickHandler = async (clientId: number) => {
    dispatch(fetchAppearedTC(clientId))
    setLoading(false)
  }

  const nextList = () => {
    if (next !== null) {
      dispatch(getNextClientsList())
    }
  }
  const prevList = () => {
    if (previous !== null) {
      dispatch(getPrevClientsList())
    }
  }

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />
  }

  return (
    <>
      <div className="clients">
        <h1 className="clients__title">Clients list</h1>
        <div className="clients__add">
          <NavLink
            className="button button_size-small button_position-left button_color-transparent"
            to={PATH.ADD_CLIENT}>+ add
            client</NavLink>
        </div>
        {loading && status == "loading" ? (
          <Preloader />
        ) : (
          <div>
            <table className='clients__table'>
              <thead className="thead">
                <tr className="tr">
                  <th className="th"><span>{'Vorname und Nachnahme'}</span></th>
                  <th className="th"><span>{'Versicherung'}</span></th>
                  <th className="th"><span>{'Telefon'}<br />{'E-Mail-Adresse'}</span></th>
                  <th className="th"><span>{'Optionen'}</span></th>
                </tr>
              </thead>
              <tbody className="tbody">
                {clients.map((client, index) => {
                  return <tr key={client.id} className="tr">
                    {client.blocked === true ? <td className="tdBlock"> {client.first_name} {client.last_name}<br />
                      {client.not_appeared === 0 ? '' : <span className="not-appeared">{`Not appeared: ${client.not_appeared}`}</span>}
                    </td> : <td className="td"> {client.first_name} {client.last_name}<br />
                      {client.not_appeared === 0 ? '' : <span className="not-appeared">{`Not appeared: ${client.not_appeared}`}</span>}
                    </td>}
                    <td className="td">{client.insurance_type}</td>
                    <td className="td">{client.phone}<br />{client.email} </td>
                    <td className="td">
                      <NavLink className="button button_size-small  button_color-green"
                        to={`${PATH.CLIENTS}${client.id}/`}>Edit</NavLink>
                      <button onClick={() => onClickHandler(client.id)}
                        className="button button_size-small  button_color-delete"
                      >Not appeared
                      </button>
                    </td>
                  </tr>
                })
                }
              </tbody>
            </table>
            {
              (next !== null || previous !== null) &&
              <Pagination previous={previous}
                next={next}
                current={current}
                currentPage={currentPagePagination}
                previousCallback={prevList}
                nextCallback={nextList} />
            }
          </div>
        )
        }
      </div>
    </>
  )
}