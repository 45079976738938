import { Dispatch } from "redux";
import {
    branchesAPI,
    BranchesType,
    DateListEventType,
    DateOfListType,
    ScheduleBranchType
} from "../api/branches-api";
import { loadToken } from "../utils/localStorage";
import { setAppStatusAC } from "./app-reducer";
import { AppRootStateType } from "./store";
import { handleServerAppError, handleServerNetworkError } from "../utils/error-utils";
import { RESET_REDUCER_GROUP, ResetReducerGroupActionType } from "./auth-reducer";
import { HolidayResponseType } from "../api/holidays-api";


const SET_BRANCHES_LIST = 'branchesReducer/SET_BRANCHES_LIST'
const ADD_BRANCH = 'branchesReducer/ADD_BRANCH'
const EDIT_BRANCH = 'branchesReducer/EDIT_BRANCH'
const REMOVE_BRANCH = 'branchesReducer/REMOVE_BRANCH'
const GET_SCHEDULE_BRANCH = 'branchesReducer/GET_SCHEDULE_BRANCH'
const SET_CURRENT_BRANCH = 'branchesReducer/SET_CURRENT_BRANCH'
const SET_ACTIVE_CURRENT_WEEK = 'branchesReducer/SET_ACTIVE_CURRENT_WEEK'
const SET_CURRENT_PATH = 'branchesReducer/SET_CURRENT_PATH'

const initialState = {
    branches: [] as Array<BranchesType>,
    currentBranch: 0,
    activeCurrentWeek: null,
    currentPath: null,
    scheduleBranch: {
        next_week: '',
        previous_week: '',
        current_week: [''],
        day_end: '',
        day_start: '',
        date_of_lists: [
            {
                date: '',
                events: [{}] as Array<DateListEventType>,
                date_index: 0,
                date_name: '',
                holidays: [] as HolidayResponseType[]
            } as DateOfListType,
        ] as Array<DateOfListType>
    } as ScheduleBranchType
}

type InitialStateType = {
    branches: Array<BranchesType>
    currentBranch: number
    activeCurrentWeek: string | null
    currentPath: string | null
    scheduleBranch: {
        next_week: string
        previous_week: string
        day_end: string
        day_start: string
        current_week: Array<string>
        date_of_lists: Array<DateOfListType>
    }
}

export const branchesReducer = (state: InitialStateType = initialState, action: ActionsType): InitialStateType => {
    switch (action.type) {
        case SET_BRANCHES_LIST: {
            return { ...state, branches: action.branches.map((tl) => ({ ...tl })) }
        }
        case ADD_BRANCH: {
            return { ...state, branches: [action.branch, ...state.branches] }
        }
        case EDIT_BRANCH: {
            return {
                ...state,
                branches: state.branches.map(e => e.id === action.module.id
                    ? {
                        ...e, ...action.module
                    }
                    : e)
            }
        }
        case REMOVE_BRANCH: {
            return { ...state, branches: state.branches.filter(e => e.id !== action.id) }
        }
        case GET_SCHEDULE_BRANCH: {
            return { ...state, scheduleBranch: action.scheduleBranch }
        }
        case SET_CURRENT_BRANCH: {
            return { ...state, currentBranch: action.currentBranchId }
        }
        case SET_ACTIVE_CURRENT_WEEK: {
            return { ...state, activeCurrentWeek: action.activeWeek }
        }
        case SET_CURRENT_PATH: {
            return { ...state, currentPath: action.path }
        }
        case RESET_REDUCER_GROUP:
            return { ...initialState };
        default:
            return state
    }
}

// actions
export const setBranchesListAC = (branches: Array<BranchesType>) =>
    ({ type: SET_BRANCHES_LIST, branches } as const)
export const addBranchAC = (branch: BranchesType) =>
    ({ type: ADD_BRANCH, branch } as const)
export const editBranchAC = (module: UpdateBranchesModuleType) =>
    ({ type: EDIT_BRANCH, module } as const)
export const removeBranchAC = (id: number) =>
    ({ type: REMOVE_BRANCH, id } as const)
export const getScheduleBranchesAC = (scheduleBranch: ScheduleBranchType) =>
    ({ type: GET_SCHEDULE_BRANCH, scheduleBranch } as const)
export const setCurrentBranchesAC = (currentBranchId: number) =>
    ({ type: SET_CURRENT_BRANCH, currentBranchId } as const)
export const setActiveCurrentWeekAC = (activeWeek: string | null) =>
    ({ type: SET_ACTIVE_CURRENT_WEEK, activeWeek } as const)
export const setCurrentPathAC = (path: string | null) =>
    ({ type: SET_CURRENT_PATH, path } as const)

// thunks
export const fetchBranchesList = () => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        const res = await branchesAPI.getList(tokenLS)
        dispatch(setBranchesListAC(res.data))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

export const fetchBranchesListFirstRender = () => async (dispatch: Dispatch<any>) => {
    try {
        const tokenLS = await loadToken()
        const res = await branchesAPI.getList(tokenLS)
        dispatch(setBranchesListAC(res.data))
        dispatch(getScheduleBranchFirstRenderTC())
        if (res.data.length) {
            dispatch(setCurrentBranchesAC(res.data[0].id))
        }
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

export const addBranchTC = (data: any) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        const res = await branchesAPI.addBranch(data, tokenLS)
        dispatch(addBranchAC(res.data))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
}

export const editBranchTC = (id: number, data: any) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        const res = await branchesAPI.editBranch(data, tokenLS, id)
        dispatch(editBranchAC(res.data))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
}

export const removeBranchTC = (branchId: number) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        await branchesAPI.removeBranch(tokenLS, branchId)
        dispatch(removeBranchAC(branchId))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

export const getScheduleBranchTC = (branchId: number) => async (dispatch: Dispatch<any>,
    getState: () => AppRootStateType) => {
    const currentDate = getState().branches.activeCurrentWeek
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        const res = await branchesAPI.getScheduleBranch(tokenLS, branchId, currentDate)
        dispatch(getScheduleBranchesAC(res.data))
        dispatch(fetchBranchesList())
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

export const getScheduleBranchNextWeekTC = (branchId: number) =>
    async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
        dispatch(setAppStatusAC("loading"))
        const next = getState().branches.scheduleBranch.next_week
        try {
            const tokenLS = await loadToken()
            const res = await branchesAPI.getScheduleBranchOfWeekly(tokenLS, branchId, next)
            dispatch(getScheduleBranchesAC(res.data))
            dispatch(setActiveCurrentWeekAC(res.data.current_week[0]))
            dispatch(setAppStatusAC("succeeded"))
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
        dispatch(setAppStatusAC("idle"))
    }

export const getScheduleBranchPrevWeekTC = (branchId: number) =>
    async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
        dispatch(setAppStatusAC("loading"))
        const prev = getState().branches.scheduleBranch.previous_week
        try {
            const tokenLS = await loadToken()
            const res = await branchesAPI.getScheduleBranchOfWeekly(tokenLS, branchId, prev)
            dispatch(getScheduleBranchesAC(res.data))
            dispatch(setActiveCurrentWeekAC(res.data.current_week[0]))
            dispatch(setAppStatusAC("succeeded"))
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
        dispatch(setAppStatusAC("idle"))
    }

export const getScheduleBranchChosenCalendarWeekTC = (branchId: number, week: string) =>
    async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
        dispatch(setAppStatusAC("loading"))
        try {
            const tokenLS = await loadToken()
            const res = await branchesAPI.getScheduleBranchOfWeekly(tokenLS, branchId, week)
            dispatch(getScheduleBranchesAC(res.data))
            dispatch(setActiveCurrentWeekAC(res.data.current_week[0]))
            dispatch(setAppStatusAC("succeeded"))
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
        dispatch(setAppStatusAC("idle"))
    }

export const getScheduleBranchActiveWeekTC = (branchId: number) =>
    async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
        // dispatch(setAppStatusAC("loading"))
        const activeWeek = getState().branches.activeCurrentWeek
        try {
            const tokenLS = await loadToken()
            const res = await branchesAPI.getScheduleBranchOfWeekly(tokenLS, branchId, activeWeek)
            dispatch(getScheduleBranchesAC(res.data))
            dispatch(setAppStatusAC("succeeded"))
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
        dispatch(setAppStatusAC("idle"))
    }

export const getScheduleBranchFirstRenderTC = () => async (dispatch: Dispatch<any>,
    getState: () => AppRootStateType) => {
    dispatch(setAppStatusAC("loading"))
    try {
        if (getState().branches.branches.length) {
            const firstBranchId = await getState().branches.branches[0].id
            const tokenLS = await loadToken()
            const res = await branchesAPI.getScheduleFirstRenderBranch(tokenLS, firstBranchId)
            dispatch(getScheduleBranchesAC(res.data))
            dispatch(setActiveCurrentWeekAC(res.data.current_week[0]))
            dispatch(setAppStatusAC("succeeded"))
        }
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}


//types
type ActionsType = ReturnType<typeof setBranchesListAC>
    | ReturnType<typeof addBranchAC>
    | ReturnType<typeof removeBranchAC>
    | ReturnType<typeof editBranchAC>
    | ReturnType<typeof getScheduleBranchesAC>
    | ReturnType<typeof setCurrentBranchesAC>
    | ReturnType<typeof setActiveCurrentWeekAC>
    | ReturnType<typeof setCurrentPathAC>
    | ResetReducerGroupActionType

export type UpdateBranchesModuleType = {
    id?: number
    name?: string
    phone?: string
    whatsapp?: string
    email?: string
    sity?: string
    street?: string
    street_number?: string
    zip_code?: string
    show_title?: boolean
    free_date?: boolean
    employees?: any
    sms_remind_text?: string
    email_remind_text?: string
    email_cancel_text?: string
    email_order_text?: string
    sms_cancel_text?: string
    sms_order_text?: string
}