import { Dispatch } from "redux";
import {
    BranchesListFieldsType,
    ClientsListFieldsType,
    ContactsListFieldsType,
    EmployeesListFieldsType,
    EventsListFieldsType,
    fieldsAPI,
    HolidayListFieldsType, ModelFieldType, ModelFieldWithChoicesType,
    OrderListFieldsType,
    ReasonsListFieldsType,
    RegistrationListFieldsType,
    UserListFieldsType,
    WorkScheduleListFieldsType,
} from "../api/fields-api";
import { loadToken } from "../utils/localStorage";
import { handleServerAppError, handleServerNetworkError } from "../utils/error-utils";


const SET_LIST_FIELDS_EMPLOYEES = "fieldsReducer/SET_LIST_FIELDS_EMPLOYEES";
const SET_LIST_FIELDS_CLIENTS = "fieldsReducer/SET_LIST_FIELDS_CLIENTS";
const SET_LIST_FIELDS_EVENTS = "employeesReducer/SET_LIST_FIELDS_EVENTS";
const SET_LIST_FIELDS_REASONS = "employeesReducer/SET_LIST_FIELDS_REASONS";
const SET_LIST_FIELDS_WORK_SCHEDULE = "employeesReducer/SET_LIST_FIELDS_WORK_SCHEDULE";
const SET_LIST_FIELDS_BRANCHES = "employeesReducer/SET_LIST_FIELDS_BRANCHES";
const SET_LIST_FIELDS_REGISTRATION = "employeesReducer/SET_LIST_FIELDS_REGISTRATION";
const SET_LIST_FIELDS_HOLIDAY = "employeesReducer/SET_LIST_FIELDS_HOLIDAY";
const SET_LIST_FIELDS_CONTACTS = "employeesReducer/SET_LIST_FIELDS_CONTACTS";
const SET_LIST_FIELDS_USER = "employeesReducer/SET_LIST_FIELDS_USER";
const SET_LIST_FIELDS_ORDER = "fieldsReducer/SET_LIST_FIELDS_ORDER";

const InitialModelField = {
    help_text: "",
    hidden: false,
    initial_value: null,
    input_type: "",
    label: "",
    required: false,
    type: "",
    widget_type: "",
};

const InitialModelFieldWithChoices = {
    choices: {},
    help_text: "",
    hidden: false,
    initial_value: null,
    input_type: "",
    label: "",
    required: false,
    type: "",
    widget_type: "",
};

const initialState = {
    employeesFields: {
        first_name: InitialModelField,
        last_name: InitialModelField,
        password: InitialModelField,
        password2: InitialModelField,
        branch_set: InitialModelFieldWithChoices,
        role: InitialModelFieldWithChoices
    } as EmployeesListFieldsType,
    clientsFields: {
        first_name: InitialModelField,
        last_name: InitialModelField,
        phone: InitialModelField,
        email: InitialModelField,
        insurance_type: InitialModelFieldWithChoices,
        blocked: InitialModelField,
        branches: InitialModelFieldWithChoices,
    } as ClientsListFieldsType,
    eventsFields: {
        first_name: InitialModelField,
        last_name: InitialModelField,
        email: InitialModelField,
        phone: InitialModelField,
        status: InitialModelFieldWithChoices,
        reason: InitialModelFieldWithChoices,
        start: InitialModelField,
        end: InitialModelField,
        date_birth: InitialModelField,
        message: InitialModelField,
        gender: InitialModelFieldWithChoices,
        insurance_type: InitialModelFieldWithChoices,
        branch: InitialModelFieldWithChoices,
        client: InitialModelFieldWithChoices,
        desired_date: InitialModelField,
        doctor: InitialModelFieldWithChoices,
        note: InitialModelField,
    } as EventsListFieldsType,
    reasonsFields: {
        branches: InitialModelFieldWithChoices,
        title: InitialModelField,
        offset_time: InitialModelFieldWithChoices,
        limit_time: InitialModelField,
        order: InitialModelField,
        color: InitialModelField,
        doctors: InitialModelFieldWithChoices,
    },
    workScheduleFields: {
        branch: InitialModelFieldWithChoices,
        day_of_week: InitialModelFieldWithChoices,
        start_time_work: InitialModelField,
        end_time_work: InitialModelField,
        start_lunch_break: InitialModelField,
        end_lunch_break: InitialModelField,
        is_weekend: InitialModelField,
        lunch_break: InitialModelField,
    },
    branchesFields: {
        name: InitialModelField,
        phone: InitialModelField,
        email: InitialModelField,
        sity: InitialModelField,
        street: InitialModelField,
        street_number: InitialModelField,
        zip_code: InitialModelField,
        employees: InitialModelFieldWithChoices,
        whatsapp: InitialModelField,
        show_title: InitialModelField,
        free_date: InitialModelField,
        sms_text: InitialModelField,
        sms_remind_text: InitialModelField,
        sms_cancel_text: InitialModelField,
        email_text: InitialModelField,
        email_remind_text: InitialModelField,
        email_cancel_text: InitialModelField,
        email_order_text: InitialModelField,
        sms_order_text: InitialModelField,
        concurrent_records: InitialModelFieldWithChoices,
        desired_date: InitialModelField
    },
    registrationFields: {
        date_birth: InitialModelField,
        email: InitialModelField,
        first_name: InitialModelField,
        gender: InitialModelFieldWithChoices,
        insurance_type: InitialModelFieldWithChoices,
        last_name: InitialModelField,
        message: InitialModelField,
        phone: InitialModelField,
        reason: InitialModelFieldWithChoices,
        start: InitialModelField,
        wait_time: InitialModelField
    } as RegistrationListFieldsType,

    holidayFields: {
        branches: InitialModelFieldWithChoices,
        name: InitialModelField,
        date: InitialModelField,
        start_time: InitialModelField,
        end_time: InitialModelField,
    },
    contactsFields: {
        branches: InitialModelFieldWithChoices,
        title: InitialModelField,
        email: InitialModelField,
        phone: InitialModelField,
        address: InitialModelField,
    },
    userFields: {
        organization_name: InitialModelField,
        email: InitialModelField,
        phone: InitialModelField,
        username: InitialModelField,
        password: InitialModelField,
        password2: InitialModelField,
        first_name: InitialModelField,
        last_name: InitialModelField
    } as UserListFieldsType,
    orderFields: {
        client: InitialModelFieldWithChoices,
        material: InitialModelFieldWithChoices,
        comment: InitialModelField,
        tooth_color: InitialModelField,
        TP18: InitialModelField,
        TP17: InitialModelField,
        TP16: InitialModelField,
        TP15: InitialModelField,
        TP14: InitialModelField,
        TP13: InitialModelField,
        TP12: InitialModelField,
        TP11: InitialModelField,
        TP21: InitialModelField,
        TP22: InitialModelField,
        TP23: InitialModelField,
        TP24: InitialModelField,
        TP25: InitialModelField,
        TP26: InitialModelField,
        TP27: InitialModelField,
        TP28: InitialModelField,
        TP48: InitialModelField,
        TP47: InitialModelField,
        TP46: InitialModelField,
        TP45: InitialModelField,
        TP44: InitialModelField,
        TP43: InitialModelField,
        TP42: InitialModelField,
        TP41: InitialModelField,
        TP31: InitialModelField,
        TP32: InitialModelField,
        TP33: InitialModelField,
        TP34: InitialModelField,
        TP35: InitialModelField,
        TP36: InitialModelField,
        TP37: InitialModelField,
        TP38: InitialModelField,

        R18: InitialModelField,
        R17: InitialModelField,
        R16: InitialModelField,
        R15: InitialModelField,
        R14: InitialModelField,
        R13: InitialModelField,
        R12: InitialModelField,
        R11: InitialModelField,
        R21: InitialModelField,
        R22: InitialModelField,
        R23: InitialModelField,
        R24: InitialModelField,
        R25: InitialModelField,
        R26: InitialModelField,
        R27: InitialModelField,
        R28: InitialModelField,
        R48: InitialModelField,
        R47: InitialModelField,
        R46: InitialModelField,
        R45: InitialModelField,
        R44: InitialModelField,
        R43: InitialModelField,
        R42: InitialModelField,
        R41: InitialModelField,
        R31: InitialModelField,
        R32: InitialModelField,
        R33: InitialModelField,
        R34: InitialModelField,
        R35: InitialModelField,
        R36: InitialModelField,
        R37: InitialModelField,
        R38: InitialModelField,

        B18: InitialModelField,
        B17: InitialModelField,
        B16: InitialModelField,
        B15: InitialModelField,
        B14: InitialModelField,
        B13: InitialModelField,
        B12: InitialModelField,
        B11: InitialModelField,
        B21: InitialModelField,
        B22: InitialModelField,
        B23: InitialModelField,
        B24: InitialModelField,
        B25: InitialModelField,
        B26: InitialModelField,
        B27: InitialModelField,
        B28: InitialModelField,
        B48: InitialModelField,
        B47: InitialModelField,
        B46: InitialModelField,
        B45: InitialModelField,
        B44: InitialModelField,
        B43: InitialModelField,
        B42: InitialModelField,
        B41: InitialModelField,
        B31: InitialModelField,
        B32: InitialModelField,
        B33: InitialModelField,
        B34: InitialModelField,
        B35: InitialModelField,
        B36: InitialModelField,
        B37: InitialModelField,
        B38: InitialModelField,
    } as OrderListFieldsType
};

type InitialStateType = {
    employeesFields: EmployeesListFieldsType;
    clientsFields: ClientsListFieldsType;
    eventsFields: EventsListFieldsType;
    reasonsFields: ReasonsListFieldsType;
    workScheduleFields: WorkScheduleListFieldsType;
    branchesFields: BranchesListFieldsType;
    registrationFields: RegistrationListFieldsType;
    holidayFields: HolidayListFieldsType;
    contactsFields: ContactsListFieldsType;
    userFields: UserListFieldsType;
    orderFields: OrderListFieldsType;
};

export const fieldsReducer = (
    state: InitialStateType = initialState,
    action: ActionsType
): InitialStateType => {
    switch (action.type) {
        case SET_LIST_FIELDS_EMPLOYEES: {
            return { ...state, employeesFields: action.fields };
        }
        case SET_LIST_FIELDS_CLIENTS: {
            return { ...state, clientsFields: action.fields };
        }
        case SET_LIST_FIELDS_EVENTS: {
            return { ...state, eventsFields: action.fields };
        }
        case SET_LIST_FIELDS_REASONS: {
            return { ...state, reasonsFields: action.fields };
        }
        case SET_LIST_FIELDS_WORK_SCHEDULE: {
            return { ...state, workScheduleFields: action.fields };
        }
        case SET_LIST_FIELDS_BRANCHES: {
            return { ...state, branchesFields: action.fields };
        }
        case SET_LIST_FIELDS_REGISTRATION: {
            return { ...state, registrationFields: action.fields };
        }
        case SET_LIST_FIELDS_HOLIDAY: {
            return { ...state, holidayFields: action.fields };
        }
        case SET_LIST_FIELDS_CONTACTS: {
            return { ...state, contactsFields: action.fields };
        }
        case SET_LIST_FIELDS_USER: {
            return { ...state, userFields: action.fields };
        }
        case SET_LIST_FIELDS_ORDER: {
            return { ...state, orderFields: action.fields };
        }
        default:
            return state;
    }
};

// actions
export const setListFieldsEmployeesAC = (fields: EmployeesListFieldsType) =>
    ({ type: SET_LIST_FIELDS_EMPLOYEES, fields } as const);
export const setListFieldsClientsAC = (fields: ClientsListFieldsType) =>
    ({ type: SET_LIST_FIELDS_CLIENTS, fields } as const);
export const setListFieldsEventsAC = (fields: EventsListFieldsType) =>
    ({ type: SET_LIST_FIELDS_EVENTS, fields } as const);
export const setListFieldsReasonsAC = (fields: ReasonsListFieldsType) =>
    ({ type: SET_LIST_FIELDS_REASONS, fields } as const);
export const setListFieldsWorkScheduleAC = (
    fields: WorkScheduleListFieldsType
) => ({ type: SET_LIST_FIELDS_WORK_SCHEDULE, fields } as const);
export const setListFieldsBranchesAC = (fields: BranchesListFieldsType) =>
    ({ type: SET_LIST_FIELDS_BRANCHES, fields } as const);
export const setListFieldsRegistrationAC = (
    fields: RegistrationListFieldsType
) => ({ type: SET_LIST_FIELDS_REGISTRATION, fields } as const);
export const setListFieldsHolidayAC = (fields: HolidayListFieldsType) =>
    ({ type: SET_LIST_FIELDS_HOLIDAY, fields } as const);
export const setListFieldsContactsAC = (fields: ContactsListFieldsType) =>
    ({ type: SET_LIST_FIELDS_CONTACTS, fields } as const);
export const setListFieldsUserAC = (fields: UserListFieldsType) =>
    ({ type: SET_LIST_FIELDS_USER, fields } as const);
export const setListFieldsOrderAC = (fields: OrderListFieldsType) =>
    ({ type: SET_LIST_FIELDS_ORDER, fields } as const);

//thunk
export const fetchEmployeesListFieldsTC =
    () => async (dispatch: Dispatch<any>) => {
        try {
            const tokenLS = await loadToken();
            const res = await fieldsAPI.getListFieldEmployees(tokenLS);
            dispatch(setListFieldsEmployeesAC(res.data));
            // dispatch(setAppStatusAC("succeeded"))
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
    };

export const fetchClientsListFieldsTC =
    () => async (dispatch: Dispatch<any>) => {
        try {
            const tokenLS = await loadToken();
            const res = await fieldsAPI.getListFieldClients(tokenLS);
            dispatch(setListFieldsClientsAC(res.data));
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
    };

export const fetchEventsListFieldsTC =
    (eventId: number) => async (dispatch: Dispatch<any>) => {
        try {
            const tokenLS = await loadToken();
            const res = await fieldsAPI.getListFieldEvents(tokenLS, eventId);
            dispatch(setListFieldsEventsAC(res.data));
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
    };

export const fetchReasonsListFieldsTC =
    () => async (dispatch: Dispatch<any>) => {
        try {
            const tokenLS = await loadToken();
            const res = await fieldsAPI.getListFieldReasons(tokenLS);
            dispatch(setListFieldsReasonsAC(res.data));
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
    };

export const fetchWorkScheduleListFieldsTC =
    () => async (dispatch: Dispatch<any>) => {
        try {
            const tokenLS = await loadToken();
            const res = await fieldsAPI.getListFieldWorkSchedule(tokenLS);
            dispatch(setListFieldsWorkScheduleAC(res.data));
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
    };

export const fetchBranchListFieldsTC =
    () => async (dispatch: Dispatch<any>) => {
        try {
            const tokenLS = await loadToken();
            const res = await fieldsAPI.getListFieldBranches(tokenLS);
            dispatch(setListFieldsBranchesAC(res.data));
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
    };

export const fetchRegistrationListFieldsTC = (branchId: number) =>
    async (dispatch: Dispatch<any>) => {
        try {
            const tokenLS = await loadToken();
            let res = await fieldsAPI.getListFieldRegistration(tokenLS, branchId);
            dispatch(setListFieldsRegistrationAC(res.data));
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
    };

export const fetchHolidayListFieldsTC =
    () => async (dispatch: Dispatch<any>) => {
        try {
            const tokenLS = await loadToken();
            let res = await fieldsAPI.getListFieldHoliday(tokenLS);
            dispatch(setListFieldsHolidayAC(res.data));
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
    };

export const fetchContactsListFieldsTC =
    () => async (dispatch: Dispatch<any>) => {
        try {
            const tokenLS = await loadToken();
            let res = await fieldsAPI.getListFieldContacts(tokenLS);
            dispatch(setListFieldsContactsAC(res.data));
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
    };

export const fetchUserListFieldsTC =
    () => async (dispatch: Dispatch<any>) => {
        try {
            const tokenLS = await loadToken();
            let res = await fieldsAPI.getListFieldUser(tokenLS);
            dispatch(setListFieldsUserAC(res.data));
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
    };

export const fetchOrderListFieldsTC =
    () => async (dispatch: Dispatch<any>) => {
        try {
            const tokenLS = await loadToken();
            let res = await fieldsAPI.getListFieldOrder(tokenLS);
            dispatch(setListFieldsOrderAC(res.data));
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
    };

//types
type ActionsType =
    | ReturnType<typeof setListFieldsEmployeesAC>
    | ReturnType<typeof setListFieldsClientsAC>
    | ReturnType<typeof setListFieldsEventsAC>
    | ReturnType<typeof setListFieldsReasonsAC>
    | ReturnType<typeof setListFieldsWorkScheduleAC>
    | ReturnType<typeof setListFieldsBranchesAC>
    | ReturnType<typeof setListFieldsRegistrationAC>
    | ReturnType<typeof setListFieldsHolidayAC>
    | ReturnType<typeof setListFieldsContactsAC>
    | ReturnType<typeof setListFieldsUserAC>
    | ReturnType<typeof setListFieldsOrderAC>

