import "./Preloader.scss";
//@ts-ignore
import preloader from "../../assets/img/preloader.gif"

const Preloader: React.FC = () => {
    return (
        <div className="preloader">
            <img src={preloader} className="preloader-img" />
        </div>
    )
}

export default Preloader;