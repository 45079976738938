import { Dispatch } from "redux";
import { loadToken } from "../utils/localStorage";
import { BrunchesType, reasonsAPI, ReasonType } from "../api/reasons-api";
import { setAppErrorAC, setAppStatusAC } from "./app-reducer";
import { handleServerAppError, handleServerNetworkError } from "../utils/error-utils";
import { registerAPI, RegisterType } from "../api/register-api";


const ADD_USER = 'registerReducer/ADD_USER'
const ACTIVATION = 'registerReducer/ACTIVATION'
const IS_SEND_DATA = 'registerReducer/IS_SEND_DATA'

type InitialStateType = {
    sendDate: boolean,
    registerDate: Array<RegisterType>
}

const initialState: InitialStateType = {
    sendDate: false,
    registerDate: [] as Array<RegisterType>
}

export const registerReducer = (state: InitialStateType = initialState, action: ActionsType) => {
    switch (action.type) {
        case ADD_USER: {
            return {
                ...state,
                registerDate: [...state.registerDate, action.model]
            }
        }
        case IS_SEND_DATA:
            return { ...state, sendData: action.value }
        default:
            return state
    }
}

// actions
export const addUserAC = (model: RegisterType) =>({ type: ADD_USER, model } as const)
export const activationAC = (id: number, token: string) =>({ type: ACTIVATION, id, token } as const)
export const setIsSendDataAC = (value: boolean) =>({ type: IS_SEND_DATA, value } as const)

// thunks
export const addUserTC = (data: any, id: number) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        let res = await registerAPI.addUser(data, tokenLS)
        dispatch(addUserAC(res.data))
        dispatch(setIsSendDataAC(true))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
}

export const activationTC = (id: number, token: string) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        registerAPI.getVerivicationOfEmail(id, token)
        dispatch(activationAC(id, token))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

//types
type ActionsType = ReturnType<typeof addUserAC>
    | ReturnType<typeof activationAC>
    | ReturnType<typeof setIsSendDataAC>

