import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const fieldsAPI = {
  getListFieldEmployees(token: string) {
    return instance.get<EmployeesListFieldsType>("/api/employees/create/", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getListFieldClients(token: string) {
    return instance.get<ClientsListFieldsType>("/api/clients/create/", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getListFieldEvents(token: string, eventId: number) {
    return instance.get<EventsListFieldsType>(
      `/api/events/create/?branch=${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  getListFieldReasons(token: string) {
    return instance.get<ReasonsListFieldsType>("/api/reasons/create/", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getListFieldWorkSchedule(token: string) {
    return instance.get<WorkScheduleListFieldsType>(
      "/api/work-schedule/create/",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  getListFieldBranches(token: string) {
    return instance.get<BranchesListFieldsType>("/api/branches/create/", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getListFieldRegistration(token: string, branchId: number) {
    return instance.get<RegistrationListFieldsType>(`/api/client/events/create/?branch=${branchId}`, {
      // headers: {
      //   Authorization: `Bearer ${token}`,
      // },
    });
  },
  getListFieldHoliday(token: string) {
    return instance.get<HolidayListFieldsType>("/api/holidays/create/", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getListFieldContacts(token: string) {
    return instance.get<ContactsListFieldsType>("/api/contacts/create/", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getListFieldUser(token: string) {
    return instance.get<UserListFieldsType>("/api/register/", {
      /*   headers: {
          Authorization: `Bearer ${token}`,
        }, */
    });
  },

  getListFieldOrder(token: string) {
    return instance.get<OrderListFieldsType>("/api/implant-order/create/", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

export type ModelFieldType = {
  help_text: string;
  hidden: boolean;
  initial_value: null | any;
  input_type: string;
  label: string;
  required: boolean;
  type: string;
  widget_type: string;
};

export type ModelFieldWithChoicesType = {
  choices: {};
  help_text: string;
  hidden: boolean;
  initial_value: null | string;
  input_type: string;
  label: string;
  required: boolean;
  type: string;
  widget_type: string;
};

export type EmployeesListFieldsType = {
  username: ModelFieldType;
  password: ModelFieldType;
  password2: ModelFieldType;
  first_name: ModelFieldType;
  last_name: ModelFieldType;
  branch_set: ModelFieldWithChoicesType
  role: ModelFieldWithChoicesType
};

export type ClientsListFieldsType = {
  phone: ModelFieldType;
  email: ModelFieldType;
  insurance_type: ModelFieldWithChoicesType;
  first_name: ModelFieldType;
  last_name: ModelFieldType;
  blocked: ModelFieldType;
  branches: ModelFieldWithChoicesType;
};

export type EventsListFieldsType = {
  first_name: ModelFieldType;
  last_name: ModelFieldType;
  email: ModelFieldType;
  phone: ModelFieldType;
  status: ModelFieldWithChoicesType;
  reason: ModelFieldWithChoicesType;
  gender: ModelFieldWithChoicesType;
  date_birth: ModelFieldType;
  start: ModelFieldType;
  message: ModelFieldType;
  insurance_type: ModelFieldWithChoicesType;
  desired_date: ModelFieldType
  doctor: ModelFieldWithChoicesType
  note: ModelFieldType
};

export type ReasonsListFieldsType = {
  branches: ModelFieldWithChoicesType;
  title: ModelFieldType;
  offset_time: ModelFieldWithChoicesType;
  limit_time: ModelFieldType;
  order: ModelFieldType;
  color: ModelFieldType;
  doctors: ModelFieldWithChoicesType;
};

export type WorkScheduleListFieldsType = {
  branch: ModelFieldWithChoicesType;
  day_of_week: ModelFieldWithChoicesType;
  start_time_work: ModelFieldType;
  end_time_work: ModelFieldType;
  start_lunch_break: ModelFieldType;
  end_lunch_break: ModelFieldType;
  is_weekend: ModelFieldType;
  lunch_break: ModelFieldType;
};

export type BranchesListFieldsType = {
  name: ModelFieldType;
  phone: ModelFieldType;
  email: ModelFieldType;
  sity: ModelFieldType;
  street: ModelFieldType;
  street_number: ModelFieldType;
  zip_code: ModelFieldType;
  employees: ModelFieldWithChoicesType;
  whatsapp: ModelFieldType;
  show_title: ModelFieldType;
  free_date: ModelFieldType;
  sms_remind_text: ModelFieldType;
  sms_cancel_text: ModelFieldType;
  email_remind_text: ModelFieldType;
  email_cancel_text: ModelFieldType;
  email_order_text: ModelFieldType;
  sms_order_text: ModelFieldType;
  concurrent_records: ModelFieldWithChoicesType
  desired_date: ModelFieldType
}
export type RegistrationListFieldsType = {
  date_birth: ModelFieldType
  email: ModelFieldType
  first_name: ModelFieldType
  gender: ModelFieldWithChoicesType
  insurance_type: ModelFieldWithChoicesType
  last_name: ModelFieldType
  message: ModelFieldType
  phone: ModelFieldType
  reason: ModelFieldWithChoicesType
  start: ModelFieldType
  wait_time: ModelFieldType
};

export type HolidayListFieldsType = {
  branches: ModelFieldWithChoicesType;
  name: ModelFieldType;
  date: ModelFieldType;
  start_time: ModelFieldType;
  end_time: ModelFieldType;
};

export type ContactsListFieldsType = {
  branches: ModelFieldWithChoicesType;
  title: ModelFieldType;
  email: ModelFieldType;
  phone: ModelFieldType;
  address: ModelFieldType;
};

export type UserListFieldsType = {
  organization_name: ModelFieldType;
  email: ModelFieldType;
  phone: ModelFieldType;
  username: ModelFieldType;
  password: ModelFieldType;
  password2: ModelFieldType;
  first_name: ModelFieldType;
  last_name: ModelFieldType;
};

export type OrderListFieldsType = {
  client: ModelFieldWithChoicesType;
  material: ModelFieldWithChoicesType;
  comment: ModelFieldType;
  tooth_color: ModelFieldType;
  TP18: ModelFieldType;
  TP17: ModelFieldType;
  TP16: ModelFieldType;
  TP15: ModelFieldType;
  TP14: ModelFieldType;
  TP13: ModelFieldType;
  TP12: ModelFieldType;
  TP11: ModelFieldType;
  TP21: ModelFieldType;
  TP22: ModelFieldType;
  TP23: ModelFieldType;
  TP24: ModelFieldType;
  TP25: ModelFieldType;
  TP26: ModelFieldType;
  TP27: ModelFieldType;
  TP28: ModelFieldType;
  TP48: ModelFieldType;
  TP47: ModelFieldType;
  TP46: ModelFieldType;
  TP45: ModelFieldType;
  TP44: ModelFieldType;
  TP43: ModelFieldType;
  TP42: ModelFieldType;
  TP41: ModelFieldType;
  TP31: ModelFieldType;
  TP32: ModelFieldType;
  TP33: ModelFieldType;
  TP34: ModelFieldType;
  TP35: ModelFieldType;
  TP36: ModelFieldType;
  TP37: ModelFieldType;
  TP38: ModelFieldType;

  R18: ModelFieldType;
  R17: ModelFieldType;
  R16: ModelFieldType;
  R15: ModelFieldType;
  R14: ModelFieldType;
  R13: ModelFieldType;
  R12: ModelFieldType;
  R11: ModelFieldType;
  R21: ModelFieldType;
  R22: ModelFieldType;
  R23: ModelFieldType;
  R24: ModelFieldType;
  R25: ModelFieldType;
  R26: ModelFieldType;
  R27: ModelFieldType;
  R28: ModelFieldType;
  R48: ModelFieldType;
  R47: ModelFieldType;
  R46: ModelFieldType;
  R45: ModelFieldType;
  R44: ModelFieldType;
  R43: ModelFieldType;
  R42: ModelFieldType;
  R41: ModelFieldType;
  R31: ModelFieldType;
  R32: ModelFieldType;
  R33: ModelFieldType;
  R34: ModelFieldType;
  R35: ModelFieldType;
  R36: ModelFieldType;
  R37: ModelFieldType;
  R38: ModelFieldType;

  B18: ModelFieldType;
  B17: ModelFieldType;
  B16: ModelFieldType;
  B15: ModelFieldType;
  B14: ModelFieldType;
  B13: ModelFieldType;
  B12: ModelFieldType;
  B11: ModelFieldType;
  B21: ModelFieldType;
  B22: ModelFieldType;
  B23: ModelFieldType;
  B24: ModelFieldType;
  B25: ModelFieldType;
  B26: ModelFieldType;
  B27: ModelFieldType;
  B28: ModelFieldType;
  B48: ModelFieldType;
  B47: ModelFieldType;
  B46: ModelFieldType;
  B45: ModelFieldType;
  B44: ModelFieldType;
  B43: ModelFieldType;
  B42: ModelFieldType;
  B41: ModelFieldType;
  B31: ModelFieldType;
  B32: ModelFieldType;
  B33: ModelFieldType;
  B34: ModelFieldType;
  B35: ModelFieldType;
  B36: ModelFieldType;
  B37: ModelFieldType;
  B38: ModelFieldType;
}

