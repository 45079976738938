import { Dispatch } from "redux";
import { loadToken } from "../utils/localStorage";
import { EventFilterParamsType, eventsAPI, } from "../api/events-api";
import { setAppStatusAC } from "./app-reducer";
import { handleServerAppError, handleServerNetworkError } from "../utils/error-utils";
import { setEventsListAC, setEventsParamsAC } from "./events-reducer";
import { AppRootStateType } from "./store";


const SET_EVENT_FILTER_PARAMS = "eventsFilterReducer/SET_EVENT_FILTER_PARAMS";
const ADD_CURRENT_EVENT_FILTER_INSURANCE = "eventsFilterReducer/ADD_CURRENT_EVENT_FILTER_INSURANCE";
const REMOVE_CURRENT_EVENT_FILTER_INSURANCE = "eventsFilterReducer/REMOVE_CURRENT_EVENT_FILTER_INSURANCE";
const ADD_CURRENT_EVENT_FILTER_REASON = "eventsFilterReducer/ADD_CURRENT_EVENT_FILTER_REASON";
const REMOVE_CURRENT_EVENT_FILTER_REASON = "eventsFilterReducer/REMOVE_CURRENT_EVENT_FILTER_REASON";
const ADD_CURRENT_EVENT_FILTER_REASON_COLOR = "eventsFilterReducer/ADD_CURRENT_EVENT_FILTER_REASON_COLOR";
const REMOVE_CURRENT_EVENT_FILTER_REASON_COLOR = "eventsFilterReducer/REMOVE_CURRENT_EVENT_FILTER_REASON_COLOR";
const ADD_CURRENT_EVENT_FILTER_STATUS = "eventsFilterReducer/ADD_CURRENT_EVENT_FILTER_STATUS";
const REMOVE_CURRENT_EVENT_FILTER_STATUS = "eventsFilterReducer/REMOVE_CURRENT_EVENT_FILTER_STATUS";
const ADD_CURRENT_EVENT_FILTER_BRANCH = "eventsFilterReducer/ADD_CURRENT_EVENT_FILTER_BRANCH";



const initialState: InitialStateType = {
    currentFilterParams: {
        reason__color: [],
        insurance_type__in: [],
        reason__in: [],
        status__in: [],
        search: {},
        branch: []
    },
    eventFilterParamsList: {
        search: {
            help_text: '',
            hidden: false,
            initial_value: null,
            input_type: '',
            label: '',
            required: true,
            type: '',
            widget_type: '',
        },
        full_name: { choices: {} },
        reason__color: { choices: {} },
        insurance_type__in: { choices: {} },
        reason__in: { choices: {} },
        status__in: { choices: {} },
        branch: { choices: {} }
    }
};

type InitialStateType = {
    currentFilterParams: {
        reason__color: Array<string>,
        insurance_type__in: Array<string>
        reason__in: Array<string>
        status__in: Array<string>
        search: {}
        branch: Array<string>
    }
    eventFilterParamsList: {
        search: {},
        full_name: { choices: {} }
        reason__color: { choices: {} },
        insurance_type__in: { choices: {} }
        reason__in: { choices: {} }
        status__in: { choices: {} }
        branch: { choices: {} }
    }
}

export const eventsFilterReducer = (
    state: InitialStateType = initialState,
    action: ActionsType
): InitialStateType => {
    switch (action.type) {
        case SET_EVENT_FILTER_PARAMS: {
            return {
                ...state, eventFilterParamsList: action.params
            }
        }
        case ADD_CURRENT_EVENT_FILTER_INSURANCE: {
            return {
                ...state,
                currentFilterParams: {
                    ...state.currentFilterParams,
                    insurance_type__in: [...state.currentFilterParams.insurance_type__in, action.params]
                }
            }
        }
        case REMOVE_CURRENT_EVENT_FILTER_INSURANCE: {
            return {
                ...state,
                currentFilterParams: {
                    ...state.currentFilterParams,
                    insurance_type__in: state.currentFilterParams.insurance_type__in
                        .filter(insurance => insurance !== action.params)
                }
            }
        }
        case ADD_CURRENT_EVENT_FILTER_REASON: {
            return {
                ...state,
                currentFilterParams: {
                    ...state.currentFilterParams,
                    reason__in: [...state.currentFilterParams.reason__in, action.params]
                }
            }
        }
        case REMOVE_CURRENT_EVENT_FILTER_REASON: {
            return {
                ...state,
                currentFilterParams: {
                    ...state.currentFilterParams,
                    reason__in: state.currentFilterParams.reason__in
                        .filter(reason => reason !== action.params)
                }
            }
        }
        case ADD_CURRENT_EVENT_FILTER_REASON_COLOR: {
            return {
                ...state,
                currentFilterParams: {
                    ...state.currentFilterParams,
                    reason__color: [...state.currentFilterParams.reason__color, action.params]
                }
            }
        }
        case REMOVE_CURRENT_EVENT_FILTER_REASON_COLOR: {
            return {
                ...state,
                currentFilterParams: {
                    ...state.currentFilterParams,
                    reason__color: state.currentFilterParams.reason__color
                        .filter(reason => reason !== action.params)
                }
            }
        }
        case ADD_CURRENT_EVENT_FILTER_STATUS: {
            return {
                ...state,
                currentFilterParams: {
                    ...state.currentFilterParams,
                    status__in: [...state.currentFilterParams.status__in, action.params]
                }
            }
        }
        case REMOVE_CURRENT_EVENT_FILTER_STATUS: {
            return {
                ...state,
                currentFilterParams: {
                    ...state.currentFilterParams,
                    status__in: state.currentFilterParams.status__in
                        .filter(status => status !== action.params)
                }
            }
        }
        case ADD_CURRENT_EVENT_FILTER_BRANCH: {
            return {
                ...state,
                currentFilterParams: {
                    ...state.currentFilterParams,
                    branch: [action.params]
                }
            }
        }

        default:
            return state;
    }
};

// actions
export const setEventFilterParamsAC = (params: EventFilterParamsType) =>
    ({ type: SET_EVENT_FILTER_PARAMS, params } as const);
export const addCurrentEventFilterInsuranceAC = (params: string) =>
    ({ type: ADD_CURRENT_EVENT_FILTER_INSURANCE, params } as const);
export const removeCurrentEventFilterInsuranceAC = (params: string) =>
    ({ type: REMOVE_CURRENT_EVENT_FILTER_INSURANCE, params } as const);
export const addCurrentEventFilterReasonAC = (params: string) =>
    ({ type: ADD_CURRENT_EVENT_FILTER_REASON, params } as const);
export const removeCurrentEventFilterReasonAC = (params: string) =>
    ({ type: REMOVE_CURRENT_EVENT_FILTER_REASON, params } as const);
export const addCurrentEventFilterReasonColorAC = (params: string) =>
    ({ type: ADD_CURRENT_EVENT_FILTER_REASON_COLOR, params } as const);
export const removeCurrentEventFilterReasonColorAC = (params: string) =>
    ({ type: REMOVE_CURRENT_EVENT_FILTER_REASON_COLOR, params } as const);
export const addCurrentEventFilterStatusAC = (params: string) =>
    ({ type: ADD_CURRENT_EVENT_FILTER_STATUS, params } as const);
export const removeCurrentEventFilterStatusAC = (params: string) =>
    ({ type: REMOVE_CURRENT_EVENT_FILTER_STATUS, params } as const);
export const addCurrentEventFilterBranchAC = (params: string) =>
    ({ type: ADD_CURRENT_EVENT_FILTER_BRANCH, params } as const);

// thunks
export const fetchFilteredEventsParamsListTC =
    () => async (dispatch: Dispatch<any>,
        getState: () => AppRootStateType) => {
        const branchId = getState().branches.currentBranch
        try {
            const tokenLS = await loadToken();
            const res = await eventsAPI.getFilteredParamsList(tokenLS, branchId);
            dispatch(setEventFilterParamsAC(res.data));
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
        dispatch(setAppStatusAC("idle"))
    };

export const searchOfFullNameTC =
    (name: string) => async (dispatch: Dispatch<any>) => {
        dispatch(setAppStatusAC("loading"));
        try {
            const tokenLS = await loadToken();
            const res = await eventsAPI.searchOfFullName(tokenLS, name);
            dispatch(setEventsParamsAC(res.data));
            dispatch(setEventsListAC(res.data.results));
            dispatch(setAppStatusAC("succeeded"));
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
        dispatch(setAppStatusAC("idle"))
    };

export const setFilteredEventsOfInsuranceTC = (currentInsurance: string) =>
    async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
        const currentInsuranceChecked = getState().eventsFilter.currentFilterParams.insurance_type__in
            .find(insurance => insurance === (currentInsurance))
        try {
            if (currentInsuranceChecked) {
                dispatch(removeCurrentEventFilterInsuranceAC(currentInsurance))
            } else {
                dispatch(addCurrentEventFilterInsuranceAC(currentInsurance))
            }
            dispatch(fetchFilteredEventsListOfGeneral())
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
    };

export const setFilteredEventsOfReasonTC = (currentReason: string) =>
    async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
        const currentReasonChecked = getState().eventsFilter.currentFilterParams.reason__in
            .find(reason => reason === (currentReason))
        const currentReasonColorChecked = getState().eventsFilter.currentFilterParams.reason__color
            .find(reason => reason === (currentReason))
        try {
            if (currentReasonChecked) {
                dispatch(removeCurrentEventFilterReasonAC(currentReason))
                dispatch(removeCurrentEventFilterReasonColorAC(currentReason))
            } else {
                dispatch(addCurrentEventFilterReasonAC(currentReason))
                dispatch(addCurrentEventFilterReasonColorAC(currentReason))
            }
            dispatch(fetchFilteredEventsListOfGeneral())
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
    };

export const setFilteredEventsOfStatusTC = (currentStatus: string) =>
    async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
        const currentReasonChecked = getState().eventsFilter.currentFilterParams.status__in
            .find(status => status === (currentStatus))

        try {
            if (currentReasonChecked) {
                dispatch(removeCurrentEventFilterStatusAC(currentStatus))
            } else {
                dispatch(addCurrentEventFilterStatusAC(currentStatus))
            }
            dispatch(fetchFilteredEventsListOfGeneral())
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
    };

    export const setFilteredEventsOfBranchTC = (branch: any) =>
    async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
        const currentBranchChecked = getState().eventsFilter.currentFilterParams.branch
            .find(status => status === (branch))

        try {
            if (currentBranchChecked) {
              
                
            } else {
                dispatch(addCurrentEventFilterBranchAC(branch))
               
            }
            dispatch(fetchFilteredEventsListOfGeneral())
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
    };

export const fetchFilteredEventsListOfGeneral =
    () => async (dispatch: Dispatch<any>,
        getState: () => AppRootStateType) => {
        const insuranceOrdering = getState().eventsFilter.currentFilterParams.insurance_type__in
        const reasonOrdering = getState().eventsFilter.currentFilterParams.reason__in
        const statusOrdering = getState().eventsFilter.currentFilterParams.status__in
        const colorOrdering = getState().eventsFilter.currentFilterParams.reason__color
        const branchOrdering = getState().eventsFilter.currentFilterParams.branch
        dispatch(setAppStatusAC("loading"));
        try {
            const tokenLS = await loadToken();
            const res = await eventsAPI.getFilteredListOfGeneral(tokenLS,
                insuranceOrdering, reasonOrdering, statusOrdering, colorOrdering,branchOrdering);
            dispatch(setEventsParamsAC(res.data));
            dispatch(setEventsListAC(res.data.results));
            dispatch(setAppStatusAC("succeeded"));
            // dispatch(setCurrentOrderingsAC(ordering))
        } catch (error: any) {
            if (error.message === "Network Error") {
                handleServerNetworkError(error, dispatch)
            } else {
                handleServerAppError(error, dispatch)
            }
        }
        dispatch(setAppStatusAC("idle"))
    };

//types
type ActionsType = ReturnType<typeof setEventFilterParamsAC>
    | ReturnType<typeof addCurrentEventFilterInsuranceAC>
    | ReturnType<typeof removeCurrentEventFilterInsuranceAC>
    | ReturnType<typeof addCurrentEventFilterReasonAC>
    | ReturnType<typeof removeCurrentEventFilterReasonAC>
    | ReturnType<typeof addCurrentEventFilterReasonColorAC>
    | ReturnType<typeof removeCurrentEventFilterReasonColorAC>
    | ReturnType<typeof addCurrentEventFilterStatusAC>
    | ReturnType<typeof removeCurrentEventFilterStatusAC>
    | ReturnType<typeof addCurrentEventFilterBranchAC>

export type CurrentFilterEventsParams = {
    insurance_type__in?: Array<string>
    reason__in?: Array<string>
    status__in?: Array<string>
    reason__color?: Array<string>
}
