import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, NavLink } from "react-router-dom";
import { PATH } from "../Routes";
import './WaitingRoom.scss'
import { ModalAlertDelete } from "../../components/ModalsAlerts/ModalAlertDelete";
import { initializeAppTC } from "../../redux/app-reducer";
import Preloader from "../Preloader/Preloader";
import { changeStatusDoctorTC, changeStatusDoneTC, changeStatusNotTC, removeEventTC } from "../../redux/events-reducer";
import { getScheduleBranchActiveWeekTC, getScheduleBranchTC, setCurrentBranchesAC } from "../../redux/branches-reducer";
import {
  fetchFilteredWaitingRoomListOfBranchTC,
  fetchFilteredWaitingRoomParamsListTC
} from "../../redux/waitingRoom-filter-reducer";

//@ts-ignore
import img from "../../../src/assets/img/closeW.png";
//@ts-ignore
import img2 from "../../../src/assets/img/icons8.svg";
import { fetchWaitingRoomListTC, fetchWaitingRoomListTC1 } from "../../redux/waitingRoom-reducer";
import {
  getActiveWeekSelector, getCurrentBranchSelector, getEventsSelector,
  getIsLoggedInSelector,
  getStatusSelector,
  getWaitingRoomSelector
} from "../../redux/selectors";

export const WaitingRoom = () => {
  const dispatch = useDispatch();

  const waitingRoom = useSelector(getWaitingRoomSelector);
  const status = useSelector(getStatusSelector);
  const isLoggedIn = useSelector(getIsLoggedInSelector);
  const activeWeek = useSelector(getActiveWeekSelector);
  const events = useSelector(getEventsSelector);
  const currentBranch = useSelector(getCurrentBranchSelector);

  const [statuss, setStatus] = useState(false)
  const [openAlertDialogForDelete, setOpenAlertDialogForDelete] = useState(false);
  const [modalDataId, setModalDataId] = useState<number>(0);


  useEffect(() => {
    if (status === "failed") {
      dispatch(initializeAppTC())
    }
  }, [status])

  useEffect(() => {
    // dispatch(fetchFilteredWaitingRoomListOfBranchTC(currentBranch.toString())) // filtered list
    dispatch(fetchWaitingRoomListTC())
  }, [events, currentBranch])

  useEffect(() => {

    const intervalId = setInterval(() => {

      dispatch(fetchWaitingRoomListTC1())
    }, 60000)
    return () => clearInterval(intervalId)

  }, [dispatch])

  useEffect(() => {
    dispatch(fetchFilteredWaitingRoomParamsListTC())
  }, [dispatch])

  const setDate = (date: string) => {
    return new Date(date);
  };

  const setBranch = (branchId: number) => {
    if (activeWeek) {
      dispatch(getScheduleBranchActiveWeekTC(branchId));
    } else {
      dispatch(getScheduleBranchTC(branchId));
    }
    dispatch(setCurrentBranchesAC(branchId))
  };

  const removeEvents = (id: number) => {
    dispatch(removeEventTC(id));
    setModalDataId(0);
  };

  const changeStatus = (eventId: number) => {
    dispatch(changeStatusDoneTC(eventId))
    setStatus(false)
  }

  const changeStatusNotWait = (eventId: number) => {
    dispatch(changeStatusNotTC(eventId))
    setStatus(false)
  }

  const changeStatusDoctor = (eventId: number) => {
    dispatch(changeStatusDoctorTC(eventId))
  }

  const openAlertHandlerDelete = (eventsId: number) => {
    setModalDataId(eventsId);
    setOpenAlertDialogForDelete(true);
  };

  const closeAlertHandler = () => {
    setOpenAlertDialogForDelete(false)
  }

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />
  }

  return (
    <>
      {/*<WaitingRoomFilter />*/}
      <div className="waitingRoom">
        <h1 className="waitingRoom__title">Waiting room</h1>
        <div className="waitingRoom__add">
          <NavLink
            className="button button_size-small button_position-left button_color-transparent"
            to={PATH.ADD_EVENT}>+ new
            event</NavLink>
        </div>
        {status === 'loading' ? (
          <Preloader />
        ) : (
          <div>
            <span className="title">Waiting</span>
            <table className='waitingRoom__table'>
              <thead className="thead">
                <tr className="tr">
                  <th style={{ width: '340px' }} className="th"><span>{'Vorname und Nachnahme'}</span></th>
                  <th className="th"><span>{'Reason'}</span></th>
                  <th className="th"><span>{'Beginn'}</span></th>
                  <th style={{ width: '110px' }} className="th"><span>{'Wartezeit'}</span></th>
                  <th className="th"><span>{'Optionen'}</span></th>
                </tr>
              </thead>
              <tbody className="tbody">
                {waitingRoom.waiting_room.map(w =>
                  <tr key={w.id} className="tr">
                    <td className="td">{w.first_name} {w.last_name}</td>
                    <td
                      style={{
                        backgroundColor: `${w.reason_color}38`,
                        borderColor: `${w.reason_color}`
                      }}
                      className="td-color">
                      <span className="td" style={{
                        opacity: 1
                      }}>{w.reason_display}</span>
                    </td>
                    <td className="td date-waiting">
                      <span >{`${setDate(w.start).getUTCDate()} ${setDate(
                        w.start
                      )
                        .toDateString()
                        .slice(4, 7)}. ${setDate(
                          w.start
                        ).getFullYear()}`}
                      </span>
                      <b className="time">
                        {`${w.start.slice(11, 16)} `}
                      </b>
                    </td>
                    <td className="td">{w.waiting_time}</td>
                    <td className="td">
                      <div className="btns">
                        <button
                          title="At the docotor"
                          onClick={() => changeStatusDoctor(w.id)}
                          className="button button_size-small  button_color-green2"
                        ><img style={{ width: '20px' }} src={img2}></img></button>
                        <button
                          title="Did not wait"
                          onClick={() => changeStatusNotWait(w.id)}
                          className="button button_size-small  button_color-delete2"
                        >  <img style={{ width: '16px' }} src={img} />
                        </button>
                      </div>
                    </td>
                    {openAlertDialogForDelete && (
                      <>
                        <ModalAlertDelete
                          deleteCallback={removeEvents}
                          id={modalDataId}
                          titleQuestion="Delete event?"
                          actionTitle="Delete"
                          cancelCallback={closeAlertHandler}
                        />
                      </>
                    )}
                  </tr>)
                }
              </tbody>
            </table>
            <span className="title">At the doctor</span>
            <table className='waitingRoom__table'>
              <thead className="thead">
                <tr className="tr">
                  <th style={{ width: '340px' }} className="th"><span>{'Vorname und Nachnahme'}</span></th>
                  <th className="th"><span>{'Reason'}</span></th>
                  <th className="th"><span>{'Beginn'}</span></th>
                  <th style={{ width: '110px' }} className="th"><span>{'Wartezeit'}</span></th>
                  <th className="th"><span>{'Optionen'}</span></th>
                </tr>
              </thead>
              <tbody className="tbody">
                {waitingRoom.at_the_doctor.map(w => <tr key={w.id} className="tr">
                  <td className="td">{w.first_name} {w.last_name}</td>
                  <td
                    style={{
                      backgroundColor: `${w.reason_color}38`,
                      borderColor: `${w.reason_color}`
                    }}
                    className="td-color">
                    <span className="td" style={{
                      opacity: 1
                    }}>{w.reason_display}</span>
                  </td>
                  <td className="td date-waiting">
                    <span >{`${setDate(w.start).getUTCDate()} ${setDate(
                      w.start
                    )
                      .toDateString()
                      .slice(4, 7)}. ${setDate(
                        w.start
                      ).getFullYear()}`}
                    </span>
                    <b className="time">
                      {`${w.start.slice(11, 16)} `}
                    </b>
                  </td>
                  <td className="td">{w.visit_time}</td>
                  <td className="td">
                    <div className="btns">
                      <button onClick={() => changeStatus(w.id)}
                        className="button button_size-small  button_color-green"
                      >Done
                      </button>

                    </div>
                  </td>
                  {openAlertDialogForDelete && (
                    <>
                      <ModalAlertDelete
                        deleteCallback={removeEvents}
                        id={modalDataId}
                        titleQuestion="Delete event?"
                        actionTitle="Delete"
                        cancelCallback={closeAlertHandler}
                      />
                    </>
                  )}
                </tr>)
                }
              </tbody>
            </table>
          </div>
        )
        }
      </div>
    </>
  )
}
