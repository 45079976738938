import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { activationTC } from "../../redux/register-reducer";
import { PATH } from "../Routes";
import {getStatusSelector} from "../../redux/selectors";

type SideBarPropsType = {

}

export const Activation: FC<SideBarPropsType> = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const status = useSelector(getStatusSelector);

    const { confirmation_token } = useParams<{ confirmation_token: string }>()
    const idUrl = params.user_id
    const user_id = Number(idUrl);

    useEffect(() => {
        if (confirmation_token) {
            dispatch(activationTC(user_id, confirmation_token))
        }
    }, [])

    if (status === 'idle') {
        return <Navigate to={PATH.SUCCESS_PAGE_ERROR} />
    } else if (status === 'succeeded') {
        return <Navigate to={PATH.SUCCESS_PAGE_CREATE} />
    }

    return (
        <div style={{
            position: "absolute",
            zIndex: "100000000000000",
            width: "1000px",
            height: "1000vh",
            background: "yellow"
        }}
        >
        </div>
    );
}