import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import "./Register.scss";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, NavLink, useParams } from "react-router-dom";
import { PATH } from "../Routes";
import { logo } from "../../assets/img/image";
import { validation } from "../../utils/validation";
import { fetchUserListFieldsTC } from "../../redux/fields-reducer";
import Inputs from "../../components/Widgets/Inputs";
import { addUserTC, setIsSendDataAC } from "../../redux/register-reducer";
import {
    getErrorStateSelector,
    getFieldsUserRegisterSelector,
    getStatusSelector
} from "../../redux/selectors";


type FormDataType = {
    organization_name: string;
    email: string;
    phone: number;
    username: string;
    password: string;
    password2: string;
    first_name: string;
    last_name: string;
}

export const Register = () => {
    const dispatch = useDispatch();

    const errorState = useSelector(getErrorStateSelector);
    const status = useSelector(getStatusSelector);
    const fields = useSelector(getFieldsUserRegisterSelector);

    const [valueReason, setValueReason] = useState<boolean>(false)

    //for edit reason
    const params = useParams();
    const idUrl = params.id
    const reasonId = Number(idUrl)

    const [sendData, setSendData] = useState<boolean>(false)

    //validation
    const formDataArray = ["organization_name", "email", "phone", "username",
        "password", "password2", "first_name", "last_name"]
    const [errorGeneral, setErrorGeneral] = useState<string | null>(null)
    const [errorOrganization, setErrorOrganization] = useState<string | null>(null)
    const [errorEmail, setErrorEmail] = useState<string | null>(null)
    const [errorPhone, setErrorPhone] = useState<string | null>(null)
    const [errorUsername, setErrorUsername] = useState<string | null>(null)
    const [errorPassword, setErrorPassword] = useState<string | null>(null)
    const [errorPassword2, setErrorPassword2] = useState<string | null>(null)
    const [errorFirstName, setErrorFirstName] = useState<string | null>(null)
    const [errorLastName, setErrorLastName] = useState<string | null>(null)

    const errorField = validation(errorState, formDataArray).testErrorField
    const errorFieldMessage = validation(errorState, formDataArray).testErrorFieldMessage

    const errorOrganizationField = errorField.filter(el => el === "organization_name")[0]
    const errorEmailField = errorField.filter(el => el === "email")[0]
    const errorPhoneField = errorField.filter(el => el === "phone")[0]
    const errorUsernameField = errorField.filter(el => el === "username")[0]
    const errorPasswordField = errorField.filter(el => el === "password")[0]
    const errorPassword2Field = errorField.filter(el => el === "password2")[0]
    const errorFirstNameField = errorField.filter(el => el === "first_name")[0]
    const errorLastNameField = errorField.filter(el => el === "last_name")[0]

    const errorOrganizationMessage = errorFieldMessage.filter(el => el[0] === "organization_name")[0]
    const errorEmailMessage = errorFieldMessage.filter(el => el[0] === "email")[0]
    const errorPhoneMessage = errorFieldMessage.filter(el => el[0] === "phone")[0]
    const errorUsernameMessage = errorFieldMessage.filter(el => el[0] === "username")[0]
    const errorPasswordMessage = errorFieldMessage.filter(el => el[0] === "password")[0]
    const errorPassword2Message = errorFieldMessage.filter(el => el[0] === "password2")[0]
    const errorFirstNameMessage = errorFieldMessage.filter(el => el[0] === "first_name")[0]
    const errorLastNameMessage = errorFieldMessage.filter(el => el[0] === "last_name")[0]

    const onClickOrganizationHandler = () => setErrorOrganization(null)
    const onClickEmailHandler = () => setErrorEmail(null)
    const onClickPhoneHandler = () => setErrorPhone(null)
    const onClickUsernameHandler = () => setErrorUsername(null)
    const onClickPasswordHandler = () => setErrorPassword(null)
    const onClickPassword2Handler = () => setErrorPassword2(null)
    const onClickFirstNameHandler = () => setErrorFirstName(null)
    const onClickLastNameHandler = () => setErrorLastName(null)

    const reasonData = {
        organization_name: fields.organization_name,
        email: fields.email,
        phone: fields.phone,
        username: fields.username,
        password: fields.password,
        password2: fields.password2,
        first_name: fields.first_name,
        last_name: fields.last_name,
    }

    useEffect(() => {
        if (validation(errorState, formDataArray).testErrorField.length === 0) {
            if (typeof errorState === "object") {
                setErrorGeneral(errorState && Object.values(errorState)[0])
            } else {
                setErrorGeneral(errorState)
            }
        }
        return () => {
            setErrorGeneral(null)
        };
    }, [status])

    useEffect(() => {
        if (reasonData) {
        }
    }, [fields])

    useEffect(() => {
        dispatch(fetchUserListFieldsTC())
    }, [dispatch])

    useEffect(() => {
        if (status === "failed") {
            setErrorOrganization(errorOrganizationField)
            setErrorEmail(errorEmailField)
            setErrorPhone(errorPhoneField)
            setErrorUsername(errorUsernameField)
            setErrorPassword(errorPasswordField)
            setErrorPassword2(errorPassword2Field)
            setErrorFirstName(errorFirstNameField)
            setErrorLastName(errorLastNameField)
        }
    }, [status])

    const {
        register,
        handleSubmit,
        control,
        reset,
    } = useForm<FormDataType>({
        mode: 'onBlur',
    });

    const onSubmit: SubmitHandler<FormDataType> = (data) => {
        const newData = {
            organization_name: data.organization_name,
            email: data.email,
            phone: data.phone,
            username: data.username,
            password: data.password,
            password2: data.password2,
            first_name: data.first_name,
            last_name: data.last_name,
        }
        dispatch(addUserTC(newData, reasonId))
        dispatch(setIsSendDataAC(true))
        setSendData(true)
    }

    if (sendData && status === "succeeded") {
        return <Navigate to={PATH.SUCCESS_PAGE_EMAIL} />
    }

    return (
        <>
            <div className="register">
                <NavLink to={PATH.LOGIN} className="img-wrapper">
                    <img src={logo.termiLogo} className="img" alt="termiNow" />
                </NavLink>
                <div className="add-register" onClick={() => {
                    setErrorGeneral(null)
                }}>
                    <h1 className="add-register__title">{"Anmelden"}</h1>
                    <form onSubmit={handleSubmit(onSubmit)} className="add-register__form">
                        <label className="add-register__inputs-label">Info</label>
                        <div className="add-register__inputs-info">
                            <Inputs
                                error={errorOrganization}
                                help_text={fields.organization_name.help_text || errorOrganization && errorOrganizationMessage[1]}
                                onClick={onClickOrganizationHandler}
                                state={"active"}
                                register={register}
                                input_type={fields.organization_name.input_type}
                                name={"organization_name"}
                                label={fields.organization_name.label}
                                defaultValue={fields.organization_name.initial_value}
                                resetForm={reset}
                                {...reset}
                            />
                            <div
                                className="input">
                                <Inputs
                                    error={errorUsername}
                                    help_text={fields.username.help_text || errorUsername && errorUsernameMessage[1]}
                                    onClick={onClickUsernameHandler}
                                    state={"active"}
                                    register={register}
                                    input_type={"text"}
                                    name={"username"}
                                    label={fields.username.label}
                                    defaultValue={fields.username.initial_value}
                                    resetForm={reset}
                                    {...reset}
                                />
                            </div>
                        </div>
                        <label className="add-register__inputs-label">User</label>
                        <div className="add-register__inputs-setting">
                            <Inputs
                                error={errorFirstName}
                                help_text={fields.first_name.help_text || errorFirstName && errorFirstNameMessage[1]}
                                onClick={onClickFirstNameHandler}
                                state={"active"}
                                register={register}
                                input_type={"text"}
                                name={"first_name"}
                                label={fields.first_name.label}
                                defaultValue={fields.first_name.initial_value}
                                resetForm={reset}
                                {...reset}
                            />
                            <Inputs
                                error={errorLastName}
                                help_text={fields.last_name.help_text || errorLastName && errorLastNameMessage[1]}
                                onClick={onClickLastNameHandler}
                                state={"active"}
                                register={register}
                                input_type={"text"}
                                name={"last_name"}
                                label={fields.last_name.label}
                                defaultValue={fields.last_name.initial_value}
                                resetForm={reset}
                                {...reset}
                            />
                            <Inputs
                                error={errorEmail}
                                help_text={fields.email.help_text || errorEmail && errorEmailMessage[1]}
                                onClick={onClickEmailHandler}
                                state={"active"}
                                register={register}
                                input_type='email'
                                name={"email"}
                                label={fields.email.label}
                                defaultValue={fields.email.initial_value}
                                resetForm={reset}
                                {...reset}
                            />
                            <Inputs
                                error={errorPhone}
                                help_text={fields.phone.help_text || errorPhone && errorPhoneMessage[1]}
                                onClick={onClickPhoneHandler}
                                state={"active"}
                                register={register}
                                name={"phone"}
                                label={fields.phone.label}
                                defaultValue={fields.phone.initial_value}
                                resetForm={reset}
                                {...reset}
                            />
                        </div>
                        <label className="add-register__inputs-label">Password</label>
                        <div className="add-register__inputs-setting1">
                            <Inputs
                                error={errorPassword}
                                help_text={fields.password.help_text || errorPassword && errorPasswordMessage[1]}
                                onClick={onClickPasswordHandler}
                                state={"active"}
                                register={register}
                                input_type='password'
                                name={"password"}
                                label={fields.password.label}
                                defaultValue={fields.password.initial_value}
                                resetForm={reset}
                                {...reset}
                            />
                            <Inputs
                                error={errorPassword2}
                                help_text={fields.password2.help_text || errorPassword2 && errorPassword2Message[1]}
                                onClick={onClickPassword2Handler}
                                state={"active"}
                                input_type='password'
                                register={register}
                                name={"password2"}
                                label={fields.password2.label}
                                defaultValue={fields.password2.initial_value}
                                resetForm={reset}
                                {...reset}
                            />
                        </div>
                        <div className="_button-wrapper">
                            <button
                                className='button button_size-middle button_position-right button_color-green'>Signup
                            </button>
                        </div>
                        <div className="btn" style={{
                            width: '740px',
                            marginRight: '20px'
                        }}>
                            <NavLink
                                to={`${PATH.LOGIN}`}
                                className="button button_size-middle button_position-right  button_color-transparent"
                                type="submit">Login
                            </NavLink>
                        </div>

                        {
                            (status === 'failed' && errorGeneral) &&
                            <p className="general-error">{errorGeneral}</p>
                        }
                    </form>
                </div>
            </div>
        </>
    )
}
