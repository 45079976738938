import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
})

export const contactsAPI = {
    getList(token: string) {
        return instance.get<Array<ContactsType>>('/api/contacts/', {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    addContact(data: any, token: string) {
        return instance.post<ContactsType>('/api/contacts/create/', data, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    editContact(data: any, token: string, contactId: number) {
        return instance.put<ContactsType>(`/api/contacts/${contactId}/detail/`, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    removeContact(token: string, contactId: number) {
        return instance.delete<any>(`/api/contacts/${contactId}/detail/`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },
}

//types
export type ContactsType = {
    id: number
    branches: any
    title: string
    email: string
    phone: number
    address: string
}
