import { Dispatch } from "redux";
import { loadToken } from "../utils/localStorage";
import { BranchType, scheduleWorkApi } from "../api/scheduleWork-api";
import { handleServerAppError, handleServerNetworkError } from "../utils/error-utils";
import { setAppStatusAC } from "./app-reducer";


const SET_SCHEDULE_LIST = 'scheduleWorkReducer/SET_SCHEDULE_LIST'
const EDIT_SCHEDULE_WORK = 'scheduleWorkReducer/EDIT_SCHEDULE_WORK'

const initialState: Array<BranchType> = []

export const scheduleWorkReducer = (state: Array<BranchType> = initialState, action: ActionsType): Array<BranchType> => {
    switch (action.type) {
        case SET_SCHEDULE_LIST: {
            return action.schedule.map((tl) => ({ ...tl }))
        }
        case EDIT_SCHEDULE_WORK: {
            return state.map(e => e.id === action.model.id ? { ...e, ...action.model } : e)
        }
        default:
            return state
    }
}

// actions
export const setScheduleListAC = (schedule: Array<BranchType>) =>({ type: SET_SCHEDULE_LIST, schedule } as const)
export const editScheduleWorkAC = (model: UpdateScheduleWorkModelType) =>({ type: EDIT_SCHEDULE_WORK, model } as const)

// thunks
export const fetchScheduleWork = () => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        const res = await scheduleWorkApi.getList(tokenLS)
        dispatch(setScheduleListAC(res.data))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

export const editScheduleWorkTC = (id: number, data: any) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"))
    try {
        const tokenLS = await loadToken()
        const res = await scheduleWorkApi.editScheduleWork(data, tokenLS, id)
        dispatch(editScheduleWorkAC(res.data))
        dispatch(setAppStatusAC("succeeded"))
    } catch (error: any) {
        if (error.message === "Network Error") {
            handleServerNetworkError(error, dispatch)
        } else {
            handleServerAppError(error, dispatch)
        }
    }
    dispatch(setAppStatusAC("idle"))
}

//types
type ActionsType = ReturnType<typeof setScheduleListAC>
    | ReturnType<typeof editScheduleWorkAC>

export type UpdateScheduleWorkModelType = {
    id?: number
    name?: string
    day_of_week?: string
    start_time_work?: string
    end_time_work?: string
    start_lunch_break?: string
    end_lunch_break?: string
    is_weekend?: boolean
    lunch_break?: boolean
} 