import React, { FC, useState } from "react";
import { motion } from "framer-motion";
import './ModalAlertDeleteEmployee.scss';


type ModalAlertPropsType = {
    deleteCallback: (id: number) => void
    id: number
    cancelCallback: () => void
    titleQuestion: string
    actionTitle: string
}

export const ModalAlertDelete: FC<ModalAlertPropsType> = ({
    deleteCallback,
    id,
    cancelCallback,
    titleQuestion,
    actionTitle,
}) => {
    const onDeleteHandler = () => {
        deleteCallback(id)
        cancelCallback()
    }
    const onCancelHandler = () => {
        cancelCallback()
    }

    return (
        <motion.div /* initial={{
            //opacity:0
        }}
        animate={{
            //opacity:1
        }} */
        className={/* active? "delete-window active" : */"delete-window "} onClick={onCancelHandler}>
            <motion.div /* initial={{
                scale:0
            }}
            animate={{
                scale:1
                
            }} 
            transition={{
                duration:0.3
            }} */
             className="delete-window__alert" onClick={e => e.stopPropagation()}>
                <motion.h2 
                animate={{
                    x:0
                }}
                className="title">{titleQuestion}</motion.h2>
                <motion.div className="btn-wrapper">
                        <button  onClick={onDeleteHandler}
                            className="button button_size-middle button_position-left button_color-delete">
                            {actionTitle}
                        </button>
                    <button 
                     onClick={onCancelHandler}
                        className="button button_size-middle button_position-right button_color-transparent">
                        Cancel
                    </button>
                </motion.div>
            </motion.div>
        </motion.div>

    )
}

