import React, { useEffect, useState } from "react";
import './Branches.scss';
import { fetchBranchesList, removeBranchTC } from "../../redux/branches-reducer";
import { Navigate, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PATH } from "../Routes";
import { ModalAlertDelete } from "../../components/ModalsAlerts/ModalAlertDelete";
import { ProfileSideBar } from "../Profile/ProfileSideBar/ProfileSideBar";
import { initializeAppTC } from "../../redux/app-reducer";
import Preloader from "../Preloader/Preloader";
import { Iframe } from "./Iframe/Iframe";
import {
    getBranchesSelector,
    getIsLoggedInSelector,
    getStatusSelector
} from "../../redux/selectors";


export const Branches = () => {
    const dispatch = useDispatch();

    const branches = useSelector(getBranchesSelector);
    const isLoggedIn = useSelector(getIsLoggedInSelector);
    const status = useSelector(getStatusSelector);

    //dialog alerts
    const [openAlertDialogForDelete, setOpenAlertDialogForDelete] = useState(false);
    const [openAlertDialogForIframe, setOpenAlertDialogForIframe] = useState(false);
    const [modalDataId, setModalDataId] = useState<number>(0);

    useEffect(() => {
        if (status === "failed") {
            dispatch(initializeAppTC())
        }
    }, [status])

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchBranchesList())
        }
    }, [dispatch])

    const removeBranch = (id: number) => {
        dispatch(removeBranchTC(id))
        setModalDataId(0)
    }

    const openAlertHandler = (clientId: number) => {
        setModalDataId(clientId)
        setOpenAlertDialogForDelete(true)
    }

    const openAlertHandlerIframe = (clientId: number) => {
        setModalDataId(clientId)
        setOpenAlertDialogForIframe(true)
    }

    const closeAlertHandler = () => {
        setOpenAlertDialogForDelete(false)
    }

    const closeAlertHandlerIframe = () => {
        setOpenAlertDialogForIframe(false)
    }

    if (!isLoggedIn) {
        return <Navigate to={PATH.LOGIN} />
    }

    return (
        <>
            <ProfileSideBar />
            <div className="branches">
                <h1 className="branches__title">Branches list</h1>
                <div className="branches__add">
                    <NavLink
                        className="button button_size-small button_position-left button_color-transparent"
                        to={PATH.ADD_BRANCH}>+ add
                        branch</NavLink>
                </div>
                {status === 'loading' ? (
                    <Preloader />
                ) : (
                    <div>
                        <table className='branches__table'>
                            <thead className="thead">
                                <tr className="tr">
                                    <th className="th"><span>{'Vorname'}</span></th>
                                    <th className="th"><span>{'Adresse'}</span></th>
                                    <th className="th"><span>{'Optionen'}</span></th>
                                </tr>
                            </thead>
                            <tbody className="tbody">
                                {branches.map((branch, index) => {
                                    return <tr key={branch.id} className="tr">
                                        <td className="td">{branch.name} </td>
                                        <td className="td"> {branch.street} {branch.street_number}, {branch.zip_code} {branch.sity}  </td>
                                        <td className="td">
                                            <NavLink
                                                className="button button_size-small  button_color-green"
                                                to={`${PATH.BRANCHES}${branch.id}/`}>
                                                Edit
                                            </NavLink>
                                            <button onClick={() => openAlertHandler(branch.id)}
                                                className="button button_size-small  button_color-delete"
                                            >Delete
                                            </button>
                                            <div
                                                onClick={() => { openAlertHandlerIframe(branch.id) }}
                                                className="button button_size-small  button_color-transparent"
                                            >
                                                Iframe
                                            </div>
                                        </td>
                                    </tr>
                                })
                                }
                                {openAlertDialogForDelete &&
                                    <>
                                        <ModalAlertDelete deleteCallback={removeBranch}
                                            id={modalDataId}
                                            titleQuestion="Delete branch?"
                                            actionTitle="Delete"
                                            cancelCallback={closeAlertHandler} />
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                )
                }

                {
                    openAlertDialogForIframe && <div
                        onClick={closeAlertHandlerIframe}
                        className="branches__wrapper-iframe">
                        <Iframe
                            cancelCallback={closeAlertHandlerIframe}
                            branchId={modalDataId} />
                    </div>
                }
            </div>
        </>
    )
}
