import React, {FC} from "react";
import '../Main.scss'
import {logo} from "../../../assets/img/image";
import {NavLink, useLocation} from "react-router-dom";
import {PATH} from "../../Routes";
import {EventsFilter} from "../../Events/EventsFilter/EventsFilter";
import {ClientsFilter} from "../../Clients/ClientsFilter/ClientsFilter";
import {ScheduleSideBar} from "../../Schedule/ScheduleSideBar/ScheduleSideBar";
import {WaitingRoomFilter} from "../../WaitingRoom/WaitingRoomFilter/WaitingRoomFilter";

type SideBarPropsType = {}

export const SideBar: FC<SideBarPropsType> = () => {
    const location = useLocation();

    return (
        <aside className="admin__left">
            <NavLink to={PATH.SCHEDULE} className="admin__left-logo">
                <img className="img" src={logo.termiLogo}
                     alt="termiNow"/>
            </NavLink>

            {
                (location.pathname === PATH.SCHEDULE
                    || location.pathname === PATH.HOLIDAYS) &&
                <ScheduleSideBar/>
            }

            {
                location.pathname === PATH.EVENTS &&
                <EventsFilter/>
            }

            {
                location.pathname === PATH.WAITING_ROOM &&
                <WaitingRoomFilter/>
            }

            {
                location.pathname === PATH.CLIENTS &&
                <ClientsFilter/>
            }
        </aside>
    )
}