import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
})

export const orderAPI = {
    getOrderList(token: string) {
        return instance.get<Array<OrderType>>('/api/implant-order/', {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    addOrder(data: any, token: string) {
        return instance.post<OrderType>('/api/implant-order/create/', data, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },

    editOrder(data: any, token: string, orderId: number) {
        return instance.put<OrderType>(`/api/implant-order/${orderId}/`, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
    },
}

//types
export type OrderType = {
    id: number,
    owner_display: string,
    client_display: string,
    insurance_type_display: string,
    material_display: string,
    insurance_type: string,
    material: string,
    tooth_color: null,
    comment: null,
    TP18: null,
    TP17: null,
    TP16: null,
    TP15: null,
    TP14: null,
    TP13: null,
    TP12: null,
    TP11: null,

    TP21: null,
    TP22: null,
    TP23: null,
    TP24: null,
    TP25: null,
    TP26: null,
    TP27: null,
    TP28: null,

    TP48: null,
    TP47: null,
    TP46: null,
    TP45: null,
    TP44: null,
    TP43: null,
    TP42: null,
    TP41: null,
    TP31: null,
    TP32: null,
    TP33: null,
    TP34: null,
    TP35: null,
    TP36: null,
    TP37: null,
    TP38: null,


    R18: null,
    R17: null,
    R16: null,
    R15: null,
    R14: null,
    R13: null,
    R12: null,
    R11: null,

    R21: null,
    R22: null,
    R23: null,
    R24: null,
    R25: null,
    R26: null,
    R27: null,
    R28: null,

    R48: null,
    R47: null,
    R46: null,
    R45: null,
    R44: null,
    R43: null,
    R42: null,
    R41: null,
    R31: null,
    R32: null,
    R33: null,
    R34: null,
    R35: null,
    R36: null,
    R37: null,
    R38: null,

    B18: null,
    B17: null,
    B16: null,
    B15: null,
    B14: null,
    B13: null,
    B12: null,
    B11: null,

    B21: null,
    B22: null,
    B23: null,
    B24: null,
    B25: null,
    B26: null,
    B27: null,
    B28: null,
    B48: null,
    B47: null,
    B46: null,
    B45: null,
    B44: null,
    B43: null,
    B42: null,
    B41: null,
    B31: null,
    B32: null,
    B33: null,
    B34: null,
    B35: null,
    B36: null,
    B37: null,
    B38: null,
    owner: null,
    client: number
}