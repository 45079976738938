import "./InputsWidgets.scss"
import React, {MouseEventHandler} from "react";
import {
    ChangeEvent,
    DetailedHTMLProps,
    FC,
    InputHTMLAttributes,
    useState
} from "react";
import { Path } from "react-hook-form";
import { FormDataType } from "../../pages/Login/Login";
import { logo } from "../../assets/img/image";


type DefaultInputPropsType = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

type InputsNewTypeW = DefaultInputPropsType & {
    onChangeText?: (value: string) => void
    onInputClick?:()=>void
    onEnter?: () => void
    order?: string
    error?: string | null
    spanClassName?: string
    maxLength?: any
    label?: any
    register?: any  //UseFormRegister<FormDataType>;
    resetForm?: () => void
    state?: string
    name?: Path<FormDataType> | string
    help_text?: string | null
    img?: string
    input_type?: string
    defaultValue?: any
    value?: string
};


const InputsWidgets: FC<InputsNewTypeW> = ({
    type, step,
    onChange, onChangeText,
    onKeyPress, onEnter,onInputClick,
    error, order, maxLength,
    className, spanClassName,
    name, state, img,
    input_type, defaultValue,
    help_text,
    value,
    label, register, resetForm, onClick,
    ...restProps
}) => {



    const [focused, setFocus] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [btn, setBtn] = useState(false);
    const [classs, setClass] = useState('');
    // const [value, setValue] = useState(defaultValue || '');
    const [labels, deleteLabel] = useState(true)



    const onInput = (event: any) => {
        setBtn(true);
    }

    const onFocusHandler = (e: ChangeEvent<HTMLInputElement>) => {

        setFocus(true);
        setClass(e.currentTarget.value)
    }

    const onClickHandler = (e: any) => {
      
        onInputClick && onInputClick()
        // deleteLabel(false)
    }

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {

        onChange && onChange(e)
        onChangeText && onChangeText(e.currentTarget.value)
        // setValue(e.target.value)
    }

    const onButtonClick = () => {
        resetForm && resetForm()
    }


    const customInputClass1 = ` 
      fieldW_${img} 
      ${focused ? "fieldW_focus" : "fieldW_unfocus"} 
      ${state === 'order' ? `order fieldW_${state}` : ` fieldW fieldW_${state}  `}
      ${error ? "fieldW_error " : `fieldW_datePicker`}
      ${value?.length ? "fieldW_focus" : "fieldW_unfocus"}
      ${defaultValue ? "fieldW_focus" : "fieldW_unfocus"}
 `


    return (
        <div
            className={customInputClass1}>
            <input className="fieldW__input"
                defaultValue={defaultValue}
                name={name}
            
               
                // {...register && {...register(name)}}
                onChange={onChangeHandler}
                maxLength={maxLength}
                id={`id_${name}`}
                onFocus={onFocusHandler}
                onClick={onClickHandler}
                type={input_type}
                disabled={state === "disabled"}
                onInput={onInput}
                value={value}
                step={step}
                {...restProps} />


            <label className={labels ? "fieldW__label" : 'fieldW__labels'}
                htmlFor={`id_${name}`}>
                {label}
            </label>
            <span className="fieldW__help-text">{help_text}</span>
            {/*  <img
                    onClick={() => {



                        


                    }}
                    src={logo.calendar}
                    alt='time'
                    className={state === "datePicker" ? 'field__img' : 'field__imgNone'}
                />  */}

        </div>

    );
}

export default InputsWidgets;
