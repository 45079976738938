import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
})

export const registerAPI = {
    addUser(data: any, token: string) {
        return instance.post<any>('/api/register/', data)
    },

    getVerivicationOfEmail(user_id: number, token: string) {
        return instance.get(`/api/email-activate/?user_id=${user_id}&confirmation_token=${token}`)
    }
}

//types
export type RegisterType = {
    id: number
    organization_name: string
    email: string
    phone: number
    username: string
    password: string
    password2: string
    first_name: string
    last_name: string
}
