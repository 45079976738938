import React from "react";
import { Route, Routes } from "react-router-dom";
import { Login } from "./Login/Login";
import { Employees } from "./Employees/Employees";
import { Events } from "./Events/Events";
import { Schedule } from "./Schedule/Schedule";
import { WaitingRoom } from "./WaitingRoom/WaitingRoom";
import SuccessPage from "./Success/SuccessPage/SuccessPage";
import DatePage from "./Success/DatePage/DatePage";
import { Success } from "./Success/Success/Success";
import { SuccessWithImg } from "./Success/SuccessWithImg/SuccessWithImg";
import { Profile } from "./Profile/Profile";
import { Form } from "./Form/Form";
import { WorkSchedule } from "./WorkSchedule/WorkSchedule";
import { Clients } from "./Clients/Clients";
import { Branches } from "./Branches/Branches";
import { Reasons } from "./Reasons/Reasons";
import { FormsEmployee } from "./Employees/FormsEmployee/FormsEmployee";
import { FormsReason } from "./Reasons/FormsReason/FormsReason";
import { FormEvent } from "./Events/FormEvent/FormEvent";
import { FormClients } from "./Clients/FormClients/FormClients";
import OnlineRegistrationDate from "./OnlineRegistration/Step1/OnlineRegistrationDate";
import OnlineRegistrationData from "./OnlineRegistration/Step2/OnlineRegistrationData";
import { Holidays } from "./Holidays/Holidays";
import NotFound from "./NotFound/NotFound";
import { FormsBranch } from "./Branches/FormsBranch/FormsBranch";
import { FormsHoliday } from "./Holidays/FormsHoliday/FormsHoliday";
import { EventDetails } from "./Events/Details/EventDetails";
import { Contacts } from "./Contacts/Contacts";
import { FormsContacts } from "./Contacts/FormsContacts/FormsContacts";
import { Register } from "./Register/Register";
import { Activation } from "./Activation/Activation";
import { Orders } from "./Orders/Orders";
import { OrderList } from "./Orders/OrderList";



export const PATH = {
  LOGIN: "/login/",
  SIGN_UP: "/signup/",
  PROFILE: "/profile/",
  ERROR_404: "/error404/",
  EMPLOYEES: "/employees/",
  REASONS: "/reasons/",
  ADD_EMPLOYEE: "/employees/create/",
  ADD_REASON: "/reasons/create/",
  EDIT_EMPLOYEE: "/employees/:id/",
  EDIT_REASON: "/reasons/:id/",
  EVENTS: "/events/",
  EDIT_EVENT: "/events/:id/",
  EVENT_DETAILS: "/events/details/:id/",
  ADD_EVENT: "/events/create",
  SCHEDULE: "/",
  WORK_SCHEDULE: "/work-schedule/",
  WAITING_ROOM: "/waiting-room/",
  CLIENTS: "/clients/",
  ADD_CLIENT: "/clients/create/",
  EDIT_CLIENT: "/clients/:id/",
  BRANCHES: "/branches/",
  ADD_BRANCH: "/branches/create/",
  EDIT_BRANCH: "/branches/:id/",
  HOLIDAYS: "/holidays/",
  ADD_HOLIDAY: "/holidays/create/",
  EDIT_HOLIDAY: "/holidays/:id/",
  SUCCESS_PAGE: "/success-page/",
  DATE: "/date/",
  SUCCESS: "/success/",
  SUCCESS_IMG: "/success-img/",
  FORM: "/form/",
  ONLINE_REGISTRATION: "/step-1/",
  ONLINE_REGISTRATION_DATE: "/step-1/:id/",//
  ONLINE_REGISTRATION_DATA: "/step-2/",//
  CONTACTS: "/contacts/",
  ADD_CONTACTS: "/contacts/create/",
  EDIT_CONTACTS: "/contacts/:id/detail/",
  REGISTER: "/register/",
  ACTIVATION: '/activation/:user_id/:confirmation_token',
  SUCCESS_PAGE_EMAIL: '/success-email/',
  SUCCESS_PAGE_ERROR: '/success-error/',
  SUCCESS_PAGE_CREATE: '/success-create/',
  ORDERS: '/orders/',
  ADD_ORDER: '/orders/create/',
  EDIT_ORDER: '/orders/:id/',
};

export const RoutesComponent = () => {
  return (
    <>
      <Routes>
        <Route path={PATH.PROFILE} element={<Profile />} />
        <Route path={PATH.EMPLOYEES} element={<Employees />} />
        <Route path={PATH.REASONS} element={<Reasons />} />
        <Route path={PATH.ADD_EMPLOYEE} element={<FormsEmployee />} />
        <Route path={PATH.ADD_REASON} element={<FormsReason />} />
        <Route path={PATH.EDIT_EMPLOYEE} element={<FormsEmployee />} />
        <Route path={PATH.EDIT_REASON} element={<FormsReason />} />
        <Route path={PATH.EVENTS} element={<Events />} />
        <Route path={PATH.ADD_EVENT} element={<FormEvent />} />
        <Route path={PATH.EDIT_EVENT} element={<FormEvent />} />
        <Route path={PATH.EVENT_DETAILS} element={<EventDetails />} />
        <Route path={PATH.SCHEDULE} element={<Schedule />} />
        <Route path={PATH.WORK_SCHEDULE} element={<WorkSchedule />} />
        <Route path={PATH.WAITING_ROOM} element={<WaitingRoom />} />
        <Route path={PATH.CLIENTS} element={<Clients />} />
        <Route path={PATH.ADD_CLIENT} element={<FormClients />} />
        <Route path={PATH.EDIT_CLIENT} element={<FormClients />} />
        <Route path={PATH.ADD_BRANCH} element={<FormsBranch />} />
        <Route path={PATH.EDIT_BRANCH} element={<FormsBranch />} />
        <Route path={PATH.BRANCHES} element={<Branches />} />
        <Route path={PATH.HOLIDAYS} element={<Holidays />} />
        <Route path={PATH.DATE} element={<DatePage />} />
        <Route path={PATH.SUCCESS} element={<Success />} />
        <Route path={PATH.SUCCESS_IMG} element={<SuccessWithImg />} />
        <Route path={PATH.FORM} element={<Form />} />
        <Route path={PATH.ADD_HOLIDAY} element={<FormsHoliday />} />
        <Route path={PATH.EDIT_HOLIDAY} element={<FormsHoliday />} />
        <Route path={PATH.CONTACTS} element={<Contacts />} />
        <Route path={PATH.ADD_CONTACTS} element={<FormsContacts />} />
        <Route path={PATH.EDIT_CONTACTS} element={<FormsContacts />} />
        <Route path={PATH.ACTIVATION} element={<Activation />} />
        <Route path={PATH.ADD_ORDER} element={<Orders />} />
        <Route path={PATH.EDIT_ORDER} element={<Orders />} />
        <Route path={PATH.ORDERS} element={<OrderList />} />


        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Schedule />} />
      </Routes>
    </>
  );
};
