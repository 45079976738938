import React, { FC, useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { customStyles, customStylesError, customStylesMulti } from "./ReactSelectStyles";

type ReactSelectType = {
    error?: string | null
    help_text?: string | null
    label?: string
    placeholder?: string
    control: any
    onClick?: () => void
    isMulti: boolean
    options: any
    name: string
    defaultValue?: any
}

export const ReactSelect: FC<ReactSelectType> = (
    {
        control, onClick, error, placeholder, defaultValue,
        isMulti, options, help_text, label, name
    }) => {

    const [focusInput, setFocusInput] = useState<boolean>(false)

    const changeLabelStatusOn = () => {
        setFocusInput(true)
    }
    const changeLabelStatusOff = () => {
        setFocusInput(false)
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
            {/*<label className="add-employee__inputs-label">{label}</label>*/}
            <div onClick={onClick}>
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => <Select
                        {...field}
                        name={field.name}
                        styles={error ? customStylesError : isMulti ? customStylesMulti : customStyles}
                        placeholder={isMulti ? placeholder : ''}
                        isMulti={isMulti}
                        onFocus={changeLabelStatusOn}
                        onBlur={field.value ? changeLabelStatusOn : changeLabelStatusOff}
                        options={options}
                    />}
                />

                {!isMulti &&
                    <span style={{
                        position: "relative",
                        top: `${focusInput || defaultValue ? "-60px" : "-45px"}`,
                        left: "18px",
                        width: "1%",
                        color: `${error ? "#c30052" : "#737484"}`,
                        fontSize: `${focusInput || defaultValue ? "14px" : "16px"}`,
                        margin: "8px",
                        letterSpacing: `${focusInput || defaultValue ? "0.4px" : "0.75px"}`,
                        transition: "0s",
                    }}>
                        {placeholder}
                    </span>
                }
            </div>

            {!isMulti &&
                <div style={{ width: "50%", }}>
                    <p style={{
                        color: "#c30052",
                        // marginTop: "3px",
                        marginTop: "-20px",
                        fontSize: "14px",
                        letterSpacing: "0.25px"
                    }}>
                        {error && help_text}
                    </p>
                </div>
            }

            {isMulti&&
              <div style={{ width: "50%", }}>
              <p style={{
                  color: "#c30052",
                   marginTop: "3px",
                 // marginTop: "-20px",
                  fontSize: "14px",
                  letterSpacing: "0.25px"
              }}>
                  {error && help_text}
              </p>
          </div>
            }


        </div>
    )
};
