import {saveProfileDataLS, saveToken} from "./localStorage";
import {editStateMenuAC, setAppIsInitializedAC} from "../redux/app-reducer";
import {resetReducerGroupAC, setIsLoggedInAC} from "../redux/auth-reducer";
import {useDispatch} from "react-redux";

export const useLogout = () => {
    const dispatch = useDispatch()

    return () => {
        dispatch(editStateMenuAC(false))
        saveToken('')
        saveProfileDataLS('')
        dispatch(setAppIsInitializedAC(false))
        dispatch(setIsLoggedInAC(false))
        dispatch(resetReducerGroupAC())
    }
}