import React, { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import "./FormsBranch.scss";
import "../../../components/Widgets/Button/Button.scss";
import {
    addBranchTC,
    editBranchTC,
    fetchBranchesList
} from "../../../redux/branches-reducer";
import { useDispatch, useSelector } from "react-redux";
import { AppRootStateType } from "../../../redux/store";
import { Navigate, useParams } from "react-router-dom";
import { PATH } from "../../Routes";
import Inputs from "../../../components/Widgets/Inputs";
import { BranchesType } from "../../../api/branches-api";
import { validation } from "../../../utils/validation";
import { RequestStatusType } from "../../../redux/app-reducer";
import { fetchEmployeesListTC } from "../../../redux/employees-reducer";
import { fetchBranchListFieldsTC } from "../../../redux/fields-reducer";
import { BranchesListFieldsType } from "../../../api/fields-api";
import {
    defaultChoicesMultiSelect,
    defaultChoicesSelect,
    newOption
} from "../../../components/Widgets/Select/ReactSelectStyles";
import Textarea from "../../../components/Widgets/Textarea";
import CheckboxInput from "../../../components/Widgets/CheckboxInput";
import { ProfileSideBar } from "../../Profile/ProfileSideBar/ProfileSideBar";
import { ReactSelect } from "../../../components/Widgets/Select/ReactSelect";
import { useErrorHandler } from "../../../utils/useErrorHandler";
import Preloader from "../../Preloader/Preloader";
import {
    getBranchesSelector,
    getErrorStateSelector, getFieldsBranchesSelector, getFieldsEventsSelector,
    getIsLoggedInSelector,
    getStatusSelector
} from "../../../redux/selectors";


type FormDataType = {
    name: string
    phone: string
    email: string
    sity: string
    street: string
    street_number: string
    zip_code: string
    employees: { value: any, label: any } | []
    email_cancel_text: string
    sms_cancel_text: string
    email_order_text: string
    email_remind_text: string
    free_date: boolean
    show_title: boolean
    sms_order_text: string
    sms_remind_text: string
    whatsapp: string
    concurrent_records: { value: any, label: any }
    desired_date: boolean
}

export const FormsBranch = () => {
    const dispatch = useDispatch()

    const errorState = useSelector(getErrorStateSelector);
    const isLoggedIn = useSelector(getIsLoggedInSelector);
    const status = useSelector(getStatusSelector);
    const branches = useSelector(getBranchesSelector);
    const fields = useSelector(getFieldsBranchesSelector);

    const [valueBranch, setValueBranch] = useState<boolean>(false)

    //for edit branch
    const params = useParams();
    const idUrl = params.id
    const branchId = Number(idUrl)
    const currentBranch = branches.length && branches.filter(br => br.id === branchId)[0]

    //validation
    const formDataArray = ["name", "email", "phone", "sity", "street", "street_number",
        "zip_code", "employees", "email_cancel_text", "sms_cancel_text",
        "email_order_text", "email_remind_text", "free_date", "show_title",
        "sms_order_text", "sms_remind_text", "whatsapp",
        "concurrent_records", "desired_date"]

    const [errorGeneral, setErrorGeneral] = useState<string | null>(null)

    const nameErrorHandler = useErrorHandler("name", errorState, formDataArray)
    const emailErrorHandler = useErrorHandler("email", errorState, formDataArray)
    const phoneErrorHandler = useErrorHandler("phone", errorState, formDataArray)
    const sityErrorHandler = useErrorHandler("sity", errorState, formDataArray)
    const streetErrorHandler = useErrorHandler("street", errorState, formDataArray)
    const streetNumberErrorHandler = useErrorHandler("street_number", errorState, formDataArray)
    const zipCodeErrorHandler = useErrorHandler("zip_code", errorState, formDataArray)
    const employeesErrorHandler = useErrorHandler("employees", errorState, formDataArray)
    const emailCancelTextErrorHandler = useErrorHandler("email_cancel_text", errorState, formDataArray)
    const smsCancelTextErrorHandler = useErrorHandler("sms_cancel_text", errorState, formDataArray)
    const emailOrderTextErrorHandler = useErrorHandler("email_order_text", errorState, formDataArray)
    const emailRemindTextErrorHandler = useErrorHandler("email_remind_text", errorState, formDataArray)
    const freeDateErrorHandler = useErrorHandler("free_date", errorState, formDataArray)
    const showTitleErrorHandler = useErrorHandler("show_title", errorState, formDataArray)
    const smsOrderTextErrorHandler = useErrorHandler("sms_order_text", errorState, formDataArray)
    const smsRemindTextErrorHandler = useErrorHandler("sms_remind_text", errorState, formDataArray)
    const whatsappErrorHandler = useErrorHandler("whatsapp", errorState, formDataArray)
    const concurrentRecordsErrorHandler = useErrorHandler("concurrent_records", errorState, formDataArray)
    const desiredDateRecordsErrorHandler = useErrorHandler("desired_date", errorState, formDataArray)

    // for select
    const choicesEmployee = Object.entries(fields.employees.choices).map(br => br)
    const valueOptionEmployee = fields && Object.entries(fields.employees.choices).map((b: any) => newOption(b[0], b[1]))
    const labelOptionEmployee = currentBranch && currentBranch.employees.map((el: any) => el.toString())

    const choicesConcurrentRecords = Object.entries(fields.concurrent_records.choices).map(br => br)
    const valueOptionConcurrentRecords = fields && Object.entries(fields.concurrent_records.choices).map((b: any) => newOption(b[0], b[1]))
    const labelOptionConcurrentRecords = currentBranch && currentBranch.concurrent_records.toString()

    const branchData = currentBranch && {
        name: currentBranch.name,
        phone: currentBranch.phone,
        email: currentBranch.email,
        sity: currentBranch.sity,
        street: currentBranch.street,
        street_number: currentBranch.street_number,
        zip_code: currentBranch.zip_code,
        employees: currentBranch.employees[0] &&
            defaultChoicesMultiSelect(valueOptionEmployee, labelOptionEmployee),
        email_cancel_text: currentBranch.email_cancel_text,
        sms_cancel_text: currentBranch.sms_cancel_text,
        email_order_text: currentBranch.email_order_text,
        email_remind_text: currentBranch.email_remind_text,
        free_date: currentBranch.free_date,
        show_title: currentBranch.show_title,
        sms_order_text: currentBranch.sms_order_text,
        sms_remind_text: currentBranch.sms_remind_text,
        whatsapp: currentBranch.whatsapp,
        concurrent_records: currentBranch.concurrent_records &&
            defaultChoicesSelect(valueOptionConcurrentRecords, labelOptionConcurrentRecords)[0],
        desired_date: currentBranch.desired_date
    }

    useEffect(() => {
        if (validation(errorState, formDataArray).testErrorField.length === 0) {
            if (typeof errorState === "object") {
                setErrorGeneral(errorState && Object.values(errorState)[0])
            } else {
                setErrorGeneral(errorState)
            }
        }
        return () => {
            setErrorGeneral(null)
        };
    }, [status])

    useEffect(() => {
        if (branchData) {
            reset(branchData)
        }
    }, [currentBranch])

    useEffect(() => {
        dispatch(fetchEmployeesListTC())
        dispatch(fetchBranchListFieldsTC())
        dispatch(fetchBranchesList())
    }, [dispatch])

    useEffect(() => {
        if (status === "failed") {
            nameErrorHandler.setErrorCallback()
            emailErrorHandler.setErrorCallback()
            phoneErrorHandler.setErrorCallback()
            sityErrorHandler.setErrorCallback()
            streetErrorHandler.setErrorCallback()
            streetNumberErrorHandler.setErrorCallback()
            zipCodeErrorHandler.setErrorCallback()
            employeesErrorHandler.setErrorCallback()
            emailCancelTextErrorHandler.setErrorCallback()
            smsCancelTextErrorHandler.setErrorCallback()
            emailOrderTextErrorHandler.setErrorCallback()
            emailRemindTextErrorHandler.setErrorCallback()
            freeDateErrorHandler.setErrorCallback()
            showTitleErrorHandler.setErrorCallback()
            smsOrderTextErrorHandler.setErrorCallback()
            smsRemindTextErrorHandler.setErrorCallback()
            whatsappErrorHandler.setErrorCallback()
            concurrentRecordsErrorHandler.setErrorCallback()
            desiredDateRecordsErrorHandler.setErrorCallback()

        }
    }, [status])

    const {
        register,
        handleSubmit,
        control,
        reset,
    } = useForm<FormDataType>({
        mode: 'onBlur',
        defaultValues: useMemo(() => {
            if (branchData) {
                return branchData
            }
        }, [branchData])
    });
    const onSubmit: SubmitHandler<FormDataType> = data => {
        const newData = {
            employees: data.employees && Object.values(data.employees).map((br: any) => br.value),
            name: data.name,
            phone: data.phone,
            email: data.email,
            sity: data.sity,
            street: data.street,
            street_number: data.street_number,
            zip_code: data.zip_code,
            email_cancel_text: data.email_cancel_text,
            sms_cancel_text: data.sms_cancel_text,
            email_order_text: data.email_order_text,
            email_remind_text: data.email_remind_text,
            free_date: data.free_date,
            show_title: data.show_title,
            sms_order_text: data.sms_order_text,
            sms_remind_text: data.sms_remind_text,
            whatsapp: data.whatsapp,
            concurrent_records: data.concurrent_records && data.concurrent_records.value,
            desired_date: data.desired_date
        }

        let emp = data.employees = []
        const newDataadd = {
            employees: emp,
            name: data.name,
            phone: data.phone,
            email: data.email,
            sity: data.sity,
            street: data.street,
            street_number: data.street_number,
            zip_code: data.zip_code,
            email_cancel_text: data.email_cancel_text,
            sms_cancel_text: data.sms_cancel_text,
            email_order_text: data.email_order_text,
            email_remind_text: data.email_remind_text,
            free_date: data.free_date,
            show_title: data.show_title,
            sms_order_text: data.sms_order_text,
            sms_remind_text: data.sms_remind_text,
            whatsapp: data.whatsapp,
            concurrent_records: data.concurrent_records && data.concurrent_records.value,
            desired_date: data.desired_date
        }

        if (currentBranch) {
            dispatch(editBranchTC(branchId, newData))
        } else {
            dispatch(addBranchTC(newDataadd))
        }
        setValueBranch(true)
    }

    if (valueBranch && status === "succeeded") {
        return <Navigate to={PATH.BRANCHES} />
    }

    if (!isLoggedIn) {
        return <Navigate to={PATH.LOGIN} />;
    }

    return (
        <>
            <ProfileSideBar />
            <div className="add-branch" onClick={() => {
                setErrorGeneral(null)
            }}>
                <h1 className="add-branch__title">{currentBranch ? "Branch edit" : "Branch add"}</h1>
                {status === 'loading' ? (
                    <Preloader />
                ) : (
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-branch__form">
                            <label className="add-branch__inputs-label">Info</label>
                            <div className="add-branch__inputs-info">
                                <Inputs
                                    error={nameErrorHandler.error}
                                    help_text={fields.name.help_text || nameErrorHandler.error && nameErrorHandler.errorMessageCurrentField[1]}
                                    onClick={nameErrorHandler.onFieldClick}
                                    state={"active"}
                                    register={register}
                                    input_type={fields.name.input_type}
                                    name={"name"}
                                    label={fields.name.label}
                                    defaultValue={currentBranch ? currentBranch.name : fields.name.initial_value}
                                    resetForm={reset}
                                    {...reset}
                                />
                                <Inputs
                                    error={phoneErrorHandler.error}
                                    onClick={phoneErrorHandler.onFieldClick}
                                    help_text={fields.phone.help_text || phoneErrorHandler.error && phoneErrorHandler.errorMessageCurrentField[1]}
                                    state={"active"}
                                    register={register}
                                    input_type={fields.phone.input_type}
                                    name={"phone"}
                                    label={fields.phone.label}
                                    defaultValue={currentBranch ? currentBranch.phone : fields.phone.initial_value}
                                    resetForm={reset}
                                    {...reset}
                                />
                                <Inputs
                                    error={emailErrorHandler.error}
                                    onClick={emailErrorHandler.onFieldClick}
                                    help_text={fields.email.help_text || emailErrorHandler.error && emailErrorHandler.errorMessageCurrentField[1]}
                                    state={"active"}
                                    register={register}
                                    input_type={'email'}
                                    name={"email"}
                                    label={fields.email.label}
                                    defaultValue={currentBranch ? currentBranch.email : fields.email.initial_value}
                                    resetForm={reset}
                                    {...reset}
                                />
                                <Inputs
                                    error={sityErrorHandler.error}
                                    help_text={fields.sity.help_text || sityErrorHandler.error && sityErrorHandler.errorMessageCurrentField[1]}
                                    onClick={sityErrorHandler.onFieldClick}
                                    state={"active"}
                                    register={register}
                                    input_type={fields.sity.input_type}
                                    name={"sity"}
                                    label={fields.sity.label}
                                    defaultValue={currentBranch ? currentBranch.sity : fields.sity.initial_value}
                                    resetForm={reset}
                                    {...reset}
                                />
                                <Inputs
                                    error={streetErrorHandler.error}
                                    help_text={fields.street.help_text || streetErrorHandler.error && streetErrorHandler.errorMessageCurrentField[1]}
                                    onClick={streetErrorHandler.onFieldClick}
                                    state={"active"}
                                    register={register}
                                    name={"street"}
                                    input_type={fields.street.input_type}
                                    label={fields.street.label}
                                    defaultValue={currentBranch ? currentBranch.street : fields.street.initial_value}
                                    resetForm={reset}
                                    {...reset}
                                />
                                <Inputs
                                    error={streetNumberErrorHandler.error}
                                    help_text={fields.street_number.help_text || streetNumberErrorHandler.error && streetNumberErrorHandler.errorMessageCurrentField[1]}
                                    onClick={streetNumberErrorHandler.onFieldClick}
                                    state={"active"}
                                    register={register}
                                    name={"street_number"}
                                    input_type={fields.street_number.input_type}
                                    label={fields.street_number.label}
                                    defaultValue={currentBranch ? currentBranch.street_number : fields.street_number.initial_value}
                                    resetForm={reset}
                                    {...reset}
                                />
                            </div>
                            <label className="add-branch__inputs-label">Setting</label>
                            <div className="add-branch__inputs-setting">
                                <Inputs
                                    error={zipCodeErrorHandler.error}
                                    help_text={fields.zip_code.help_text || zipCodeErrorHandler.error && zipCodeErrorHandler.errorMessageCurrentField[1]}
                                    onClick={zipCodeErrorHandler.onFieldClick}
                                    state={"active"}
                                    register={register}
                                    name={"zip_code"}
                                    input_type={fields.zip_code.input_type}
                                    label={fields.zip_code.label}
                                    defaultValue={currentBranch ? currentBranch.zip_code : fields.zip_code.initial_value}
                                    resetForm={reset}
                                    {...reset}
                                />
                                <Inputs
                                    error={whatsappErrorHandler.error}
                                    help_text={fields.whatsapp.help_text || whatsappErrorHandler.error && whatsappErrorHandler.errorMessageCurrentField[1]}
                                    onClick={whatsappErrorHandler.onFieldClick}
                                    state={"active"}
                                    register={register}
                                    input_type={fields.whatsapp.input_type}
                                    name={"whatsapp"}
                                    label={fields.whatsapp.label}
                                    defaultValue={currentBranch ? currentBranch.whatsapp : fields.whatsapp.initial_value}
                                    resetForm={reset}
                                    {...reset}
                                />

                                <ReactSelect
                                    name="concurrent_records"
                                    error={concurrentRecordsErrorHandler.error}
                                    help_text={concurrentRecordsErrorHandler.error && concurrentRecordsErrorHandler.errorMessageCurrentField[1]}
                                    label={fields.concurrent_records.label}
                                    placeholder={fields.concurrent_records.label}
                                    onClick={concurrentRecordsErrorHandler.onFieldClick}
                                    control={control}
                                    defaultValue={currentBranch &&
                                        defaultChoicesSelect(valueOptionConcurrentRecords, labelOptionConcurrentRecords)[0]}
                                    isMulti={false}
                                    options={choicesConcurrentRecords && Object.entries(fields.concurrent_records.choices).map((b: any) => newOption(b[0], b[1]))}
                                />
                            </div>
                            <Textarea
                                error={emailCancelTextErrorHandler.error}
                                onClick={emailCancelTextErrorHandler.onFieldClick}
                                state={"active"}
                                register={register}
                                input_type={fields.email_cancel_text.input_type}
                                name={"email_cancel_text"}
                                label={fields.email_cancel_text.label}
                                defaultValue={currentBranch ? currentBranch.email_cancel_text : fields.email_cancel_text.initial_value}
                                help_text={fields.email_cancel_text.help_text}
                                resetForm={reset}
                                {...reset}
                            />
                            <Textarea
                                error={smsCancelTextErrorHandler.error}
                                onClick={smsCancelTextErrorHandler.onFieldClick}
                                state={"active"}
                                register={register}
                                input_type={fields.sms_cancel_text.input_type}
                                name={"sms_cancel_text"}
                                label={fields.sms_cancel_text.label}
                                defaultValue={currentBranch ? currentBranch.sms_cancel_text : fields.sms_cancel_text.initial_value}
                                help_text={fields.sms_cancel_text.help_text}
                                resetForm={reset}
                                {...reset}
                            />
                            <Textarea
                                error={emailOrderTextErrorHandler.error}
                                onClick={emailOrderTextErrorHandler.onFieldClick}
                                state={"active"}
                                register={register}
                                input_type={fields.email_order_text.input_type}
                                name={"email_order_text"}
                                label={fields.email_order_text.label}
                                defaultValue={currentBranch ? currentBranch.email_order_text : fields.email_order_text.initial_value}
                                help_text={fields.email_order_text.help_text}
                                resetForm={reset}
                                {...reset}
                            />
                            <Textarea
                                error={smsOrderTextErrorHandler.error}
                                onClick={smsOrderTextErrorHandler.onFieldClick}
                                state={"active"}
                                register={register}
                                input_type={fields.sms_order_text.input_type}
                                name={"sms_order_text"}
                                label={fields.sms_order_text.label}
                                defaultValue={currentBranch ? currentBranch.sms_order_text : fields.sms_order_text.initial_value}
                                help_text={fields.sms_order_text.help_text}
                                resetForm={reset}
                                {...reset}
                            />
                            <Textarea
                                error={emailRemindTextErrorHandler.error}
                                onClick={emailRemindTextErrorHandler.onFieldClick}
                                state={"active"}
                                register={register}
                                input_type={fields.email_remind_text.input_type}
                                name={"email_remind_text"}
                                label={fields.email_remind_text.label}
                                defaultValue={currentBranch ? currentBranch.email_remind_text : fields.email_remind_text.initial_value}
                                help_text={fields.email_remind_text.help_text}
                                resetForm={reset}
                                {...reset}
                            />
                            <Textarea
                                error={smsRemindTextErrorHandler.error}
                                onClick={smsRemindTextErrorHandler.onFieldClick}
                                state={"active"}
                                register={register}
                                input_type={fields.sms_remind_text.input_type}
                                name={"sms_remind_text"}
                                label={fields.sms_remind_text.label}
                                defaultValue={currentBranch ? currentBranch.sms_remind_text : fields.sms_remind_text.initial_value}
                                help_text={fields.sms_remind_text.help_text}
                                resetForm={reset}
                                {...reset}
                            />
                            <CheckboxInput
                                id="free_date"
                                state={"square"}
                                input_type={fields.free_date.input_type}
                                name={"free_date"}
                                label={fields.free_date.label}
                                defaultChecked={currentBranch ? currentBranch.free_date : fields.free_date.initial_value}
                                help_text={fields.free_date.help_text}
                                register={register} />
                            <CheckboxInput
                                id="show_title"
                                state={"square"}
                                input_type={fields.show_title.input_type}
                                name={"show_title"}
                                label={fields.show_title.label}
                                defaultChecked={currentBranch ? currentBranch.show_title : fields.show_title.initial_value}
                                help_text={fields.show_title.help_text}
                                register={register} />
                            <CheckboxInput
                                id="desired_date"
                                state={"square"}
                                input_type={fields.desired_date.input_type}
                                name={"desired_date"}
                                label={fields.desired_date.label}
                                defaultChecked={currentBranch ? currentBranch.desired_date : fields.desired_date.initial_value}
                                help_text={fields.desired_date.help_text}
                                register={register} />
                            <label
                                className="add-branch__inputs-label">{fields.employees.label}</label>
                            <div className="add-branch__inputs-setting">
                                <ReactSelect
                                    name="employees"
                                    error={employeesErrorHandler.error}
                                    help_text={fields.employees.help_text && employeesErrorHandler.error && employeesErrorHandler.errorMessageCurrentField[1]}
                                    label={fields.employees.label}
                                    placeholder={fields.employees.label}
                                    onClick={employeesErrorHandler.onFieldClick}
                                    control={control}
                                    isMulti={true}
                                    options={choicesEmployee && Object.entries(fields.employees.choices).map((b: any) => newOption(b[0], b[1]))}
                                />
                            </div>
                            <div>
                                <button
                                    className='button button_size-middle button_position-right button_color-black'>Sichern
                                </button>
                            </div>
                            {
                                (status === 'failed' && errorGeneral) &&
                                <p className="general-error">{errorGeneral}</p>
                            }
                        </form>
                    </div>
                )}
            </div>
        </>
    )
}