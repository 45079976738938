import { Dispatch } from "redux";
import { loadToken } from "../utils/localStorage";
import {
  EventDetailType,
  eventsAPI,
  EventsLinksType,
  EventsResponseData,
  EventsType,
} from "../api/events-api";
import { setAppStatusAC } from "./app-reducer";
import { AppRootStateType } from "./store";
import { handleServerAppError, handleServerNetworkError } from "../utils/error-utils";
import { EventType } from "react-hook-form";
import { fetchWaitingRoomListTC } from "./waitingRoom-reducer";

const SET_EVENTS_LIST = "eventsReducer/SET_EVENTS_LIST";
const SET_EVENTS_PARAMS = "eventsReducer/SET_EVENTS_PARAMS";
const ADD_EVENT = "eventsReducer/ADD_EVENT";
const EDIT_EVENT = "eventsReducer/EDIT_EVENT";
const REMOVE_EVENT = "eventsReducer/REMOVE_EVENT";
const SET_CURRENT_ORDERING = "eventsReducer/SET_CURRENT_ORDERING";
const SET_CURRENT_PAGE = "eventsReducer/SET_CURRENT_PAGE";
const SET_CURRENT_EVENT_DETAILS = "eventsReducer/SET_CURRENT_EVENT_DETAILS";
const CANCEL_ORDER_EVENT = "eventsReducer/CANCEL_ORDER_EVENT";
const SET_CHOSEN_DAY_SCHEDULE = "eventsReducer/SET_CHOSEN_DAY_SCHEDULE";
const CHAHGE_STATUS = "eventsReducer/CHAHGE_STATUS";
const CHAHGE_STATUS_DONE = "eventsReducer/CHAHGE_STATUS_DONE";
const CHAHGE_STATUS_NOT = "eventsReducer/CHAHGE_STATUS_NOT";
const CHAHGE_STATUS_DOCTOR = "eventsReducer/CHAHGE_STATUS_DOCTOR";

const initialState: InitialStateType = {
  chosenDayInSchedule: null,
  currentOrdering: null,
  currentPage: null,
  currentDetails: {} as EventDetailType,
  events: {
    links: {
      next: null,
      previous: null,
    },
    count: 0,
    current_page: 0,
    lastPage: 0,
    current: 0,
    results: [] as Array<EventsType>,
  } as EventsResponseData
};

type InitialStateType = {
  chosenDayInSchedule: ChosenDayInScheduleType | null
  currentOrdering: string | null
  currentPage: string | null
  currentDetails: EventDetailType
  events: EventsResponseData
}

export const eventsReducer = (
  state: InitialStateType = initialState,
  action: ActionsType
): InitialStateType => {
  switch (action.type) {
    case SET_EVENTS_LIST: {
      return {
        ...state,
        events: { ...state.events, results: action.events.map((tl) => ({ ...tl })) }
      };
    }

    case SET_EVENTS_PARAMS: {
      return { ...state, events: { ...state.events, ...action.module } };
    }

    case ADD_EVENT: {
      return {
        ...state,
        events: { ...state.events, results: [...state.events.results, action.event] }
      };
    }

    case EDIT_EVENT: {
      return {
        ...state,
        events:
        {
          ...state.events, results: state.events.results.map((e) =>
            e.id === action.model.id ? { ...e, ...action.model } : e
          ),
        }
      };
    }

    case REMOVE_EVENT: {
      return {
        ...state,
        events:
        {
          ...state.events,
          results: state.events.results.filter((e) => e.id !== action.id),
        }
      };
    }
    case CHAHGE_STATUS: {
      return {
        ...state,
        events:
        {
    
          ...state.events, results: state.events.results.map((e) =>
            e.id === action.event.id ? { ...e, ...action.event } : e
          ),
        }
      };
      
    }

    case CHAHGE_STATUS_NOT: {
      return {
        ...state,
        events:
        {
          /*  ...state.events, results: state.events.results.map((e) =>
             e.id === action.id ? { ...e, status: action.status } : e */
          ...state.events, results: state.events.results.map((e) =>
            e.id === action.event.id ? { ...e, ...action.event } : e
          ),
        }
      };
    }
    case CHAHGE_STATUS_DOCTOR: {
      return {
        ...state,
        events:
        {
          /*  ...state.events, results: state.events.results.map((e) =>
             e.id === action.id ? { ...e, status: action.status } : e */
          ...state.events, results: state.events.results.map((e) =>
            e.id === action.event.id ? { ...e, ...action.event } : e
          ),
        }
      };
    }

    case CHAHGE_STATUS_DONE: {
      return {
        ...state,
        events:
        {
          /*  ...state.events, results: state.events.results.map((e) =>
             e.id === action.id ? { ...e, status: action.status } : e */
          ...state.events, results: state.events.results.map((e) =>
            e.id === action.event.id ? { ...e, ...action.event } : e
          ),
        }
      };
    }

    case SET_CURRENT_ORDERING: {
      return { ...state, currentOrdering: action.ordering }
    }

    case SET_CURRENT_PAGE: {
      return { ...state, currentPage: action.page }
    }

    case SET_CURRENT_EVENT_DETAILS: {
      return { ...state, currentDetails: action.details }
    }

    case CANCEL_ORDER_EVENT: {
      return {
        ...state,
        events:
        {
          ...state.events, results: state.events.results.map((e) =>
            e.id === action.event.id ? { ...e, ...action.event } : e
          ),
        }
      };
    }

    case SET_CHOSEN_DAY_SCHEDULE: {
      return { ...state, chosenDayInSchedule: action.day }
    }

    default:
      return state;
  }
};

// actions
export const setEventsListAC = (events: Array<EventsType>) =>
  ({ type: SET_EVENTS_LIST, events } as const);
export const setEventsParamsAC = (module: EventsParamsType) =>
  ({ type: SET_EVENTS_PARAMS, module } as const);
export const addEventAC = (event: EventsType) =>
  ({ type: ADD_EVENT, event } as const);
export const editEventAC = (model: UpdateEventModelType) =>
  ({ type: EDIT_EVENT, model } as const);
export const changeStatusAC = (event: EventsType) =>
  ({ type: CHAHGE_STATUS, event } as const);
export const changeStatusDoneAC = (event: EventsType) =>
  ({ type: CHAHGE_STATUS_DONE, event } as const);
export const changeStatusNotAC = (event: EventsType) =>
  ({ type: CHAHGE_STATUS_NOT, event } as const);
export const changeStatusDoctorAC = (event: EventsType) =>
  ({ type: CHAHGE_STATUS_DOCTOR, event } as const);
export const removeEventAC = (id: number) =>
  ({ type: REMOVE_EVENT, id } as const);
export const setCurrentOrderingsAC = (ordering: string | null) =>
  ({ type: SET_CURRENT_ORDERING, ordering } as const);
export const setCurrentPageAC = (page: string | null) =>
  ({ type: SET_CURRENT_PAGE, page } as const);
export const setCurrentEventDetailsAC = (details: EventDetailType) =>
  ({ type: SET_CURRENT_EVENT_DETAILS, details } as const);
export const cancelOrderEventsAC = (event: EventsType) =>
  ({ type: CANCEL_ORDER_EVENT, event } as const);
export const setChosenDayInScheduleAC = (day: any) =>
  ({ type: SET_CHOSEN_DAY_SCHEDULE, day } as const);

// thunks
export const fetchEventsList = () => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC("loading"));
  try {
    const tokenLS = await loadToken();
    const res = await eventsAPI.getList(tokenLS);
    dispatch(setEventsParamsAC(res.data));
    dispatch(setEventsListAC(res.data.results));
    dispatch(setAppStatusAC("succeeded"));
  } catch (error: any) {
    if (error.message === "Network Error") {
      handleServerNetworkError(error, dispatch)
    } else {
      handleServerAppError(error, dispatch)
    }
  }
  dispatch(setAppStatusAC("idle"))
};


export const fetchSortEventsList =
  (ordering: string | null) => async (dispatch: Dispatch<any>) => {
    dispatch(setAppStatusAC("loading"));
    try {
      const tokenLS = await loadToken();
      const res = await eventsAPI.getSortList(tokenLS, ordering);
      dispatch(setEventsParamsAC(res.data));
      dispatch(setEventsListAC(res.data.results));
      dispatch(setAppStatusAC("succeeded"));
      dispatch(setCurrentOrderingsAC(ordering))
    } catch (error: any) {
      if (error.message === "Network Error") {
        handleServerNetworkError(error, dispatch)
      } else {
        handleServerAppError(error, dispatch)
      }
    }
    dispatch(setAppStatusAC("idle"))
  };

export const addEventTC =
  (data: any, branch: number) => async (dispatch: Dispatch<any>) => {
    try {
      const tokenLS = await loadToken();
      const res = await eventsAPI.addEvent(data, tokenLS, branch);
      dispatch(addEventAC(res.data));
      dispatch(setAppStatusAC("succeeded"));
    } catch (error: any) {
      if (error.message === "Network Error") {
        handleServerNetworkError(error, dispatch)
      } else {
        handleServerAppError(error, dispatch)
      }
    }
    // dispatch(setAppStatusAC("idle"))
  };
export const editEventTC =
  (id: number, data: any) => async (dispatch: Dispatch<any>) => {
    try {
      const tokenLS = await loadToken();
      const res = await eventsAPI.editEvent(data, tokenLS, id);
      dispatch(editEventAC(res.data));
      dispatch(setAppStatusAC("succeeded"));
    } catch (error: any) {
      if (error.message === "Network Error") {
        handleServerNetworkError(error, dispatch)
      } else {
        handleServerAppError(error, dispatch)
      }
    }
    // dispatch(setAppStatusAC("idle"))
  };

export const changeStatusTC =
  (eventId: number) => async (dispatch: Dispatch<any>) => {
    try {
      const tokenLS = await loadToken();
      const res = await eventsAPI.changeStatus(tokenLS, eventId);
      dispatch(changeStatusAC(res.data));
      dispatch(fetchEventsList())


      dispatch(setAppStatusAC("succeeded"));
    } catch (error: any) {
      if (error.message === "Network Error") {
        handleServerNetworkError(error, dispatch)
      } else {
        handleServerAppError(error, dispatch)
      }
    }
    // dispatch(setAppStatusAC("idle"))
  };

export const changeStatusDoneTC =
  (eventId: number) => async (dispatch: Dispatch<any>) => {
    try {
      const tokenLS = await loadToken();
      const res = await eventsAPI.changeStatusOnDone(tokenLS, eventId);

      dispatch(changeStatusDoneAC(res.data));
      dispatch(fetchEventsList())
      dispatch(setAppStatusAC("succeeded"));
    } catch (error: any) {
      if (error.message === "Network Error") {
        handleServerNetworkError(error, dispatch)
      } else {
        handleServerAppError(error, dispatch)
      }
    }
    dispatch(setAppStatusAC("idle"))
  };
export const changeStatusNotTC =
  (eventId: number) => async (dispatch: Dispatch<any>) => {
    try {
      const tokenLS = await loadToken();
      const res = await eventsAPI.changeStatusNotWait(tokenLS, eventId);
      dispatch(changeStatusDoneAC(res.data));
      dispatch(fetchEventsList())
      dispatch(setAppStatusAC("succeeded"));
    } catch (error: any) {
      if (error.message === "Network Error") {
        handleServerNetworkError(error, dispatch)
      } else {
        handleServerAppError(error, dispatch)
      }
    }
    dispatch(setAppStatusAC("idle"))
  };
export const changeStatusDoctorTC =
  (eventId: number) => async (dispatch: Dispatch<any>) => {
    try {
      const tokenLS = await loadToken();
      const res = await eventsAPI.changeStatusAtDoctor(tokenLS, eventId);
      dispatch(changeStatusDoneAC(res.data));
      dispatch(fetchEventsList())
      dispatch(setAppStatusAC("succeeded"));
    } catch (error: any) {
      if (error.message === "Network Error") {
        handleServerNetworkError(error, dispatch)
      } else {
        handleServerAppError(error, dispatch)
      }
    }
    dispatch(setAppStatusAC("idle"))
  };
export const removeEventTC =
  (eventId: number) => async (dispatch: Dispatch<any>) => {
    try {
      const tokenLS = await loadToken();
      await eventsAPI.removeEvents(tokenLS, eventId);
      dispatch(removeEventAC(eventId));
      fetchEventsList();
    } catch (error: any) {
      if (error.message === "Network Error") {
        handleServerNetworkError(error, dispatch)
      } else {
        handleServerAppError(error, dispatch)
      }
    }
    dispatch(setAppStatusAC("idle"))
  };
export const getNextEventsList =
  () => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const next = getState().events.events.links.next;
    dispatch(setAppStatusAC("loading"));
    try {
      const tokenLS = await loadToken();
      const res = await eventsAPI.getNextList(tokenLS, next);
      // dispatch(setEventsParamsAC(res.data.count, res.data.next, res.data.previous))
      dispatch(setEventsParamsAC(res.data));
      dispatch(setEventsListAC(res.data.results));
      dispatch(setAppStatusAC("succeeded"));
      dispatch(setCurrentPageAC(next))
    } catch (error: any) {
      if (error.message === "Network Error") {
        handleServerNetworkError(error, dispatch)
      } else {
        handleServerAppError(error, dispatch)
      }
    }
    dispatch(setAppStatusAC("idle"))
  };

export const getPrevEventsList =
  () => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const prev = getState().events.events.links.previous;
    dispatch(setAppStatusAC("loading"));
    try {
      const tokenLS = await loadToken();
      const res = await eventsAPI.getPrevList(tokenLS, prev);
      // dispatch(setEventsParamsAC(res.data.count, res.data.next, res.data.previous))
      dispatch(setEventsParamsAC(res.data));
      dispatch(setEventsListAC(res.data.results));
      dispatch(setAppStatusAC("succeeded"));
      dispatch(setCurrentPageAC(prev))
    } catch (error: any) {
      if (error.message === "Network Error") {
        handleServerNetworkError(error, dispatch)
      } else {
        handleServerAppError(error, dispatch)
      }
    }
    dispatch(setAppStatusAC("idle"))
  };

export const getCurrentEventsList =
  () => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
    const curr = getState().events.currentPage;
    dispatch(setAppStatusAC("loading"));
    try {
      const tokenLS = await loadToken();
      const res = await eventsAPI.getNextList(tokenLS, curr);
      // dispatch(setEventsParamsAC(res.data.count, res.data.next, res.data.previous))
      dispatch(setEventsParamsAC(res.data));
      dispatch(setEventsListAC(res.data.results));
      dispatch(setAppStatusAC("succeeded"));
      dispatch(setCurrentPageAC(curr))
    } catch (error: any) {
      if (error.message === "Network Error") {
        handleServerNetworkError(error, dispatch)
      } else {
        handleServerAppError(error, dispatch)
      }
    }
    dispatch(setAppStatusAC("idle"))
  };

export const fetchEventDetailsTC = (eventId: number) => async (dispatch: Dispatch<any>) => {
  dispatch(setAppStatusAC("loading"));
  try {
    const tokenLS = await loadToken();
    const res = await eventsAPI.getEventDetails(tokenLS, eventId);
    dispatch(setCurrentEventDetailsAC(res.data))
    dispatch(setAppStatusAC("succeeded"));
  } catch (error: any) {
    if (error.message === "Network Error") {
      handleServerNetworkError(error, dispatch)
    } else {
      handleServerAppError(error, dispatch)
    }
  }
  // dispatch(setAppStatusAC("idle"))
};

export const cancelOrderEventTC = (uuid_field: string) => async (dispatch: Dispatch<any>) => {
  // dispatch(setAppStatusAC("loading"));
  try {
    const tokenLS = await loadToken();
    const res = await eventsAPI.cancelOrderEvent(tokenLS, uuid_field);
    dispatch(cancelOrderEventsAC(res.data))
    dispatch(setAppStatusAC("succeeded"));
  } catch (error: any) {
    if (error.message === "Network Error") {
      handleServerNetworkError(error, dispatch)
    } else {
      handleServerAppError(error, dispatch)
    }
  }
  dispatch(setAppStatusAC("idle"))
};

// export const setChosenDayInScheduleTC =
//     () => async (dispatch: Dispatch<any>, getState: () => AppRootStateType) => {
//       const date = getState().events.chosenDayInSchedule.date;
//       const time = getState().events.chosenDayInSchedule.time;
//       // console.log({date, time})
//       try {
//         dispatch(setChosenDayInScheduleAC({date, time}))
//
//       } catch (e) {
//
//       }
//     }

//types
type ActionsType =
  | ReturnType<typeof setEventsListAC>
  | ReturnType<typeof removeEventAC>
  | ReturnType<typeof editEventAC>
  | ReturnType<typeof addEventAC>
  | ReturnType<typeof setEventsParamsAC>
  | ReturnType<typeof setCurrentOrderingsAC>
  | ReturnType<typeof setCurrentPageAC>
  | ReturnType<typeof setCurrentEventDetailsAC>
  | ReturnType<typeof cancelOrderEventsAC>
  | ReturnType<typeof setChosenDayInScheduleAC>
  | ReturnType<typeof changeStatusAC>
  | ReturnType<typeof changeStatusDoneAC>
  | ReturnType<typeof changeStatusNotAC>
  | ReturnType<typeof changeStatusDoctorAC>
// | ReturnType<typeof setEventFilterParamsAC>

export type UpdateEventModelType = {
  id?: number;
  firstName?: string;
  lastName?: string;
  status?: any;
  start?: string;
  end?: string;
  email?: string;
  phone?: string;
  reason?: number;
  branch?: number;
  insurance_type?: string;
  client?: null;
  message?: string;
  gender?: number;
  date_birth?: string;
};

export type EventsParamsType = {
  links: EventsLinksType;
  count: number;
  current_page: number;
  lastPage: number;
  current: number;
};

export type ChosenDayInScheduleType = {
  date: string
  time: string
}
