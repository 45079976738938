import axios from "axios";
import { EventsType } from "./events-api";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
})

export const registrationAPI = {
    getClientWeek(branchId: number, reasonId: number, week: number) {
        return instance.get</*DataOfClientsType*/ any>(`/api/client/events/free/times/?branch=${branchId}&reason=${reasonId}&week=${week}`, {
        })
    },

    getClientWeekFirstRender(branchId: number, reasonId: number) {
        return instance.get<DataOfClientsType>(`/api/client/events/free/times/?branch=${branchId}&reason=${reasonId}`, {
        })
    },

    createClientEvent(data: any, branchId: number) {
        return instance.post<any>(`/api/client/events/create/?branch=${branchId}`, data, {
        }
        )
    },

    getClientBranchInfo(branchId: number) {
        return instance.get<DateInfoOfClientType>(`/api/branches/${branchId}/info/`, {
        })
    },
}

//types
export type RegistrationType = {
    start: string
    client: string
    status: string
    insurance_type: string
    branch: string
    reason: any
    gender: string
    firs_name: string
    last_name: string
    date_birth: any
    email: string
    phone: string
    message: string
    unfinished: string
    email_reminded: string
    sms_reminded: string

}

export type TimeSlotsType = {
    start: string
    end: string
    disable: string
    full_date: string

}

export type DatesSlotsType = {
    day_name: string
    date: string
    time_slots: Array<TimeSlotsType>


}

export type DataOfClientsType = {
    first_last_date_week: string
    next_week: number
    previous_week: number
    dates_slots: Array<DatesSlotsType>
    empty_slots: boolean
}

export type ReasonsInfoType = {
    branches: Array<number>
    color: string
    doctor: number
    doctor_display: string
    doctor_email: string
    doctor_phone: string
    doctor_photo: string
    id: number
    limit_time: number
    offset_time: number
    offset_time_display: string
    order: number
    title: string
}

export type WorkScheduleInfoType = {
    branch: number
    branch_display: string
    day_of_week: number
    day_of_week_display: string
    end_lunch_break: string
    end_time_work: string
    id: number
    is_weekend: boolean
    lunch_break: boolean
    start_lunch_break: string
    start_time_work: string
}

export type DateInfoOfClientType = {
    official: []
    private: []
    reasons: Array<ReasonsInfoType>
    workschedule: Array<WorkScheduleInfoType>
}
