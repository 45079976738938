import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, NavLink } from "react-router-dom";
import { PATH } from "../Routes";
import './Contacts.scss'
import {
    ModalAlertDelete
} from "../../components/ModalsAlerts/ModalAlertDelete";
import { fetchBranchesList } from "../../redux/branches-reducer";
import { ProfileSideBar } from "../Profile/ProfileSideBar/ProfileSideBar";
import { initializeAppTC } from "../../redux/app-reducer";
import Preloader from "../Preloader/Preloader";
import { fetchContactsList, removeContactTC } from "../../redux/contacts-reducer";
import {
    getContactsSelector,
    getIsLoggedInSelector,
    getStatusSelector
} from "../../redux/selectors";

export const Contacts = () => {
    const dispatch = useDispatch();

    const contacts = useSelector(getContactsSelector);
    const status = useSelector(getStatusSelector);
    const isLoggedIn = useSelector(getIsLoggedInSelector);

    //dialog alerts
    const [openAlertDialogForDelete, setOpenAlertDialogForDelete] = useState(false)
    const [modalDataId, setModalDataId] = useState<number>(0);

    useEffect(() => {
        if (status === "failed") {
            dispatch(initializeAppTC())
        }
    }, [status])

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchContactsList())
            dispatch(fetchBranchesList())
        }
    }, [dispatch])

    const removeContact = (id: number) => {
        dispatch(removeContactTC(id))
        setModalDataId(0)
    }

    const openAlertHandler = (employeeId: number) => {
        setModalDataId(employeeId)
        setOpenAlertDialogForDelete(true)
    }
    const closeAlertHandler = () => {
        setOpenAlertDialogForDelete(false)
    }

    if (!isLoggedIn) {
        return <Navigate to={PATH.LOGIN} />
    }

    return (
        <>
            <ProfileSideBar />
            <div className="employees">
                <h1 className="employees__title">Contacts </h1>
                <div className="employees__add">
                    <NavLink
                        className="button button_size-small button_position-left button_color-transparent"
                        to={PATH.ADD_CONTACTS}>+ add
                        contact</NavLink>
                </div>
                {status === 'loading' ? (
                    <Preloader />
                ) : (
                    <div>
                        <table className='employees__table'>
                            <thead className="thead">
                                <tr className="tr">
                                    <th className="th"><span>{'Title'}</span></th>
                                    <th className="th"><span>{'Telefon'}<br />{'E-Mail-Adresse'}</span></th>
                                    <th className="th"><span>{'Address'}</span></th>
                                    <th className="th"><span>{'Optionen'}</span></th>
                                </tr>
                            </thead>
                            <tbody className="tbody">
                                {contacts.map(contact => <tr key={contact.id} className="tr">
                                    <td className="td">{contact.title}</td>
                                    <td className="td">{contact.phone}<br />{contact.email}</td>
                                    <td className="td">{contact.address}</td>
                                    <td className="td">
                                        <div className="btns">
                                            <NavLink
                                                className="button button_size-small  button_color-green"
                                                to={`${PATH.CONTACTS}${contact.id}/detail/`}>Edit</NavLink>

                                            <button onClick={() => openAlertHandler(contact.id)}
                                                className="button button_size-small  button_color-delete"
                                            >Delete
                                            </button>
                                        </div>
                                    </td>
                                    {openAlertDialogForDelete &&
                                        <>
                                            <ModalAlertDelete deleteCallback={removeContact}
                                                id={modalDataId}
                                                titleQuestion="Delete reason?"
                                                actionTitle="Delete"
                                                cancelCallback={closeAlertHandler} />
                                        </>
                                    }
                                </tr>)
                                }
                            </tbody>
                        </table>

                    </div>
                )
                }
            </div>
        </>
    )
}
